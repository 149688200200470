/* eslint-disable no-use-before-define */
import React, {useEffect, useState, useCallback} from 'react';
import Axios from 'axios' 
import urlLinks from '../../urlLinks'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import locationIds from './locationIds'
export default function LocationFilter(props) {
  const {filters, setFilters, id , label}= props
  return (
    <Autocomplete
      multiple
      id={id}
      options={locationIds.sort((a, b) => -b.state_name.localeCompare(a.state_name))}
      groupBy={(option) => option.state_name}
      value={filters[id]}
      onChange={(event, value) => setFilters({...filters, [id]: value})}
      getOptionLabel={(option) => option.city_name}
      style={{ width: "250px" }}
      renderInput={(params) => <TextField {...params} label={label} className="marginLeft-0" />}
    />
  );
}



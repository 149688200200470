import React, {useMemo, useState, useEffect} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Grid,List, Button } from '@material-ui/core/';
import Table from '../../../utils/react-table/table'
const FormSuccess = () => {
    const history= useHistory();

    const location= useLocation();
    const [recordsRejected, setRecordsRejected] = useState(0)
    const [responseData, setResponseData] = useState()
    console.log({history, location});
    const columns = useMemo(
      () => [
        {
          Header: "First Name",
          accessor: "firstName",
          // Cell: row => {
          //     return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
          defaultCanFilter :false,
          defaultCanSort:false
        },
        {
          Header: "Last name",
          accessor: "lastName",
          // Cell: row => {
          //     return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
          defaultCanFilter :false,
          defaultCanSort:false
        },
        {
          Header: "Phone no.",
          accessor: "Mobile No",
          defaultCanFilter :false,      //needed filter 
          defaultCanSort:false
        }
      ], 
      []
    );
    const columnsResponse = useMemo(
      () => [
        {
          Header: "First Name",
          accessor: "firstName",
          // Cell: row => {
          //     return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
          defaultCanFilter :false,
          defaultCanSort:false
        },
        {
          Header: "Last name",
          accessor: "lastName",
          // Cell: row => {
          //     return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
          defaultCanFilter :false,
          defaultCanSort:false
        },
        {
          Header: "Phone no.",
          accessor: "phoneNumber",
          defaultCanFilter :false,      //needed filter 
          defaultCanSort:false
        },
        {
          Header: "Status",
          accessor: "status",
          defaultCanFilter :false,      //needed filter 
          defaultCanSort:false
        }

      ], 
      []
    );
  const makeResponseData =() =>{
    let dataArray =[]
    location.data.responseData.map(el =>{
      if(el.status==="EXISTING_EMPLOYER" || el.status==="EXISTING_EXT_WORKER")
        dataArray.push({
          firstName: el.firstName,
          lastName: el.lastName?el.lastName:'',
          phoneNumber: el.phoneNumber,
          status: el.status==="EXISTING_EMPLOYER"?"Exist as employer":el.status==="EXISTING_EXT_WORKER"?"Worker already Exists":''
        })
    })
    setRecordsRejected(dataArray.length)
    setResponseData(dataArray);
  }
  useEffect(() => {
    if(location.data)
      makeResponseData()
  }, [])
  const incorrectData =location.data?location.data.incorrectData?location.data.incorrectData:[]:[]
  
  const failedRecords=incorrectData.length>0?` and ${incorrectData.length} ${incorrectData.length>1?"records":"record"} failed.`:'.'
  return (
    <div className="postJob">
      {(location.data && location.data.code ===1000 && incorrectData.length===0 )?
            <h1>Excel uploaded Successfully</h1>:
            (location.data && location.data.code ===1000 && incorrectData.length>0 )?
                <h1>Excel uploaded Successfully without some foul records. Please upload these records again after fixing them.</h1>:<h1>Excel upload failed</h1>
      }
      {(location.data && location.data.code ===1000 )?
        <h3>{`${location.data.dataLength- recordsRejected} ${location.data.dataLength>1?"records":"record"} uploaded${failedRecords}`}</h3>:null}
  

        <Button 
        color="primary"
        variant="contained"
        style={{textAlign: "center"}}
        onClick={()=> history.push("/worker/externalWorkers")}
        >
        Go to External worker data
        </Button>
        {incorrectData && incorrectData.length>0 && <Grid container className="padding20">
            <Grid item xs={12}  className="padding20">
              <span className="fontSize16 fontWeightBold">Data did not upload </span>
            </Grid>
            <Table  
              columns={columns}
              data={incorrectData}
              isPagination={false}
            />
          </Grid>
        }
        {responseData && responseData.length>0 && <Grid container className="padding20">
            <Grid item xs={12}  className="padding20">
              <span className="fontSize16 fontWeightBold">{`${recordsRejected} records rejected by Server`}</span>
            </Grid>
            <Table  
              columns={columnsResponse}
              data={responseData}
              isPagination={false}
            />
          </Grid>
        }
    </div>
  )
}

export default FormSuccess;
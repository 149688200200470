import React, { useState, useContext, useEffect } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { AccountContext } from '../_services/user.account';
import './Login.css'
import Axios from 'axios';
import urlLinks from '../urlLinks'

function LoginForm() {

    const [rememberMe, setRememberMe] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const { authenticate, getSession, getUser } = useContext(AccountContext);

    useEffect(() => {
        if (localStorage.checkbox && localStorage.username !== "") {

                setRememberMe(true);
                setEmail(localStorage.username);
                setPassword(localStorage.password);
        }
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userId');
        localStorage.removeItem('emailId');
        // localStorage.username = email
        // localStorage.password = password
        // localStorage.checkbox = rememberMe
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorMessage("");
        const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (email == null || email == "") {
            setErrorMessage("Please enter the email id.");
        } else if (!emailRegex.test(email)) {
            setErrorMessage("Please enter valid email address.");
        }
        else if (password == null || password == "") {
            setErrorMessage("Please enter the password.");
        }
        else {
            authenticate(email, password)
                .then(data => {
                    getSession()
                        .then(session => {
                            localStorage.clear();

                            if (rememberMe) {
                                localStorage.username = email
                                localStorage.password = password
                                localStorage.checkbox = rememberMe
                            }


                            getUser(session.email).then(data => {
                                localStorage.setItem('userInfo', JSON.stringify(data.response.content[0]));
                                localStorage.setItem('userId', data.response.content[0].userId);
                                localStorage.setItem('emailId', data.response.content[0].emailId);

                                Axios.get(`${urlLinks.baseUrl}/user/?role_types=1,2,5&rows=1400`)
                                    .then(response2 => {
                                        if (response2.data.response.content.length > 0) {
                                            const allAdmins = {};
                                            response2.data.response.content.map(el => {
                                                allAdmins[el.userId] = `${el.firstName}${el.lastName ? " " + el.lastName : ''}`
                                            })
                                            localStorage.setItem('allAdmins', JSON.stringify(allAdmins));
                                        }
                                    })
                                switch (data.response.content[0].recruiterStatus) {
                                    case ("Active").toUpperCase(): history.push({
                                        pathname: "/dashboard",
                                        state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
                                    });
                                    break;
                                    case ("INCOMPLETE REGISTRATION").toUpperCase(): history.push({
                                        pathname: "/create-account",
                                        state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
                                    });
                                    break;
                                    default: history.push({
                                        pathname: "/success",
                                        state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
                                    });
                                        break;
                                }

                            }).catch(err => {
                                console.log('notLogged');
                            });

                        });

                }).catch(err => {
                    console.log(err);
                    setErrorMessage(err.message);
                })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container className="loginFormContainer">
                <Grid item xs={12} className="padding0" >
                    <span className="fontSize32 fontWeightBold">Login</span>
                </Grid>
                {errorMessage ?
                    <Grid item xs={12} className="padding0" >
                        <Alert variant="filled" severity="error" className="marginTop10">
                            {errorMessage}
                        </Alert> </Grid> : ''}
                <Grid item xs={12} className="padding0" >
                    <Grid item xs={12} className="padding0 marginTop35" >
                        <span className="fontSize18 fontWeightBold">Email ID</span>
                    </Grid>
                    <Grid item xs={12} className="padding0 marginTop20" >
                        <TextField
                            name="email"
                            fullwidth
                            className={`margin0`}
                            variant="outlined"
                            placeholder="you@example.com"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="padding0 displayFlex marginTop20"  >
                    <Grid item xs={6} className="padding0" >
                        <span className="fontSize18 fontWeightBold">Password</span>
                    </Grid>
                    <Grid item xs={6} className="padding0 textAlignEnd" >

                        <Link
                            className="forgotPassword"
                            to="/forgot-password"
                        >Forgot Password?</Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="padding0 marginTop20" >
                    <TextField
                        name="password"
                        fullwidth
                        variant="outlined"
                        className={` Rectangle-995`}
                        placeholder="Enter your password"
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                </Grid>

                <Grid item xs={12} className="padding0 marginTop20" >
                    <FormControlLabel
                        control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} name="rememberMe" color="primary" />}
                        label="Remember Me"
                    />
                </Grid>
                <Grid item xs={12} className="padding0 marginTop35 textAlignCenter" >
                    <Button
                        type="submit"
                        className="LoginButton"
                    >Login</Button>
                </Grid>

                <Grid item xs={12} className="padding0 marginTop35 textAlignCenter" >
                    <span className="fontSize20">Not a member yet? &nbsp;&nbsp;</span>
                    <Link
                        className="createAccount"
                        to="/register"
                    >Create account</Link>
                </Grid>
            </Grid>
        </form>
    );
}

export default LoginForm

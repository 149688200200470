import React, { useState, useEffect } from 'react'
import { Grid , Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectVacancy from './SelectVacancyPage'
import { useHistory } from "react-router-dom";
import Axios from 'axios'
import urlLinks from '../../urlLinks'
import {experience, ageBracket, weekTodays, setqualification, setcuisine, setgender} from "../Job/JobList/newJobViewComponents/jobRequirement"
import * as moment from 'moment'
import VacancyRecruiterStatus from './VacancyRecruiterStatus';
import ActiveApplications from './ApplicationList'
import JobModal from '../../utils/Modals/jobModal'
const RecruiterDashboard =() =>{
    const history= useHistory();
    const [jobList, setJobList] = useState([])
    const [propRows, setPropRows] = useState(history?.location?.recruiterDashBoardData?.perPage)
    const [pageNo, setPageNo] = useState(history?.location?.recruiterDashBoardData?.pageNo)
    const [jobChanged, setJobChanged] = useState(false)
    const [jobValue, setJobValue] = useState(history?.location?.recruiterDashBoardData?.jobValue?history?.location?.recruiterDashBoardData?.jobValue:undefined)  
    const [jobDetails, setJobDetails] = useState()
    const [quesList, setQuesList] = useState(0)
    const [vacancyStatusData, setVacancyStatusData] = useState()
    const [recruiterList, setRecruiterList] = useState()
    const [recruiterData, setRecruiterData] = useState((localStorage.getItem('roleType')!== "Super Admin" || localStorage.getItem('roleType')!== "Operational Admin")?{userID: localStorage.getItem('userId')}: history?.location?.recruiterDashBoardData?.recruiterId?{userID: history?.location?.recruiterDashBoardData?.recruiterId}:undefined)
    const [openJobModal, setOpenJobModal] = useState(false)
    const [disableQuestionnaireButton, setDisableQuestionnaireButton] = useState(1)
    console.log({history})
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                jobDetailId: el.jobDetailId,
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:''
            })
        })
        return list;
    }
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    useEffect(()=>{
        setDisableQuestionnaireButton(0)
        console.log("function called",{ jobValue});
        if(jobValue){

            Axios.get(`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${jobValue.jobId}`)
            .then(response=>{
                let disable= response.data.length
                console.log({disable})
                setQuesList(disable)
                setDisableQuestionnaireButton(disable>0?2:1);
            })
        }
    },[jobValue])
    const makeJobData =(data) =>{
        let exp="None";
        let gender="None";
        let qualification="None";
        let cuisine="None";
        console.log({data});
        
        if(data.detailsJobs && data.detailsJobs[0] && data.detailsJobs[0].detailSkills.length>0){
            data.detailsJobs[0].detailSkills.map(skill =>{
                if(skill.skillsTypeId>0 && skill.skillsTypeId<8 )
                qualification=skill.skillsTypeId;
                else if(skill.skillsTypeId===26 || skill.skillsTypeId===27 )
                gender=skill.skillsTypeId;
                else if(skill.skillsTypeId>39 && skill.skillsTypeId<44 )
                exp=skill.skillsTypeId;
                else if((skill.skillsTypeId>31 && skill.skillsTypeId<36)|| skill.skillsTypeId===115)
                cuisine=skill.skillsTypeId;
                else{
                }
            })
        }
        const startTime=data.loginTime?moment(data.loginTime, "hh:mm a").format("hh:mm A"):null;
        const endTime=data.logoutTime?moment(data.logoutTime, "hh:mm a").format("hh:mm A"):null;
        const startDate= data.startDate? data.startDate:null;
        let perkArray=[]
        if(data.company.is_2meal)
            perkArray.push("Meals provided")
        if(data.company.is_conveyance)
            perkArray.push("Conveyance provided")
        if(data.company.is_pf_esic)
            perkArray.push("Pf/Esic provided")
        if(data.company.is_rooms)
            perkArray.push("Accommodation provided")
        const jobData={
            brandName: data.brandName?data.brandName:data.companyName,
            encryptId: data.detailsJobs[0].jobDetailsId?convertJobIdtoHex(data.detailsJobs[0].jobDetailsId):'-',
            jobInterviewType: data.detailsJobs[0].jobInterviewType,
            companyDetails: data.company.shortDescription,
            perks: perkArray,
            jobTitle: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'-',
            workType:data.workType,
            vacancyType: data.detailsJobs[0].vacancyType==="entry"?
                "Entry level"
                :data.detailsJobs[0].vacancyType==="mid"?
                    "Mid level"
                    :data.detailsJobs[0].vacancyType==="senior"?
                        "Senior level"
                        :'N/a',
            payrollType: data.detailsJobs[0].payrollType==="company"?
                "Company"
                :data.detailsJobs[0].payrollType==="third"?
                    "Third Party"
                    :data.detailsJobs[0].payrollType==="contract"?
                        "Contract":'N/a',
            noOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'-',
            city: data.city_name?`${data.city_name}${data.state_name?", " :''}${data.state_name?data.state_name:''}`:'-',
            ExactJobAddress: data.address?data.address:'-',
            lat:data.jobLocationLat?data.jobLocationLat:null,
            long:data.jobLocationLong?data.jobLocationLong:null,
            salary: data.workType===3?`${numberFormat(data.detailsJobs[0].amount)}`:(data.detailsJobs[0].minSalary===data.detailsJobs[0].maxSalary)?`${numberFormat(data.detailsJobs[0].minSalary)}`:`${numberFormat(data.detailsJobs[0].minSalary)} - ${numberFormat(data.detailsJobs[0].maxSalary)}`,
            salaryType: data.detailsJobs[0].amountPer,
            jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'-',
            noOfDays: data.workdaysCount?data.workdaysCount:'-',
            noOfHrs: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:'-',
            weekdays: data.workdaysDetails?weekTodays(JSON.parse(data.workdaysDetails)): [],
            startTime: startTime,
            endTime: endTime,
            startDate: startDate,
            jobRole:data.detailsJobs[0].jobTypeId,
            exp: experience(exp),
            jobInterviewType: data?.jobInterviewType,
            gender: setgender(gender),
            qualification:  setqualification(qualification),
            cuisine: setcuisine(data.detailsJobs[0].cuisine===null? cuisine:data.detailsJobs[0].cuisine),
            specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification: "N/a",
            engFluency: data.detailsJobs[0].englishFluency===1?
                "No English"
                :data.detailsJobs[0].englishFluency===2?
                    "Thoda English"
                    :data.detailsJobs[0].englishFluency===3?
                        "Good English":"-",
            ageBracket: ageBracket(data.detailsJobs[0].minAge, data.detailsJobs[0].maxAge),
            cvRequired: data.detailsJobs[0].cvRequired===0?
                "Not Required"
                :data.detailsJobs[0].cvRequired===1?
                    "Optional"
                    :data.detailsJobs[0].cvRequired===2?
                        "Mandatory":"-",
            interviewAddress: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewAddress?
                    data.detailsJobs[0].jobInterviewDetails.interviewAddress:'-'
                :'-' ,
            interviewProcess: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewProcess?
                    data.detailsJobs[0].jobInterviewDetails.interviewProcess:'-'
                :'-',
            lat:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLat?
                    data.detailsJobs[0].jobInterviewDetails.interviewLat:null
                :null,
            long:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLong?
                    data.detailsJobs[0].jobInterviewDetails.interviewLong:null
                :null,
            // interviewSlots:  interviewArraytoSlots(JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays)),
            interviewSlots:  data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays? 
                    JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
                :[],
            interviewDesc: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
                    data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:'-'
                :'-',
            pocName:data.contactCandidateName?data.contactCandidateName:'-' ,
            recruiterPayoutAmount:data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.remoteRecPayoutAmount?
                    data.detailsJobs[0].jobPayout.remoteRecPayoutAmount:0:0,
            instructionsCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
            instructionsRecruiter:data.detailsJobs[0].instructionsRecruiter?data.detailsJobs[0].instructionsRecruiter:'', 
            recruiterPitch: data.detailsJobs[0].recruiterPitch?data.detailsJobs[0].recruiterPitch:'',
            recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.remoteRecPayout_details?
                    data.detailsJobs[0].jobPayout.remoteRecPayout_details:'':'',

        }
        return jobData;
    }
    const getJobDetails =() =>{
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${jobValue.jobId}`)
                .then(response =>{
                    if(response){
                        setJobDetails(makeJobData(response.data.response.content[0]))
                    }
                })
        }
    }
    const getVacancyStatus =() =>{
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}/extWorker/getRecruiterDashboardData?job_details_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recuiter_id=${recruiterData.userID}`)
                .then(response =>{
                    if(response){
                        console.log({response})
                        setVacancyStatusData(response.data.response)
                    }
                })
        }
    }
    useEffect(() => {
        getJobDetails();
        getVacancyStatus();
        setPropRows(10)
        setPageNo(1)
        if(history?.location?.recruiterDashBoardData)
            setJobChanged(true)
    }, [jobValue])
    

    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                setRecruiterList(response.data.response)
                console.log("recruiter", history?.location?.recruiterDashBoardData?.recruiterId, recruiterData)
                if(history?.location?.recruiterDashBoardData?.recruiterId && localStorage.getItem('roleType')!=="recruiter"){
                    console.log(response.data.response)
                    const recruiter=response.data.response.find(x => x.userID === history.location.recruiterDashBoardData.recruiterId)
                    console.log("================33333211=================", recruiter)
                    setRecruiterData(recruiter)
                }
            })
    }, [])
    
    useEffect(() => {
        if(recruiterData?.userID)
            Axios.get(`${urlLinks.baseUrl}extWorker/getJobRecruiter?recruiter_id=${recruiterData.userID}`)
                .then(response =>{
                    setJobList(makeJobList(response.data.response))
                })
        
    }, [recruiterData])
    
    console.log({disableQuestionnaireButton})
    return (
        <Grid container>
            <Grid item sm={12}><span className="fontSize24 fontWeightBold">Recruiter Dashboard</span></Grid>
            {(localStorage.getItem('roleType')=== "Super Admin" || localStorage.getItem('roleType')=== "Operational Admin")  && <Grid container className="padding0" style={{marginBottom: "20px"}}>
                <span className="fontSize16 fontWeightBold">Select recruiter: </span>
                <Autocomplete
                    id="jobList"
                    options={recruiterList}
                    value={recruiterData}
                    onChange={(event, value) => {console.log(value); setRecruiterData(value)}}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName?option.lastName:''}`
                        }
                    style={{ width: "450px", margin: "0 50px 0 30px"}}
                    renderInput={(params) => <TextField {...params} className="margin0 borderLightGrey " />}
                />
                
            </Grid>}
            <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="space-between">
                <Grid item xs={10} className="flexDisplay padding0" direction="row" justify="flex-start" alignItems="center">
                    <SelectVacancy
                        jobList={jobList} 
                        jobValue = {jobValue} 
                        setJobValue ={setJobValue} 
                        setOpenJobModal ={setOpenJobModal}
                    />
                </Grid>
                <Grid item xs={2} className="flexDisplay padding0"  direction ="row-reverse">
                    <Button 
                        onClick={() => console.log("FAQS")}
                        className="dataButton"
                    >
                        FAQS
                    </Button>
                </Grid>    
            </Grid>
            {vacancyStatusData && jobDetails && <Grid item xs={12} className="padding0" >
                <VacancyRecruiterStatus values={vacancyStatusData} data={jobDetails} />
                </Grid>
                    }
            {jobValue && jobDetails && disableQuestionnaireButton>0 && <Grid item xs={12} className="padding0" > <ActiveApplications
                recruiterId={recruiterData?.userID}
                jobInterviewType= {jobDetails.jobInterviewType}
                jobList={jobList}
                jobValue={jobValue}
                quesLength={quesList}
                getVacancyStatus={getVacancyStatus}
                disableQuestionnaireButton={disableQuestionnaireButton===2?false:true}
                rows={history?.location?.recruiterDashBoardData?.perPage}
                pageNo={history?.location?.recruiterDashBoardData?.pageNo}
                jobChanged={jobChanged}
            />
                </Grid>
            }
            {openJobModal && <JobModal
                openJobModal={openJobModal}
                setOpenJobModal={setOpenJobModal}
                values={jobDetails}
            />}
        </Grid>
    )
}
export default RecruiterDashboard;
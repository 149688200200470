import React, { Component } from 'react'
import WorkerOnBoardUtils from './WorkerOnBoardUtils';
import Constant from '../../../constant';
import { Grid, Card } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../../utils/Table/Table";

 class WorkerOnBoardingReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onBoardList: [],
            filters: WorkerOnBoardUtils.OnBoardingFilterList,
            currentPage: 0,
            count:0,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "status",
                label: Constant.workerOnBoarding.status,
                isColumnVisible: true
            }, {
                id: "2",
                name: "workerId",
                label: Constant.workerOnBoarding.workerId,
                isColumnVisible: true
            }, {
                id: "3",
                name: "name",
                label: Constant.workerOnBoarding.name,
                isColumnVisible: true
            }, {
                id: "4",
                name: "phone_number",
                label: Constant.workerOnBoarding.phone_number,
                isColumnVisible: true
            }
                , {
                id: "5",
                name: "registration_date",
                label: Constant.workerOnBoarding.registration_date,
                isColumnVisible: true
            },
            {
                id: "6",
                name: "added_on",
                label: Constant.workerOnBoarding.added_on,
                isColumnVisible: true

            },
            {
                id: "7",
                name: "added_by",
                label: Constant.workerOnBoarding.added_by,
                isColumnVisible: true
            }, {
                id: "8",
                name: "referral_code",
                label: Constant.workerOnBoarding.referral_code,
                isColumnVisible: true
            }, {
                id: "9",
                name: "review_status",
                label: Constant.workerOnBoarding.review_status,
                isColumnVisible: true
            }, 
            {
                id: "10",
                name: "action",
                label: Constant.workerOnBoarding.action,
                isColumnVisible: true
            }]
        }
        this.data = [
            {
                "status": "Partial Info",
                "workerId": "W_001",
                "name": "Ram K",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registration_date":"20/11/2019",
                "added_on":"22/11/2019",
                "added_by":"Self",
                "referral_code":"",
                "review_status":"Not Reviewed",
            },
            {
                
                "status": "Registered Only",
                "workerId": "W_002",
                "name": "Mohan K",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registration_date":"22/11/2019",
                "added_on":"23/11/2019",
                "added_by":"Self",
                "referral_code":"",
                "review_status":"Not Reviewed",

            },
            {

                
                "status": "Registered Only",
                "workerId": "W_003",
                "name": "Ajay K",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registration_date":"23/11/2019",
                "added_on":"24/11/2019",
                "added_by":"Self",
                "referral_code":"",
                "review_status":"Not Reviewed",
            }
        ];


        this.rowsOptions=[5,10,25,50,100,200,500];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);

    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }
    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        //to do
     
    }

    componentDidMount() {
        // Axios.get("http://localhost:3001/employers").then(
        //     (response) => { 
        //         this.setState({
        //             employer: response.data,

        //         });
        //     }
        // ).catch(
        //     (error) => { console.log(error) }
        // );
        this.setState({ onBoardList: this.data });

    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}></HideUnhideColumns>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomFilter filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid>
                    </Grid>
                </Card>
                <CustomTable name={Constant.workerOnBoarding.idName}columns={this.state.column} data={this.state.onBoardList} links={WorkerOnBoardUtils.OnBoardingListAction} title={WorkerOnBoardUtils.title} tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}

export default WorkerOnBoardingReview

import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
// import VerifiedImg from '../assets/thumb-up.png';
import VerifiedImg from '../assets/Group 3228.png';
import { Link, useHistory } from 'react-router-dom';


export default function VerificationLinkDialog(props) {
	const [open, setOpen] = useState(props.open);
	const history = useHistory();

	const handleClose = () => {
		setOpen(false);
		// closeBulkModal()
		history.push("/login");
	}

	let dialogData =
		<Dialog
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth={true}
			disableBackdropClick={true}
			className="email-verification"
		>
			<IconButton
				aria-label="close"
				className={'closeButtonIcon'}
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<Grid item xs={12} className="padding0">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<div className="success-page">
								<h2 style={{ width: 64, margin: '0 auto' }} ><img src={VerifiedImg} alt="Hourglass" style={{ width: 64, margin: '0 auto' }} /></h2>
								<Grid item xs={12} className="padding0 Registration-with-OkayGo" >
									<p className="success-p">
									A verification link has been sent on your email ID. Please click on the link to verify your email.
                					</p>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	return dialogData;
}

import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import './filter.css'
const useStyles = makeStyles({
 
});

export default function SelectFilter(props) {
    const {label, filters, setFilters, id}= props
    let multipleFlag= true
    switch (id) {
        case "year_of_passing":
            multipleFlag=false;
            break;
    
        default:
            multipleFlag=true;
            break;
    }
    // const id=column.id;
    const classes = useStyles();
    
    const workerStatus =[
        ["", "All"],
        ["Activated", "OnBoarded"],
        ["Deactivated", "Not OnBoarded"]   
    ]
    const optionsSourceList =[
        ["", "All"],
        ["Quickr", "Quickr"],
        ["Naukri", "Naukri"],
        ["SkillMirror", "SkillMirror"],
        ["Facebook", "Facebook"],
        ["Shine", "Shine"],


    ]
    const optionsGender =[
        ["", "All"],
        [26, "Male"],
        [27, "Female"],
    ]
    const employerStatus =[
        ["", "All"],
        ["Activated", "Activated"],
        ["Deactivated", "Deactivated"],
        ["Suspended", "Suspended"]   
    ]
    const currentYear=  (new Date()).getFullYear()
    const optionsYearofPassing= []
    for(var i=currentYear; i>=currentYear-35;i--){
        optionsYearofPassing.push([i, i])
    }
    const optionsQualification=[
        [1,"Below 10th"],
        [2,"10th passed"],
        [3,"12th passed"],
        [4,"Diploma"],
        [5,"In college"],
        [6,"Graduate"],
        [7,"Post Graduate"]
    ]
    const jobStatus =[
        ["", "All"],
        [1, "Active"],
        [2, "Inactive"],
        [0, "Closed"]   
    ]
    const optionsWorkType =[
        ["", "All"],
        [1, "Full time"],
        [2, "Part time"],
        [3, "On demand"]
    ]
    const optionsApprovalStatus =[
        ["", "All"],
        ["APPROVED", "Approved"],
        ["NOT_APPROVED", "Not approved"]  
    ]
    const optionsInterviewFollowUp =[
        ["", "All"],
        ["GOING", "Going"],
        ["NOT_GOING", "Not going"],
        ["RESCHEDULE", "Reschedule"],
        ["NO_RESPONSE", "No Response"],
        ["APPEARED", "Appeared"]    
    ]
    const optionsApplicationStage =[
        ["", "All"],
        ["APPLIED", "Applied"],
        ["SHORTLISTED", "Shortlisted"],
        ["REJECTED", "Candidate rejected"],
        ["INTERVIEWING", "Interviewing"],
        ["IREJECTED", "Rejected"],
        ["SELECTED", "Selected"],
        ["ACCEPTED", "Offer accepted"],
        ["UPDATE_JOINING_DATE", "Updated joining date"],
        ["OREJECTED", "Offer rejected"],
        ["JOINED", "Joined"],  
        ["LEFT_AFTER_JOINING", "Left after joining"],
        ["POSTPONED", "Postponed"],
        ["NOT_JOINING", "Candidate not joining"],
        ["DROPPED", "Candidate dropped"],
        ["AWAITED", "Result awaited"]
    ]
    
    const optionsFeedback =[
        ["", "All"],
        ["SELECTED", "Selected"],
        ["REJECTED", "Rejected"],
        ["ROUNDS_PENDING", "Rounds pending"],
        ["RESULT_AWAITED", "Result awaited"],
        ["CANDIDATE_DROPPED", "Candidate dropped"],
        ["NOT_APPEARED", "Not appeared"]   
    ]
    const optionsJoiningStatus =[
        ["", "All"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["POSTPONED", "Postponed"],
        ["NO_RESPONSE", "No response"],
        ["LEFT_AFTER_JOINING", "Left after joining"]
    ]

    const optionCandidateStatus = [
        ["", "All"],
        ["SELECTED", "Selected"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["LEFT_AFTER_JOINING", "Left after joining"]
    ]

    const optionInvoiceStatus = [
        ["", "All"],
        ["RAISED", "Raised"],
        ["DEAD", "Dead"],
        ["CANCELLED", "Cancelled"],
        ["CREDITED", "Credited"],
        ["TO_BE_RAISED", "To be raised"],
        ["PENDING", "Pending"]
    ]
    const optionCommissionType = [
        ["", "All"],
        [0, "Fixed"],
        [1, "Variable"]
    ]

    const optionsReferralApprovalStatus = [
        ["", "All"],
        ["CLAIMED", "Claimed"],
        ["APPROVED", "Approved"],
        ["NOT_APPROVED", "Not approved"],
        ["LEFT_BEFORE_REPLACEMENT_PERIOD", "Left before replacement period"]
    ]
    const allAdmins=JSON.parse(localStorage.getItem('allAdmins'));    
    let optionsAppliedBy=Object.entries(allAdmins);
    if(id==="addedBy")
    optionsAppliedBy.push(["-1","employer"]);
    if(id==="appliedBy")
    optionsAppliedBy.push(["-1","self"]);

    optionsAppliedBy.unshift(["", "All"]);
    
    let options=null
    switch (id) {
        case "worker_status":
            options= workerStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0] }>{option[1]}</MenuItem>
            })
            break;
        case "employer_status":
            options= employerStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0] }>{option[1]}</MenuItem>
            })
            break;
        case "jobStatus":
            options= jobStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0] }>{option[1]}</MenuItem>
            })
            break;
        case "workType":
            options= optionsWorkType.map(option =>{
                return <MenuItem key={option[0]} value={option[0] }>{option[1]}</MenuItem>
            })
            break;
        case "approvalStatus":
            options= optionsApprovalStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "applicationStage":
            options= optionsApplicationStage.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "interviewFollowUp":
            options= optionsInterviewFollowUp.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "recruiterFeedback":
        case "employerFeedback":
        case "finalFeedback":
            options= optionsFeedback.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "joiningStatus":
            options= optionsJoiningStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "candidate_status":
            options= optionCandidateStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "invoice_status":
            options= optionInvoiceStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "commission_type":
            options= optionCommissionType.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "appliedBy":
        case "addedBy":
            options= optionsAppliedBy.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "source":
            options= optionsSourceList.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "gender":
            options= optionsGender.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "year_of_passing":
            options= optionsYearofPassing.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "qualification":
            options= optionsQualification.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "referralApprovalStatus":
            options= optionsReferralApprovalStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        default:
            options=null
            break;
    }
    const handleChange = (event) => {
        if(filters[id].includes("") && event.target.value.length>1)
            setFilters({...filters, [id]: event.target.value.filter(x => x!=="")})
        else if(event.target.value.includes("") || event.target.value.length===0)
            setFilters({...filters, [id]: [""]})
        else
            setFilters({...filters, [id]: event.target.value})
    };
    const handleSingleChange = (event) => {
            setFilters({...filters, [id]: event.target.value})
    };
    const applicationStageFilter =(array) =>{
        let eventName=[];
        let eventStatus=array;

        if(array.includes("REJECTED")){
            if(!eventName.includes("APPLIED_JOB"))
                eventName.push("APPLIED_JOB")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="REJECTED")
        }
        if(array.includes("INTERVIEWING")){
            eventStatus.push("GOING", "SLOTS_CREATED", "SLOT_SELECTED", "SLOT_UPDATED", "RESCHEDULE", "APPEARED");
            eventStatus.filter(x=> x!=="INTERVIEWING")
        }
        if(array.includes("IREJECTED")){
            if(!eventName.includes("INTERVIEW_STATUS"))
                eventName.push("INTERVIEW_STATUS")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="IREJECTED")
        }
        if(array.includes("OREJECTED")){
            if(!eventName.includes("OFFER_JOB"))
                eventName.push("OFFER_JOB")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="OREJECTED")
        }
        if(array.includes("DROPPED")){
            eventStatus.push("NO_SHOW", "NOT_GOING", "NO_RESPONSE", "CANDIDATE_DROPPED")
        }
        if(array.includes("AWAITED")){
            eventStatus.push("ROUNDS_PENDING", "RESULT_AWAITED")
        }

        let interviewStageName = eventName.length>0?`&interviewStageName=${eventName}`:''
        let interviewStage = eventStatus.length>0?`&interviewStage=${eventStatus}`:''
        
        return `${interviewStage}${interviewStageName}`
    }
    // const handleBlur = (event) => {
    //     if(id==="applicationStage"){
    //         setFilters({...filters, [id]: applicationStageFilter(value)})
    //     }

    //     else
    //         setFilters({...filters, [id]: value})
    // };
    // console.log({label});
    
    return (
        <Grid container alignItems="center">
            <Grid  xs={12}  style={{ margin: 0 }}>
                <FormControl >
                    <InputLabel id={id}>{label}</InputLabel>
                    <Select
                        labelId={id}
                        id={id}
                        multiple={multipleFlag}
                        value={filters[id]}
                        className= "margin0"
                        onChange={multipleFlag?handleChange:handleSingleChange}
                        style={{ width: 200}}
                    >
                        {options}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
  );
}

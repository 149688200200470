import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import urlLinks from '../../urlLinks'
import Axios from 'axios'
import qs from 'querystring'
import calender from '../../assets/calender.svg'
import time from '../../assets/timer.svg'

const flexDisplay = {
    margin: 'auto',
  },
  ipBorder = {
    border: 'solid 1px #1c75bc',
    borderRadius: '4px',
    width: '170px',
    padding: '0 8px',
    marginRight: '20px'
  },
  subTitle = {
    objectFit: 'contain',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  },
  txtArea = {
    border: 'none',
    fontSize: '14px',
    width: "90%",
    margin: '20px 0'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    marginRight: '25px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '490px'
    },
  }));

const CallModal = (props) => {
  const classes = useStyles();
  const {callReminderModal, setCallReminderModal, row, showToast, noteData, getAllReminders} = props;
  const [reminderData, setReminderData] = useState({
    data: noteData?.note?noteData.note:"",
    date: noteData?.rem_date?noteData.rem_date:null,
    time: noteData?.rem_time?noteData.rem_time:null
  })
  const [reminderDataError, setReminderDataError] = useState({
    data:"",
    date:"",
    time:""
  })
  console.log({reminderData})
  const handleSaveReminder = () => {
    if(reminderData.data && reminderData.date && reminderData.time){

      let postData={
        insertedBy:localStorage.getItem("userId"),
        jobId: row.original.jobId,
        note: reminderData.data,
        recruiterId: row.original.recruiterId,
        remDate:moment(reminderData.date).format("YYYY-MM-DD"),
        remTime: moment(reminderData.time).format("HH:mm:00"),
        userId: row.original.userId
      }
      Axios.post(`${urlLinks.baseUrl}recruiter/addEditReminder`, qs.stringify(postData))
      .then(response =>{
        if (response.data.code === 1000) {
          getAllReminders();
          setCallReminderModal(false);
          showToast("success", "Reminder added successfully.");
      } else {
        setCallReminderModal(false);
          showToast("error", "Set reminder failed");
      }
      })
    }
    else {
      if(!reminderData.data){
        setReminderDataError({...reminderDataError, data:"Enter reminder note."})
      }
      if(!reminderData.date){
        setReminderDataError({...reminderDataError, date:"Enter reminder date."})
      }
      if(!reminderData.time){
        setReminderDataError({...reminderDataError, time:"Enter reminder time."})
      }
    }
  }
  return (

    <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={callReminderModal}
        onClose={() =>setCallReminderModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <div className={classes.paper}>
        <Grid container>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
              <h1>Call Reminder</h1>
          </Grid>
          <Grid>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
              <span style={subTitle}>Enter date and time</span>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* <TextField
                id="date"
                type="date"
                value={reminderData.date}
                onChange={(date) => setReminderData({...reminderData, date: date})}
                InputLabelProps={{
                  shrink: true,
                }}
                style={ipBorder}
                keyboardIcon={ <img src={calender} />}
              />
               <TextField
                id="time"
                type="time"
                value={reminderData.time}
                onChange={(date) => setReminderData({...reminderData, time: date})}
                className={classes.textField}
                style={ipBorder}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              /> */}
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                placeholder= "Date"
                InputProps={{
                  disableUnderline: true,
                 }}
                value={reminderData.date}
                // label="Date"
                onChange={(date) => setReminderData({...reminderData, date: date})}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={ipBorder}
                keyboardIcon={ <img src={calender} />}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                format="hh:mm a"
                placeholder= "Time"
                // label="Time"
                InputProps={{
                  disableUnderline: true,
                 }}
                value={reminderData.time?moment(reminderData.time, "HH:mm:ss"):null}
                onChange={(date) => setReminderData({...reminderData, time: date})}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                style={ipBorder}
                keyboardIcon={<img src={time} />}
              />
          </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} className="padding0" style={{marginBottom: '15px'}}>
            <TextField
                rows={3}
                multiline
                fullWidth
                variant="outlined"
                style={txtArea}
                value={reminderData.data}
                onChange={(event) => setReminderData({...reminderData, data: event.target.value})}
                placeholder="Notes"
                required
            />
          </Grid>
          <Grid item xs={12} className="padding0">
            {reminderDataError.data && <span style={{color:"red"}}>{reminderDataError.data}</span>}
            {reminderDataError.date && <span style={{color:"red"}}>{reminderDataError.date}</span>}
            {reminderDataError.time && <span style={{color:"red"}}>{reminderDataError.time}</span>}
          </Grid>
          <Grid item xs={12} className="padding0" direction="row">
            <Button style={cancel} onClick={() =>setCallReminderModal(false)}>CANCEL</Button>
            <Button style={confirm} onClick={handleSaveReminder}>CONFIRM</Button>
          </Grid>
        </Grid>
        </div>
    </Modal>
  );
};

export default CallModal;

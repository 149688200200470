import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Login.css'
import OurClients from "./OurClients";
import LoginForm from './LoginForm'
import RegisterForm from '../Register/RegisterForm'
import Testimonials from "./Testimonials";
import { Button, Grid } from '@material-ui/core'
import OkayGoLogo from '../assets/OkayGo website 6.png'
import OkayGoLoginAside from '../assets/Group 689.png'
import RecruitmentIllustration from '../assets/illustrations1.png'
import YellowLines from '../assets/Group 2973.png'
import YellowLinesRight from '../assets/Group 2974.png'
import Home from '../assets/home (1).png'
import SignalStrength from '../assets/signal-strength.png'
import Star from '../assets/star (2).png'
import Opportunity from '../assets/opportunity.png'
import Laptop from '../assets/Group 2995@3x.png'
import RegistrationImage from '../assets/Group 2971.png'
import HelpCenter from '../Pages/HelpCenter'

import Footer from '../Footer/Footer';
const useMountEffect = fun => useEffect(fun, []);


const Login = (props) => {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    useMountEffect(executeScroll);

    return (
        <Grid container className="opensansFont">
            <Grid container className="login-register main-content">
                <Grid container className="paddingHorizontal " ref={myRef}>
                    <Grid item xs={12} className="paddingHorizontal5percent displayFlex ">
                        <Grid item xs={6} className="padding0 displayFlex alignItemsCenter" direction="column">
                            <img src={OkayGoLogo} alt="logo" className="widthFitContent alignSelfFlexStart" />
                            <img src={OkayGoLoginAside} alt="okaygoLoginAside" />
                            <span className="fontWeightBold fontSize16" >Earn handsomely as a freelance recruiter with OkayGo </span>
                            <span className="width55percent fontSize14 textAlignCenter">Recruit the best talent for the best companies in India while working from home using our simple to use technology application</span>
                        </Grid>
                        <Grid item xs={6} className="padding0">
                            {props.isLogin ?
                                <LoginForm /> : <RegisterForm />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="paddingHorizontal paddingTop100">
                    <Grid item xs={12} className="padding0 displayFlex alignItemsCenter" direction="column">
                        <span className="fontSize28 fontWeightBold blueColor" >Redefining Recruitment</span>
                        <span className="fontSize20 fontWeightBold paddingTop20">Register.Recruit.Earn</span>
                        <div className="yellowRectangle marginTop20"></div>
                    </Grid>
                </Grid>
                <Grid container className="paddingHorizontal paddingTop100">
                    <div class="image-container">
                        <img src={RecruitmentIllustration} alt="RecruitmentIllustration" />
                        <div class="common_tab bottom-left">Earn attractive payout while working from home at your convenience</div>
                        <div class="common_tab top-left">Register and create your account on OkayGo</div>
                        <div class="common_tab top-right">Work on high paying vacancies where you have expertise and experience</div>
                        <div class="common_tab bottom-right">Manage recruitment process through our seamless technology platform</div>
                    </div>

                </Grid>
                <Grid container className="paddingTop150">
                    <Grid item xs={12} className="padding0 displayFlex alignItemsCenter" direction="column">
                        <span className="fontSize28 blueColor fontWeightBold">OkayGo's cutting edge recruitment platform</span>
                        <div className="yellowRectangle margin20"></div>
                    </Grid>
                    <Grid item xs={12} className="padding0 displayFlex marginTop35 recruitmentPlatform1" >
                        <Grid item xs={7} className="padding0">
                            <Grid item xs={12} className="padding0" >
                                <img src={YellowLines} alt="yellowlines" className=" zIndex-1 yellowLinesPosition" />
                            </Grid>
                            <Grid container className="marginTop35 paddingLeft82px">
                                <Grid item xs={6} className="padding0">
                                    <div className="displayFlex flexDirectionColumn cardStyles">
                                        <div className="displayFlex flexDirectionColumn padding20-30">
                                            <img src={Home} alt="home" className="widthFitContent" />
                                            <span className="fontSize18 fontWeightBold margin5-14">Virtual login - work from home at your convenience</span>
                                            <span className="fontSize14 ">Earn as much as you can based on your availability</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className="padding0">
                                    <div className="cardStyles">
                                        <div className="displayFlex flexDirectionColumn padding20-30">
                                            <img src={SignalStrength} alt="signal-strength" className="widthFitContent" />
                                            <span className="fontSize18 fontWeightBold margin5-14">High End Tech platform </span>
                                            <span className="fontSize14 ">End to end processing using one platform - Request data, Schedule interviews, set reminders, track applications etc.</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className="padding0">
                                    <div className="cardStyles">
                                        <div className="displayFlex flexDirectionColumn padding20-30">
                                            <img src={Star} alt="star" className="widthFitContent" />
                                            <span className="fontSize18 fontWeightBold margin5-14">Exciting payouts and rewards</span>
                                            <span className="fontSize14 ">Earn attractive payouts. No limit to how much you can earn</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className="padding0">
                                    <div className="displayFlex flexDirectionColumn cardStyles">
                                        <div className="displayFlex flexDirectionColumn padding20-30">
                                            <img src={Opportunity} alt="opportunity" className="widthFitContent" />
                                            <span className="fontSize18 fontWeightBold margin5-14">Flexibility to use your own data</span>
                                            <span className="fontSize14 ">Use your own data to earn even better payouts for vacancies</span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={5} className="padding0 overflowHidden positionRelative">
                            <img src={Laptop} alt="laptop" className="laptopImage" />
                            <img src={YellowLinesRight} alt="yellowlines" className=" zIndex-1 yellowLinesPositionRight" />
                        </Grid>
                        {/* <Grid item xs={12} className="padding0" >
                                
                            </Grid> */}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className="padding0">
                        <Testimonials />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className="padding0 displayFlex alignItemsCenter" direction="column">
                        <span className="fontSize32 blueColor fontWeightBold">Our clients</span>
                        <div className="yellowRectangle margin20"></div>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <OurClients />
                    </Grid>
                    <Grid item xs={12} className="padding0 displayFlex alignItemsCenter" direction="column">
                        <span className="fontSize32 blueColor marginBottom35">To know more about the process,</span>
                        <Button className="FAQButton" >
                            <Link to="/help" >Go to FAQ</Link>
                        </Button>
                    </Grid>
                </Grid>
            </Grid><Grid container>
                <Grid item xs={12} className="padding0 industryTogethrt alignItemsCenter blueBackground" direction="row">
                    <Grid item xs={4} className="padding0 recruiterImageDiv">
                        <img src={RegistrationImage} alt="regImage" className="recruiterImage" />
                    </Grid>
                    <Grid item xs={4} className="whiteColor fontSize28 fontWeightBold industryTogethrt paddingTopBottom35 textAlignCenter" direction="row" justify="center">
                        <span>Let's transform the recruitment industry together</span>
                    </Grid>
                    <Grid item xs={4} className="padding0 displayFlex" direction="row" justify="center">
                        {props.isLogin ?
                            <Link
                                className="RegisterNowButton"
                                to="/register"
                            >Register Now
                            </Link> : <Link
                                className="RegisterNowButton"
                                to="/login"
                            >Login Now</Link>
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Footer />
        </Grid>
    )
}

export default Login

import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
// import { Container, Divider, Typography } from '@material-ui/core';

const styles = {
  Container: {
    // paddingTop:"0%",
    // backgroundColor: '',
    // marginTop:'0%',
    // fontSize:'.8rem',
    // paddingBottom:"0%",
    // Width:'2%'
  },
  size: {
    Width: '2px'
    //padingRight:'2px'
  }
};

class DatePickers extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.state = {
      start_SelectedDate: this.props.start,
      end_SelectedDate: this.props.end,
      startDate: '',
      endDate: ''
    };
  }

  sendDates = (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    this.props.sendData(startDate, endDate);
  };

  handleChange(event) {
    const date =
      event.getDate() < 10
        ? `0${event.getDate().toString()}`
        : event.getDate().toString();
    const month =
      event.getMonth() < 10
        ? `0${(event.getMonth() + 1).toString()}`
        : (event.getMonth() + 1).toString();
    const startDate = `${event
      .getFullYear()
      .toString()}-${month}-${date} 00:00:00`;
    this.setState({ startDate: startDate }, () =>
      this.sendDates(this.state.startDate, this.state.endDate)
    );
    this.setState({
      start_SelectedDate: event
    });
  }

  handleChange1(event) {
    const date =
      event.getDate() < 10
        ? `0${event.getDate().toString()}`
        : event.getDate().toString();
    const month =
      event.getMonth() < 10
        ? `0${(event.getMonth() + 1).toString()}`
        : (event.getMonth() + 1).toString();
    const endDate = `${event
      .getFullYear()
      .toString()}-${month}-${date} 00:00:00`;
    this.setState({ endDate: endDate }, () =>
      this.sendDates(this.state.startDate, this.state.endDate)
    );
    this.setState({
      end_SelectedDate: event
    });
  }
  render() {
    console.log(this.props.start,this.state.start_SelectedDate )
    
    const { classes } = this.props;
    if(this.props.toMax) {
      return (
        <Grid className={classes.Container}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.size}>
            <Grid container justify='space-around'>
              <KeyboardDatePicker
                margin='normal'
                id='date From'
                label={this.props.label}
                format='dd/MM/yyyy'
                // minDate={new Date()}
                value={this.props.start || this.state.start_SelectedDate}
                onChange={this.handleChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
              <KeyboardDatePicker
                margin='normal'
                id='date-picker-dialog1'
                label={this.props.label1}
                format='dd/MM/yyyy'
                // minDate={new Date()}
                value={this.props.end || this.state.end_SelectedDate}
                onChange={this.handleChange1}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.Container}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.size}>
            <Grid container justify='space-around'>
              <KeyboardDatePicker
                margin='normal'
                id='date From'
                label={this.props.label}
                format='dd/MM/yyyy'
                // maxDate={new Date()}
                value={this.props.start || this.state.start_SelectedDate}
                onChange={this.handleChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
              <KeyboardDatePicker
                margin='normal'
                id='date-picker-dialog1'
                label={this.props.label1}
                format='dd/MM/yyyy'
                // maxDate={new Date()}
                value={this.props.end || this.state.end_SelectedDate}
                onChange={this.handleChange1}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      );
    }
  }
}
export default withStyles(styles)(DatePickers);

import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import TimeDateComponent from './TimeDateComponent';
import AddBoxIcon from '@material-ui/icons/AddBox';

class AvailableTime extends Component {
  constructor(props) {
    super(props);
    this.isErrorPropAvailable = false;
    // this.getElements =this.getElements.bind(this);
    this.getFilteredArray = this.getFilteredArray.bind(this);
    this.addElement = this.addElement.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
    this.updateData = this.updateData.bind(this);
    this.count = props.count || 1;
    this.data = [
      // {
      //   id: 1,
      //   isVisible: true,
      //   values: {
      //     day: {
      //       Monday: false,
      //       Tuesday: false,
      //       Wednesday: false,
      //       Thursday: false,
      //       Friday: false,
      //       Saturday: false,
      //       Sunday: false
      //     },
      //     startTime: null,
      //     endTime: null
      //   }
      // }
    ];
    this.newAvailability = [];
    this.availability = [
      {
        id: '1',
        day: 'Everyday',
        isSelected: false
      },
      {
        id: '2',
        day: 'Monday',
        isSelected: false
      },
      {
        id: '3',
        day: 'Tuesday',
        isSelected: false
      },
      {
        id: '4',
        day: 'Wednesday',
        isSelected: false
      },
      {
        id: '5',
        day: 'Thursday',
        isSelected: false
      },
      {
        id: '6',
        day: 'Friday',
        isSelected: false
      },
      {
        id: '7',
        day: 'Saturday',
        isSelected: false
      },
      {
        id: '8',
        day: 'Sunday',
        isSelected: false
      }
    ];
    this.state = {
      availabilityArr: [],
      elements: [
        //     {
        //       id: 1,
        //       isVisible: true,
        //       component: (
        //         <TimeDateComponent
        //           checkDays={this.checkDays}
        //           id={1}
        //           errors={this.props.errors}
        //           name={this.props.name}
        //           updateData={this.updateData}
        //           deleteItem={this.deleteElement}
        //           availability={this.availability}
        //           values={this.data[0].values}
        //         />
        //       )
        //     }
      ],
      counter: 1,
      isEveryDaySelected: false,
      availabilityTime: [],
      errors: this.props.errors,
      isErrorPropAvailable: false,
      touched: this.props.touched
    };
  }
  updateData(id, values) {
    this.data = this.data.map(
      data => {
        if (data.id === id) {
          data.values = values;
        }
        return data;
      },
      id,
      values
    );
    // const newValuesArray = this.data.map((data, index) => {
    //   return data.values
    // });
    // console.log(this.props.name, this.data);

    // console.log(newValuesArray)
    this.props.setFieldValue(this.props.name, this.data);
  }

  addElement() {
    
    // console.log(availability);
    // console.log(this.state.availabilityArr);

    if (this.count < 7) {
      this.count++;
      let element = {};
      // console.log('AddElement start counter value: ', this.state.counter);
      // const availability = this.getFilteredArray();
      element['id'] = this.state.counter; //1
      element['isVisible'] = true;
      element['component'] = (
        <TimeDateComponent
          checkDays={this.checkDays}
          errors={this.state.errors}
          name={this.props.name}
          updateData={this.updateData}
          deleteItem={this.deleteElement}
          id={this.state.counter}
          values={{
            id: this.state.counter, //1
            isVisible: true,
            day: {
              Monday: false,
              Tuesday: false,
              Wednesday: false,
              Thursday: false,
              Friday: false,
              Saturday: false,
              Sunday: false
            },
            startTime: null,
            endTime: null
          }}
          availability={this.newAvailability}
        />
      );
      const newAvailability = [...this.state.availabilityArr, element];

      this.setState({
        availabilityArr: newAvailability,
        counter: this.state.counter + 1
      });
      // console.log('AddElement end counter value: ', this.state.counter);

      this.data.push({
        id: this.state.counter,
        isVisible: true,
        day: {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false
        },
        startTime: null,
        endTime: null
      });
      // console.log(this.data);

      const newValuesArray = newAvailability.map(data => {
        return data.values;
      });
      this.props.setFieldValue(this.props.name, newValuesArray);
    }
  }

  checkDays = day => {
    if (day === 'Everyday') {
      this.setState({ isEveryDaySelected: true });
      // this.availability = this.availability.filter(el => el.day !== day);
    } else {
      this.newAvailability = this.availability.filter(
        el => el.day !== 'Everyday'
      );
      this.newAvailability = this.newAvailability.filter(el => el.day !== day);
      this.setState({ isEveryDaySelected: false });
    }
  };

  deleteElement(id) {
    if (this.count > 1) {
      // console.log(id);

      this.count--;
      // console.log('DeleteElement start counter value: ', this.state.counter);

      this.data[id - 1].isVisible = false;
      let stateData = [];
      // console.log(this.data);
      stateData = this.state.availabilityArr.map(data => {
        return data;
      });
      stateData[id - 1].isVisible = false;
      // console.log(stateData[id-1])
      if (stateData[id - 1].values) {
        stateData[id - 1].values.isVisible = false
        stateData[id - 1].values.startTime = new Date().toISOString().split('T')[0];
        stateData[id - 1].values.endTime = new Date().toISOString().split('T')[0];
      } else if(stateData[id - 1].component) {
        stateData[id - 1].component.props.values.isVisible = false
        stateData[id - 1].component.props.values.startTime = new Date().toISOString().split('T')[0]
        stateData[id - 1].component.props.values.endTime = new Date().toISOString().split('T')[0]
        // stateData[id - 1].component.props.values.values.startTime = new Date().toISOString().split('T')[0]
        // stateData[id - 1].component.props.values.values.endTime = new Date().toISOString().split('T')[0]
      }
      this.data = this.data.map((data, index) => {
        data.id = index + 1;
        data.values = data.values;
        data.isVisible = data.isVisible;
        return data;
      });
      this.data[id-1] = {...stateData[id-1].component.props.values, values: {...stateData[id-1].component.props.values}}
      // console.log(this.data);
      // console.log(stateData);
      // console.log(stateData)
      this.setState({ availabilityArr: stateData });
      //  const newValuesArray = stateData.map(data => {
      //   //  console.log(data)
      //    const data2 = {
      //     ...data.component.props.values,
      //     values: {...data.component.props.values}
      //    }
      //   return data2;
      // });
      // console.log(newValuesArray);
      this.props.setFieldValue(this.props.name, this.data);
      this.setState({ availabilityArr: stateData });

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.errors) !== JSON.stringify(prevState.errors)) {
      return {
        errors: nextProps.errors,
        isErrorPropAvailable: true,
        touched: nextProps.touched
      };
    }
    return null;
  }

  mapNumbersToDays = days => {
    // console.log(days);
    const dayMap = {
      1: 'Sunday',
      2: 'Monday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Thursday',
      6: 'Friday',
      7: 'Saturday'
    };
    const availableDays = {};
    Object.values(days).map(
      (el, index) => (availableDays[dayMap[index + 1]] = el)
    );
    return availableDays;
  };

  toDateWithOutTimeZone = date => {
    let tempTime = date.split(':');
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  };

  componentDidMount() {
    // console.log(this.props);
    const aT = [...this.props.data];
    // availId: avail.availablityId,
    //   id: availIndex+1,
    //   isVisible: true,
    //   day: availabilityDays,
    //   startTime: startTime,
    //   endTime: endTime,
    //   values: {
    //     day: availabilityDays,
    //     startTime: startTime,
    //     endTime: endTime,
    // }
    // console.log(aT);
    const data = [];
    let counter = this.state.counter;
    let avalArray = [];
    aT.map((element, index) => {
      // console.log(element);
      // const day = this.mapNumbersToDays(JSON.parse(element.workdaysDetails));
      const values = {
        availId: element.availId,
        id: counter,
        isVisible: true,
        day: element.day,
        startTime: element.startTime,
        endTime: element.endTime
      };
      // console.log(day);

      data.push(values);
      let innerCounter = counter;
      avalArray.push({
        id: counter,
        isVisible: true,
        component: (
          <TimeDateComponent
            checkDays={this.checkDays}
            id={innerCounter}
            errors={this.props.errors}
            name={this.props.name}
            updateData={this.updateData}
            deleteItem={this.deleteElement}
            availability={this.availability}
            values={values}
          />
        )
      });
      counter++;
    });
    // console.log(avalArray);
    // console.log(this.data);
    this.data = [...data];

    //this.setState({elements: avalArray})
    this.setState({ availabilityArr: avalArray, counter: counter });
    const newValuesArray = avalArray.map(data => {
      return data.component.props.values;
    });
    // console.log(this.state.expArr);
    // console.log(this.data);
    // console.log(newValuesArray)
    // this.props.setAvailabilityValues(newValuesArray);
    this.props.setFieldValue(this.props.name, newValuesArray);
  }

  // componentDidUpdate(prevProps, PrevState) {
  //   // console.log(this.data);
  //   if (this.state.isErrorPropAvailable) {
  //     const elements = this.state.elements.map((element, index) => {
  //       element.id = index + 1;
  //       element.component = (
  //         <TimeDateComponent
  //           checkDays={this.checkDays}
  //           errors={this.state.errors}
  //           name={this.props.name}
  //           updateData={this.updateData}
  //           deleteItem={this.deleteElement}
  //           id={index + 1}
  //           values={{
  //             day: {
  //               Monday: false,
  //               Tuesday: false,
  //               Wednesday: false,
  //               Thursday: false,
  //               Friday: false,
  //               Saturday: false,
  //               Sunday: false
  //             },
  //             startTime: null,
  //             endTime: null
  //           }}
  //           availability={this.availability}
  //         />
  //       );
  //       return element;
  //     });

  //     this.setState({
  //       isErrorPropAvailable: false,
  //       elements: elements
  //     });
  //   }
  // }

  getFilteredArray() {
    let items = [];
    if (this.state.isEveryDaySelected) {
      return items;
    } else {
      items = [...this.newAvailability];
    }
    return items;
  }

  render() {
    // console.log(this.props.data);

    let Elements = this.state.availabilityArr.map((element, index) => {
      if (element.isVisible) {
        return (
          <div key={index} style={{ margin: '10px 0' }}>
            {element.component}
          </div>
        );
      }
    });

    // console.log(this.state.availabilityArr);
    // const Elements = this.state.elements.map((element, index) => {
    //   if (element.isVisible) {
    //     return (
    //       <div key={index} style={{ margin: '10px 0' }}>
    //         {element.component}
    //       </div>
    //     );
    //   }
    // });
    // console.log(Elements);

    const addComponent = (
      <Button style={{ marginLeft: 'auto' }} onClick={this.addElement}>
        <AddBoxIcon></AddBoxIcon>
      </Button>
    );
    return (
      <div>
        <div style={{ display: 'flex' }}>
          {addComponent}
        </div>
        <div>{Elements}</div>
      </div>
    );
  }
}

export default AvailableTime;

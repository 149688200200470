import React from 'react';
import { MdComment, MdModeEdit } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';

const WorkerListUtils = {
  WorkerListAction: [
    {
      id: 1,
      name: 'View Details',
      subIcon: <FaEye className='fitIcon' />,
      route: '/worker/workerList/viewWorker'
    },
    {
      id: 2,
      name: 'Edit Details',
      subIcon: <MdModeEdit className='fitIcon' />,
      route: '/worker/newAddWorker'
    },
    {
      id: 3,
      name: 'Comments',
      subIcon: <MdComment className='fitIcon' />,
      route: '/worker/workerList/commentSection'
    }
  ],
  WorkerListFilterList: [
    // {
    //   od_rating: ['1', '2', '3', '4', '5'],
    //   id: '1',
    //   name: 'od_rating',
    //   label: 'ODRating',
    //   default: []
    // },

    // {
    //   city: ['Delhi', 'Gurgaon', 'Noida'],
    //   id: '2',
    //   name: 'city',
    //   label: 'city',
    //   default: []
    // },
    {
      city: ['Gurgaon', 'Noida', 'Delhi'],
      id: '3',
      name: 'city',
      label: 'Location',
      default: []
    },
    {
      worker_status: ['Active', 'Suspended', 'Deactivated'],
      id: '7',
      name: 'worker_status',
      label: 'Status',
      default: []
    }
    //{od_breach_score:["1","2","3","4","5"],id:"4",name:"od_breach_score",label:"OD Breach Score",default:[]},
    // {
    //   Experince: ['1', '2', '3', '4', '5'],
    //   id: '6',
    //   name: 'Experince',
    //   label: 'Experince',
    //   default: []
    // },

    // {
    //   JobProfile: [' ', ' ', ' ', ' ', ' '],
    //   id: '8',
    //   name: 'Job_Profile',
    //   label: 'Job Profile',
    //   default: []
    // },
    // {
    //   AppResistered: ['1', '2', '3', '4', '5'],
    //   id: '9',
    //   name: 'OD_Rating',
    //   label: 'AppResistered',
    //   default: []
    // }
  ]
};

export default WorkerListUtils;

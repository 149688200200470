import React, {Fragment, useEffect, useState, useCallback} from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import TextFieldWithDropdown from '../../../../utils/TextFieldWithDropDown/textFieldWithDropdown'
import OtherExperiences from './OtherExperienceList'
import WorkerExpModal from '../../../../utils/Modals/WorkerExpModal'
import JobComponent from './jobComponent'
import ExperienceTextField from './ExperienceinYearsMonths'
import Autocomplete from '@material-ui/lab/Autocomplete';
import WorkerAvailability from './workerAvailability'
// import WorkerExpModal from '../../../../utils/Modals/WorkerExpModal'
import DeleteIcon from '@material-ui/icons/Delete'; 
import UploadButton from '../../../../utils/upload/uploadButton'
import urlLinks from '../../../../urlLinks'
import * as moment from 'moment';
import Axios from 'axios';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import {TextField, Checkbox, FormControl, FormControlLabel, InputLabel, Grid, InputAdornment, Switch, Button} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export const SecondPage = formikProps =>{
    const classes = useStyles();
    const { errors, touched, values, handleChange, setFieldValue, companyList, jobRoleList, setValues, locationList, interestList, editFlag} = formikProps;
    const [openWorkerExpModal, setOpenWorkerExpModal] = useState(false)
    const [skillError, setSkillError] = useState("")
    const [skillsList, setSkillsList] = useState([])
    const [otherSkillText, setOtherSkillText] = useState("")
    console.log(interestList)
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const handleCV = (event) =>{
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFieldValue("cv", reader.result) 
            setFieldValue("cvName", file.name)
        }        
    }
    const workerAppStatusOptions = [
        [0 , "Registered on app"],
        [1 , "Not registered on app"]
    ]
    useEffect(() => {
        let jobCategoryList=[]
        let jobTypeList= []
        if(values?.lastExperience?.job_category)
            jobCategoryList.push(values.lastExperience.job_category.id)
        if(values?.lastExperience?.job_role)
            jobTypeList.push(values.lastExperience.job_role.id)
        if(values?.interests?.length>0){
            values.interests.map(interest =>{
                if(interest.type==="category")
                    jobCategoryList.push(interest.id)
                if(interest.type==="role")
                    jobTypeList.push(interest.id)
            })
        }
        console.log(values.interests, jobCategoryList, jobTypeList)
        Axios.get(`${urlLinks.baseUrl}/workermaster/getskills?job_type_id=${jobTypeList.toString()}&job_category_id=${jobCategoryList.toString()}`)
            .then(response =>{
                let list = response.data.response
                // list.push({
                //     skill_type_id: -1,
                //     skills_name: "Other"
                // })
                setSkillsList(response.data.response)
            })
    }, [values.lastExperience, values.interests])
    const handleAddSkill =() =>{

    }
    useEffect(() => {
        let tempArray=[]
        if(values?.skillsReceived?.length>0){
            values.skillsReceived.map(skill =>{
                const skillObj = skillsList.find(x =>{
                    if( skill.skillsTypeId=== x.skills_type_id){
                        return skill;
                    }
                })
                if(skillObj && Object.keys(skillObj).length>0)
                    tempArray.push(skillObj)
            })
            setFieldValue("skills", tempArray)
            setTimeout(() => {
                setFieldValue("skillsReceived", null)
            }, 500);
        }
    }, [skillsList])
    useEffect(() => {
        if(values.years_exp || values.month_exp){
            setFieldValue('no_exp', false)
        }
    }, [values.years_exp, values.month_exp])
    return (
        <Grid container className="stepButtons ">
            <Grid item xs={12} className="padding0 marginTop40">
                <span className="fontSize24 textUnderline fontWeightBold">Worker profile</span>
            </Grid>
            <Grid item xs={12} className="padding0 " >
                <Grid item xs={12} className="padding0" >
                </Grid>
                <Grid item xs={12} className="padding0" >
                    
                    <Field
                        name="total_experience"
                        labelYrs="Experience (in yrs)"
                        labelMonths="Experience (in months)"
                        nameTextMonths="month_exp"
                        nameTextYrs="years_exp"
                        variant= "outlined"
                        className="marginLeft-0"
                        setFieldValue= {setFieldValue}
                        textYrs={values.years_exp}
                        textMonths={values.month_exp}
                        fullWidth={true}
                        as={ExperienceTextField}
                        errorYrs={ errors.years_exp}
                        errorMonths={ errors.month_exp}
                    />        
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding0 flexDisplay marginVertical20" >
                <Grid item sm={6} xs={12}  className={`padding0`}>
                    <Field
                        name="expected_salary"
                        label="Expected salary"
                        variant= "outlined"
                        nameText="expected_salary"
                        nameSelect= "expected_salary_type"
                        error={errors.expected_salary}
                        text={values.expected_salary}
                        select = {values.expected_salary_type}
                        setFieldValue= {setFieldValue}
                        fullWidth={true}
                        as={TextFieldWithDropdown}
                    />        
                    {errors.expected_salary && <span style={{color:"red"}}>Enter salary as numeric value</span>}
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <FormControlLabel
                    control={
                        <Field 
                            checked={values.no_exp} 
                            onChange={(e) =>{console.log(e.target.name) ; setFieldValue('no_exp', e.target.checked);}} 
                            component={Checkbox}
                            disabled={values.years_exp || values.month_exp}
                            color="primary"
                        />
                    }
                    label="No work experience"
                />
            </Grid>
            
            <Grid item xs={12} className="padding0">
                <UploadButton 
                    name= {values.cvName}
                    setUpload= {handleCV}
                    
                />
            </Grid>
            {!values.no_exp && <Grid item xs={12} className="padding0 marginVertical20">
                <span className="fontSize24 textUnderline fontWeightBold">Last job details</span>
            </Grid>}
            {!values.no_exp && <Grid item sm={6} xs={12} className="padding0">
                {Object.keys(values.lastExperience).length>0?
                    <JobComponent
                        values= {values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        lastExp={true}
                        touched={touched}
                        locationList={locationList}
                        editFlag={editFlag}
                    />:
                    <Button 
                        color="primary"
                        onClick={() => setOpenWorkerExpModal(true)}
                    >
                        Add last job
                    </Button>

                }
            </Grid>}
            {!values.no_exp && <Grid item xs={12} className="padding0 marginVertical20">
                <span className="fontSize24 textUnderline fontWeightBold">Other experiences</span>
            </Grid>}
            {!values.no_exp && <Grid item xs={12} className="padding0">
                <OtherExperiences
                    values= {values}
                    setFieldValue= {setFieldValue}
                    locationList={locationList}
                    editFlag={editFlag}
                    setValues={setValues}
                    errors={errors}
                    touched={touched}
                />
            </Grid>}
            <Grid item xs={12} className="padding0 marginVertical20">
                <span className="fontSize24 textUnderline fontWeightBold">Interests</span>
            </Grid>
            <Grid item xs={12} className="padding0" >
                <Autocomplete
                    id="interests"
                    multiple
                    options={interestList.sort((a, b) => -b.name.localeCompare(a.name))}
                    value ={values.interests}
                    name="interests"
                    fullWidth ={true}
                    onChange={(event, value) => setFieldValue("interests", value)}
                    getOptionLabel={(option) =>{console.log({option}); return option.name}}
                    // style={{ width: "250px" }}
                    renderInput={(params) => <TextField {...params} variant= "outlined" className="marginLeft-0" />}
                />  
            </Grid>
            <Grid item xs={12} className="padding0 marginVertical20">
                <span className="fontSize24 textUnderline fontWeightBold">Skills</span>
            </Grid>
            <Grid item xs={12} className="padding0" >
                <Autocomplete
                    id="skills"
                    multiple
                    options={skillsList.sort((a, b) => -b.skills_name.localeCompare(a.skills_name))}
                    value ={values.skills}
                    name="skills"
                    fullWidth ={true}
                    onChange={(event, value) =>{
                        if(value.length<6){
                            setFieldValue("skills", value)
                            setSkillError("")
                        } 
                        else{
                            setSkillError("Can't add more than 5 skills.")
                        }
                    }}
                    getOptionLabel={(option) => option.skills_name}
                    // style={{ width: "250px" }}
                    renderInput={(params) => <TextField {...params} variant= "outlined" className="marginLeft-0" />}
                />  
            </Grid>
            {skillError && <Grid item xs={12} className="padding0" >
                <span style={{color: "red"}}>{skillError}</span> 
            </Grid>}
            {/* values.skills.find(x => x.skill_type_id=== -1) && <Grid container className="padding0 columnOrder">
                <Grid item xs={12} sm={6} className="padding0">
                <Field
                        name="otherSkill"
                        label="Other skill"
                        variant= "outlined"
                        className="marginLeft-0"
                        fullWidth={true}
                        value ={values.otherSkill}
                        onChange={ (event) =>setOtherSkillText(event.target.value)}
                        as={TextField}
                    /> 
                <Button
                    variant="contained"
                    className="blueColor"
                    onClick={handleAddSkill}
                >
                    Add Skill
                </Button>     
                </Grid>
            </Grid> */}
            <Grid item xs={12} className="padding0 marginVertical20">
                <span className="fontSize24 textUnderline fontWeightBold">Availability</span>
            </Grid>
            <Grid item xs={12} className="padding0" >
                <FormControlLabel
                    control={
                        <Field 
                            checked={values.global_available} 
                            onChange={(e) =>setFieldValue("global_available",e.target.checked)} 
                            component={Checkbox}
                            color="primary"
                        />
                    }
                    label="Available all time"
                />
            </Grid>
            {!values.global_available && <Grid item xs={12} className="padding0">
                <WorkerAvailability
                    values= {values}
                    setFieldValue= {setFieldValue}
                    errors={errors}
                    touched= {touched}
                />
            </Grid>}

            {openWorkerExpModal && <WorkerExpModal
                openWorkerExpModal={openWorkerExpModal}
                setOpenWorkerExpModal = {setOpenWorkerExpModal}
                lastExp= {true}
                editFlag={editFlag}
                values= {values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                locationList={locationList}
            />}

        </Grid>
    )
}
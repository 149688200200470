import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import urlLinks from '../../urlLinks'
import Axios from 'axios'
import qs from 'querystring'
import calender from '../../assets/calender.svg'
import time from '../../assets/timer.svg'

const flexDisplay = {
    margin: 'auto',
  },
  subTitle = {
    objectFit: 'contain',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    marginRight: '25px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '490px'
    },
  }));

const CallModal = (props) => {
  const classes = useStyles();
  const {approvalConfirmationModal, setApprovalConfirmationModal, row, handleCancel, updateStatus, quesList, setShowButtons} = props;
  const cancelApproval =() =>{
    handleCancel();
    setApprovalConfirmationModal(false);
  }
  return (

    <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={approvalConfirmationModal}
        onClose={() =>setApprovalConfirmationModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
    >
        <div className={classes.paper}>
            <Grid container>
                <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                    <h1>Approval confirmation</h1>
                </Grid>
                <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                    <span style={subTitle}>Questionnaire score : {row.questionnaireScore} / {quesList}</span>
                </Grid>
                <Grid item xs={12} className="padding0" direction="row">
                    <Button style={cancel} onClick={cancelApproval}>CANCEL</Button>
                    <Button style={confirm} onClick={() =>{updateStatus(); setApprovalConfirmationModal(false); setShowButtons(false)}}>CONFIRM</Button>
                </Grid>
            </Grid>
        </div>
    </Modal>
  );
};

export default CallModal;

import React, { useState, useContext, useEffect } from 'react'
import { Button, Checkbox, FormGroup, FormControlLabel, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VerificationLinkDialog from "./VerificationLinkDialog";
import InfoIcon from '@material-ui/icons/Info';

import Alert from '@material-ui/lab/Alert';
import Terms from '../App/Terms';
import UserPool from '../_services/UserPool';
import { AccountContext } from '../_services/user.account';

import { Link, useHistory } from 'react-router-dom';
import '../Login/Login.css'

function RegisterForm() {
    const [rememberMe, setRememberMe] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [termsPolicy, setTermsPolicy] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [helperTextInfo, setHelperTextInfo] = useState(false);

    const history = useHistory();
    const { getSession, registerUser, getUser } = useContext(AccountContext);
    useEffect(() => {
        getSession()
            .then(session => {
                history.push("/profile");
            }).catch(err => {
                console.log('err', err);
            });
    }, []);

    const handleModel = () => { setOpen(!open); };

    const label = (
        <span>I accept the &nbsp;
            <Link to="#" onClick={handleModel} className="underline" >Terms and Policies</Link>
        </span>
    );

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const showHelperText = () => {
        setHelperTextInfo(!helperTextInfo)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorMessage("");
        setIsSuccess(false);
        const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!email || !password || !retypePassword) {
            setErrorMessage("Email or Password details are missing");
        } else if (!emailRegex.test(email)) {
            setErrorMessage("Please enter valid email address.");
        }
        else if (password != retypePassword) {
            setErrorMessage("Password does not match.");
        } else if (!passwordRegex.test(password)) {
            setErrorMessage("The entered password does not meet the password policy.");
        }
        else if (!termsPolicy) {
            setErrorMessage("Please accept the  Terms and Policies");
        } else {
            registerUser(email).then(data => {
                if (data.code !== 1002) {
                    storeUser(email, password);
                } else {
                    setErrorMessage('This email id is already registered in our system for a different role.');
                    setIsSuccess(false);
                }
            }).catch(err => {
                setErrorMessage("An account with the given email already exists.");
                setIsSuccess(false);
            });

        }
    }

    const storeUser = (email, password) => {
        UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                setErrorMessage(err.message);
            }
            else {
                setIsSuccess(true);
            }
        });
    }

    return (<>
        <form onSubmit={handleSubmit}>
            <Grid container className="loginFormContainer">
                <Grid item xs={12} className="padding0" >
                    <span className="fontSize32 fontWeightBold">Create Account</span>
                </Grid>
                {errorMessage ?
                    <Alert variant="filled" severity="error">
                        {errorMessage}
                    </Alert> : ''}
                <Grid item xs={12} className="padding0" >
                    <Grid item xs={12} className="padding0 marginTop35" >
                        <span className="fontSize18 fontWeightBold">Email ID</span>
                    </Grid>
                    <Grid item xs={12} className="padding0 marginTop20" >
                        <TextField
                            name="email"
                            fullwidth
                            className={`margin0`}
                            variant="outlined"
                            placeholder="you@example.com"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="padding0 displayFlex marginTop20"  >
                    <Grid item xs={12} className="padding0" >
                        <span className="fontSize18 fontWeightBold">Password <InfoIcon className="helperInfo" onClick={showHelperText} /></span>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="padding0 marginTop20" >
                    <TextField
                        name="password"
                        fullwidth
                        variant="outlined"
                        className={` Rectangle-995`}
                        placeholder="Enter password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        helperText={helperTextInfo && "Password must contain minimum one lowercase, uppercase, symbol, number and minimum 8 characters"}
                        onChange={event => setPassword(event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />

                </Grid>
                <Grid item xs={12} className="padding0 displayFlex marginTop20 paddingTop20"  >
                    <Grid item xs={12} className="padding0" >
                        <span className="fontSize18 fontWeightBold">Confirm Password</span>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="padding0 marginTop20" >
                    <TextField
                        name="retypepassword"
                        fullwidth
                        variant="outlined"
                        className={` Rectangle-995`}
                        placeholder="Retype password"
                        type="password"
                        value={retypePassword}
                        onChange={event => setRetypePassword(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className='field-content marginTop20'  >
                    <Grid item xs={12} className={'form-group'} >
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox name="termsPolicy" color="primary" onChange={event => setTermsPolicy(!termsPolicy)} />}
                                label={label}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                    <Button
                        type="submit"
                        className="LoginButton"
                    >Create Account</Button>
                </Grid>

                <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                    <span className="fontSize20">Already have an account? &nbsp;&nbsp;</span>
                    <Link
                        className="createAccount"
                        to="/login"
                    >
                        Login
                            </Link>
                </Grid>

            </Grid>
            <Terms open={open} handleClose={handleModel} />
        </form>
        {isSuccess && <VerificationLinkDialog open={isSuccess} />}
    </>
    );
}



export default RegisterForm

import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as moment from 'moment'
import urlLinks from '../../../urlLinks'
import Axios from 'axios'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import qs from 'querystring'

const flexDisplay = {
    margin: 'auto',
  },
  ipBorder = {
    border: 'solid 1px #1c75bc',
    borderRadius: '4px',
    width: '170px',
    padding: '0 8px'
  },
  subTitle = {
    objectFit: 'contain',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  },
  txtArea = {
    border: 'none',
    borderBottom: 'solid 1px #1c75bc',
    fontSize: '14px',
    width: '320px',
    margin: '0 55px'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    marginRight: '25px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '490px'
    },
  }));

  const CustomAutocomplete = withStyles({
  
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: 0
      }
    }
  })(Autocomplete);
const CallModal = (props) => {
  const classes = useStyles();
    const [jobValue, setJobValue] = useState()

  const {openAssignVacancyModal, setOpenAssignVacancyModal, jobList, recrutierData, showToast, userIdList, toggleAllRowsSelected, currentJob} = props;
  const handleSaveReminder = () => {
     
        const postData={
            existing_job_detail_id : currentJob.jobDetailId,
            user_ids: userIdList.toString(), 
            new_job_detail_id : jobValue.jobDetailId
        }
        Axios.post(`${urlLinks.baseUrl}extWorker/assignCandidateToAnotherJob`, qs.stringify(postData))
        .then(response => {
            if(response.data.code ===1000){
                showToast("success", "Workers assigned successfully")
                toggleAllRowsSelected(false)
            }
            else{
                showToast("error", "workers assignment failed")
            }
        })
        .catch(error =>{
            // console.log("error", error)
            showToast("error", "workers assignment failed")
        })
        setOpenAssignVacancyModal(false)
    }
  return (

    <Modal
        className={classes.modal}
        open={openAssignVacancyModal}
        onClose={() =>setOpenAssignVacancyModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <div className={classes.paper}>
        <Grid container>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
              <h1>Assign to vacancy</h1>
          </Grid>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                <CustomAutocomplete
                    id="jobList"
                    options={jobList}
                    value={jobValue}
                    popupIcon= {<ExpandMoreIcon />}
                    onChange={(event, value) => setJobValue(value)}
                    style={{ width: "450px", margin: "0 50px 0 0", padding:0}}
                    getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                    renderInput={(params) => <TextField {...params} 
                        // style={{paddingLeft: "16px"}} 
                        className="margin0 " 
                        placeholder="Select Vacancy" 
                        variant="outlined" />}
                />
          </Grid>
          <Grid item xs={12} className="padding0" direction="row" style={{marginTop: "20px"}}>
            <Button style={confirm} onClick={handleSaveReminder} style={{marginRight: "20px"}}>CONFIRM</Button>
            <Button style={cancel} onClick={() =>setOpenAssignVacancyModal(false)}>CANCEL</Button>
          </Grid>
        </Grid>
        </div>
    </Modal>
  );
}

export default CallModal;

import React, { useState, useEffect } from 'react'
import { Grid , Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectVacancy =(props) =>{
    const {jobList, jobValue ,setJobValue, setOpenJobModal}=  props
    
    return (
        <Grid container>
            <span className="fontSize16 fontWeightBold">Select vacancy</span>
            <Autocomplete
                id="jobList"
                options={jobList}
                value={jobValue}
                onChange={(event, value) => setJobValue(value)}
                style={{ width: "450px", margin: "0 50px 0 30px"}}
                getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                renderInput={(params) => <TextField {...params}  className="margin0 borderLightGrey "/>}
            />
            <Button 
                variant="outlined" 
                className= "uploadButton fitContentHeight"
                disabled={!jobValue}
                onClick={() => setOpenJobModal(true)}
            >
                View Job details
            </Button>
        </Grid>
    )
}

export default SelectVacancy;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Done from '../assets/done.png';
import VerifiedImg from '../assets/thumb-up.png';
import OnHoldIcon from '../assets/sand-clock.png';
import RejectedIcon from '../assets/warning.png';
import InactiveIcon from '../assets/minus.png';
import { CommonConstants } from "../_constants";

const StatusTitleText = (props) => {
    switch (props.userStatus) {
        case ("REGISTERED").toUpperCase():
            return <>
            <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Done} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2><Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.REGISTERED_TITLE}</h4>
                <p className="success-p">{CommonConstants.REGISTERED_TEXT}</p>
            </Grid>
            </>
            break;
        case ("UNDER ASSESMENT").toUpperCase():
            return <>
            <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Done} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
            <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.UNDER_ASSESSMENT_TITLE}</h4>
                <p className="success-p">{CommonConstants.UNDER_ASSESSMENT_TEXT}</p>
            </Grid>
            </>
            break;
        case ("Approved").toUpperCase():
            return <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.APPROVED_TITLE}</h4>
                <p className="success-p" dangerouslySetInnerHTML={{__html:CommonConstants.APPROVED_TEXT}} />
            </Grid>
            break;
        case ("PENDING FOR ONBOARDING").toUpperCase():
            return <>
            <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Done} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
            <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.PENDING_FOR_ONBOARDING_TITLE}</h4>
                <p className="success-p" dangerouslySetInnerHTML={{__html:CommonConstants.PENDING_FOR_ONBOARDING_TEXT}} />
            </Grid>
            </>
            break;
        case ("On Hold").toUpperCase():
            return <><h2 style={{ width: 32, margin: '0 auto' }} ><img src={OnHoldIcon} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
            <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.ON_HOLD_TITLE}</h4>
                <p className="success-p" dangerouslySetInnerHTML={{__html:CommonConstants.ON_HOLD_TEXT}} />
            </Grid>
            </>
            break;
        case ("Rejected").toUpperCase():
            return <>
            <h2 style={{ width: 32, margin: '0 auto' }} ><img src={RejectedIcon} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
            <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4">{CommonConstants.REJECTED_TITLE}</h4>
                <p className="success-p">{CommonConstants.REJECTED_TEXT}</p>
            </Grid>
            </>
            break;
        case ("Inactive").toUpperCase():
            return <>
            <h2 style={{ width: 32, margin: '0 auto' }} ><img src={InactiveIcon} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
                <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                    <h4 className="success-h4">{CommonConstants.INACTIVE_TITLE}</h4>
                    <p className="success-p" dangerouslySetInnerHTML={{__html:CommonConstants.INACTIVE_TEXT}} />
                </Grid>
                <Grid item xs={12} className="padding0 marginTop20 textAlignCenter Registration-with-OkayGo" >
                    <Button
                        type="submit"
                        className="LoginButton"
                    ><Link to="/login" className="LoginButton"
                    >Login</Link></Button>
                </Grid>
            </>
            break;
        default:
            const text = (CommonConstants.REGISTERED_TEXT).replace('rpsupport@okaygo.in', "<a href='ll'>LinkedIn handle</a>");
            return <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                <h4 className="success-h4" >{CommonConstants.REGISTERED_TITLE}</h4>
                <p className="success-p" dangerouslySetInnerHTML={{__html:CommonConstants.REGISTERED_TEXT}} />
            </Grid>
            break;
    }
}


const RegisterSuccess = () => {
    const location = useLocation();
    const [userStatus, setUserStatus] = useState('');

    useEffect(() => {
        setUserStatus((location.state) ? location.state.userStatus : '')
    }, []);

    return (
        <>
            <Header link='true' />
            <Grid container className="Rectangle-989 Recruiter_create-account">
                <div className="success-page">
                    
                    <StatusTitleText userStatus={userStatus} />
                </div>
            </Grid>
        </>
    );
}


const EmailVerified = () => {
    return (
        <>
            <Header />

            <div className="Recruiter_create-account">

                <Grid container className="Rectangle-989">
                    <div className="success-page">
                        <h2 style={{ width: 32, margin: '0 auto' }} ><img src={VerifiedImg} alt="Hourglass" style={{ width: 64, margin: '0 auto' }} /></h2>
                        <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                            <h4 className="success-h4">Email ID has been verified</h4>
                            <p className="success-p">
                                You may now proceed to login into your account.
                            </p>

                        </Grid>
                        <Grid item xs={12} className="padding0 marginTop20 textAlignCenter Registration-with-OkayGo" >
                            <Button
                                type="submit"
                                className="LoginButton"
                            ><Link to="/login" className="LoginButton"
                            >Login</Link></Button>

                        </Grid>
                    </div>
                </Grid>

            </div>
        </>
    );
}

const UnderAssessment = () => {
    return (
        <>
            <Header />
            <Grid container className="Rectangle-989 Recruiter_create-account">
                <div className="success-page">
                    <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Done} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
                    <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                        <h4 className="success-h4">Your application is submitted</h4>
                        <p className="success-p">Allow us to process your application. It usually takes 2-3 days.</p>
                        <h3 className="success-h4">Application under assessment</h3>
                    </Grid>
                </div>
            </Grid>
        </>
    );
}

const PasswordChanged = () => {
    return (
        <>
            <div className={' profile-content'}>
                <Header />
                <Grid container className="Rectangle-989 Recruiter_create-account">
                    <div className="success-page">
                        <h2 style={{ width: 32, margin: '0 auto' }} ><img src={VerifiedImg} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
                        <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                            <p className="success-p">
                                Password changed successfully. Please login again with new password
                            </p>
                        </Grid>
                        <Grid item xs={12} className="padding0 marginTop20 textAlignCenter Registration-with-OkayGo" >
                            <Button
                                type="submit"
                                className="LoginButton"
                            ><Link to="/login" className="LoginButton"
                            >Login</Link></Button>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    );
}

const ResetSuccess = () => {
    return (
        <>
            <div className={' profile-content'}>
                <Header />
                <Grid container className="Rectangle-989 Recruiter_create-account">
                    <div className="success-page">
                        <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Done} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
                        <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                            <h4 className="success-h4">Your password has been reset</h4>
                            <p className="success-p">Login with your new password to access your account</p>
                        </Grid>
                        <Grid item xs={12} className="padding0 marginTop20 textAlignCenter Registration-with-OkayGo" >
                            <Button
                                type="submit"
                                className="LoginButton"
                            ><Link to="/login" className="LoginButton"
                            >Login</Link></Button>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    );
}

export default RegisterSuccess;
export { EmailVerified, PasswordChanged, ResetSuccess, UnderAssessment }

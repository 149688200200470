import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import TimeDateComponent from './TimeDateComponent';
import AddBoxIcon from '@material-ui/icons/AddBox';

class AvailableTime extends Component {
  constructor(props) {
    super(props);
    this.isErrorPropAvailable = false;
    // this.getElements =this.getElements.bind(this);
    this.getFilteredArray = this.getFilteredArray.bind(this);
    this.addElement = this.addElement.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
    this.updateData = this.updateData.bind(this);
    this.count = 1;
    this.data = [
      {
        id: 1,
        isVisible: true,
        values: {
          day: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false
          },
          startTime: null,
          endTime: null
        }
      }
    ];
    this.newAvailability = [];
    this.availability = [
      {
        id: '1',
        day: 'Everyday',
        isSelected: false
      },
      {
        id: '2',
        day: 'Monday',
        isSelected: false
      },
      {
        id: '3',
        day: 'Tuesday',
        isSelected: false
      },
      {
        id: '4',
        day: 'Wednesday',
        isSelected: false
      },
      {
        id: '5',
        day: 'Thursday',
        isSelected: false
      },
      {
        id: '6',
        day: 'Friday',
        isSelected: false
      },
      {
        id: '7',
        day: 'Saturday',
        isSelected: false
      },
      {
        id: '8',
        day: 'Sunday',
        isSelected: false
      }
    ];
    this.state = {
      elements: [
        {
          id: 1,
          isVisible: true,
          component: (
            <TimeDateComponent
              checkDays={this.checkDays}
              id={1}
              errors={this.props.errors}
              name={this.props.name}
              updateData={this.updateData}
              deleteItem={this.deleteElement}
              availability={this.availability}
              values={this.data[0].values}
            />
          )
        }
      ],
      isEveryDaySelected: false,
      availabilityTime: [],
      errors: this.props.errors,
      isErrorPropAvailable: false,
      touched: this.props.touched
    };
  }
  updateData(id, values) {
    this.data = this.data.map(
      data => {
        if (data.id === id) {
          data.values = values;
        }
        return data;
      },
      id,
      values
    );

    // const newValuesArray = this.data.map((data, index) => {
    //   return data.values
    // });

    // console.log(newValuesArray)
    this.props.setFieldValue(this.props.name, this.data);
  }

  addElement() {
    // const availability = this.getFilteredArray();
    // console.log(availability);
    if (this.count < 7) {
      this.count++;
      let element = {};
      element['id'] = this.state.elements.length + 1;
      element['component'] = (
        <TimeDateComponent
          checkDays={this.checkDays}
          errors={this.state.errors}
          name={this.props.name}
          updateData={this.updateData}
          deleteItem={this.deleteElement}
          id={this.state.elements.length + 1}
          values={{
            day: {
              Monday: false,
              Tuesday: false,
              Wednesday: false,
              Thursday: false,
              Friday: false,
              Saturday: false,
              Sunday: false
            },
            startTime: null,
            endTime: null
          }}
          availability={this.newAvailability}
        />
      );
      element['isVisible'] = true;
      const newAvailability = [...this.state.elements, element];
      this.setState({
        elements: newAvailability
      });
      this.data.push({
        id: this.state.elements.length + 1,
        values: {
          day: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false
          },
          startTime: null,
          endTime: null
        },
        isVisible: true
      });
      const newValuesArray = this.data.map((data, index) => {
        return data.values;
      });
      this.props.setFieldValue(this.props.name, newValuesArray);
    }
  }

  checkDays = day => {
    if (day === 'Everyday') {
      this.setState({ isEveryDaySelected: true });
      // this.availability = this.availability.filter(el => el.day !== day);
    } else {
      this.newAvailability = this.availability.filter(
        el => el.day !== 'Everyday'
      );
      this.newAvailability = this.newAvailability.filter(el => el.day !== day);
      this.setState({ isEveryDaySelected: false });
    }
  };

  deleteElement(id) {
    if (this.count > 1) {
      this.count--;
      this.data[id - 1].isVisible = false;
      let stateData = [...this.state.elements];
      stateData[id - 1].isVisible = false;
      if (stateData[id - 1].values) {
        stateData[id - 1].values.startTime = new Date();
        stateData[id - 1].values.endTime = new Date();
      }
      // stateData[id - 1].values.startTime = stateData[id - 1].values ?  new Date() : null;
      // stateData[id - 1].values.endTime = stateData[id - 1].values ?  new Date() : null;
      this.data = this.data.map((data, index) => {
        const values = { ...data.values };
        values['startTime'] = new Date();
        values['endTime'] = new Date();
        data.id = index + 1;
        data.values = values;
        data.isVisible = data.isVisible;
        return data;
      });
      // console.log(this.data);
      this.setState({ elements: stateData });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.errors) !== JSON.stringify(prevState.errors)) {
      return {
        errors: nextProps.errors,
        isErrorPropAvailable: true,
        touched: nextProps.touched
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, PrevState) {
    // console.log(this.data);
    if (this.state.isErrorPropAvailable) {
      this.setState({
        isErrorPropAvailable: false,
        elements: this.state.elements.map((element, index) => {
          element.id = index + 1;
          element.component = (
            <TimeDateComponent
              checkDays={this.checkDays}
              errors={this.state.errors}
              name={this.props.name}
              updateData={this.updateData}
              deleteItem={this.deleteElement}
              id={index + 1}
              values={{
                day: {
                  Monday: false,
                  Tuesday: false,
                  Wednesday: false,
                  Thursday: false,
                  Friday: false,
                  Saturday: false,
                  Sunday: false
                },
                startTime: null,
                endTime: null
              }}
              availability={this.availability}
            />
          );
          return element;
        })
      });
    }
  }

  getFilteredArray() {
    let items = [];
    if (this.state.isEveryDaySelected) {
      return items;
    } else {
      items = [...this.newAvailability];
    }
    return items;
  }

  render() {
    const Elements = this.state.elements.map((element, index) => {
      if (element.isVisible) {
        return (
          <div key={index} style={{ margin: '10px 0' }}>
            {element.component}
          </div>
        );
      }
    });

    const addComponent = (
      <Button style={{ marginLeft: 'auto' }} onClick={this.addElement}>
        <AddBoxIcon></AddBoxIcon>
      </Button>
    );
    return (
      <div>
        <div style={{ display: 'flex' }}>
          {!this.state.isEveryDaySelected ? addComponent : null}
        </div>
        <div>{Elements}</div>
      </div>
    );
  }
}

export default AvailableTime;

import React, { Component } from 'react';
import { Button, Typography, Divider, OutlinedInput } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ImageUpload from '../../utils/ImageUploader/ImageUpload';
import MenuItem from '@material-ui/core/MenuItem';
import Experience from './experience'
import Switch from '@material-ui/core/Switch';
import { lightGreen } from '@material-ui/core/colors';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import qs from 'querystring';
import urlLinks from '../../urlLinks';
import * as moment from 'moment';
import { CircleLoader } from 'react-spinners';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import AvailableTime from '../../utils/AvailableTimeComponent/AvailableTime';
import Experince from '../../utils/Experience/experience';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DropDownConstants from '../../dropDownConstant';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import getworkerFilterList from './workerConstants';

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: '70px'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  textContent: {
    fontSize: '0.8rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  }
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: lightGreen[200],
    '&$checked': {
      color: lightGreen['A700']
    },
    '&$checked + $track': {
      backgroundColor: lightGreen['A700']
    }
  },
  checked: {},
  track: {}
})(Switch);

class AddWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('userId'),
      isSubmitting: false,
      industry: [],
      industryOptions:{},
      jobTypes: [],
      isExperience: false,
      qualification: {},
      gender:["Male", "Female"],
      everyDaySelected: false, 
      pincode:null,
      isValidPin:true,
      isAvailability:false
    };
    this.workDaysError = false;
    this.addWorkerSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
        
      dateOfBirth: Yup.date()
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'Age Must Be Greater Than 18'
        )
        .required('Required')
        .nullable(),
      contactNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid Contact Number')
        .required('Contact Number is Required'),
      qualification: Yup.string().required('Required'),
      gender: Yup.string().required('Required'),
      addressLine1: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
      // profilePhotoName: Yup.string().required('Required'),
      // aadhaarFrontName: Yup.string().required('Required'),
      // aadhaarBackName: Yup.string().required('Required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      city: Yup.string()
        .trim()
        .required('Required'),
      state: Yup.string()
        .trim()
        .required('Required'),
      pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid Pincode')
        .required('Required'),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     //  day: Yup.string().required('Required'),
      //     values: Yup.object().shape({
      //       startTime: Yup.date()
      //         .required('Required')
      //         .nullable(),
      //       endTime: Yup.date()
      //         .required('Required')
      //         .nullable()
      //       //   day:Yup.object().shape({
      //       //     Monday:Yup.boolean(),
      //       //     Tuesday:Yup.boolean(),
      //       //     Wednesday:Yup.boolean(),
      //       //     Thursday:Yup.boolean(),
      //       //     Friday:Yup.boolean(),
      //       //     Saturday:Yup.boolean(),
      //       //     Sunday:Yup.boolean()
      //       //   })
      //       //   .test(
      //       //     'myCustomTest',
      //       //     null,
      //       //     (obj) => {
      //       //       if ( obj.red || obj.orange || obj.green ) {
      //       //         return true; // everything is fine
      //       //       }

      //       //       return new Yup.ValidationError(
      //       //         'Please check one checkbox',
      //       //         null,
      //       //         'myCustomFieldName'
      //       //       );
      //       //     }
      //       //   )
      //     })
      //   })
      // )
      // Experince: Yup.array().of(
      //   Yup.object().shape({
      //     industry: Yup.string().required('Required'),
      //     company: Yup.string().required('Required'),
      //     jobProfile: Yup.string().required('Required'),
      //     // experience: Yup.string().required('Required')
      //   })
      // ),
      // socialMedium: Yup.string().required('Required'),
      // otherSource: Yup.string().when('socialMedium', {
      //   is: socialMedium => socialMedium === 'Other Source',
      //   then: Yup.string().required('Required')
      // })
    });

    this.englishFluency=[
      {
        id:1,
        label:'No English',
        value:1,
      },
      {
        id:2,
        label:'Thoda English',
        value:2,
      },
      {
        id:3,
        label:'Good English',
        value:3,
      }
    ];
    this.addressProof = ['Driving Licence', 'Voter ID'];
    this.socialMedium = [
      'Quicker/Job Portal',
      'Leader',
      'Personal Contact',
      'Reference from some worker',
      'Other Source'
    ];
    this.addressProofBlob = null;
    this.addressProofBlob1 = null;

    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.updateProfileImage = this.updateProfileImage.bind(this);
    this.formikSetValues = null;
    this.formikValues = {};

    this.profileImage = '';
    this.profileImageFileName = '';
    this.aadhaarFront = '';
    this.aadhaarFrontName = '';
    this.aadhaarFrontType = '';
    this.aadhaarBack = '';
    this.aadhaarBackName = '';
    this.aadhaarBackType = '';
    
    this.cv = '';
    

    
    this.lat = "";
    this.lat = "";
  }

  updateProfileImage(image, filename) {
    this.profileImage = image;
    this.profileImageFileName = filename;
    let newformikValues = { ...this.formikValues };
    newformikValues['profilePhotoName'] = filename;
    this.formikSetValues(newformikValues);
  }

  updateAadhaarFront = (image, filename, imageType) => {
    this.aadhaarFront = image;
    this.aadhaarFrontName = filename;
    this.aadhaarFrontType = imageType;
    let newformikValues = { ...this.formikValues };
    newformikValues['aadhaarFrontName'] = filename;
    this.formikSetValues(newformikValues);
  };
  

  updateAadhaarBack = (image, filename, imageType) => {
    this.aadhaarBack = image;
    this.aadhaarBackName = filename;
    this.aadhaarBackType = imageType;
    let newformikValues = { ...this.formikValues };
    newformikValues['aadhaarBackName'] = filename;
    this.formikSetValues(newformikValues);
  };

  // updateCv = (image, filename, imageType) => {
  //   this.Cv = image;
  //   this.CvName = filename;
  //   this.CvType = imageType;
  //   let newformikValues = { ...this.formikValues };
  //   newformikValues['CvName'] = filename;
  //   this.formikSetValues(newformikValues);
  // };
  handlePincodeChange =(event) =>{
    let pincode=event.target.value;
    console.log({pincode});
    
    this.setFormikFieldValue("pincode", pincode)
    if(pincode.length===6){
      this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    }
    
  }
  checkPincode =(pincode) =>{
    Axios.get(`${urlLinks.baseUrl}tracking/getcityandstate?pincode=${pincode}`)
      .then(response =>{
        if(response.data.response.length!==0){
          this.setState({isValidPin:true})
          let addressDetails = {
            pincode: pincode,
            city:response.data.response[0].district, 
            state:response.data.response[0].stateName
          };
          let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
          this.formikSetValues(newformikValues);
        }
        else{
          this.setState({isValidPin:false})
        }
      })
      .catch(error =>{
        // toast.error("Unable to retrieve city and state.")
      })
  }
  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const geometry = addressObject.geometry.location;
    this.lat = geometry.lat();
    this.lang = geometry.lng();
    let pincode= null;
    addressObject.address_components.map(component =>{
        if(component.types.includes("postal_code")){
          pincode=component.long_name;
        }
      })
    let addressDetails = {
      searchAddress: addressObject.formatted_address,
      pincode: pincode,
    };
    let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
    this.formikSetValues(newformikValues);
    this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    
  }

  handleChange1=(event)=>{
    console.log(event)
    console.log(event.target.value)
    this.setState({
      isAvailability:!this.state.isAvailability
    })
  }

  componentDidMount() {
    const industries = [];
    const jobTypes = [];
    Axios.get(`${urlLinks.baseUrl}/configmaster/entityList`).then(response => {
      const qualification = {};
      for (let [key, value] of Object.entries(
        response.data.response.content.qualification_type
      )) {
        qualification[value] = key;
      }
      this.setState({ qualification: qualification });
    });
    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&inserted_by=0&rows=20&status=1`
    ).then(response => {
      this.setState({ industry: response.data.response.content });
    });
    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`
    ).then(response => {
      this.setState({ jobTypes: response.data.response.content});
    });
    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('google-autocomplete-input-worker', {})
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }


  render() {
    
    const { classes } = this.props;
    const qualifications = Object.keys(this.state.qualification);
    console.log(this.state.qualification);
    if (this.state.isSubmitting) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
      return (
        <div className={classes.root}>
          <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              gender: '',
              dateOfBirth: null,
              contactNumber: '',
              qualification: '',
              EValue:'',
              email:'',
              searchAddress:'',
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              cv:'',
              pincode: '',
              addressProof: '',
              availability: [
                {
                  id: null,
                  isVisible: null,

                  values: {
                    day: {
                      Monday: null,
                      Tuesday: null,
                      Wedneday: null,
                      Thursday: null,
                      Firday: null,
                      Saturday: null,
                      Sunday: null
                    },
                    startTime: null,
                    endTime: null
                  }
                }
              ],
              Experince: [],
              jobCategory: [],
              jobProfile: [],
              profileImageName: '',
              aadhaarFrontName: '',
              aadhaarBackName: '',
              CvName:'',
              socialMedium: '',
              otherSource: ''
            }}
            validationSchema={this.addWorkerSchema}
            onSubmit={values => {
              console.log(values)
              this.setState({ isSubmitting: true });

              const values2 = values['availability'].filter((values, index) => {
                return values['isVisible'] !== false;
              });

              const values3 = values['Experince'].filter((values, index) => {
                return values['isVisible'] !== false;
              });
              console.log({values3});
              
              const availability1 = values2.map(el => el.values['startTime']);
              const availability2 = values2.map(el => el.values['endTime']);
              let availability3 = values2.map(el => el.values['day']);

              const daysMap = ['2', '3', '4', '5', '6', '7', '1'];
              // console.log(availability3);
              // console.log(daysToMap);
              const allEqualArr = [];
              availability3.map((el, index) => {
                const availability4 = {};
                const daysToMap = Object.values(availability3[index]);
                for (var i = 0, len = daysMap.length; i < len; i++) {
                  availability4[daysMap[i]] = daysToMap[i];
                }
                const arr = Object.values(availability3[index]);
                const first = Object.values(availability3[index])[0];
                const allEqual = arr.every(v => v === false);
                // console.log(Object.values(availability3[index]));
                // console.log(allEqual);
                if (allEqual) {
                  allEqualArr.push(false);
                  // this.workDaysError = true;
                } else {
                  allEqualArr.push(true);
                  // this.workDaysError = false;
                }
                availability3[index] = availability4;
              });
              // console.log(allEqualArr);
              const allEqual = allEqualArr.every(v => v === true);
              // console.log(allEqual);
              this.workDaysError = allEqual;
              // console.log(availability3);
              // const isEmpty = Object.values(availability3).every(
              //   x => x === false || x === ''
              // );
              // console.log(Object.values(availability3[0]));

              // console.log(allEqual);
              // availability3 = [{ ...availability4 }];

              var StartTime = [],
                j = 0;
              if(this.state.isAvailability!==true){
                for (let i = 0; i < availability1.length; i++) {
                  StartTime[j] = availability1[i].toTimeString().substring(0, 8);
                  j++;
                }
              }
              
              var EndTime = [],
                k = 0;
              if(this.state.isAvailability!==true){
                for (let i = 0; i < availability2.length; i++) {
                  EndTime[k] = availability2[i].toTimeString().substring(0, 8);
                  k++;
                }
              }  
              
              var finalAvailability = [];
              
              for (let i = 0; i < StartTime.length; i++) {
                var a = {};
                a.active_status = 1;
                a.availabilityType = 0;
                a.loginTime = StartTime[i];
                a.logoutTime = EndTime[i];
                a.workdaysDetails = JSON.stringify(availability3[i]);
                finalAvailability[i] = a;
              }
              console.log({finalAvailability});
              
              var finalExperince = [];
              
              values3.map(exp =>{
                finalExperince.push({
                  toDate: exp.endDate?moment(exp.endDate).format("YYYY-MM-DD"): null,
                  fromDate: moment(exp.startDate).format("YYYY-MM-DD"),
                  companyName: exp.company,
                  industryType: exp.industry,
                  jobType: exp.jobProfile
                })
              })
              console.log({finalExperince});
              var document = [];
              var c = {};
              var c2 = {};
              // c.file_mime_typ1=values.aadhaarBackName;
              // c.file_path1=this.addressProofBlo1;
              if (this.aadhaarFront) {
                document.push({
                  b64EncodedDocument: this.aadhaarFront,
                  documentName: this.aadhaarFrontName,
                  documentTypeId: 17,
                  fileMimeType: this.aadhaarFrontType
                });
              }

              if (this.aadhaarBack) {
                document.push({
                  b64EncodedDocument: this.aadhaarBack,
                  documentName: this.aadhaarBackName,
                  documentTypeId: 18,
                  fileMimeType: this.aadhaarBackType
                });
              }
              console.log({values});
              
              // c.file_mime_typ = values.aadhaarFrontName;
              // c.file_path = this.addressProofBlob;
              // document[0] = c;

              // c2.file_mime_typ = values.aadhaarBackName;
              // c2.file_path = this.addressProofBlob1;
              // document[1] = c2;
              let cityId = DropDownConstants.states[values.state] || -1;
              const jobProfiles = [];
              console.log(values.jobProfile);
              values.jobProfile.map(el => {
                console.log({el});
                
                jobProfiles.push({
                  jobType: el.id
                });
              });
              var DateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");

              var updatedValues = Object.assign({});
              updatedValues.addressLine1 = values.addressLine1;
              updatedValues.addressLine2 = values.addressLine2;
              updatedValues.availableFt = 1;
              updatedValues.availableOd = 0;
              updatedValues.availablePt = 1;
              updatedValues.cityId = cityId;
              updatedValues.dateOfBirth = DateOfBirth;
              updatedValues.emailId = '';
              updatedValues.userStatus = 1;
              updatedValues.firstName = values.firstName;
              updatedValues.lastName = values.lastName;
              updatedValues.gender = values.gender==="Male"?26:27;
              updatedValues.requestedBy = +this.state.userId;
              updatedValues.pincode = values.pincode;
              updatedValues.userGoogleLocation=values.searchAddress;
              updatedValues.city = values.city;
              updatedValues.state = values.state;
              updatedValues.globalAvailability = 0;
              updatedValues.hasExperience = 1;
              // updatedValues.lastName = '';
              updatedValues.paytmNumber = values.contactNumber.toString();
              updatedValues.phoneNumber = values.contactNumber.toString();
              // updatedValues.roleType = 4;
              updatedValues.profilePhoto = this.profileImage;
              updatedValues.profilePhotoName = this.profileImageFileName;
              // updatedValues.aadhaarFront = this.aadhaarFront;
              // updatedValues.aadhaarBack = this.aadhaarBack;
              updatedValues.qualificationId = +this.state.qualification[
                values.qualification
              ];
              updatedValues.spokenLanguage = 'hinglish';
              updatedValues.countryCode = '91';
              updatedValues.userDocuments = document;
              updatedValues.jobInterests = jobProfiles;
              updatedValues.workerAvailabilities = this.state.isAvailability?[]:finalAvailability;
              updatedValues.workerExperiences = this.state.isExperience
                ? finalExperince
                : [];
              updatedValues.globalAvailability =this.state.isAvailability?1:0;
              updatedValues.english_known_level=values.EValue;
              updatedValues.emailId=values.email;
              updatedValues.cvLink=this.cv;
              updatedValues.cvName=values.cv;


              if(this.state.isAvailability){
                this.workDaysError=true;
              }
              
              if (this.workDaysError) {
                console.log({updatedValues});
                Axios.post(
                  `${urlLinks.baseUrl}${urlLinks.workerUrls.postAddEditWorker}`,
                  updatedValues
                )
                  .then(response => {
                    console.log(response);
                    if (response.data.code === 1000) {

                      this.setState({ isSubmitting: false });
                      const sessionData = {
                        last_seen_lat: +this.lat,
                        last_seen_long: +this.lang,
                        user_id: response.data.response.userId,
                      }
                      Axios.post(`${urlLinks.baseUrl}/user/session`, qs.stringify(sessionData))
                      .then(response2 => {
                        toast.success('Worker Added Successfully');
                        // setTimeout(window.location.reload(), 4000);
                      })
                    } else {
                      throw new Error(response.data.response);
                    }
                  })
                  .catch(error => {
                    // console.log(error);
                    this.setState({ isSubmitting: false });
                    // setTimeout(window.location.reload(), 4000);
                    toast.error(error.message);
                  });
              } else {
                this.setState({ isSubmitting: false });
                toast.error(`Available Days Can't Be Unchecked`);
              }
            }}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
                setValues
              } = props;
              this.formikValues = values;
              this.formikSetValues = setValues;
              this.setFormikFieldValue=setFieldValue
              return (
                <>
                  {/* <OutlinedInput
                  name='profileImageName'
                  value={values.profileImageName}
                  error={
                    errors.profileImageName && touched.profileImageName
                  }
                  className={classes.textBoxStyle}
                  readOnly
                > */}

                  <ImageUpload
                    updateProfileImage={this.updateProfileImage}
                  ></ImageUpload>
                  {/* </OutlinedInput> */}
                  <Form
                    className={classes.form}
                    // onSubmit={(event, values) => {
                    //   event.preventDefault();
                    //   console.log(this.formikValues)

                    // }}
                  >
                    <Grid container spacing={2} className={classes.grid}>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Worker Details
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.firstName && touched.firstName}
                          label='First Name'
                          name='firstName'
                          value={values.firstName}
                          onChange={handleChange}
                          helperText={
                            errors.firstName && touched.firstName && errors.firstName
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          // error={errors.name && touched.name}
                          label='Last Name'
                          name='lastName'
                          value={values.lastName}
                          onChange={handleChange}
                          // helperText={
                          //   errors.name && touched.name && errors.name
                          // }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.gender && touched.gender}
                          label='Gender'
                          name='gender'
                          value={values.gender}
                          onChange={handleChange}
                          helperText={
                            errors.gender &&
                            touched.gender &&
                            errors.gender
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {this.state.gender.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          type='number'
                          error={errors.contactNumber && touched.contactNumber}
                          label='Contact Number'
                          name='contactNumber'
                          value={values.contactNumber}
                          onChange={handleChange}
                          helperText={
                            errors.contactNumber &&
                            touched.contactNumber &&
                            errors.contactNumber
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.email && touched.email}
                        label='Email Id'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.qualification && touched.qualification}
                          label='English Fluency'
                          name='EValue'
                          value={values.EValue}
                          onChange={handleChange}
                          helperText={
                            errors.qualification &&
                            touched.qualification &&
                            errors.qualification
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {this.englishFluency.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>  

                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            maxDate={new Date()}
                            autoOk
                            InputProps={{
                              className: classes.input
                            }}
                            helperText={
                              errors.dateOfBirth &&
                              touched.dateOfBirth &&
                              errors.dateOfBirth
                            }
                            error={errors.dateOfBirth && touched.dateOfBirth}
                            variant='inline'
                            inputVariant='outlined'
                            label='Date Of Birth'
                            format='dd/MM/yyyy'
                            value={values.dateOfBirth}
                            InputAdornmentProps={{ position: 'start' }}
                            onChange={date => {
                              setFieldValue('dateOfBirth', date);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.qualification && touched.qualification}
                          label='Qualification'
                          name='qualification'
                          value={values.qualification}
                          onChange={handleChange}
                          helperText={
                            errors.qualification &&
                            touched.qualification &&
                            errors.qualification
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {qualifications.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <TextField
                          className={classes.text_container}
                          id='google-autocomplete-input-worker'
                          onKeyPress={event =>
                            event.key === 'Enter'
                              ? event.preventDefault()
                              : null
                          }
                          variant='outlined'
                          label='Search Google Maps'
                          name='searchAddress'
                          onChange={handleChange}
                          value={values.searchAddress}
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.addressLine1 && touched.addressLine1}
                          onChange={handleChange}
                          label='Exact address'
                          name='addressLine1'
                          value={values.addressLine1}
                          helperText={
                            errors.addressLine1 &&
                            touched.addressLine1 &&
                            errors.addressLine1
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.addressLine2 && touched.addressLine2}
                          label='Landmark'
                          name='addressLine2'
                          value={values.addressLine2}
                          onChange={handleChange}
                          helperText={
                            errors.addressLine2 &&
                            touched.addressLine2 &&
                            errors.addressLine2
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          type='number'
                          error={(errors.pincode && touched.pincode)|| !this.state.isValidPin}
                          label='Pincode'
                          name='pincode'
                          disabled={this.state.isValidPin}
                          value={values.pincode}
                          onChange={event => this.handlePincodeChange(event)}
                          helperText={
                            (errors.pincode && touched.pincode) || (!this.state.isValidPin && "Please enter a valid pin number.")
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.city && touched.city}
                          label='City'
                          name='city'
                          disabled
                          value={values.city}
                          helperText={
                            errors.city && touched.city && errors.city
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.state && touched.state}
                          label='State'
                          name='state'
                          disabled
                          value={values.state}
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Availability
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        Mein sab time free hun
                        <PurpleSwitch
                         checked={this.state.isAvailability}
                          onChange={this.handleChange1}
                        />
                        {this.state.isAvailability===false?(
                          <Grid item xs={12} sm={12}>
                            <FieldArray
                              name='availability'
                              render={arrayHelpers => (
                                <AvailableTime
                                  setFieldValue={arrayHelpers.form.setFieldValue}
                                  errors={arrayHelpers.form.errors['availability']}
                                  touched={
                                    arrayHelpers.form.touched['availability']
                                  }
                                  name='availability'
                                />
                              )}
                            ></FieldArray>
                          </Grid>):null
                        }
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Experience
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        Does The Worker Have Some Previous Experience
                        <PurpleSwitch
                          checked={this.state.isExperience}
                          onChange={() => {
                            this.setState({
                              isExperience: !this.state.isExperience
                            });
                          }}
                        />
                        { 
                          this.state.isExperience &&
                          this.state.jobTypes.length > 0 &&
                          this.state.industry.length > 0 &&
                            <Experience
                              values={values}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              industry={this.state.industry}
                              jobRoles= {this.state.jobTypes}

                            />}
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Interested In
                        </Typography>
                      </Grid>

                      {/* <Grid item sm={6} xs={12}>
                        <Autocomplete
                          multiple
                          name='jobCategory'
                          onChange={(event, value) => {
                            setFieldValue('jobCategory', value);
                          }}
                          options={Object.keys(this.state.industry)}
                          getOptionLabel={option => option}
                          defaultValue={values.jobCategory}
                          renderInput={params => {
                            return (
                              <TextField
                                {...params}
                                variant='standard'
                                error={Boolean(
                                  errors.jobCategory && touched.jobCategory
                                )}
                                style={{ width: '200px' }}
                                label={'Job Category'}
                              />
                            );
                          }}
                        />
                      </Grid> */}
                      {/* {console.log(this.state.jobTypes)} */}
                      <Grid item sm={6} xs={12}>
                        <Autocomplete
                          multiple
                          name='jobProfile'
                          onChange={(event, value) => {
                            console.log(values.jobProfile, value);
                            
                            setFieldValue('jobProfile', value);
                          }}
                          options={this.state.jobTypes}
                          getOptionLabel={option =>  option.categorySubType}
                          defaultValue={values.jobProfile}
                          renderInput={params => {
                            return (
                              <TextField
                                {...params}
                                variant='standard'
                                error={Boolean(
                                  errors.jobProfile && touched.jobProfile
                                )}
                                style={{ width: '200px' }}
                                label={'Job Profile'}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                       {/* <Grid item xs={12} sm={12}> */}
                        {/* <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Aadhaar Card
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        style={{ padding: '8px', marginBottom: '15px' }}
                      > */}
                        {/* <Grid item xs={4} style={{ alignSelf: 'center' }}>
                        <TextField
                          variant='outlined'
                          className={classes.text_container}
                          select
                          onChange={event => {
                            setFieldValue(
                              [event.target.name],
                              event.target.value
                            );
                          }}
                          label='Address Proof'
                          name='addressProof'
                          value={values.addressProof}
                          error={errors.addressProof && touched.addressProof}
                          helperText={
                            errors.addressProof &&
                            touched.addressProof &&
                            errors.addressProof
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {this.addressProof.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                        {/* <Grid item xs={2}>
                          FRONT
                        </Grid>
                        {/* updateAadhaarFrontupdateAadhaarFront */}
                        {/* style={{display: '-webkit-inline-box'}} */}
                        {/* <Grid item xs={4}>
                          <OutlinedInput
                            name='addressProofFileName'
                            value={values.aadhaarFrontName}
                            error={
                              errors.aadhaarFrontName &&
                              touched.aadhaarFrontName
                            }
                            className={classes.textBoxStyle}
                            readOnly
                          />
                        </Grid>
                        <Grid item xs={3} style={{ marginTop: '0px' }}>
                          <ImageUpload
                            display={true}
                            updateProfileImage={this.updateAadhaarFront}
                          ></ImageUpload> */} 
                          {/* <Button
                            variant='contained'
                            component='label'
                            error={
                              errors.aadhaarFrontName &&
                              touched.aadhaarFrontName
                            }
                            className={classes.alignFormat}
                          >
                            Browse
                            <input
                              type='file'
                              accept='image/png , image/jpeg'
                              style={{ display: 'none' }}
                              onChange={event => {
                                const file = event.target.files[0];
                                this.addressProofBlob = URL.createObjectURL(file);
                                setFieldValue(
                                  'aadhaarFrontName',
                                  event.target.files[0].name
                                );
                              }}
                            />
                          </Button> */}
                        {/* </Grid>

                        
                        <Grid item xs={3}></Grid>

                        <Grid item xs={2} style={{ paddingTop: '10px' }}>
                          {' '}
                          BACK{' '}
                        </Grid>
                        <Grid item xs={4} style={{ paddingTop: '10px' }}>
                          <OutlinedInput
                            name='addressProofFileName'
                            value={values.aadhaarBackName}
                            error={
                              errors.aadhaarBackName && touched.aadhaarBackName
                            }
                            className={classes.textBoxStyle}
                            readOnly
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{ paddingTop: '10px', marginTop: '0px' }}
                        >
                          <ImageUpload
                            display={true}
                            updateProfileImage={this.updateAadhaarBack}
                          ></ImageUpload> 
                          {/* <Button
                            variant='contained'
                            component='label'
                            className={classes.alignFormat}
                          >
                            Browse
                            <input
                              type='file'
                              accept='image/png , image/jpeg'
                              style={{ display: 'none' }}
                              onChange={event => {
                                const file = event.target.files[0];
                                this.addressProofBlob1 = URL.createObjectURL(
                                  file
                                );
                                setFieldValue(
                                  'aadhaarBackName',
                                  event.target.files[0].name
                                );
                              }}
                            />
                          </Button> */}
                        
                        
                        <Grid item xs={2} style={{ alignSelf: 'Left' }}>
                        <Typography style={{ fontSize: '0.9rem' }}>
                          CV Upload:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <OutlinedInput
                          name='cv'
                          value={values.cv}
                          error={
                            errors.companyRegistrationDoc &&
                            touched.companyRegistrationDoc
                          }
                          className={classes.textBoxStyle}
                          readOnly
                        />
                      </Grid>
                      
                        <Grid item xs={3}>
                        <Button
                          variant='contained'
                          component='label'
                          className={classes.alignFormat}
                        >
                          Browse
                          <input
                            type='file'
                            accept='application/pdf'
                            style={{ display: 'none' }}
                            onChange={event => {
                              
                              const reader = new FileReader();
                              const file = event.target.files[0];
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                this.cv = reader.result;
                                console.log(this.cv)
                                setFieldValue(
                                  'cv',
                                  file.name
                                );
                                setFieldTouched(
                                  'cv',
                                  true,
                                  false
                                );
                              };
                              
                            }}
                          />
                        </Button>
                      </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                      {/* <Grid
                        container
                        style={{ padding: '8px', marginBottom: '15px' }}
                      >
                        <Grid item xs={4} style={{ alignSelf: 'center' }}>
                          <Typography
                            variant='h6'
                            className={classes.textContent}
                          >
                            Where did worker come to know about portal?
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ alignSelf: 'center' }}>
                          <TextField
                            variant='outlined'
                            className={classes.text_container}
                            select
                            onChange={event => {
                              setFieldValue(
                                [event.target.name],
                                event.target.value
                              );
                            }}
                            label='Select Option'
                            name='socialMedium'
                            value={values.socialMedium}
                            error={errors.socialMedium && touched.socialMedium}
                            helperText={
                              errors.socialMedium &&
                              touched.socialMedium &&
                              errors.socialMedium
                            }
                            InputProps={{
                              className: classes.input
                            }}
                            InputLabelProps={{
                              className: classes.inputLabel
                            }}
                          >
                            {this.socialMedium.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <OutlinedInput
                            name='otherSource'
                            value={values.otherSource}
                            error={errors.otherSource && touched.otherSource}
                            className={classes.textBoxStyle}
                            style={{
                              display:
                                values.socialMedium.toLowerCase() ===
                                'other source'
                                  ? 'flex'
                                  : 'none'
                            }}
                          />
                        </Grid>
                      </Grid> */}

                      <Button
                        type='submit'
                        disabled={this.state.isSubmitting}
                        variant='contained'
                        className={classes.buttonStyle}
                        color='primary'
                      >
                        Submit
                      </Button>
                    {/* </Grid> */}
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      );
    }
  }
}

export default withStyles(styles)(AddWorker);

import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide, TextField, FormControlLabel, Checkbox} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../urlLinks';
import Axios from 'axios';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const AssignToRecruiterModal =(props) =>{
    const { openAssignRecruiterJobModal, setOpenAssignRecruiterJobModal, handleUpload, jobRecData, setJobRecData, handlejobAssign} = props
    const classes = useStyles();
    const [recruiterAssignCheck, setRecruiterAssignCheck] = useState(false)
    const [recruiterList, setRecruiterList] = useState()
    const [recruiterData, setRecruiterData] = useState()
    const [jobValue, setJobValue] = useState()
    const [disableAssignButton, setDisableAssignButton] = useState(true)
    const [jobList, setJobList] = useState([])
    console.log({jobList})
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        // console.log("called");
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:''
            })
        })
        return list;
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}extWorker/getAllJobDetails`)
            .then(response =>{
                setJobList(makeJobList(response.data.response))
            })
        Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                setRecruiterList(response.data.response)
            })
    }, [])

    
    const confirmAssignRecruiter = () =>{
        
        console.log({recruiterData})
        let jobRecId={
            jobId: jobValue.jobId 
        }
        if(recruiterData)
            jobRecId.recruiterId= recruiterData.userID
        console.log({jobRecId})
        if(setJobRecData)
            setJobRecData(jobRecId)
    }
    useEffect(() => {
        if(jobRecData ){
            if(handleUpload){
                handleUpload();
                setOpenAssignRecruiterJobModal(false)
            }
            else if(handlejobAssign){
                console.log("handleAssignJob");
                handlejobAssign();
                setOpenAssignRecruiterJobModal(false)
            }
        }
    }, [jobRecData])
    const handleDisableConfirmButton =() =>{
        if(!recruiterAssignCheck && !jobValue){
            console.log("jobValue check")
            setDisableAssignButton(true);
        }
        else if(recruiterAssignCheck && !recruiterData){
            console.log("recruiter check")
            setDisableAssignButton(true);
        }
        else{
            console.log("no error check")
            setDisableAssignButton(false);
        } 
    }
    useEffect(() => {
        handleDisableConfirmButton()
    }, [recruiterAssignCheck, jobValue, recruiterData])

    const handleRecruiterAssignCheck =() =>{
        
        setRecruiterAssignCheck(!recruiterAssignCheck)

    }

    const handleAssignJobClose =() =>{
        setOpenAssignRecruiterJobModal(false)
    }
    return (
        <Modal
            className={classes.modal}
            open={openAssignRecruiterJobModal}
            onClose={handleAssignJobClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openAssignRecruiterJobModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    <Autocomplete
                        id="jobList"
                        options={jobList}
                        value={jobValue}
                        onChange={(event, value) => setJobValue(value)}
                        getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`
                            }
                        style={{ width: "450px" }}
                        renderInput={(params) => <TextField {...params} label="Select a job" />}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={recruiterAssignCheck}
                            onChange={handleRecruiterAssignCheck}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Assign to recruiter"
                    />
                    {
                        recruiterAssignCheck &&
                        <Autocomplete
                            id="jobList"
                            options={recruiterList}
                            value={recruiterData}
                            onChange={(event, value) => setRecruiterData(value)}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName?option.lastName:''}`
                                }
                            style={{ width: "450px" }}
                            renderInput={(params) => <TextField {...params} label="Select a Recruiter" />}
                        />
                    }
                    <Button color="primary" disabled={disableAssignButton} onClick={confirmAssignRecruiter} >Confirm</Button>
                    <Button color="secondary" onClick={handleAssignJobClose} >Cancel</Button>
                </div>
            </Slide>
        </Modal>
    )
}

export default AssignToRecruiterModal;
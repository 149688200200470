import React, { useState, useEffect } from 'react'
import { Modal,  Backdrop, Grid, Slide} from '@material-ui/core';
import {  makeStyles} from '@material-ui/core/styles';
// import Availabilities from './Availabilitylist'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const JobModal =(props) =>{
    const classes = useStyles();
    const {values, openRemarkModal, setOpenRemarkModal} =props
    console.log({values})
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    
    const jobView=
        <Grid container>
            <Grid item xs={12} className="columnOrder padding20">
                <span className="headings">Remark Status</span>
            </Grid>
            <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Status</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.remark_status}</span>
                </Grid>
            </Grid>
            {values.low_salary && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Salary Expectation</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{numberFormat(values.low_salary)}</span>
                </Grid>
            </Grid>}
            {values.preferred_job_type && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Preferred job type</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.preferred_job_type}</span>
                </Grid>
            </Grid>}
            {values.preferred_location && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Preferred location</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.preferred_location}</span>
                </Grid>
            </Grid>}
            {values.skill_mismatch==="Yes" && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Skill mismatch</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.skill_mismatch}</span>
                </Grid>
            </Grid>}
            {values.communication_issue==="Yes" && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Communication mismatch</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.communication_issue}</span>
                </Grid>
            </Grid>}
            {values.other && <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Other reason</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.other}</span>
                </Grid>
            </Grid>}
        </Grid>
    return (
        <Modal
            className={classes.modal}
            open={openRemarkModal}
            onClose={() => setOpenRemarkModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className={`${classes.paper}`}>
                    {jobView}
                </div>
            </Slide>
        </Modal>
    )
}
export default JobModal;
import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import InterviewModal from './interviewModal'
import { withStyles, makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const EditableDropdownSubComponent =( props) =>{
    const {row, updateMyData, showToast, interviewAddress, setLoading} = props
    const classes = useStyles();
    const interview=row.original;
    const [openUpdateSlots, setOpenUpdateSlots] = useState(false)
    const [disableSelect, setDisableSelect] = useState(false)
    const [selectSlot, setSelectSlot] = useState('selectSlot')
    const [interviewData, setInterviewData] = useState({
        interviewSlot1:interview.interviewStartTime,
        interviewSlot1StartTime: interview.interviewStartTime,
        interviewSlot1EndTime: interview.interviewEndTime,
        interviewSlot2:interview.interviewStartTime2,
        interviewSlot2StartTime: interview.interviewStartTime2,
        interviewSlot2EndTime: interview.interviewEndTime2,
        interviewSlot3:interview.interviewStartTime3,
        interviewSlot3StartTime: interview.interviewStartTime3,
        interviewSlot3EndTime: interview.interviewEndTime3,
        updatearray:[]
    })
    // console.log({row });
    
    const changeSelectSlot =(event) =>{
        setSelectSlot(event.target.value)
    }
    const updateInterviewData= (date, location) =>{
        setDisableSelect(true)
        if(location===1){
            setInterviewData({...interviewData, interviewSlot1: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 1]})
        }
        else if(location===2){
            setInterviewData({...interviewData, interviewSlot1StartTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 2]})
        }
        else if(location===3){
            setInterviewData({...interviewData, interviewSlot1EndTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 3]})
        }
        else if(location===4){
            setInterviewData({...interviewData, interviewSlot2: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 4]})
        }
        else if(location===5){
            setInterviewData({...interviewData, interviewSlot2StartTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 5]})
        }
        else if(location===6){
            setInterviewData({...interviewData, interviewSlot2EndTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 6]})
        }
        else if(location===7){
            setInterviewData({...interviewData, interviewSlot3: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 7]})
        }
        else if(location===8){
            setInterviewData({...interviewData, interviewSlot3StartTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 8]})
        }
        else if(location===9){
            setInterviewData({...interviewData, interviewSlot3EndTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 9]})
        }
    }
    const [confirmSlot, setConfirmSlot] = useState(4);
    
    const [showButtons, setShowButtons] = useState(false)

    const handleUpdateSlot =() =>{
        // updateInteriewSlots(updateData, setDisableSelect)    
    }
    const handleSelectSlot =() =>{
        setOpenUpdateSlots(false)
    }
    const handleUpdateSlotClose =() =>{
        setOpenUpdateSlots(false)
    }


    let interviewStatus=null
    if((row.original.interviewFollowUp==="APPEARED" || row.original.interviewFollowUp==="GOING") &&  row.original.whichSlot!==0)
        interviewStatus=
            <div></div>
    else if(row.original.interviewId && row.original.whichSlot!==0 && !(row.original.interviewFollowUp==="APPEARED" || row.original.interviewFollowUp==="GOING"))
        interviewStatus=
            <button
                onClick={() => setOpenUpdateSlots(true)}
                className="linkButton"
                >Reschedule
            </button>
    else if((row.original.interviewId) && row.original.whichSlot===0 && (row.original.interviewMode===2 || row.original.interviewMode===3))
        interviewStatus=
            <button
                onClick={() => setOpenUpdateSlots(true)}
                className="linkButton"
                >Select slot
            </button>
    else if(row.original.interviewMode===2 || row.original.interviewMode===3)
        interviewStatus=
            <button
                className="linkButton"
                onClick={() => setOpenUpdateSlots(true)}
                >Create slots
            </button>
    else
        interviewStatus=null

    return (
        <React.Fragment>
            {interviewStatus}
            <InterviewModal
                interview={row.original}
                updateMyData={updateMyData}
                setLoading= {setLoading}
                showToast={showToast}
                openUpdateSlots={openUpdateSlots}
                setOpenUpdateSlots={setOpenUpdateSlots}
                interviewAddress={interviewAddress}
            />
        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;
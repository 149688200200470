import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import * as moment from "moment"
import ApplicationStatus from './ApplicationStatus'
import History from './ApplicationHistoryComponent'
import urlLinks from '../../../urlLinks';
import Axios from 'axios';

const callStatus = {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.71',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070'
},
ShiftRight = {
    float: 'right',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
},
FontColor = {
    color: '#707070',
},
gapSpace = {
    marginLeft: '40px'
};

const ApplicationHistory =(props) =>{
    const {row, quesList} = props;
    const workerDetails=row.original
    const [history, setHistory] = useState([]);
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    // console.log({workerDetails})
    const createHistoryList =(data) =>{
        let list=[]
        data.map((event, index) =>{
            console.log({event})
            let data=null;
            let eventBy=(event.event_by_role===1 || event.event_by_role===2)?`${event.event_by} - Admin`:(event.event_by_role===3)?`Employer`:(event.event_by_role===4)?`Worker`:(event.event_by_role===5)?`Recruiter`:""
            let dotColor=""
            let isInitial=false
            if(event.event==="ASSIGN_STATUS"){
                if(event.eventStatus==="ASSIGNED_TO_RECRUITER"){
                    data=<History
                            data={<Grid container>
                                    <Grid item xs={12} className="padding0">
                                        <span style={FontColor}><b>Assigned on: {moment(event.insertedOn).format("DD/MM/YY")}</b></span>
                                    </Grid>
                                    <Grid item xs={12} className="padding0">
                                        <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                                    </Grid>
                                </Grid>
                            }
                        />
                }
            }
            else if(event.event==="CALL_STATUS"){
                let status=""
                switch (event.eventStatus) {
                    case "INTERESTED":
                        status="Interested"
                        break;
                    case "SWITCHED_OFF":
                        status="Switched off"
                        break;
                    case "CALL_LATER":
                        status="Call later"
                        break;
                    case "BUSY":
                        status="Busy"
                        break;
                    case "CALL_NOT_RECIEVED":
                        status="Call not received"
                        break;
                    case "NOT_INTERESTED":
                        status="Not interested"
                        break;
                    case "INVALID_NUMBER":
                        status="Invalid number"
                        break;
                    default:
                        break;
                }
                data=<History
                        data={<Grid container>
                                <Grid item xs={12} className="padding0">
                                    <span style={callStatus}><b>Call status: {status}</b></span>
                                </Grid>
                                <Grid item xs={12} className="padding0">
                                    <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                                </Grid>
                            </Grid>}
                        />
            }
            else if(event.event==="Questionnaire Submitted"){
                const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                let scoreValue=""
                prevValue.map(prev =>{
                    const temp=prev.split("=")
                    if(temp[0]==="question_score")
                        scoreValue=`${temp[1]} / `
                })
                data=<History
                    data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Questionnaire submitted</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                            <span style={FontColor}>Score: {scoreValue} {quesList}</span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                />
            }
            else if(event.event==="Questionnaire Updated"){
                const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                let scoreValue=""
                prevValue.map(prev =>{
                    const temp=prev.split("=")
                    if(temp[0]==="question_score")
                        scoreValue=`${temp[1]} / `
                })
                data=<History
                    data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Questionnaire updated</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                            <span style={FontColor}>Score: {scoreValue} {quesList}</span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                />
            }
            else if(event.event==="APPROVAL_STATUS"){
                if(event.eventStatus==="APPROVED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Application approved</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="NOT_APPROVED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Not approved</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
            }
            else if(event.event==="INTERVIEW_MODE"){
                if(event.eventStatus==="Profile Shared"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Profile shared</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                // else if(event.eventStatus==="Telephonic" || event.eventStatus==="Face to Face"){
                //     const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                //     let interviewValues={
                //         mode:"",
                //         startTime:"",
                //         endTime:"",
                //         startDate:"",
                //         endDate:"",
                //         interviewDate:""
                //     }
                //     prevValue.map(prev =>{
                //         const temp=prev.split("=")
                //         if(temp[0]==="interview_mode")
                //             interviewValues.mode= temp[1]
                //         if(temp[0]==="existFromDate")
                //             interviewValues.startDate= temp[1]
                //         if(temp[0]==="existToDate")
                //             interviewValues.endDate= temp[1]
                //         if(temp[0]==="existStartTime")
                //             interviewValues.startTime= temp[1]
                //         if(temp[0]==="existEndTime")
                //             interviewValues.endTime= temp[1]
                //     })
                //     let date=""
                //     if(interviewValues.mode==="2"){
                //         date= `${moment(interviewValues.startDate, "YYYY-MM-DD").format("DD/MM/YY")}${interviewValues.endDate!==interviewValues.startDate?" - ":''}${interviewValues.endDate!==interviewValues.startDate?moment(interviewValues.endDate, "YYYY-MM-DD").format("DD/MM/YY"):''}`
                //     }
                //     if(interviewValues.mode==="3"){
                //         date= moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("DD/MM/YY")
                //     }
                //         data=<History
                //             data={<Grid container>
                //                     <Grid item xs={12} className="padding0">
                //                         <span><b>Interview scheduled</b></span>
                //                     </Grid>
                //                     <Grid item xs={12} className="padding0">
                //                         <span>Mode: {interviewValues.mode==="2"?"Telephonic Interview":"F2F Interview"}</span>
                //                     </Grid>
                //                     <Grid item xs={12} className="padding0">
                //                         <span>Date: {date}</span>
                //                     </Grid>
                //                     {interviewValues.mode==="3" && <Grid item xs={12} className="padding0">
                //                         <span>{`Time: ${moment(workerDetails.interviewStartTime).format("hh:mm a")} - ${moment(workerDetails.interviewEndTime).format("hh:mm a")}`}</span>
                //                     </Grid>}
                //                     <Grid item xs={12} className="padding0">
                //                         <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                //                     </Grid>
                //                 </Grid>}
                //         />
                // }
                else if(event.eventStatus==="Profile Rejected"){
                    data=<History
                            data={<Grid container>
                                    <Grid item xs={12} className="padding0">
                                        <span style={FontColor}><b>Profile rejected</b></span>
                                    </Grid>
                                    <Grid item xs={12} className="padding0">
                                        <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                                    </Grid>
                                </Grid>}
                        />
                }
            }
            else if(event.event==="APPLIED JOB"){
                if(event.eventStatus==="APPLIED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span><b>Worker applied</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="SHORTLISTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span><b>Worker shortlisted for job</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
            }
            else if(event.event==="INTERVIEW STATUS"){
                let status=""
                const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                    let feedbackValues={
                        type:"",
                        value:"",
                        selectionDate:"",
                        joiningDate:"",
                        offeredSalary:"",
                        offeredSalaryType:"",
                        salary:""
                    }
                    prevValue.map(prev =>{
                        const temp=prev.split("=")
                        if(temp[0]==="final_feedback" || temp[0]==="finalFeedback"){
                            feedbackValues.type= "Final feedback"
                            feedbackValues.value= temp[1]
                        }
                        if(temp[0]==="employer_feedback" || temp[0]==="employerFeedback"){
                            feedbackValues.value= temp[1]
                            feedbackValues.type= "Employer feedback"
                        }
                        if(temp[0]==="recruiter_feedback" || temp[0]==="recruiterFeedback"){
                            feedbackValues.value= temp[1]
                            feedbackValues.type= "Recruiter feedback"
                        }
                        if(temp[0]==="joining_date")
                            feedbackValues.joiningDate= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="offered_date")
                            feedbackValues.selectionDate= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="job_offered_amount")
                            feedbackValues.offeredSalary= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="offered_amount_type")
                            feedbackValues.offeredSalaryType= temp[1]!=="null"?temp[1]:""
                        feedbackValues.salary= `${feedbackValues.offeredSalary?numberFormat(parseInt(feedbackValues.offeredSalary)):0} / ${feedbackValues.offeredSalaryType==="0"?"month":"year"}`
                    })
                switch (event.eventStatus) {
                    case "GOING":
                        status="Going"
                        break;
                    case "NOT_GOING":
                        status="Not going"
                        break;
                    case "APPEARED":
                        status="Appeared"
                        break;
                    case "NO_RESPONSE":
                        status="No response"
                        break;
                    case "NOT_APPEARED":
                        status="Not appeared"
                        break;
                    case "REJECTED":
                        status="Rejected"
                        break;
                    case "SELECTED":
                        status="Selected"
                        break;
                    case "CANDIDATE_DROPPED":
                        status="Candidate dropped"
                        break;
                    case "ROUNDS_PENDING":
                        status="Rounds pending"
                        break;
                    case "RESULT_AWAITED":
                        status="Result awaited"
                        break;
                    default:
                        break;
                }
                if(event.eventStatus==="GOING" || event.eventStatus==="NOT_GOING" || event.eventStatus==="APPEARED" || event.eventStatus==="NO_RESPONSE" ){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Follow up: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                if(event.eventStatus==="NOT_APPEARED" || event.eventStatus==="ROUNDS_PENDING" || event.eventStatus==="REJECTED" || event.eventStatus==="RESULT_AWAITED" || event.eventStatus==="CANDIDATE_DROPPED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>{feedbackValues.type}: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="SELECTED" && feedbackValues.type!== "Final feedback"){
                    console.log({feedbackValues})
                    data=<History
                    data={<Grid container>
                        <Grid item xs={12} className="padding0">
                            <span><b>{feedbackValues.type}: {status}</b></span>
                        </Grid>
                        {feedbackValues.selectionDate && <Grid item xs={12} className="padding0">
                            <span>Selection date: {moment(feedbackValues.selectionDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                        </Grid>}
                        {feedbackValues.joiningDate && <Grid item xs={12} className="padding0">
                            <span>Joining date: {moment(feedbackValues.joiningDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                        </Grid>}
                        {feedbackValues.salary && <Grid item xs={12} className="padding0">
                            <span>Salary: {feedbackValues.salary}</span>
                        </Grid>}
                        <Grid item xs={12} className="padding0">
                            <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                        </Grid>
                    </Grid>}
                    />
                }
                
                else if(event.eventStatus==="SLOTS CREATED" || event.eventStatus==="RESCHEDULE"){
                    const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                    let interviewValues={
                        mode:"",
                        startTime:"",
                        endTime:"",
                        startDate:"",
                        endDate:"",
                        interviewDate:""
                    }
                    prevValue.map(prev =>{
                        const temp=prev.split("=")
                        if(temp[0]==="interview_mode")
                            interviewValues.mode= temp[1]
                        if(temp[0]==="existFromDate")
                            interviewValues.startDate= temp[1]
                        if(temp[0]==="existToDate")
                            interviewValues.endDate= temp[1]
                        if(temp[0]==="existStartTime")
                            interviewValues.startTime= temp[1]
                        if(temp[0]==="existEndTime")
                            interviewValues.endTime= temp[1]
                    })
                    let date=""
                    if(interviewValues.mode==="2"){
                        date= `${moment(interviewValues.startDate, "YYYY-MM-DD").format("DD/MM/YY")}${interviewValues.endDate!==interviewValues.startDate?" - ":''}${interviewValues.endDate!==interviewValues.startDate?moment(interviewValues.endDate, "YYYY-MM-DD").format("DD/MM/YY"):''}`
                    }
                    if(interviewValues.mode==="3"){
                        date= moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("DD/MM/YY")
                    }
                    data=<History
                        data={<Grid container>
                                <Grid item xs={12} className="padding0">
                                    <span style={FontColor}><b>{event.eventStatus==="SLOTS CREATED"?"Interview scheduled":"Interview rescheduled"}</b></span>
                                </Grid>
                                <Grid item xs={12} className="padding0">
                                    <span>Mode: {interviewValues.mode==="2"?"Telephonic Interview":"F2F Interview"}</span>
                                </Grid>
                                <Grid item xs={12} className="padding0">
                                    <span>Date: {date}</span>
                                </Grid>
                                {interviewValues.mode==="3" && <Grid item xs={12} className="padding0">
                                    <span>{`Time: ${moment(workerDetails.interviewStartTime).format("hh:mm a")} - ${moment(workerDetails.interviewEndTime).format("hh:mm a")}`}</span>
                                </Grid>}
                                <Grid item xs={12} className="padding0">
                                    <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                                </Grid>
                            </Grid>}
                    />
                }
                
            }
            else if(event.event==="RECRUITER_FEEDBACK"){
                let status=""
                switch (event.eventStatus) {
                    case "ROUNDS_PENDING":
                        status="Rounds pending"
                        break;
                    case "CANDIDATE_DROPPED":
                        status="Candidate dropped"
                        break;
                    case "REJECTED":
                        status="Rejected"
                        break;
                    case "RESULT_AWAITED":
                        status="Result awaited"
                        break;
                    case "SELECTED":
                        status="Selected"
                        break;
                    default:
                        break;
                }
                if(event.eventStatus==="ROUNDS_PENDING"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Recruiter feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="CANDIDATE_DROPPED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Recruiter feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="REJECTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Recruiter feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="RESULT_AWAITED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Recruiter feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="SELECTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Recruiter feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
            }
            else if(event.event==="EMPLOYER_FEEDBACK"){
                let status=""
                switch (event.eventStatus) {
                    case "ROUNDS_PENDING":
                        status="Rounds pending"
                        break;
                    case "CANDIDATE_DROPPED":
                        status="Candidate dropped"
                        break;
                    case "REJECTED":
                        status="Rejected"
                        break;
                    case "RESULT_AWAITED":
                        status="Result awaited"
                        break;
                    case "SELECTED":
                        status="Selected"
                        break;
                    default:
                        break;
                }
                if(event.eventStatus==="ROUNDS_PENDING"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Employer feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="CANDIDATE_DROPPED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Employer feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="REJECTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Employer feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="RESULT_AWAITED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Employer feedback: {status}</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                // else if(event.eventStatus==="SELECTED"){
                //     data=<History
                //         data={<Grid container>
                //             <Grid item xs={12} className="padding0">
                //                 <span style={FontColor}><b>Employer feedback: {status}</b></span>
                //             </Grid>
                //             <Grid item xs={12} className="padding0">
                //                 <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                //             </Grid>
                //         </Grid>}
                //     />
                // }
            }
            else if(event.event==="employerFeedback"){
                let status=""
                switch (event.eventStatus) {
                    case "ROUNDS_PENDING":
                        status="Rounds pending"
                        break;
                    case "CANDIDATE_DROPPED":
                        status="Candidate dropped"
                        break;
                    case "REJECTED":
                        status="Rejected"
                        break;
                    case "RESULT_AWAITED":
                        status="Result awaited"
                        break;
                    case "SELECTED":
                        status="Selected"
                        break;
                    default:
                        break;
                }
                if(event.eventStatus==="SELECTED"){
                    const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                    let feedbackValues={
                        type:"",
                        value:"",
                        selectionDate:"",
                        joiningDate:"",
                        offeredSalary:"",
                        offeredSalaryType:"",
                        salary:""
                    }
                    prevValue.map(prev =>{
                        const temp=prev.split("=")
                        if(temp[0]==="final_feedback" || temp[0]==="finalFeedback"){
                            feedbackValues.type= "Final feedback"
                            feedbackValues.value= temp[1]
                        }
                        if(temp[0]==="employer_feedback" || temp[0]==="employerFeedback"){
                            feedbackValues.value= temp[1]
                            feedbackValues.type= "Employer feedback"
                        }
                        if(temp[0]==="recruiter_feedback" || temp[0]==="recruiterFeedback"){
                            feedbackValues.value= temp[1]
                            feedbackValues.type= "Recruiter feedback"
                        }
                        if(temp[0]==="joining_date")
                            feedbackValues.joiningDate= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="offered_date")
                            feedbackValues.selectionDate= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="job_offered_amount")
                            feedbackValues.offeredSalary= temp[1]!=="null"?temp[1]:""
                        if(temp[0]==="offered_amount_type")
                            feedbackValues.offeredSalaryType= temp[1]!=="null"?temp[1]:""
                        feedbackValues.salary= `${feedbackValues.offeredSalary?numberFormat(parseInt(feedbackValues.offeredSalary)):0} / ${feedbackValues.offeredSalaryType==="0"?"month":"year"}`
                    })
                    console.log({feedbackValues})
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span><b>{feedbackValues.type}: {status}</b></span>
                            </Grid>
                            {feedbackValues.selectionDate && <Grid item xs={12} className="padding0">
                                <span>Selection date: {moment(feedbackValues.selectionDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                            </Grid>}
                            {feedbackValues.joiningDate && <Grid item xs={12} className="padding0">
                                <span>Joining date: {moment(feedbackValues.joiningDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                            </Grid>}
                            {feedbackValues.salary && <Grid item xs={12} className="padding0">
                                <span>Salary: {feedbackValues.salary}</span>
                            </Grid>}
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
            }
            else if(event.event==="finalFeedback"){
                let status=""
                switch (event.eventStatus) {
                    case "ROUNDS_PENDING":
                        status="Rounds pending"
                        break;
                    case "CANDIDATE_DROPPED":
                        status="Candidate dropped"
                        break;
                    case "REJECTED":
                        status="Rejected"
                        break;
                    case "RESULT_AWAITED":
                        status="Result awaited"
                        break;
                    case "SELECTED":
                        status="Selected"
                        break;
                    default:
                        break;
                }
                if(event.eventStatus==="SELECTED"){
                    const prevValue=event.preNextStatusValue?event.preNextStatusValue.split(","):[]
                    let feedbackValues={
                        type:"",
                        value:"",
                        selectionDate:"",
                        joiningDate:"",
                        offeredSalary:"",
                        offeredSalaryType:"",
                        salary:""
                    }
                    prevValue.map(prev =>{
                        const temp=prev.split("=")
                        if(temp[0]==="finalFeedback"){
                            feedbackValues.type= "Final feedback"
                            feedbackValues.value= temp[1]
                        }
                        if(temp[0]==="employerFeedback"){
                            feedbackValues.value= temp[1]
                            feedbackValues.type= "Employer feedback"
                        }
                        if(temp[0]==="joining_date")
                            feedbackValues.joiningDate= temp[1]
                        if(temp[0]==="offered_date")
                            feedbackValues.selectionDate= temp[1]
                        if(temp[0]==="job_offered_amount")
                            feedbackValues.offeredSalary= temp[1]
                        if(temp[0]==="offered_amount_type")
                            feedbackValues.offeredSalaryType= temp[1]
                        feedbackValues.salary= `${feedbackValues.offeredSalary?numberFormat(parseInt(feedbackValues.offeredSalary)):0} / ${feedbackValues.offeredSalaryType==="0"?"month":"year"}`
                    })
                    console.log({feedbackValues})
                    data=<History
                        data={<Grid container>
                        <Grid item xs={12} className="padding0">
                            <span><b>{feedbackValues.type}: {status}</b></span>
                        </Grid>
                        {feedbackValues.selectionDate && <Grid item xs={12} className="padding0">
                            <span>Selection date: {moment(feedbackValues.selectionDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                        </Grid>}
                        {feedbackValues.joiningDate && <Grid item xs={12} className="padding0">
                            <span>Joining date: {moment(feedbackValues.joiningDate,"YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                        </Grid>}
                        {feedbackValues.salary && <Grid item xs={12} className="padding0">
                            <span>Salary: {feedbackValues.salary}</span>
                        </Grid>}
                        <Grid item xs={12} className="padding0">
                            <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                        </Grid>
                    </Grid>}
                    />
                }
            }
            else if(event.event==="OFFER JOB"){
                if(event.eventStatus==="ACCEPTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Worker response: Accepted</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="JOINED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Joining status: Joined</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="POSTPONED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Joining status: Postponed</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                else if(event.eventStatus==="UPDATE JOINING DATE"){
                    data=<History
                        data={<Grid container>
                        <Grid item xs={12} className="padding0">
                            <span style={FontColor}><b>Joining status: Joining date updated</b></span>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            <span style={FontColor}>Joining date: {workerDetails.joiningDate?moment(workerDetails.joiningDate).format("DD/MM/YYYY"):""}</span>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                        </Grid>
                    </Grid>}
                    />
                }
                else if(event.eventStatus==="NOT_JOINING"){
                    data=<History
                        data={<Grid container>
                        <Grid item xs={12} className="padding0">
                            <span style={FontColor}><b>Joining status: Not joining</b></span>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                        </Grid>
                    </Grid>}
                    />
                }
                else if(event.eventStatus==="REJECTED"){
                    data=<History
                        data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Worker response: Rejected</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                    />
                }
                
            }
            else if(event.event==="DATE_OF_LEAVING"){
                data=<History
                    data={<Grid container>
                            <Grid item xs={12} className="padding0">
                                <span style={FontColor}><b>Candidate left</b></span>
                            </Grid>
                            <Grid item xs={12} className="padding0">
                                <span style={ShiftRight}>By {eventBy} on {moment(event.insertedOn).format("DD/MM/YY, hh:mm a")}</span>
                            </Grid>
                        </Grid>}
                />
            }
            list.push(data)
        })
        // console.log({list})
        setHistory(list)
    }

    const getWorkerHistory =() =>{
        Axios.get(`${urlLinks.baseUrl}tracking/getworkerHistory?jobDetailId=${workerDetails.jobDetailsId}&workerId=${workerDetails.workerId}`)
            .then(response =>{
                // console.log({response})
                createHistoryList(response.data.response)
            })
    }
    useEffect(() => {
        getWorkerHistory();
    }, [])
    return (
        <Grid container style={{width: "556px"}}>
            <Grid item xs={12} className="padding0" style={{margin:"40px 0 10px 10px", paddingLeft: "16px"}}>
                <h1>Application history</h1>
            </Grid>
            <hr  style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: .5,
                borderColor : '#000000'
            }}/>
            {history}
        </Grid>
    )
}

export default ApplicationHistory;
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Grid} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  borderRadius0:{
    borderRadius:0
  }
}));

export default function InputAdornments(props) {
  const classes = useStyles();
  const {textYrs = '' ,textMonths = '' , select= "Monthly", nameTextYrs, nameTextMonths,  nameSelect, setFieldValue, labelYrs, labelMonths,  errorYrs, errorMonths }= props
  
  return (
    <Grid container className="experienceContainer">
    <span className="experienceContainerlabel">Total Experience</span>
    <div style={{display: "flex"}} >
        <FormControl className={clsx(classes.margin, classes.textField)} className="margin0" variant="outlined" fullWidth={true}>
          
            <OutlinedInput
                id={nameTextYrs}
                name= {nameTextYrs}
                value={textYrs}
                error={errorYrs?errorYrs:false}
                classes={{root:classes.borderRadius0}}
                onChange={e => setFieldValue(nameTextYrs, e.target.value)}
                style={{padding:0}}
                // inputProps={{
                //   style: {
                //     padding: 10
                //   }
                // }}
                endAdornment={
                <InputAdornment position="end" style={{marginRight:"10px"}}>
                    Yrs
                </InputAdornment>
                }
            />
            {errorYrs && <span style={{color:"red"}}>Enter experience as numeric value</span>}
        </FormControl>
        <FormControl className={clsx(classes.margin, classes.textField)} className="margin0" variant="outlined" fullWidth={true}>
          
          <OutlinedInput
            id={nameTextMonths}
            name= {nameTextMonths}
            value={textMonths}
            error={errorMonths?errorMonths:false}
            classes={{root:classes.borderRadius0}}
            onChange={e => setFieldValue(nameTextMonths, e.target.value)}
            style={{padding:0}}
            // inputProps={{
            //   style: {
            //     padding: 10
            //   }
            // }}
            endAdornment={
                <InputAdornment position="end" style={{marginRight:"10px"}}>
                Months
              </InputAdornment>
            }
          />
          {errorMonths && <span style={{color:"red"}}>Enter experience as numeric value</span>}
        </FormControl>
    </div>  
  </Grid> 
  );
}

import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Checkbox, Typography, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '400px'
  },
  gridItemStyle: {
    padding: '2px !important'
  },
  textSize: {
    fontSize: '0.8rem'
  },
  checkboxStyle: {
    padding: '0px'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class HideUnhideColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckBox: this.props.columns
        .filter(cols => {
          return cols.isColumnVisible;
        })
        .map(cols => {
          return cols.id;
        })
    };

    this.getColumnsList = this.getColumnsList.bind(this);
    this.isCheckboxChecked = this.isCheckboxChecked.bind(this);
    this.handelEnter = this.handelEnter.bind(this);
  }
  getAllSelectedCheckBox(id) {
    let selected = this.state.selectedCheckBox;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedCheckBox: newSelected });
  }
  isCheckboxChecked(id) {
    return this.state.selectedCheckBox.includes(id);
  }
  getColumnsList(classes) {
    const columnCheckbox = this.props.columns.map(cols => {
      if(cols.label){
        let isSelected = this.isCheckboxChecked(cols.id);
        return cols.label.toLowerCase() !== 'action' ? (
          <Grid item xs={5} key={cols.id} className={classes.gridItemStyle}>
            <Checkbox
              className={classes.checkboxStyle}
              color='primary'
              checked={isSelected}
              onChange={() => {
                this.getAllSelectedCheckBox(cols.id);
              }}
            />
            <Typography
              varient='h6'
              style={{ display: 'inline-block' }}
              className={classes.textSize}
            >
              {cols.label}
            </Typography>
          </Grid>
        ) : null;

      }
    });
    return columnCheckbox;
  }

  handelEnter() {
    const updatedSelectedCheckBox = this.props.columns
      .filter(cols => {
        return cols.isColumnVisible;
      })
      .map(cols => {
        return cols.id;
      });
    if (
      JSON.stringify(updatedSelectedCheckBox) !==
      JSON.stringify(this.state.selectedCheckBox)
    ) {
      this.setState({
        selectedCheckBox: updatedSelectedCheckBox
      });
    }
  }
  render() {
    console.log(this.props.columns);
    
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => {
          return (
            <div>
              <Button
                variant='contained'
                color='primary'
                className={this.props.classes.buttonTextSize}
                {...bindTrigger(popupState)}
              >
                Columns
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                onEnter={this.handelEnter}
              >
                <Grid
                  container
                  spacing={3}
                  className={this.props.classes.gridStyle}
                >
                  {this.getColumnsList(this.props.classes)}
                </Grid>
                <Divider />
                <Grid container className={this.props.classes.gridStyle}>
                  {/* <Grid item xs={8}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={this.props.classes.buttonTextSize}
                    >
                      Save Custom Settings
                    </Button>
                  </Grid> */}
                  <Grid item xs={3}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={this.props.classes.buttonTextSize}
                      onClick={() => {
                        this.props.toggleColumn(this.state.selectedCheckBox);
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Popover>
            </div>
          );
        }}
      </PopupState>
    );
  }
}

export default withStyles(styles)(HideUnhideColumns);

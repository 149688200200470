import React, {Fragment,  useState } from 'react'
import { Field, FieldArray } from "formik";
import * as moment from 'moment'
import JobComponent from './jobComponent'
import WorkerExpModal from '../../../../utils/Modals/WorkerExpModal'
import DeleteIcon from '@material-ui/icons/Delete';

import {TextField ,Button , MenuItem, FormControl, InputLabel, Grid, InputAdornment} from "@material-ui/core";


const InterviewDetails = formikProps =>{
    const { errors, touched, values, setFieldValue, setValues, handleChange, locationList, editFlag } = formikProps;
    const [openWorkerExpModal, setOpenWorkerExpModal] = useState(false)
    
    console.log({values});
    
    const handleAddExp =() =>{
        
        console.log("attended")
        setOpenWorkerExpModal(true)
    }
    
    return(
        <Fragment>
            <FieldArray
              name="experienceList"
              render={({ insert, remove, push }) => (
                <Grid container spacing={0}>
                  {values.experienceList.length > 0 &&
                    values.experienceList.map((interview, index) => (
                        
                    
                        <Grid container spacing={0} key={index}>
                           <Grid item xs={12} sm={5}>
                                <JobComponent
                                    values= {values}
                                    setValue={setFieldValue}
                                    index={index}
                                    locationList={locationList}
                                    setValues={setValues}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{marginTop: "10px"}}>
                                <Button
                                    color="secondary"
                                    onClick={() =>{ 
                                        let temp= values.delExp
                                        temp.push(values.experienceList[index].expId)
                                        setFieldValue("delExp", temp)    
                                        remove(index)
                                    }}
                                    >
                                    <DeleteIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        color="primary"
                        onClick={() => handleAddExp(push)}
                        >
                        {values.experienceList.length > 0?<span>ADD MORE OTHER EXPERIENCE</span>:<span>ADD OTHER EXPERIENCE</span>}
                    </Button>
                </Grid>
              )}
            />
            {openWorkerExpModal &&  <WorkerExpModal
                openWorkerExpModal={openWorkerExpModal}
                setOpenWorkerExpModal = {setOpenWorkerExpModal}
                values= {values}
                setFieldValue={setFieldValue}
                editFlag={editFlag}
                locationList={locationList}
                newExp={true}
                errors={errors}
                touched={touched}
            />}
        </Fragment>
    )
}

export default InterviewDetails;
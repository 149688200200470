import React, {useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
	}),
);

export default function Faq(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const classes = useStyles();

	
	return (
		<div>
			<div >
					<Grid container>
						<div>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How can I know my payout for the vacancy &amp; how it works?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You can see your payout and payout terms upfront as soon as you select
									vacancy you want to work upon. Please refer to screenshot below.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted recruiter
									payout and payout terms on the dashboard.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									Who should I connect with for any query related to vacancy or queries
									coming from candidates?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You can see the name of Recruitment Manager displayed on the vacancy
									page. Please refer to screenshot below. Also, you can reach out to
									OkayGo team for the same by putting the queries in OkayGo WhatsApp
									group, all recruitment managers are active on the group for
									coordination.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted recruitment
									manager name on the dashboard.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How do I see the detailed job description?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You can click on the name of the vacancy and the detailed job
									description will pop up in a new tab. Please refer to screenshot below.
										{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted vacancy
										name to click.
										</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How do I assign a candidate to another role/vacancy?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You can only assign any candidate on the vacancies are assigned to you
									and visible in the vacancy drop down list, by following the step below.
									1- Select one or more candidate you want to assign to a vacancy. 2-
									Click assign candidate button. (please refer to screenshot below) 3-
									Select the vacancy on which you want assign the candidate/candidates to.
									4- Click Confirm Now your assigned candidate will appear on selected
									vacancy as new candidates.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted selected
									candidate and assign candidate button.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How do I know if any candidate is from OkayGo data or self-data?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You can refer to the ‘Added by’ tag just under the name of candidate.
									Every candidate added by you will say ‘Added by Self’ and candidate from
									OkayGo pool will say ‘Added by OkayGo’. Please refer to screenshot
									below.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted added by
									tags for both OkayGo and Self.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What does approval status signifies?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									Approval status signifies weather you as a recruitment expert feels
									wither the candidate is eligible or not for the role. This is your
									judgment call based upon job description, instruction, response to
									approval questions and your interaction with the interested candidate.
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What are approval questions?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									These are the set of screening question based on bare minimum
									eligibility criteria of the vacancy. You should only mark a candidate as
									approved or not approved only after confirming the response of approval
									question for the vacancy.
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									I am facing challenge/issue with my recruiter dashboard, what should I
									do?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									You should report the issue immediately along with a screenshot on the
									OkayGo WhatsApp group by tagging the concerned recruitment manager.
									Also, you can report the issue with screenshot <a href="mailto:rpsupport@okaygo.in">rpsupport@okaygo.in</a>
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									I am unable to understand the functionality of the old/new dashboard
									feature, can I get a doubt clearance or repeat training session of the
									dashboard?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									Yes, you can put in a request on OkayGo WhatsApp group for the same and someone from OkayGo team will connect to schedule the training session. Also, you can join us at OkayGo Recruitment Partner Open Session conducted virtually every Saturday over a Meet call. Link to join the Session <a rel="noopener noreferrer" href="https://meet.google.com/rhk-geyg-reg" target="_blank"
									>https://meet.google.com/rhk-geyg-reg </a> Every Saturday 4:00 PM to 5:00 PM We conduct this session to ensure maximum support to recruitment partners. During this session we address and try to resolve each and every query or challenge related to work, dashboard, vacancy, payout or any other concern you may have.
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How can I get new vacancies to be assigned to me?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									Simply raise a request in OkayGo WhatsApp Group and concern recruitment
									manager will assign the vacancy to you.
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How can I see complete list of Active OkayGo Vacancies?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									We are currently in process of building the active vacancy listing with necessary details on your dashboard. However till then you can download OkayGo Jobs App on your android phone and check out active vacancies on the platform.<br /><br /> Link to download the OkayGo Jobs App -  <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.okaygo.worker&amp;hl=en"
									target="_blank" >https://play.google.com/store/apps/details?id=com.okaygo.worker&amp;hl=en</a>
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									How can I add candidates to any vacancy on the dashboard?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										There are 2 ways to do it
										
										<Typography > 1. By using ‘Add Candidate’ button (Please
										refer to screenshots below) Add Candidate button allows you to add
										candidate profile manually to the vacancy.</Typography>
										{/* <Typography className="marginTop20">
										<b>Note-</b> we should add 2 screenshots here – first of highlighted add
										candidate button and 2nd of candidate profile page that opens once we
										click add candidate button.</Typography> */}
										<Typography className="marginTop20">
										2. By using upload excel ‘Upload Bulk Data’
										button (Please refer to screenshots below) Upload Bulk Data button
										allows you to upload one or more candidate profiles in excel or CSV
										format to the system. To upload bulk data please follow the following
										steps- </Typography> I – Go to the vacancy you want to add bulk/excel data to.<br /> II-
										Click ‘Upload Bulk Data’ button<br /> III – Download the default File Template
										by click ‘Download File Template’ from the pop-up. (Please refer to
										screenshots below) <br />IV – Arrange your excel data in the Default File
										Template. (Please Note – Data can only be uploaded in the default file
										template) <br />V – Once you have saved the data in required format on your
										computer, now click Browse File Button on the Upload Bulk data pop-up.
										<br />VI – Select your templated file from computer and click Upload. All data
										will be added to the vacancy in a single go.
										{/* <Typography className="marginTop20"><b>Note-</b> we should add 2 screenshots here – first of highlighted
										Upload Bulk Data button; 2nd of Pop-up highlighting ‘Download File Type’
										and ‘Browse File Button’.</Typography> */}
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What does Vacancy status signifies?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									Active: It signifies that employer is actively hiring the role and you
									can line up relevant candidates. Inactive: It signifies that either the
									hiring is on-hold or closed at employer’s end. You should not spend time
									and energy to work on such requirements.
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									I want data support from OkayGo, how can I fetch data for the vacancy
									I am working on?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									At OkayGo we provide flexibility to every recruitment partner to use
									self or OkayGo data as per your own choice. To work upon OkayGo Data
									simply click on ‘Get Candidate’ button on the dashboard. (Please refer
									to screenshot)
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted get
									candidate button.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What is application history?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									Application History side bar shows you the complete history of the
									candidate on the vacancy.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted sample
									application history.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What does send job link button does?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									By clicking on ‘Send Job Link’ you can share the link to the job
									description to the candidate in a templated email. Please Note – This
									link only works on android phones and will take candidate directly to
									the detailed job description or current application stage once candidate
									downloads the app with the link.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted send job
									link button.
									</Typography> */}
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion className="accordian_container">
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<Typography className={classes.heading}>
									What does copy job link button does?
								</Typography>
								</AccordionSummary>
								<AccordionDetails>
								<Typography>
									This button copies the job link to your clipboard and now you can paste
									and share the link with the candidate manually using any platform you or
									candidate prefers. Please Note – This link only works on android phones
									and will take candidate directly to the detailed job description or
									current application stage once candidate downloads the app with the
									link.
									{/* <Typography className="marginTop20"><b>NOTE –</b> We should attach a screenshot with highlighted copy job
									link button.
									</Typography> */}
								</Typography>
								</AccordionDetails>
							</Accordion>
						</div>
						
					</Grid>
			</div>
		</div>
	);
}


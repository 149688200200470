import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, Backdrop, Slide, TextField, Grid} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../../urlLinks';
import Axios from 'axios';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const ShowJobLink =(props) =>{
    const {link, openJobLink, setOpenJobLink} = props
    const classes = useStyles()
    // console.log(link);
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openJobLink}
            onClose={() =>setOpenJobLink(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openJobLink} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                   <Grid container>
                        <Grid item xs={12} className="padding0">
                            <h1>Job link Generated</h1>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            <span style={{color:"green"}}>{link}</span>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            <button 
                            onClick={() =>  navigator.clipboard.writeText(link)
                            }>Copy Link</button>
                        </Grid>
                   </Grid>
                </div>
            </Slide>
        </Modal>
    )
}

export default ShowJobLink;
import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import * as moment from "moment"
import ApplicationStatus from './ApplicationStatus'

const ApplicationStatuses = (props) => {
    const { row, quesList } = props;
    const workerDetails = row.original
    const [statusData, setStatusData] = useState([]);
    // console.log({workerDetails, quesList})
    const numberFormat = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(value);
    }
    const handleEventBy = (workerDetails) => {

    }

    useEffect(() => {
        console.log("started applicationUpdates")
    }, [])
    const eventList = [
        {
            Event: workerDetails.firstEvent,
            EventStatus: workerDetails.firstEventStatus,
            EventDate: workerDetails.firstEventDate,
            EventValue: workerDetails.firstEventValue,
            eventBy: (workerDetails.firstEventRoleType === 1 || workerDetails.firstEventRoleType === 2) ? `${workerDetails.firstEventName} - Admin` : (workerDetails.firstEventRoleType === 3) ? `Employer` : (workerDetails.firstEventRoleType === 4) ? `Worker` : (workerDetails.firstEventRoleType === 5) ? `Recruiter` : ""
        },
        {
            Event: workerDetails.secondEvent,
            EventStatus: workerDetails.secondEventStatus,
            EventDate: workerDetails.secondEventDate,
            EventValue: workerDetails.secondEventValue,
            eventBy: (workerDetails.secondEventRoleType === 1 || workerDetails.secondEventRoleType === 2) ? `${workerDetails.secondEventName} - Admin` : (workerDetails.secondEventRoleType === 3) ? `Employer` : (workerDetails.secondEventRoleType === 4) ? `Worker` : (workerDetails.secondEventRoleType === 5) ? `Recruiter` : ""
        },
        {
            Event: workerDetails.thirdEvent,
            EventStatus: workerDetails.thirdEventStatus,
            EventDate: workerDetails.thirdEventDate,
            EventValue: workerDetails.thirdEventValue,
            eventBy: (workerDetails.thirdEventRoleType === 1 || workerDetails.thirdEventRoleType === 2) ? `${workerDetails.thirdEventName} - Admin` : (workerDetails.thirdEventRoleType === 3) ? `Employer` : (workerDetails.thirdEventRoleType === 4) ? `Worker` : (workerDetails.thirdEventRoleType === 5) ? `Recruiter` : ""
        }
    ]
    // console.log("true or false   ---  ",workerDetails.endDate!==workerDetails.startDate)
    let dataList = []
    eventList.map((event, index) => {
        let data = null;
        let dotColor = ""
        let isInitial = false
        if (event.Event === "ASSIGN_STATUS") {
            if (event.EventStatus === "ASSIGNED_TO_RECRUITER") {
                data = <Grid container>
                    <span><b>Assigned on: {moment(event.EventDate).format("DD/MM/YY")}</b></span>
                </Grid>
                isInitial = true
            }
        }
        else if (event.Event === "CALL_STATUS") {
            let status = ""
            switch (event.EventStatus) {
                case "INTERESTED":
                    status = "Interested"
                    break;
                case "SWITCHED_OFF":
                    status = "Switched off"
                    break;
                case "CALL_LATER":
                    status = "Call later"
                    break;
                case "BUSY":
                    status = "Busy"
                    break;
                case "CALL_NOT_RECIEVED":
                    status = "Call not received"
                    break;
                case "NOT_INTERESTED":
                    status = "Not interested"
                    break;
                case "INVALID_NUMBER":
                    status = "Invalid number"
                    break;
                default:
                    break;
            }
            data = <Grid container>
                <Grid item xs={12} className="padding0">
                    <span><b>Call status: {status}</b></span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                </Grid>
            </Grid>
            if (event.EventStatus === "INTERESTED" || event.EventStatus === "SWITCHED_OFF" || event.EventStatus === "CALL_LATER" || event.EventStatus === "BUSY" || event.EventStatus === "CALL_NOT_RECIEVED") {
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "NOT_INTERESTED" || event.EventStatus === "INVALID_NUMBER") {
                dotColor = "Red"
            }
        }
        else if (event.Event === "Questionnaire Submitted") {
            const prevValue = event.EventValue ? event.EventValue.split(",") : []
            let scoreValue = ""
            prevValue.map(prev => {
                const temp = prev.split("=")
                if (temp[0] === "question_score")
                    scoreValue = `${temp[1]} / `
            })
            data = <Grid container>
                <Grid item xs={12} className="padding0">
                    <span><b>Questionnaire submitted</b></span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>Score: {scoreValue} {quesList}</span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                </Grid>
            </Grid>
            if (index === eventList.length - 1)
                dotColor = "Blue"
        }
        else if (event.Event === "Questionnaire Updated") {
            const prevValue = event.EventValue ? event.EventValue.split(",") : []
            let scoreValue = ""
            prevValue.map(prev => {
                const temp = prev.split("=")
                if (temp[0] === "question_score")
                    scoreValue = `${temp[1]} / `
            })
            data = <Grid container>
                <Grid item xs={12} className="padding0">
                    <span><b>Questionnaire updated</b></span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>Score: {scoreValue} {quesList}</span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                </Grid>
            </Grid>
            if (index === eventList.length - 1)
                dotColor = "Blue"
        }
        else if (event.Event === "APPROVAL_STATUS") {
            if (event.EventStatus === "APPROVED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Application approved</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "NOT_APPROVED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Not approved</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                dotColor = "Red"
            }
        }
        else if (event.Event === "INTERVIEW_MODE") {
            if (event.EventStatus === "Profile Shared") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Profile shared</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            // else if(event.EventStatus==="Telephonic" || event.EventStatus==="Face to Face"){
            //     const prevValue=event.EventValue?event.EventValue.split(","):[]
            //     let interviewValues={
            //         mode:"",
            //         startTime:"",
            //         endTime:"",
            //         startDate:"",
            //         endDate:"",
            //         interviewDate:""
            //     }
            //     prevValue.map(prev =>{
            //         const temp=prev.split("=")
            //         if(temp[0]==="interview_mode")
            //             interviewValues.mode= temp[1]
            //         if(temp[0]==="existFromDate")
            //             interviewValues.startDate= temp[1]
            //         if(temp[0]==="existToDate")
            //             interviewValues.endDate= temp[1]
            //         if(temp[0]==="existStartTime")
            //             interviewValues.startTime= temp[1]
            //         if(temp[0]==="existEndTime")
            //             interviewValues.endTime= temp[1]
            //     })
            //     let date=""
            //     if(interviewValues.mode==="2"){
            //         date= `${moment(interviewValues.startDate, "YYYY-MM-DD").format("DD/MM/YY")}${interviewValues.endDate!==interviewValues.startDate?" - ":''}${interviewValues.endDate!==interviewValues.startDate?moment(interviewValues.endDate, "YYYY-MM-DD").format("DD/MM/YY"):''}`
            //     }
            //     if(interviewValues.mode==="3"){
            //         date= moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("DD/MM/YY")
            //     }
            //     data=<Grid container>
            //             <Grid item xs={12} className="padding0">
            //                 <span><b>Interview scheduled</b></span>
            //             </Grid>
            //             <Grid item xs={12} className="padding0">
            //                 <span>Mode: {interviewValues.mode==="2"?"Telephonic Interview":"F2F Interview"}</span>
            //             </Grid>
            //             <Grid item xs={12} className="padding0">
            //                 <span>Date: {date}</span>
            //             </Grid>
            //             {interviewValues.mode==="3" && <Grid item xs={12} className="padding0">
            //                     <span>{`Time: ${moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")} - ${moment(interviewValues.endTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")}`}</span>
            //                 </Grid>
            //             }
            //             <Grid item xs={12} className="padding0">
            //                 <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
            //             </Grid>
            //         </Grid>
            //     if(index===eventList.length-1)
            //         dotColor="Blue"
            // }
            else if (event.EventStatus === "Profile Rejected") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Profile rejected</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                dotColor = "Red"
            }
        }
        else if (event.Event === "APPLIED JOB") {
            if (event.EventStatus === "APPLIED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Worker applied</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
            }
            else if (event.EventStatus === "SHORTLISTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Worker shortlisted for job</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
            }
        }
        else if (event.Event === "INTERVIEW STATUS") {
            let status = ""
            const prevValue = event.EventValue ? event.EventValue.split(",") : []
            let feedbackValues = {
                type: "",
                value: "",
                selectionDate: "",
                joiningDate: "",
                offeredSalary: "",
                offeredSalaryType: "",
                salary: ""
            }
            prevValue.map(prev => {
                const temp = prev.split("=")
                if (temp[0] === "final_feedback" || temp[0] === "finalFeedback") {
                    feedbackValues.type = "Final feedback"
                    feedbackValues.value = temp[1]
                }
                if (temp[0] === "employer_feedback" || temp[0] === "employerFeedback") {
                    feedbackValues.value = temp[1]
                    feedbackValues.type = "Employer feedback"
                }
                if (temp[0] === "recruiter_feedback" || temp[0] === "recruiterFeedback") {
                    feedbackValues.value = temp[1]
                    feedbackValues.type = "Recruiter feedback"
                }
                if (temp[0] === "joining_date")
                    feedbackValues.joiningDate = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "offered_date")
                    feedbackValues.selectionDate = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "job_offered_amount")
                    feedbackValues.offeredSalary = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "offered_amount_type")
                    feedbackValues.offeredSalaryType = temp[1] !== "null" ? temp[1] : ""
                feedbackValues.salary = `${feedbackValues.offeredSalary ? numberFormat(parseInt(feedbackValues.offeredSalary)) : 0} / ${feedbackValues.offeredSalaryType === "0" ? "month" : "year"}`
            })
            switch (event.EventStatus) {
                case "GOING":
                    status = "Going"
                    break;
                case "NOT_GOING":
                    status = "Not going"
                    break;
                case "APPEARED":
                    status = "Appeared"
                    break;
                case "NO_RESPONSE":
                    status = "No response"
                    break;
                case "ROUNDS_PENDING":
                    status = "Rounds pending"
                    break;
                case "CANDIDATE_DROPPED":
                    status = "Candidate dropped"
                    break;
                case "REJECTED":
                    status = "Rejected"
                    break;
                case "RESULT_AWAITED":
                    status = "Result awaited"
                    break;
                case "SELECTED":
                    status = "Selected"
                    break;
                default:
                    break;
            }
            if (event.EventStatus === "GOING" || event.EventStatus === "NOT_GOING" || event.EventStatus === "APPEARED" || event.EventStatus === "NO_RESPONSE") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Follow up: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "SLOTS CREATED") {
                const prevValue = event.EventValue ? event.EventValue.split(",") : []
                let interviewValues = {
                    mode: "",
                    startTime: "",
                    endTime: "",
                    startDate: "",
                    endDate: "",
                    interviewDate: ""
                }
                prevValue.map(prev => {
                    const temp = prev.split("=")
                    if (temp[0] === "interview_mode")
                        interviewValues.mode = temp[1]
                    if (temp[0] === "existFromDate")
                        interviewValues.startDate = temp[1]
                    if (temp[0] === "existToDate")
                        interviewValues.endDate = temp[1]
                    if (temp[0] === "existStartTime")
                        interviewValues.startTime = temp[1]
                    if (temp[0] === "existEndTime")
                        interviewValues.endTime = temp[1]
                })
                console.log({ interviewValues })
                let date = ""
                if (interviewValues.mode === "2") {
                    date = `${moment(interviewValues.startDate, "YYYY-MM-DD").format("DD/MM/YY")}${interviewValues.endDate !== interviewValues.startDate ? " - " : ''}${interviewValues.endDate !== interviewValues.startDate ? moment(interviewValues.endDate, "YYYY-MM-DD").format("DD/MM/YY") : ''}`
                }
                if (interviewValues.mode === "3") {
                    date = moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("DD/MM/YY")
                }
                console.log({ interviewValues })
                console.log({ date })
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Interview scheduled</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>Mode: {interviewValues.mode === "2" ? "Telephonic Interview" : "F2F Interview"}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>Date: {date}</span>
                    </Grid>
                    {interviewValues.mode === "3" && <Grid item xs={12} className="padding0">
                        <span>{`Time: ${moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")} - ${moment(interviewValues.endTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")}`}</span>
                    </Grid>
                    }
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "RESCHEDULE") {

                const prevValue = event.EventValue ? event.EventValue.split(",") : []
                let interviewValues = {
                    mode: "",
                    startTime: "",
                    endTime: "",
                    startDate: "",
                    endDate: "",
                    interviewDate: ""
                }
                prevValue.map(prev => {
                    const temp = prev.split("=")
                    if (temp[0] === "interview_mode")
                        interviewValues.mode = temp[1]
                    if (temp[0] === "existFromDate")
                        interviewValues.startDate = temp[1]
                    if (temp[0] === "existToDate")
                        interviewValues.endDate = temp[1]
                    if (temp[0] === "existStartTime")
                        interviewValues.startTime = temp[1]
                    if (temp[0] === "existEndTime")
                        interviewValues.endTime = temp[1]
                })
                let date = ""
                if (interviewValues.mode === "2") {
                    date = `${moment(interviewValues.startDate, "YYYY-MM-DD").format("DD/MM/YY")}${interviewValues.endDate !== interviewValues.startDate ? " - " : ''}${interviewValues.endDate !== interviewValues.startDate ? moment(interviewValues.endDate, "YYYY-MM-DD").format("DD/MM/YY") : ''}`
                }
                if (interviewValues.mode === "3") {
                    date = moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("DD/MM/YY")
                }
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Interview rescheduled</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>Mode: {interviewValues.mode === "2" ? "Telephonic Interview" : "F2F Interview"}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>Date: {date}</span>
                    </Grid>
                    {interviewValues.mode === "3" && <Grid item xs={12} className="padding0">
                        <span>{`Time: ${moment(interviewValues.startTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")} - ${moment(interviewValues.endTime, "YYYY-MM-DD HH:mm:ss.0").format("hh:mm a")}`}</span>
                    </Grid>
                    }
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>

                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "SLOT RESELECTED") {

            }
            else if (event.EventStatus === "ROUNDS_PENDING") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "CANDIDATE_DROPPED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Red"
            }
            else if (event.EventStatus === "REJECTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                dotColor = "Red"
            }
            else if (event.EventStatus === "RESULT_AWAITED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "SELECTED" && feedbackValues.type !== "Final feedback" && feedbackValues.type !== "Employer feedback") {
                // console.log({row})
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    {feedbackValues.selectionDate && <Grid item xs={12} className="padding0">
                        <span>Selection date: {moment(feedbackValues.selectionDate, "YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                    </Grid>}
                    {feedbackValues.joiningDate && <Grid item xs={12} className="padding0">
                        <span>Joining date: {moment(feedbackValues.joiningDate, "YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                    </Grid>}
                    {feedbackValues.salary && <Grid item xs={12} className="padding0">
                        <span>Salary: {feedbackValues.salary}</span>
                    </Grid>}
                    {/* <Grid item xs={12} className="padding0">
                        <span>Expected earning: {row.original.recruiterPayout}</span>
                    </Grid> */}
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }

        }
        else if (event.Event === "RECRUITER_FEEDBACK") {
            let status = ""
            switch (event.EventStatus) {
                case "ROUNDS_PENDING":
                    status = "Rounds pending"
                    break;
                case "CANDIDATE_DROPPED":
                    status = "Candidate dropped"
                    break;
                case "REJECTED":
                    status = "Rejected"
                    break;
                case "RESULT_AWAITED":
                    status = "Result awaited"
                    break;
                case "SELECTED":
                    status = "Selected"
                    break;
                default:
                    break;
            }

        }
        else if (event.Event === "EMPLOYER_FEEDBACK") {
            let status = ""
            switch (event.EventStatus) {
                case "ROUNDS_PENDING":
                    status = "Rounds pending"
                    break;
                case "CANDIDATE_DROPPED":
                    status = "Candidate dropped"
                    break;
                case "REJECTED":
                    status = "Rejected"
                    break;
                case "RESULT_AWAITED":
                    status = "Result awaited"
                    break;
                case "SELECTED":
                    status = "Selected"
                    break;
                default:
                    break;
            }
            if (event.EventStatus === "ROUNDS_PENDING") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Employer feedback: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "CANDIDATE_DROPPED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Employer feedback: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "REJECTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Employer feedback: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                dotColor = "Red"
            }
            else if (event.EventStatus === "RESULT_AWAITED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Employer feedback: {status}</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            // else if(event.EventStatus==="SELECTED"){
            //     data=<Grid container>
            //         <Grid item xs={12} className="padding0">
            //             <span><b>Employer feedback: {status}</b></span>
            //         </Grid>
            //         <Grid item xs={12} className="padding0">
            //             <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
            //         </Grid>
            //     </Grid>
            //     if(index===eventList.length-1)
            //         dotColor="Blue"
            // }
        }
        else if (event.Event === "employerFeedback" || event.Event === "finalFeedback") {
            let status = ""
            const prevValue = event.EventValue ? event.EventValue.split(",") : []
            let feedbackValues = {
                type: "",
                value: "",
                selectionDate: "",
                joiningDate: "",
                offeredSalary: "",
                offeredSalaryType: "",
                salary: ""
            }
            prevValue.map(prev => {
                const temp = prev.split("=")
                if (temp[0] === "final_feedback" || temp[0] === "finalFeedback") {
                    feedbackValues.type = "Final feedback"
                    feedbackValues.value = temp[1]
                }
                if (temp[0] === "employer_feedback" || temp[0] === "employerFeedback") {
                    feedbackValues.value = temp[1]
                    feedbackValues.type = "Employer feedback"
                }
                if (temp[0] === "recruiter_feedback" || temp[0] === "recruiterFeedback") {
                    feedbackValues.value = temp[1]
                    feedbackValues.type = "Recruiter feedback"
                }
                if (temp[0] === "joining_date")
                    feedbackValues.joiningDate = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "offered_date")
                    feedbackValues.selectionDate = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "job_offered_amount")
                    feedbackValues.offeredSalary = temp[1] !== "null" ? temp[1] : ""
                if (temp[0] === "offered_amount_type")
                    feedbackValues.offeredSalaryType = temp[1] !== "null" ? temp[1] : ""
                feedbackValues.salary = `${feedbackValues.offeredSalary ? numberFormat(parseInt(feedbackValues.offeredSalary)) : 0} / ${feedbackValues.offeredSalaryType === "0" ? "month" : "year"}`
            })
            switch (event.EventStatus) {
                case "ROUNDS_PENDING":
                    status = "Rounds pending"
                    break;
                case "CANDIDATE_DROPPED":
                    status = "Candidate dropped"
                    break;
                case "REJECTED":
                    status = "Rejected"
                    break;
                case "RESULT_AWAITED":
                    status = "Result awaited"
                    break;
                case "SELECTED":
                    status = "Selected"
                    break;
                default:
                    break;
            }
            if (event.EventStatus === "SELECTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>{feedbackValues.type}: {status}</b></span>
                    </Grid>
                    {feedbackValues.selectionDate && <Grid item xs={12} className="padding0">
                        <span>Selection date: {moment(feedbackValues.selectionDate, "YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                    </Grid>}
                    {feedbackValues.joiningDate && <Grid item xs={12} className="padding0">
                        <span>Joining date: {moment(feedbackValues.joiningDate, "YYYY-MM-DD 00:00:00.0").format("DD/MM/YY")}</span>
                    </Grid>}
                    {feedbackValues.salary && <Grid item xs={12} className="padding0">
                        <span>Salary: {feedbackValues.salary}</span>
                    </Grid>}
                    {/* <Grid item xs={12} className="padding0">
                        <span>Expected earning: {row.original.recruiterPayout}</span>
                    </Grid> */}
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
        }

        else if (event.Event === "OFFER JOB") {
            if (event.EventStatus === "ACCEPTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Worker response: Accepted</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "JOINED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Joining status: Joined</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                    {/* <Grid item xs={12} className="padding0">
                        <span>Earned: {row.original.recruiterPayout}</span>
                    </Grid> */}
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Green"
            }
            else if (event.EventStatus === "POSTPONED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Joining status: Postponed</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "UPDATE JOINING DATE") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Joining status: Joining date updated</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>Joining date: {moment(workerDetails.joiningDate, "YYYY-MM-DD 00:00:00").format("DD/MM/YYYY")}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }
            else if (event.EventStatus === "NOT_JOINING") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Joining status: Not joining</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                dotColor = "Red"
            }
            else if (event.EventStatus === "REJECTED") {
                data = <Grid container>
                    <Grid item xs={12} className="padding0">
                        <span><b>Worker response: Rejected</b></span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                    </Grid>
                </Grid>
                if (index === eventList.length - 1)
                    dotColor = "Blue"
            }

        }
        else if (event.Event === "DATE_OF_LEAVING") {
            data = <Grid container>
                <Grid item xs={12} className="padding0">
                    <span><b>Candidate left</b></span>
                </Grid>
                <Grid item xs={12} className="padding0">
                    <span>By {event.eventBy} on {moment(event.EventDate).format("DD/MM/YY")}</span>
                </Grid>
            </Grid>
            dotColor = "Red"
        }

        dataList.push({ data, dotColor, isInitial })
    })
    // console.log({eventList, dataList})
    let statusList = <Grid container style={{ width: "250px" }}>
        {dataList.map(status => {
            let stat = null
            if (status.data)
                stat = <ApplicationStatus
                    data={status.data}
                    isInitial={status.isInitial}
                    circleColor={status.dotColor}
                />
            return stat
        })}
    </Grid>

    // console.log({statusList})

    return (
        statusList
    )
}

export default ApplicationStatuses;
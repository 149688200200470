import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import utils from '../../utilMethods';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  TextField,
  Card,
  MenuItem,
  Button,
  Grid,
  Container,
  Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { isWednesday } from 'date-fns';

const Styles = () => ({
  grid: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '15px 10px',
    alignItems: 'center',
    marginLeft: '60px',
    marginRight: '60px'
  },
  inputDate: {
    width: '150px'
  },
  selectBox: {
    width: '130px'
  },
  checkBox: {
    marginLeft: '10px'
  },
  Container: {
    alignItems: 'center',
    marginLeft: '30px',
    fontSize: '.8rem'
  },
  Container1: {
    alignItems: 'center',
    marginLeft: '70px',
    fontSize: '.8rem'
  }
});

class TimeDateComponent extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      availId: this.props.values.availId,
      values: {
        day: {
          Monday: this.props.values.day ? this.props.values.day.Monday : false,
          Tuesday: this.props.values.day ? this.props.values.day.Tuesday : false,
          Wednesday: this.props.values.day ? this.props.values.day.Wednesday : false,
          Thursday: this.props.values.day ? this.props.values.day.Thursday : false,
          Friday: this.props.values.day ? this.props.values.day.Friday : false,
          Saturday: this.props.values.day ? this.props.values.day.Saturday : false,
          Sunday: this.props.values.day ? this.props.values.day.Sunday : false,
        },
        startTime: utils.roundTimeQuarterHour(this.props.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.props.values.endTime)
      }
    };
    this.id = '';
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleChange5 = this.handleChange5.bind(this);
    this.handleChange6 = this.handleChange6.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }
  handleChange(event) {
    const name = event.target.name;

    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),
        day: {
          Monday: !this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange1(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),
        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: !this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange2(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),
        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: !this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange3(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),
        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: !this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange4(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),
        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: !this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange5(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),

        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: !this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  handleChange6(event) {
    const name = event.target.name;
    this.setState({
      values: {
        [name]: event.target.value,
        startTime: utils.roundTimeQuarterHour(this.state.values.startTime),
        endTime: utils.roundTimeQuarterHour(this.state.values.endTime),

        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: !this.state.values.day.Sunday
        }
      }
    });
  }

  handleTimeChange(date, name) {
    const endTime = name === 'endTime' ? date : this.state.values.endTime;
    const startTime = name === 'startTime' ? date : this.state.values.startTime;
    this.setState({
      values: {
        startTime: utils.roundTimeQuarterHour(startTime),
        endTime: utils.roundTimeQuarterHour(endTime),
        day: {
          Monday: this.state.values.day.Monday,
          Tuesday: this.state.values.day.Tuesday,
          Wednesday: this.state.values.day.Wednesday,
          Thursday: this.state.values.day.Thursday,
          Friday: this.state.values.day.Friday,
          Saturday: this.state.values.day.Saturday,
          Sunday: this.state.values.day.Sunday
        }
      }
    });
  }

  render() {
    console.log("AAAAAAAAAAAAAAA");
    const { classes } = this.props;
    this.id = this.props.id;
    this.props.updateData(this.id, this.state.values);
    return (
      <Card className={classes.grid}>
        {/* <TextField
          variant='outlined'
          className={classes.selectBox}
          error={
            this.props.errors
              ? this.props.errors[this.id - 1]
                ? !Boolean(this.state.values['day'])
                : false
              : false
          }
          select
          onChange={this.handleChange}
          label='Select Day'
          name='day'
          value={this.state.values.day}
        >
          {this.props.availability.map(option => (
            <MenuItem key={option.id} value={option.day}>
              {option.day}
            </MenuItem>
          ))}
        </TextField> */}
        <Grid item xs={12}>
          <Container className={classes.Container}>
            <Checkbox
              checked={this.state.values.day.Monday}
              onChange={this.handleChange}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            MON
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Tuesday}
              onChange={this.handleChange1}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            TUE
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Wednesday}
              onChange={this.handleChange2}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            WED
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Thursday}
              onChange={this.handleChange3}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            THU
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Friday}
              onChange={this.handleChange4}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />{' '}
            FRI
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Saturday}
              onChange={this.handleChange5}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />{' '}
            SAT
            <Checkbox
              className={classes.checkBox}
              checked={this.state.values.day.Sunday}
              onChange={this.handleChange6}
              value='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />{' '}
            SUN
          </Container>
        </Grid>

        <div className={classes.Container1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              variant='outlined'
              label='Start Time'
              mask='__:__ _M'
              // error={
              //   this.props.errors
              //     ? this.props.errors[this.id - 1]
              //       ? !Boolean(this.state.values['startTime'])
              //       : false
              //     : false
              // }
              error={this.state.values.startTime ? false : true}
              value={this.state.values.startTime}
              onChange={date => this.handleTimeChange(date, 'startTime')}
              InputProps={{
                className: classes.inputDate
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            variant='outlined'
            label='End Time'
            mask='__:__ _M'
            // error={
            //   this.props.errors
            //     ? this.props.errors[this.id - 1]
            //       ? !Boolean(this.state.values['endTime'])
            //       : false
            //     : false
            // }
            error={this.state.values.endTime ? false : true}
            value={this.state.values.endTime}
            onChange={date => this.handleTimeChange(date, 'endTime')}
            InputProps={{
              className: classes.inputDate
            }}
          />
        </MuiPickersUtilsProvider>
        <Button
          onClick={() => {
            this.props.deleteItem(this.id);
          }}
        >
          <DeleteIcon></DeleteIcon>
        </Button>
      </Card>
    );
  }
}
export default withStyles(Styles)(TimeDateComponent);

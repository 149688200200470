import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { Card, Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import Axios from 'axios'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const styles = {
  Container: {
    paddingTop: '2%',
    backgroundColor: '#cfe8fc',
    marginTop: '3%',
    fontSize: '.8rem',
    paddingBottom: '2%'
  },
  grid: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '9px 27px',
    alignItems: 'center'
  },
  selectBox: {
    width: '130px'
  },
  inputDate: {
    width: '150px'
  },
  set: {
    marginLeft: '27px'
  }
};

class Experince extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      id: this.props.values.id,
      isVisible: this.props.values.isVisible,
      industry: this.props.values.industry,
      Company: this.props.values.Company,
      jobProfile: this.props.values.jobProfile,
      expId: this.props.values.expId ? this.props.values.expId : null,
      startDate: this.props.values.startDate,
      endDate: this.props.values.endDate,
      error: ''
    };

    this.id = '';
  }
  handleIndustries =(event) =>{
    console.log(event);
    
  }
  handleChange(event) {
    const name = event.target.name;
    this.setState({
      [name]: event.target.value
    });
  }

  handleTimeChange(date, name) {
    const endDate = name === 'endDate' ? date : this.state.endDate;
    const startDate = name === 'startDate' ? date : this.state.startDate;

      this.setState({
        startDate: startDate,
        endDate: endDate,

      });
  }

  render() {
    const { classes } = this.props;
    this.id = this.props.id;
    this.props.updateData(this.id, this.state);
    return (
      <Container>
        <Card className={classes.grid}>
          <Grid item xs={12}>
            <div className={classes.grid}>
              <TextField
                variant='outlined'
                className={classes.selectBox}
                error={
                  this.props.errors
                    ? this.props.errors[this.id - 1]
                      ? !Boolean(this.state['industry'])
                      : false
                    : false
                }
                select
                onChange={this.handleIndustries}
                label='Industry'
                name='industry'
                value={this.state.industry}
              >
                {this.props.industry.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant='outlined'
                className={classes.selectBox}
                onChange={this.handleChange}
                label='Company'
                name='Company'
                error={
                  this.props.errors
                    ? this.props.errors[this.id - 1]
                      ? !Boolean(this.state['Company'])
                      : false
                    : false
                }
                value={this.state.Company}
              ></TextField>
              <TextField
                variant='outlined'
                className={classes.selectBox}
                error={
                  this.props.errors
                    ? this.props.errors[this.id - 1]
                      ? !Boolean(this.state['jobProfile'])
                      : false
                    : false
                }
                select
                onChange={this.handleChange}
                label='JobProfile'
                name='jobProfile'
                value={this.state.jobProfile}
              >
                {this.props.jobProfile.map(option => (
                  <MenuItem key={option.id} value={option.type}>
                    {option.type}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          {/* <TextField variant="outlined"
                    className={classes.selectBox}
                        error={this.props.errors?(this.props.errors[this.id-1]?!Boolean(this.state["Experince"]):false):false}
                        select
                        onChange={this.handleChange}
                        label="Experince"
                        name="Experince"
                        value={this.state.Experince}>
                        {this.props.Experince.map(option => (
                            <MenuItem key={option.id} value={option.year}>
                                {option.year}
                            </MenuItem>
                        ))}
                    </TextField> */}
          <div className={classes.set}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // name="startDate"
                margin='normal'
                id='date-picker-dialog1'
                label='Exp Start Date'
                format='MM/dd/yyyy'
                // maxDate={new Date()}
                value={this.state.startDate}
                onChange={date => this.handleTimeChange(date, 'startDate')}
                InputProps={{
                  className: classes.inputDate
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // name="endDate"
              margin='normal'
              id='date-picker-dialog'
              label='Exp End Date'
              format='MM/dd/yyyy'
              value={this.state.endDate}
              // minDate={this.state.startDate}
              onChange={date => this.handleTimeChange(date, 'endDate')}
              InputProps={{
                className: classes.inputDate
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>

          <Button
            onClick={() => {
              this.props.deleteItem(this.id);
            }}
          >
            <DeleteIcon></DeleteIcon>
          </Button>
        </Card>
      </Container>
    );
  }
}
export default withStyles(styles)(Experince);

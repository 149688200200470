import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Axios from 'axios';
import { Button, List } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import qs from 'querystring';
import { ToastContainer, toast } from 'react-toastify';
import urlLinks from '../../urlLinks';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  icon: {
    marginRight: '8px',
    width: '20px',
    height: '20px',
    minWidth: '0px',
    color: 'rgba(0, 0, 0, 0.74)'
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.84)'
  },
  nested: {
    paddingLeft: theme.spacing(2)
  },
  textSize: {
    fontSize: '0.8rem'
  },
  buttonStyle: {
    fontSize: '0.8rem',
    width: '50px',
    minWidth: '50px'
  }
});
class CustomPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getListItemsForPopover = this.getListItemsForPopover.bind(this);
  }
  ChangeJobStatus =() =>{
    let postData={
      job_id: this.props.id, 
      requested_by: parseInt(localStorage.getItem('userId')),
      job_status: this.props.status===1?2:1
    }
    Axios.post(`${urlLinks.baseUrl}job/ActiveJob?`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    toast.success(`Status changed successfully`);
                    console.log("Success"); 
                } else {
                    console.log("Fail");
                    throw toast.error(response.data.message);
                }
            })
            .catch(error => {
              toast.error(`Status change failed`);
            });
  }
  getListItemsForPopover(classes) {
    console.log(this.props);
    
    let StatusLinks= this.props.links;
    if(this.props.status===1)
      StatusLinks= this.props.links[0];
    if(this.props.status===0)
      StatusLinks= this.props.links[1];
    if(this.props.status===2)
      StatusLinks= this.props.links[2];

    if(this.props.workType==="On Demand"){
      StatusLinks=StatusLinks.filter(x =>
        {
          console.log(x.name);
          
          return x.name!=="Interview DashBoard"
        }) 
      
        console.log({StatusLinks});
        
    }
    const links = StatusLinks.map((link, index) => {
      if (link.route) {
        return (
          <Link
            key={link.id}
            to={{ pathname: link.route, id: link.route === '/job/jobList/commentSection' ? this.props.idColumn : this.props.id, phoneNumber: link.route === '/worker/workerList/viewWorker' ? this.props.phoneNumber : null,
            fullName: link.route === '/worker/workerList/viewWorker' ? this.props.fullName : null }}
            className={classes.link}
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon className={classes.icon}>
                {link.subIcon}
              </ListItemIcon>
              <ListItemText
                primary={link.name}
                className={classes.textSize}
                disableTypography
              ></ListItemText>
            </ListItem>
          </Link>
        );
      } else {
        return (
          <ListItem
            key={index + 1}
            button
            className={classes.nested}
            onClick={this.ChangeJobStatus}
          >
            <ListItemIcon className={classes.icon}>{link.subIcon}</ListItemIcon>
            <ListItemText
              primary={link.name}
              className={classes.textSize}
              disableTypography
            ></ListItemText>
          </ListItem>
        );
      }
    });
    return links;
  }
  render() {
    
    return (
      <React.Fragment>
       <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                autoClose={2000}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                >
            </ToastContainer>
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={
                this.props.className
                  ? this.props.className
                  : this.props.classes.buttonStyle
              }
            >
              <MoreVertIcon></MoreVertIcon>
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <List>{this.getListItemsForPopover(this.props.classes)}</List>
            </Popover>
          </div>
        )}
      </PopupState>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CustomPopover);

import React, { useState, useEffect, useMemo } from 'react';
import {ExcelRenderer } from "react-excel-renderer";
import {Button, Grid} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import Select from '../SelectComponent/Select'
import urlLinks from '../../urlLinks'
import Axios from "axios" 
import qs from 'querystring';
import * as moment from 'moment';
import PublishIcon from '@material-ui/icons/Publish';
import * as yup from 'yup';
import locationIds from '../filters/locationIds';
import AssignToRecruiterThroughJob from '../Modals/AssignToRecruiterThroughJob'
import { parseISOWithOptions } from 'date-fns/esm/fp';

const UploadExcel =  (props) => {
    const history= useHistory();
    const [sourceValue, setSourceValue] = useState("")
    const { showToast, setUploadLoading}= props
    const [excelData, setExcelData] = useState([])
    const [jobRecData, setJobRecData] = useState()
    const [incorrectData, setIncorrectData] = useState([])
    const [openAssignRecruiterJobModal, setOpenAssignRecruiterJobModal] = useState(false)
    // const validationSchema= yup.object().shape({
    //     // "firstName": yup.string("Not a string").matches(/^[a-zA-Z]+$/, "Incorrect format").required("cant be empty"),
    //     "Mobile No": yup.number().test('len', 'Candidate Contact number should be 10 digits', val => (val && val.toString().length === 10) || !val).notRequired("Number is required"), 
    //     Email: yup.string().email().notRequired(),
    // })
    const fixCity =(data) =>{
        data=data.toLowerCase();
    }
    const fixExperience =(exp) =>{
        // console.log({exp});
        
        
        let numbersArray= exp.match(/\d+/g);
        // console.log({numbersArray})
        if(numbersArray.length===2)
            return parseInt(numbersArray[0])*12 + parseInt(numbersArray[1])
        if(numbersArray.length===1)
            return parseInt(numbersArray[0])*12
        
    }
    const fixSalary = (salary) =>{
        const stringSalary= salary.toLowerCase()
        const lacsCheckArray=["lpa", "lac", "lacs", "lakhs", "lakh", "l"]
        let lacsCheck= false
        lacsCheckArray.some(c => stringSalary.includes(c)? lacsCheck=true: null)
        let SalaryVal= parseFloat(stringSalary.replace ( /[^\d.]/g, '' )); 
        if(lacsCheck){
            return SalaryVal*= 100000;
        }
        return SalaryVal;
    }
    const fixQualification =(row, cols) =>{

        let res={}
        console.log("inside Education" , {row}) 
        const postGradCheckArray=["m.a", "m.c", "m.e", "m.t", "mca", "m.s", "mba", "m.d", "pgdm"]
        const gradCheckArray=["b.a", "b.c", "b.e", "b.t", "bca", "b.s", "mbbs", "b.d"]
        const diplomaCheckArray=["diploma", "iti", "poly"]
        const inCollegeCheckArray=[]
        const twelfthCheckArray=["not", "12"]
        const tenthCheckArray=["inter", "10"]
        const belowTenthCheckArray=["other", "9", "8", "7", "6", "5", "4"]
        const ppg= cols.indexOf("Post P. G. Course")
        const pg= cols.indexOf("P. G. Course")
        const ug= cols.indexOf("U.G. Course")
        const edu = cols.indexOf("Education")
        // console.log({row})
        // console.log({ppg, pg, ug, edu})
        // console.log(row[ppg] , row[pg], row[ug], row[edu])
        if(row[ppg]){
            console.log("ppg")
            res["specialization"]=row[ppg]
            res["highestQualification"]="Post Graduate"
        }
        else if(row[pg]){
            res["specialization"]=row[pg]
            res["highestQualification"]="Post Graduate"
        }
        else if(row[ug] || row[edu]){
            const data= row[ug]?row[ug]:row[edu]
            console.log({data})
            if(typeof data=== "number")
                return null;
            res["specialization"]=data
            if(postGradCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="Post Graduate"
            else if(gradCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="Graduate"
            else if(inCollegeCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="In College"
            else if(diplomaCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="diploma"
            else if(twelfthCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="12th Pass"
            else if(tenthCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="10th Pass"
            else if(belowTenthCheckArray.some(g => data.toLowerCase().includes(g)))
                res["highestQualification"]="Below 10th"
        }
        console.log({res})
        return res
    }
    const fixAge = (age) =>{
        const ageArray=age.split("\n")
        const derivedAge=ageArray[0].replace(/[a-zA-Z\s]/g, '');
        const derivedDob= ageArray[1].replace(/\(\)/g, '')
        console.log({age, derivedAge, derivedDob})
        return {
            age: parseInt(derivedAge),
            dob:  moment(derivedDob, "DD MMM YYYY").format("YYYY-MM-DD")
        }
    }

    function xlSerialToJsDate(xlSerial){
        return new Date(-2209075200000 + (xlSerial - (xlSerial < 61 ? 0 : 1)) * 86400000);
      }
    
    const makeObject =(data, columns) =>{
        let result={};
        // console.log({data})
        if(sourceValue==="naukri"){
            console.log("entered");
            
            result=fixQualification(data, columns)
        }
        columns.map( (col, index) =>{
            switch (col) {
                case "Name":
                case "Employee Name":
                case "Candidate Name":
                    // code to be written //
                    let nameArray= data[index]?data[index].trim().split(' '):[];
                    if(nameArray.length>0)
                        result["firstName"]= nameArray[0]
                    if(nameArray.length>1){
                        result["lastName"]= nameArray.slice(1).join(" ");
                    }
                    break;
                case "Current CTC":
                case "Expected CTC":
                case "Annual Salary":
                case "Salary (monthly)":
                case "Salary (annual)":
                    if(data[index])
                        result[col]=fixSalary(data[index].toString())
                    break;
                case "Education":
                    result={...result, ...fixQualification(data, columns)}
                    break;
                case "Phone":
                case "Mobile No":
                case "Contact No.":
                    if(data[index]){
                        result[col]=parseInt((data[index].toString()).replace(/[^0-9]/g, '').slice(-10))
                    }
                    break;
                case "Age":
                    if(data[index]){
                        result[col]=parseInt((data[index].toString()).replace(/[^0-9]/g, ''))
                    }
                    break;
                case "City":
                case "Location":
                    if(data[index]){
                        let cityList= data[index].split("/")
                        let city=cityList[0]
                        city.replace(/[^a-zA-Z]/g, '')
                        locationIds.some(location =>{
        
                            if((location.city_name).toLowerCase()===city.toLowerCase()){
                                
                                result["city_id"]=location.id;
                                result["City"]=city
                                return true;
                            }  
                            // console.log("hello", location)
                        })
                        if(!result["city_id"])
                            result["city_id"]=0

                        result["City"]=city
                    }
                    break;
                case "Current Location":
                    if(data[index]){
                        let city=data[index]
                        city.replace(/[^a-zA-Z]/g, '')
                        locationIds.some(location =>{
        
                            if((location.city_name).toLowerCase()===city.toLowerCase()){
                                // console.log("(location.city_name).toLowerCase()===city.toLowerCase()", (location.city_name).toLowerCase()===city.toLowerCase(), location.city_name.toLowerCase(), city.toLowerCase(), )
                                result["city_id"]=location.id;
                                return null;
                            }  
                            // console.log("hello", location)
                        })
                        if(!result["city_id"])
                            result["city_id"]=0
                        result.City=city
                    }
                    break;
                case "Age/Date of Birth":
                    if(data[index]){
                        result={...result, ...fixAge(data[index])}
                    }
                    break;
                case "Last Active Date":
                    if(data[index]){
                        console.log(data[index])
                        result[col]=  moment(data[index], "DD MMM YYYY").format("YYYY-MM-DD")
                    }
                    break;
                case "Own Bike":
                case "Bike License":
                case "Car License":
                case "Gender":
                    if(data[index])
                        result[col]=data[index].charAt(0).toUpperCase() + data[index].slice(1)
                    break;
                case "Work Exp":
                case "Experience":
                case "Total experience (in Years and Months)":
                    if(data[index]){
                        result[col] = fixExperience(data[index].toString())
                    }
                    break;
                case "DOB":
                case "Last Activity":
                    if(data[index]){
                        // console.log("date",data[index])
                        if(typeof data[index]==="string"){
                            let date=""
                            date=data[index].replace(/['"]+/g, '')
                            if(date.match(/[a-zA-Z]/)){
                                result[col] = moment(data[index], "DD-MMM-YY").format("YYYY-MM-DD"); console.log('1')}
                            else if(date.match(/-/)){
                                result[col] = moment(data[index], "DD-MM-YY HH:mm:ss").format("YYYY-MM-DD"); console.log('2')}
                        }
                        else if(typeof data[index]==="number"){
                            result[col]= moment(xlSerialToJsDate(data[index])).format("YYYY-MM-DD");
                            console.log('3')
                            console.log(result[col], "data")
                        }
                    }
                    break;
                default:
                    if(data[index])
                        result[col]=data[index];
                    if(col==="Last Activity")
                        // console.log(data[index]);
                        
                    break;
                }
            })
        // console.log({result,columns})
        return result;
    }
    const createPostDataPrescribed = (list) =>{
        let postDataList= []
        // console.log(list)
        list.map(data =>{
            
            let postData={}
            postData["applied_on"]=moment().format("YYYY-MM-DD HH:mm:ss")
            postData["insertedBy"]=parseInt(localStorage.getItem("userId"))
            if(postData["userId"]) postData["userId"]=data.age
            if(data.Age) postData["age"]=data.Age
            if(data["Bike License"]) postData["bike_license"]=data["Bike License"]
            if(data["Car License"]) postData["car_license"]=data["Car License"]
            if(data["Chef  at (Bakery/Restaurant/Hotel etc.)"]) postData["chef_at"]=data["Chef  at (Bakery/Restaurant/Hotel etc.)"]
            if(data["Chef Type(CDP/Commis/Sous Chef etc.)"]) postData["chef_type"]=data["Chef Type(CDP/Commis/Sous Chef etc.)"]
            if(data.City) postData["city"]=data.City
            if(data.city_id) postData["city_id"]= data.city_id
            if(data["Current Company"]) postData["current_company"]=data["Current Company"]
            if(data["Current Job Category"]) postData["current_job_category"]=data["Current Job Category"]
            if(data["Current Role"]) postData["current_role"]=data["Current Role"]
            if(data.DOB) postData["date_of_birth"]=data.DOB
            if(data.Designation) postData["designation"]=data.Designation
            if(data.Email) postData["email"]=data.Email
            if(data["Experience Type"]) postData["experience_type"]=data["Experience Type"]
            if(data.firstName) postData["firstName"]=data.firstName
            if(data.Gender) postData["gender"]=data.Gender
            if(data["Highest Qualification"]) postData["highest_qualification"]=data["Highest Qualification"]
            if(data.Industry) postData["industry"]=data.Industry
            if(data["Job Role applied for"]) postData["job_role_applied_for"]=data["Job Role applied for"]
            if(data["Languages Known"]) postData["languages_known"]=data["Languages Known"]
            if(data.lastName) postData["lastName"]=data.lastName
            if(data["Last Activity"]) postData["last_activity"]=data["Last Activity"]
            if(data.Locality) postData["locality"]=data.Locality
            if(data["Notice Period"]) postData["notice_period"]=data["Notice Period"]
            if(data["Own Bike"]) postData["own_bike"]=data["Own Bike"]
            if(data["Mobile No"]) postData["phoneNumber"]=data["Mobile No"]
            if(data["Preferred Location"]) postData["preferred_location"]=data["Preferred Location"]
            if(data.Remarks) postData["remark"]=data.Remarks
            if(data["Salary (monthly)"]) postData["salary_monthly"]=data["Salary (monthly)"]
            if(data["Salary (annual)"]) postData["salary_annual"]=data["Salary (annual)"]
            if(data.Skill) postData["skill"]=data.Skill
            if(data.Source) postData["source"]=data.Source
            if(data.Specialisation) postData["specialisation"]=data.Specialisation
            // if(postData["status"]) postData["status"]=data.age
            if(data["Total experience (in Years and Months)"]) postData["total_experince"]=data["Total experience (in Years and Months)"]
            if(data["Year of Passing"]) postData["year_of_passing"]=data["Year of Passing"]
            postDataList.push(postData)
            // console.log({postDataList, postData})
        })
        // console.log({postDataList})
        return postDataList;
    }
    const createPostDataNaukri = (list) =>{
        let postDataList= []
        // console.log(list)
        list.map(data =>{
            // console.log({data})
            let postData={}
            postData["applied_on"]=moment().format("YYYY-MM-DD HH:mm:ss")
            postData["insertedBy"]=parseInt(localStorage.getItem("userId"))
            if(data.firstName) postData["firstName"]=data.firstName
            if(data.lastName) postData["lastName"]=data.lastName
            if(data["Contact No."]) postData["phoneNumber"]=data["Contact No."]
            if(data.Email) postData["email"]=data.Email
            postData["total_experince"]=data["Work Exp"]
            if(data["Annual Salary"]) postData["salary_annual"]=data["Annual Salary"]
            if(data.City) postData["city"]=data.City
            if(data.city_id) postData["city_id"]= data.city_id
            if(data["Preferred Location"]) postData["preferred_location"]=data["Preferred Location"]
            if(data["Current Employer"]) postData["current_company"]=data["Current Employer"]
            if(data["Designation"]) postData["current_role"]=data["Designation"]
            if(data["highestQualification"]) postData["highest_qualification"]=data["highestQualification"]
            if(data.specialization) postData["specialisation"]=data.specialization
            if(data.age) postData["age"]=data.age
            if(data.dob) postData["date_of_birth"]=data.dob
            if(data["Last Active Date"]) postData["last_activity"]=data["Last Active Date"]
            postData["source"]="Naukri"
            postDataList.push(postData)
            // console.log({postDataList, postData})
        })
        // console.log({postDataList})
        return postDataList;
    }
    const createPostDataSkillMirror = (list) =>{
        let postDataList= []
        // console.log(list)
        list.map(data =>{
            
            let postData={}
            postData["applied_on"]=moment().format("YYYY-MM-DD HH:mm:ss")
            postData["insertedBy"]=parseInt(localStorage.getItem("userId"))
            if(data.firstName) postData["firstName"]=data.firstName
            if(data.lastName) postData["lastName"]=data.lastName
            if(data["Phone"]) postData["phoneNumber"]=data["Phone"]
            if(data.Email) postData["email"]=data.Email
            if(data.Skills) postData["skill"]=data.Skills
            if(data["Experience"]) postData["total_experince"]=data["Experience"]
            if(data["Current CTC"]) postData["salary_annual"]=data["Current CTC"]
            if(data["Expected CTC"]) postData["salary_annual"]=data["Expected CTC"]
            if(data.City) postData["city"]=data.City
            if(data.city_id) postData["city_id"]= data.city_id
            if(data["Preferred Location"]) postData["preferred_location"]=data["Preferred Location"]
            if(data["Current Organization"]) postData["current_company"]=data["Current Organization"]
            if(data["Current Designation"]) postData["current_role"]=data["Current Designation"]
            if(data["highestQualification"]) postData["highest_qualification"]=data["highestQualification"]
            if(data.specialization) postData["specialisation"]=data.specialization
            if(data["CV Source"]) postData["source"]=data["CV Source"]
            postDataList.push(postData)
        })
        // console.log({postDataList})
        return postDataList;
    }
    const uploadData =(data, incorrectData) =>{
        
        let postData= []
        if(sourceValue==="prescribed")
            postData= createPostDataPrescribed(data)
        else if(sourceValue==="skillMirror")
            postData= createPostDataSkillMirror(data)
        else if(sourceValue==="naukri")
            postData= createPostDataNaukri(data)
        console.log({postData, jobRecData})

        const recId=jobRecData.recruiterId?`&recruiter_id=${jobRecData.recruiterId}`:''
        Axios.post(`${urlLinks.baseUrl}extWorker/extWorkerDetail?job_id=${jobRecData.jobId}${recId}`, postData)
            .then(response => {
                setUploadLoading(false)
                if (response.data.code === 1000) {
                history.push({
                    pathname: '/worker/uploadExcel',
                    data: { code: response.data.code, incorrectData: incorrectData , dataLength: data.length , responseData: response.data.response.response}
                })
                } 
                else {
                    history.push({
                        pathname: '/worker/uploadExcel',
                        data: { code: response.data.code, incorrectData: incorrectData, responseData: response.data.response.response}
                    })
                }
            })
            .catch(error => {
                setUploadLoading(false)
                history.push({
                pathname: '/worker/uploadExcel',
                data: { incorrectData: incorrectData }
                
                })
            })
    }
    const validateData= async (data, cols) => {
        let excelRows= [];
        let incorrectRows= []
        for(var i=0;i<data.length;i++) {
            if(data[i].length>0){
                
                const row= makeObject(data[i], cols)
                // const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                // console.log({row});
                
                // await validationSchema.validate(row)
                // .then(response => {
                    //     excelRows.push(response)
                // })
                if(sourceValue==="prescribed" && (!row["Mobile No"] || row["Mobile No"].toString().length!==10))
                    incorrectRows.push(row)
                else if(sourceValue==="naukri" && (!row["Contact No."] || row["Contact No."].toString().length!==10))
                    incorrectRows.push(row)
                else if(sourceValue==="skillMirror" && (!row["Phone"] || row["Phone"].toString().length!==10))
                    incorrectRows.push(row)
                else
                    excelRows.push(row)
                // .catch(err => {
                    // })
                    // console.log("loops");
            }
        }
        // console.log({excelRows, incorrectRows});
        uploadData(excelRows, incorrectRows);
        
        return {excelRows, incorrectRows}
    }
    const handleExcel = (event) =>{
        setUploadLoading(true)
        var fileObj = event.target.files[0];
        if(event.target.files[0]){
            ExcelRenderer(fileObj, (err,resp)=>{
                if(err){
                    // console.log(err);
                }
                else{
                    console.log({resp})
                    setExcelData(resp)
                    
                    setOpenAssignRecruiterJobModal(true)
                    // 
                }
            })
        }
    }
    const handleUpload =() =>{
        validateData(excelData.rows.slice(1), excelData.rows[0])
    }


    const ExcelOptions= useMemo(() => [
        ["prescribed", "Prescribed"],
        ["skillMirror", "SkillMirror"],
        ["naukri","Naukri"]
    ]

    , [])



    return (
        <Grid container>
            <Grid container>
                <Grid item xs className="padding0">
                    <Select 
                        options= {ExcelOptions}
                        value= {sourceValue}
                        setValue={setSourceValue}
                        label="Select a source: "
                    />
                </Grid>
                {sourceValue && <Grid item xs className="padding0">
                    <input
                        style={{display:"none"}}
                        id="contained-button-file"
                        type="file"
                        onChange={handleExcel}
                    />
                    <label htmlFor="contained-button-file">
                        <Button 
                            variant="outlined" 
                            className= "uploadButton marginLight20"
                            component="span"
                            disabled={!sourceValue}
                            startIcon= {<PublishIcon />}
                        >
                            Upload Excel
                        </Button>
                    </label>
                </Grid>}
            </Grid>
            <Grid container>
                <AssignToRecruiterThroughJob
                    openAssignRecruiterJobModal={openAssignRecruiterJobModal}
                    setOpenAssignRecruiterJobModal ={setOpenAssignRecruiterJobModal}
                    showToast= {showToast}
                    handleUpload= {handleUpload}
                    setJobRecData={setJobRecData}
                    jobRecData = {jobRecData}
                />
            </Grid>
        </Grid>
    )

}

export default UploadExcel;
import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import * as moment from 'moment'
import './filter.css'
const useStyles = makeStyles({
 
});

export default function DateFilter(props) {
    const {id, filters, setFilters, labelTo, labelFrom}= props
    const classes = useStyles();
    const [value, setValue] = React.useState(filters[id]);
    const [temp, setTemp] = React.useState(0)
    console.log({filters, id, value});
    console.log(filters[id]);
    useEffect(() => {
        setValue(filters[id])
        setTemp(1)
    }, [])
    const handleInputChange = (event,pos) => {
        
        let dateData= filters[id]
        if(pos===1)
            dateData[0]=event.target.value
        if(pos===2)
            dateData[1]=event.target.value
        
        // setValue([...dateData])
        setFilters({...filters, [id]: dateData})
    };

    return (
        <Grid container  alignItems="center">
            <Grid  xs={12}  style={{ minWidth: "100px", padding: "20px"}}>
                <TextField
                    className={`${id==="experience"?"marginTop-10":''}`}
                    style={{minWidth: "175px"}}
                    value={filters[id][0]}
                    label={labelFrom}  
                    onChange={event =>handleInputChange(event, 1)}
                />
                    <br />
                <TextField
                    className={`${id==="experience"?"marginTop-10":''}`}
                    style={{minWidth: "175px"}}
                    value={filters[id][1]}
                    label={labelTo}  
                    onChange={event =>handleInputChange(event, 2)}
                />
            </Grid>
        </Grid>
  );
}

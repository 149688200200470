import React from 'react'
import { Modal,  Backdrop, Grid, Slide} from '@material-ui/core';
import {  makeStyles} from '@material-ui/core/styles';
// import Availabilities from './Availabilitylist'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const RecruiterInstructionModal =(props) =>{
    const classes = useStyles();
    const {data, openInstructionModal, setOpenInstructionModal} =props
    
    const instructionData=
        <Grid container style={{maxWidth: "1000px"}}>
            <Grid item xs={12} className="columnOrder padding20">
                <span className="headings">Instructions</span>
            </Grid>
            <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Recruiter Pitch</span>
                </Grid>
                <Grid item xs={8} className="padding0 value" style={{whiteSpace: "pre-line"}}>
                    <span>{data.recruiterPitch}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Instructions for recruiter</span>
                </Grid>
                <Grid item xs={8} className="padding0 value" style={{whiteSpace: "pre-line"}}>
                    <span>{`${data.instructionsRecruiter}`}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding0 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Instructions for candidate</span>
                </Grid>
                <Grid item xs={8} className="padding0 value" style={{whiteSpace: "pre-line"}}>
                    <span>{data.instructionsCandidate}</span>
                </Grid>
            </Grid> 
            
        </Grid>
    return (
        <Modal
            className={classes.modal}
            open={openInstructionModal}
            onClose={() => setOpenInstructionModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className={`${classes.paper}`}>
                    {instructionData}
                </div>
            </Slide>
        </Modal>
    )
}
export default RecruiterInstructionModal;
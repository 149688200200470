import React, {   useState  } from 'react'
import { Modal, IconButton,  Backdrop, Grid, Slide,Button, Container} from '@material-ui/core';
import {  makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Axios from 'axios';
import urlLinks from '../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { GrFormClose } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';
import { MdComment, MdModeEdit, MdLibraryAdd } from 'react-icons/md';

import InputLabel from '@material-ui/core/InputLabel';
// import './modals.css'
import * as moment from 'moment';
import Parser from 'html-react-parser';
import WeekToggle from '../../components/Job/JobList/newJobViewComponents/weektoggleButton'
//import getActiveApplicationList from '././components/RecuiterDashboard/ApplicationList/getActiveApplicationList'
// import Availabilities from './Availabilitylist'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    minWidth:"1000px",
    width:"52%",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const SubmitResponseModel =(props) =>{
    const classes = useStyles();
    const {questionValues, openJobModal, setOpenJobModal, data, updateMyData} =props
    const [isEdit,setIsEdit]=useState(0);
    const [disable, setDisable]=useState(true);
    // const [answer, setAnswer]=useState();
    console.log(questionValues)
    console.log({props});
    console.log(data)
    var index=0;
    var answer=0;
    let expectedAnswer=0;
    var addAnswer=0;
    function useForceUpdate(){
      const [value, setValue] = useState(0); // integer state
      return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  const submit=()=>{
    var workerArray=[];
    
    console.log(questionValues);
    var questionfillcheck=false;
    questionValues.map((questionValues,index)=>{
      var myObj={};
      
      myObj.answer=questionValues.answer;
      myObj.inserted_by=localStorage.getItem('userId')
      myObj.updated_by=localStorage.getItem('userId')
      myObj.job_Question_id=questionValues.job_question_id
      myObj.user_id=data.userId
      myObj.worker_Question_id=0
      workerArray[index]=myObj;
    })

    workerArray.map((workerArray)=>{
      if(workerArray.answer!=0 && workerArray.answer!=1 ){
        questionfillcheck=true;
      }
      
    })


    if(questionfillcheck==true){
      toast.error(`Fill all question answer`)
      console.log("call 11")
      questionfillcheck=false;
    }else{  
      console.log(workerArray)
      Axios.post(`${urlLinks.baseUrl}question_controller/workerJobQuestion?job_id=${data.jobId}&job_details_id=${data.jobDetailsId}&user_id=${data.userId}`,workerArray).then(response=>{
        console.log(response);
        setOpenJobModal(false)
        toast.success(`Submitted successfully`)
        updateMyData(10,1)
       // getActiveApplicationList();
      })
    }      
  }

  const cancel=()=>{
    setOpenJobModal(false);
    
  }

  const edit=()=>{
    setDisable(0)
    setIsEdit(1)
  }

  const editSubmit=()=>{
    console.log(questionValues);
    setOpenJobModal(false)
    var workerArray=[];
    console.log(questionValues);
    
    questionValues.map((questionValues,index)=>{
      var myObj={};
      myObj.answer=questionValues.answer;
      myObj.updated_by=localStorage.getItem('userId')
      myObj.job_Question_id=questionValues.job_question_id
      myObj.user_id=data.userId
      myObj.worker_Question_id=questionValues.worker_question_id
      workerArray[index]=myObj;

    })
    console.log(workerArray)
    Axios.post(`${urlLinks.baseUrl}question_controller/workerJobQuestion?job_id=${data.jobId}&job_details_id=${data.jobDetailsId}&user_id=${data.userId}`,workerArray).then(response=>{
      console.log(response);
      setOpenJobModal(false)
      toast.success(`Submitted successfully`)
      updateMyData(10,1)
    })

  }

    const getAnswerHandleChange=(event)=>{
      console.log(event);
     
      var values=0;
      var i=0;
      console.log(answer)
      if(event!=null){
          values=event.target.value.split(' ');
          console.log(values);
        if(values[2]!=null){
          // if(this.state.questionObj.job_question_id==values[1]){
          //   // if(values[0]=='a'){
          //   //   this.addAnswer=1;
          //   //   this.forceUpdateHandler()
          //   // }else{
          //   //   this.addAnswer=0;
          //   //   this.forceUpdateHandler()
          //   // }
          // }
        }else{
          questionValues.map((jobQuestionList)=>{
            i++;
            if(jobQuestionList.job_question_id==values[1]){
              if(values[0]=='a'){
                jobQuestionList.answer=1;
                answer=1;
                forceUpdate()
                console.log(jobQuestionList)
              }else{
                jobQuestionList.answer=0;
                answer=0;
                forceUpdate()
                console.log(jobQuestionList)
              }
              
            }
          })
        }
      }
    }
    
   const setQuestion=(question, pramsNo, first, second, third, forth, fifth, id)=>{
      var arr=[first,second,third,forth,fifth];
      var str=question
      var final=0;
      if(pramsNo===0){
        return str
      }
      else if(id===7){
        str=`Are you comfortable working from <b style="color: #1c75bc;">${moment(arr[0]).format("hh:mm A")}</b> to <b style="color: #1c75bc;">${moment(arr[1]).format("hh:mm A")}</b>`
        return str
      }
      else{
        for(var i=0; i<pramsNo; i++){
            str=str.replace(arr[i],'<b style="color: #1c75bc;"> '+arr[i]+'</b>')
        }
        final= str;
        console.log({final})
        return final
      }
    }

    const setQuestion1=(question, pramsNo, first, second, third, forth, fifth, id)=>{
      var arr=[first,second,third,forth,fifth];
      var str=question
      var final=0;
      console.log(question, pramsNo, first, second, third, forth, fifth)
      if(pramsNo==0){
        return str
      }
      else if(id===7){
        str=`Are you comfortable working from <b style="color: #1c75bc;">${moment(arr[0]).format("hh:mm A")}</b> to <b style="color: #1c75bc;">${moment(arr[1]).format("hh:mm A")}</b>`
        return str
      }
      else{
        for(var i=0; i<pramsNo; i++){
            str=str.replace(arr[i],'<b style="color: #1c75bc;"> '+arr[i]+'</b>')
        }
        final= str;
        console.log({final})
        return final
      }
    }

    const close = () => {
      setOpenJobModal(false)
    }

  const submitView=
  <Container>
    <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
  <Grid item xs={12}>
    <Typography variant='h6' display="block" className={classes.textStyle}>
      Job questionnaire
    </Typography>
  </Grid>
  <Grid container style={{padding: '10px 20px 5px 0px'}}>
    {questionValues.length>0?
      questionValues.map((jobQuestionList,index) =>(

        answer=jobQuestionList.answer,
        jobQuestionList.status===1?
          <Grid container style={{padding: '10px 20px 5px 0px'}}>
            <Grid item xs={12} style={{padding: '10px 20px 5px 0px'}}>
              <Typography>
              { index+1} . {Parser(setQuestion(jobQuestionList.question, jobQuestionList.question_parameters_no, jobQuestionList.first_parameter,
                jobQuestionList.second_parameter, jobQuestionList.third_parameter,jobQuestionList.forth_parameter, jobQuestionList.fifth_parameter,jobQuestionList.question_id))}
              </Typography>
            </Grid>
           
            <Grid item xs={1} style={{padding: '5px 20px 5px 25px'}}>
              <Radio
                 color="default"
                checked={answer == 1 }
                onChange={getAnswerHandleChange}
                value={'a'+' '+jobQuestionList.job_question_id}
                name="radio-button-demo"
              />
            </Grid>
            <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >Yes</InputLabel>
            <Grid item xs={1} style={{padding: '5px 20px 10px 25px'}}>
              <Radio
                color="default"
                checked={answer == 0}
                onChange={getAnswerHandleChange}
                value={'b'+' '+jobQuestionList.job_question_id}
                name="radio-button-demo"
              />
            </Grid>
            <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >No</InputLabel>
            <Grid item xs={8}>
            </Grid>
          </Grid>
        :null
      ))
    :null}
    {questionValues.length>0?

    <Grid container style={{padding: '10px 20px 5px 0px'}}>
      <Grid item xs={8}>

      </Grid>
          <Grid item xs={2} style={{padding: '10px 20px 5px 0px'}}>
      <Button
        variant="contained"
        onClick={submit}
        color="primary">
        Submit
        </Button>
      </Grid>
      <Grid item xs={2} style={{padding: '10px 30px 5px 0px'}}>
        <Button
          color="primary"
          onClick={cancel} 
          variant="outlined">
          Cancel
        </Button>
      </Grid>
    </Grid>:null}
  </Grid>
  </Container>

  const editView=
  
  <Container>
    <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
  <Grid container >
    <Grid item xs={8} style={{padding: '10px 20px 5px 0px'}}>
        <Typography variant='h6' display="block" className={classes.textStyle}>
        Job questionnaire
        </Typography>
        </Grid>
        {
        questionValues.length > 0 && isEdit===0?
        <Grid item xs={2} style={{padding: '10px 0 5px 0'}}>
          <Button
          color="primary"
          onClick={edit} 
          variant="outlined">
          Edit
          </Button>
          
        </Grid>:null
        }
        {questionValues.length > 0?
          <Grid item xs={2} style={{padding: '10px 0 5px 0'}}>
          <IconButton onClick={close} style={{float: "right"}}>
              <CloseSharpIcon />
          </IconButton>
      </Grid>:
      <Grid item xs={4} style={{padding: '10px 0 5px 0'}}>
      <IconButton onClick={close} style={{float: "right"}}>
          <CloseSharpIcon />
      </IconButton>
  </Grid>
        }
        
    </Grid>
  <Grid container style={{padding: '10px 20px 5px 0px'}}>
    { questionValues.length>0?
      questionValues.map((jobQuestionList,index) =>(
        console.log(jobQuestionList),
        answer=jobQuestionList.answer,
        expectedAnswer=jobQuestionList.expected_answer,
        jobQuestionList.status===1?
          <Grid container style={{padding: '10px 20px 5px 0px'}}>
            <Grid item xs={12} style={{padding: '10px 20px 5px 0px'}}>
              <Typography>
              { index+1} . {Parser(setQuestion1(jobQuestionList.question, jobQuestionList.question_parameters_no, jobQuestionList.first_parameter,
                jobQuestionList.second_parameter, jobQuestionList.third_parameter,jobQuestionList.forth_parameter, jobQuestionList.fifth_parameter,jobQuestionList.question_id))}
              </Typography>
            </Grid>
           
            <Grid item xs={1} style={{padding: '5px 20px 5px 25px'}}>
              <Radio
                color="default"
                checked={answer === 1 }
                disabled={disable}
                onChange={getAnswerHandleChange}
                value={'a'+' '+jobQuestionList.job_question_id}
                name="radio-button-demo"
              />
            </Grid>
            <InputLabel style={{marginTop:'17px',fontWeight: 'bold', color: `${(expectedAnswer===1 && answer===1)?"green":(expectedAnswer===1 && answer===0)?"red":"inherit"}`}} >Yes</InputLabel>
            <Grid item xs={1} style={{padding: '5px 20px 10px 25px'}}>
              <Radio
                color="primary"
                checked={answer === 0}
                disabled={disable}
                onChange={getAnswerHandleChange}
                value={'b'+' '+jobQuestionList.job_question_id}
                name="radio-button-demo"
              />
            </Grid>
            <InputLabel style={{marginTop:'17px',fontWeight: 'bold', color: `${(expectedAnswer===0 && answer===0)?"green":(expectedAnswer===0 && answer===1)?"red":"inherit"}`}} >No</InputLabel>
            <Grid item xs={8}>
            </Grid>
          </Grid>
        :null
      )):null
    }
    <Grid  style={{padding: '10px 20px 5px 300px'}}>
      
      {isEdit===1?
      
      <Grid container style={{padding: '10px 20px 5px 0px'}}>
        <Grid item xs={ 4}>

        </Grid>
      <Grid item xs={4} style={{padding: '10px 20px 5px 0px'}}>
            
      <Button
        variant="contained"
        onClick={editSubmit}
        color="primary">
        Submit
        </Button>
      </Grid>
      <Grid item xs={4} style={{padding: '10px 30px 5px 0px'}}>
        <Button
          color="primary"
          onClick={cancel} 
          variant="outlined">
          Cancel
        </Button>
      </Grid>
      </Grid>:null

      }
    
    </Grid>
  </Grid>
  </Container>

  return (
    <Modal
      className={classes.modal}
      open={openJobModal}
      onClose={() => setOpenJobModal(false)}
      closeAfterTransition
      
      BackdropComponent={Backdrop}
      BackdropProps={{
      timeout: 500,
      }}>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
          <div className={`${classes.paper} modalContainer`} style={{height:"80%", overflow: "auto"}}>
              {data.submitResponse?editView:submitView}
          </div>
      </Slide>
    </Modal>
  )
}
export default SubmitResponseModel;
import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import urlLinks from '../../../urlLinks'
import Axios from 'axios'
import qs from 'querystring'
import user from '../../../assets/user-regular.png'
import time from '../../../assets/timer.svg'

const flexDisplay = {
    margin: 'auto',
  },
  ipBorder = {
    border: 'solid 1px #1c75bc',
    borderRadius: '4px',
    width: '170px',
    padding: '0 8px'
  },
  subTitle = {
    objectFit: 'contain',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  },
  txtArea = {
    border: 'none',
    borderBottom: 'solid 1px #1c75bc',
    fontSize: '14px',
    width: '320px',
    margin: '0 55px'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    marginLeft: '25px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '490px'
    },
  }));

const CallModal = (props) => {
  const classes = useStyles();
  const {data, handleOk, handleSnooze, jobValue, recruiterData} = props;
  console.log({data})
  const [openShowReminderModal, setOpenShowReminderModal] = useState(moment().format("DD/MM/YYYY")===moment(data.rem_date).format("DD/MM/YYYY") && moment().format("HH:mm")=== moment(data.rem_time, "HH:mm:ss").format("HH:mm"))

  const handleShowJobRecord =() =>{
    const temp={
      jobValue: jobValue,
      phoneNumber:data.phone_number,
      recruiterData:recruiterData
  }
  localStorage.setItem("showingReminderRecord", JSON.stringify(temp)  )
  window.open("/recruiter/DB", "_blank")
  }
  return (

    <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openShowReminderModal}
        // onClose={() =>setOpenShowReminderModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <div className={classes.paper}>
            <Grid container>
                <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                    <h1>Call Reminder</h1>
                </Grid>
                <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                    <img src={user} style={{paddingRight: "15px"}} />
                    <span>{`${data.first_name} ${data.last_name?data.last_name:""}`}&nbsp;-&nbsp;</span>
                    <span style={{color: "#1c75bc"}}>{data.phone_number}</span>
                </Grid>
                <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                  <Button
                    className="linkButton fontSize13 textCapitalize"
                    onClick={handleShowJobRecord}
                  >
                    {`${jobValue.brandName} - ${jobValue.jobTitle} - ${jobValue.city}`} 
                  </Button>  
                </Grid>
                <Grid item xs={12} className="padding0" style={{marginTop: '18px'}}>
                    <img src={time} style={{paddingRight: "15px"}}  />
                    <span>{moment(data.rem_time, "HH:mm:ss").format("hh:mm a")}, {moment(data.rem_date).format("dddd, DD MMMM YYYY")} &nbsp; </span>
                </Grid>
                <Grid item xs={12} className="padding10 marginVertical20" style={{border: "1px solid #cccccc"}}>
                    <Grid item xs={12} className="padding0" style={{marginBottom:"16px", color: "#707070"}}>
                        Notes:
                    </Grid>   
                    <Grid item xs={12} className="padding0">
                        {data.note}
                    </Grid>    
                </Grid>
                <Grid item xs={12} className="padding0" direction="row">
                    <Button style={confirm} onClick={ () => handleOk(data, setOpenShowReminderModal)}>OK</Button>
                    <Button style={cancel} onClick={() =>handleSnooze(data, setOpenShowReminderModal)}>SNOOZE</Button>
                </Grid>
            </Grid>
        </div>
    </Modal>
  );
};

export default CallModal;

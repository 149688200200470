import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { JsonToExcel } from 'react-json-excel';
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
// import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import {
  Button,
  Toolbar,
  Typography,
  Checkbox,
  TablePagination
} from '@material-ui/core';
import CustomPopover from '../customPopOver/customPopover';
import { ScaleLoader } from 'react-spinners';

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    flex: '1 1 100%'
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  colvisible: {
    display: 'table-cell'
  },
  colhide: {
    display: 'none'
  },
  tabcell: {
    padding: '2px 10px',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap'
  },
  notificationButton: {
    marginBottom: '0%',
    marginTop: '1%',
    marginLeft: '2%'
  }
});
class CustomTable extends Component {
  constructor(props) {
    super(props);
    // console.log({props});
    
    this.state = {
      selectedRows: [],
      data: props.data,
      classes: props.classes
    };
    this.tableLoader = (
      <TableRow style={{ height: '300px', position: 'relative' }}>
        <TableCell colSpan={100}>
          <ScaleLoader
            color={'#3F75B3'}
            css={{
              textAlign: 'center'
            }}
          ></ScaleLoader>
        </TableCell>
      </TableRow>
    );
    this.checkBoxesVisible = true;
    this.numericIndex = false;
    this.headerChecked = false;
    this.getRoWData = this.getRoWData.bind(this);
    this.getTableCellElement = this.getTableCellElement.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClickOnRow = this.handleClickOnRow.bind(this);
    this.checkRowIsSelected = this.checkRowIsSelected.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  getRoWData(data, cols, classes) {
    
    const rowData = data.map((row, index) => {
      // console.log(row);
      
      if(row.jobId!=6){
        const isItemSelected = this.checkRowIsSelected(row[this.props.name]);      
        return (
          <TableRow
            key={row[this.props.name] ? row[this.props.name] : index + 1}
            selected={isItemSelected}
            style={
              index % 2 ? { background: '#f2f2f2' } : { background: 'white' }
            }
          >
            <TableCell
              padding='checkbox'
              className={
                this.checkBoxesVisible ? classes.colvisible : classes.colhide
              }
            >
              <Checkbox
                checked={isItemSelected}
                onChange={event => {
                  this.handleClickOnRow(event, row[this.props.name]);
                }}
              />
            </TableCell>
            <TableCell
              className={`${
                this.numericIndex ? classes.colvisible : classes.colhide
              } ${classes.tabcell}`}
            >
              {index + 1}
            </TableCell>
            {this.getTableCellElement(row, cols)}
          </TableRow>
        );

      }
    });
    return rowData;
  }

  checkRowIsSelected(id) {
    return this.state.selectedRows.indexOf(id) !== -1;
  }

  getTableHeaderCols(cols) {
    const classes = this.state.classes;
    // console.log(cols)
    const colData = cols.map(col => {
      return (
        <TableCell
          style={ col.name === 'workerId' || col.name === 'employerId' || col.name === 'companyName'  ? 
            {
              position: 'sticky',
              left: '0px',
              zIndex: '1070',
              backgroundColor: 'inherit'
            } : col.name === 'name' || col.name ==='companyName' ? 
              col.name === 'name' ? 
            {
              position: 'sticky',
              left: '70px',
              zIndex: '1070',
              backgroundColor: 'inherit'
            } : 
            {
              position: 'sticky',
              left: '90px',
              zIndex: '1070',
              backgroundColor: 'inherit'
            } : null
          }
          key={col.id}
          align='center'
          className={`${
            col.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {col.label}
        </TableCell>
      );
    });
    return colData;
  }
  getTableCellElement(row, cols) {
    // console.log({row});
    
    const classes = this.state.classes;
    const lastColumn = cols.length - 1;
    let count=0;
    const Tablecells = cols.map((column, index) => {
      count++;
      if(column.name === 'invoiceUrl') {
        return (<Link target='_blank' href={row[column.name]}>
          {row[column.name]}
        </Link>)
      }
      // if(column.name==='cv'){
      //   return(<Link href={row[column.name]}>
      //     {row[column.name]!='--'?'Downlode':''}
      //   </Link>)
      // }
      return index !== lastColumn ? (
        <TableCell
          style={ column.name === 'workerId' || column.name === 'employerId' || column.name === 'companyName' ? 
            {
              position: 'sticky',
              left: '0px',
              // zIndex: '1070',
              backgroundColor: 'inherit'
            } : column.name === 'name' || column.name ==='companyName' ? 
            {
              position: 'sticky',
              left: '75px',
              // zIndex: '1070',
              backgroundColor: 'inherit'
            } : null
          }
          key={column.id}
          align='center'
          className={`${
            column.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
           {column.name === 'cv' && row[column.name]!='--' ? 
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              href={row[column.name]}
            >
             Download
            </Link>):('')}
          {column.name!='cv'?row[column.name]:''}
        </TableCell>
      ) : (
        <TableCell
          key={column.id}
          className={`${
            column.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {column.isColumnVisible ? (
            // console.log(this.props),
            
            <CustomPopover
              id={row[this.props.name]}
              idColumn={this.props.idColumn ? row[this.props.idColumn] : null}
              workType={row.workType}
              phoneNumber={row['contactNumber']}
              status={row.status==='Active' ? 1 :row.status==='InActive' ? 2 : row.status==='Closed' ? 0 : 9}
              jobStatus={row['jobStatus']}
              fullName={row['name']}
              links={this.props.links}
            ></CustomPopover>
          ) : null}
        </TableCell>
      );
    });
    return Tablecells;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {

  //   return ({
  //     data: nextProps.data,
  //     cols: nextProps.columns
  //   }) // <- this is setState equivalent

  // }

  handleSelectAll(event) {
    const target = event.target;
    this.headerChecked = target.checked;
    if (target.checked) {
      const newRows = this.props.data.map(row => row[this.props.name]);
      this.setState({
        selectedRows: newRows
      });
    } else {
      this.setState({
        selectedRows: []
      });

    }
  }

  handleClickOnRow(event, id) {
    let selected = this.state.selectedRows;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.headerChecked =
      newSelected.length === this.props.data.length ? true : false;
    this.setState({
      selectedRows: newSelected
    });
  }

  handleChangePage(nextPage) {
    this.props.tablePagination.changePage(
      this.props.tablePagination.rowsPerPage,
      nextPage
    );
  }
  
  handleChangeRowsPerPage(event) {
    const newrowsPerPage = parseInt(event.target.value, 10);
    if (newrowsPerPage !== this.props.tablePagination.rowsPerPage) {
      this.props.tablePagination.changePage(newrowsPerPage, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const rowData = this.getRoWData(
      nextProps.data,
      nextProps.columns,
      this.state.classes
    );
    this.setState({rowData: rowData})
  }
  
  componentDidMount(){
    const rowData = this.getRoWData(
      this.props.data,
      this.props.columns,
      this.state.classes
    );
    this.setState({rowData: rowData})
  }

  render() {
    console.log(this.props.data);
    const classes = this.state.classes;
    this.checkBoxesVisible =
      this.props.isCheckBoxesVisible === undefined
        ? false
        : this.props.isCheckBoxesVisible;
    this.numericIndex =
      this.props.numericIndex === undefined
        ? this.numericIndex
        : this.props.numericIndex;
    const colms = this.getTableHeaderCols(this.props.columns);
    // console.log(this.props.data);
    
    
    
    return (
      <Paper className={classes.root} style={{ height: '73vh', width: '100%' }}>
        {this.props.title ? (
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6' id='tableTitle' className={classes.title}>
              {this.props.title}
            </Typography>
          </Toolbar>
        ) : null}
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell
                padding='checkbox'
                className={`${
                  this.checkBoxesVisible ? classes.colvisible : classes.colhide
                } ${classes.tabcell}`}
              >
                <Checkbox
                  checked={this.headerChecked}
                  onChange={this.handleSelectAll}
                />
              </TableCell>
              <TableCell
                className={`${
                  this.numericIndex ? classes.colvisible : classes.colhide
                } ${classes.tabcell}`}
              >
                No.
              </TableCell>
              {colms}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tablePagination.tableUpdating
              ? this.tableLoader
              : this.state.rowData
            }
          </TableBody>
        </Table>
        {this.props.invoiceLabel ? (
          <Button
            className={classes.notificationButton}
            variant='contained'
            color='primary'
            onClick={() => this.props.generateInvoice(this.state.selectedRows)}
          >
            Generate Invoice
          </Button>
        ) : this.props.assignLabel ? (
          <Button
            className={classes.notificationButton}
            variant='contained'
            color='primary'
            onClick={() => this.props.assignWorkers(this.state.selectedRows)}
          >
            Assign Workers
          </Button>
        ) : null
        // (
        //   <Button
        //     className={classes.notificationButton}
        //     variant='contained'
        //     color='primary'
        //   >
        //     Send Notification
        //   </Button>
        // )
        }

        <Button
          className={classes.notificationButton}
          variant='contained'
          color='primary'
        >
          <CSVLink
            data={this.props.data}
            filename={`${this.props.title}.csv`}
            className='btn btn-primary'
            target='_blank'
            style={{
              color: 'inherit',
              textDecoration: 'none',
              fontWeight: '500'
            }}
          >
            Export To Excel
          </CSVLink>
        </Button>

        <TablePagination
          rowsPerPageOptions={this.props.tablePagination.rowsOptions}
          component='div'
          count={this.props.tablePagination.count}
          rowsPerPage={this.props.tablePagination.rowsPerPage}
          page={this.props.tablePagination.currentPage}
          onChangePage={(event, nextPage) => {
            this.handleChangePage(nextPage);
          }}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}
export default withStyles(useStyles)(CustomTable);

import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const InterviewModal = ( props) =>{
    const {interview, updateMyData, setLoading, showToast, openUpdateSlots, interviewAddress, setOpenUpdateSlots, row} = props
    
    const classes = useStyles();
    const [interivewAddressDetails, setInterivewAddressDetails] = useState({})
    const [disableSelect, setDisableSelect] = useState(false)
    const [selectSlot, setSelectSlot] = useState('selectSlot')
    const [interviewData, setInterviewData] = useState({
        interviewSlot1:interview.interviewStartTime,
        interviewSlot1StartTime: interview.interviewStartTime,
        interviewSlot1EndTime: interview.interviewEndTime,
        interviewSlot2:interview.interviewStartTime2,
        interviewSlot2StartTime: interview.interviewStartTime2,
        interviewSlot2EndTime: interview.interviewEndTime2,
        interviewSlot3:interview.interviewStartTime3,
        interviewSlot3StartTime: interview.interviewStartTime3,
        interviewSlot3EndTime: interview.interviewEndTime3,
        interviewStartDate: interview.startDate,
        interviewEndDate: interview.endDate,
        updatearray:[]
    })
    
    console.log({interview});
    const changeSelectSlot =(event) =>{
        setSelectSlot(event.target.value)
    }
    // const address = document.getElementById('googleInterviewAddress');
    // const autocompleteInterview = new window.google.maps.places.Autocomplete( address, {types: ['geocode']});
    // const handlePlaceSelectInterview = () => {
    //     let addressObject = autocompleteInterview.getPlace();
    //     const geometry = addressObject.geometry.location;
    //     const loc={
    //     lat:  geometry.lat(),
    //     lng: geometry.lng(),
    //     address: addressObject.formatted_address,
    //     interviewAddressUrl: addressObject.url
    //     }
    //     setInterivewAddressDetails({ searchInterviewAddress: loc.address,latInterview: loc.lat , longInterview: loc.lng , interviewAddressUrl: loc.interviewAddressUrl })
    // }
    // autocompleteInterview.addListener('place_changed', handlePlaceSelectInterview);
    const updateInterviewData= (date, location) =>{
        setDisableSelect(true)

        if(location===1){
            setInterviewData({...interviewData, interviewSlot1: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 1]})
        }
        else if(location===2){
            setInterviewData({...interviewData, interviewSlot1StartTime: `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 2]})
        }
        else if(location===3){
            setInterviewData({...interviewData, interviewSlot1EndTime: `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 3]})
        }
        else if(location===4){
            setInterviewData({...interviewData, interviewSlot2: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 4]})
        }
        else if(location===5){
            setInterviewData({...interviewData, interviewSlot2StartTime: `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 5]})
        }
        else if(location===6){
            setInterviewData({...interviewData, interviewSlot2EndTime: `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 6]})
        }
        else if(location===7){
            setInterviewData({...interviewData, interviewSlot3: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 7]})
        }
        else if(location===8){
            setInterviewData({...interviewData, interviewSlot3StartTime:`${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 8]})
        }
        else if(location===9){
            setInterviewData({...interviewData, interviewSlot3EndTime: `${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 9]})
        }
        else if(location===10){
            console.log("startDate", date)
            setInterviewData({...interviewData, interviewStartDate: moment(date).format("YYYY-MM-DD"), updatearray: [...interviewData.updatearray, 10]})
        }
        else if(location===11){
            console.log("endDate", date)
            setInterviewData({...interviewData, interviewEndDate: moment(date).format("YYYY-MM-DD"), updatearray: [...interviewData.updatearray, 11]})
        }
    }
    console.log({interviewData})
    const [confirmSlot, setConfirmSlot] = useState(4);

    const [showButtons, setShowButtons] = useState(false)

    const handleSelectSlot = () =>{
        const postData={
            interview_id: interview.interviewId,
            requested_by: localStorage.getItem('userId'),
            which_slot: selectSlot
        }
        Axios.post(`${urlLinks.baseUrl}interview/?`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success","slot selected successfully" )
                    updateMyData()
                    setOpenUpdateSlots(false)  
                } else {
                    console.log("Fail");
                    setOpenUpdateSlots(false)  
                    throw new Error(response.data.message);
                }
            })
            .catch(error => {
                setOpenUpdateSlots(false)
            });
    }
    const handleUpdateSlot  = () =>{
        const postData={
            requested_by: localStorage.getItem('userId'),
            job_detail_id: interview.jobDetailsId,
            user_id: interview.userId,
            interview_status:1,
            interview_mode: interview.interviewMode
        }
        if(interview.interviewId)
            postData.interview_id=interview.interviewId
        if(interviewData.updatearray.includes(1)||interviewData.updatearray.includes(2))
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot1StartTime).format("HH:mm:ss")}`
            postData.interview_start_time= interivewstartSlot
        }
        if(interviewData.updatearray.includes(3))
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot1EndTime).format("HH:mm:ss")}`
            postData.interview_end_time= interivewstartSlot
        }
        if(interviewData.updatearray.includes(4)||interviewData.updatearray.includes(5))
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot2StartTime).format("HH:mm:ss")}`
            postData.interview_start_time2= interivewstartSlot
        }
        if(interviewData.updatearray.includes(6)) 
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot2EndTime).format("HH:mm:ss")}`
            postData.interview_end_time2= interivewstartSlot
        }
        if(interviewData.updatearray.includes(7) || interviewData.updatearray.includes(8))
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot3StartTime).format("HH:mm:ss")}`
            postData.interview_start_time3= interivewstartSlot
        }
        if(interviewData.updatearray.includes(9))
        {
            let interivewstartSlot = `${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(interviewData.interviewSlot3EndTime).format("HH:mm:ss")}`
            postData.interview_end_time3= interivewstartSlot

        }
        if(interviewData.updatearray.includes(10))
        {
            postData.from_date= interviewData.interviewStartDate

        }
        if(interviewData.updatearray.includes(11))
        {
            postData.to_date= interviewData.interviewEndDate

        }
        
        Axios.post(`${urlLinks.baseUrl}interview/schedule`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", 'Status updated Successfully');
                    // if(props.type!=="interviewRescheduleStatus")
                    updateMyData()
                    setDisableSelect(false)
                    setOpenUpdateSlots(false)
                } else {
                    setOpenUpdateSlots(false)
                    throw showToast("error", response.data.message);
                }
            })
            .catch(error => {
                showToast("error", "Status update failed");
                setOpenUpdateSlots(false)
            });
    }

    const handleInterviewAddress =() =>{
        // const postData={
        //     interview_id: interview.interviewId,
        //     which_slot : selectSlot,
            
        //     requested_by: localStorage.getItem('userId')
        // }
        // Axios.post(`${urlLinks.baseUrl}interview/?`,qs.stringify(postData))
        //     .then(response => {
        //         if (response.data.code === 1000) {
        //             showToast("success","slot selected successfully" )
        //             updateMyData()
        //             setOpenUpdateSlots(false)  
        //         } else {
        //             console.log("Fail");
        //             setOpenUpdateSlots(false)  
        //             throw new Error(response.data.message);
        //         }
        //     })
        //     .catch(error => {
        //         setOpenUpdateSlots(false)
        //     });
    }
    
    const handleUpdateSlotClose =() =>{
        setOpenUpdateSlots(false)
    }
    
    return (
        <React.Fragment>
            <Modal
                className={classes.modal}
                open={openUpdateSlots}
                onClose={handleUpdateSlotClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openUpdateSlots} mountOnEnter unmountOnExit>
                    <div className={classes.paper}>
                        {(interivewAddressDetails.interviewUrl || (interivewAddressDetails.interviewLat && interivewAddressDetails.interviewLong)) &&
                            <Grid item xs={12}>
                                <TextField
                                    id='googleInterviewAddress'
                                    variant='outlined'
                                    fullWidth={true}
                                    label='Search Interview Address'
                                    onKeyPress={event =>
                                        event.key === 'Enter' ? event.preventDefault() : null
                                    }
                                    InputProps={{
                                        className: classes.input,
                                        inputProps: {
                                        className: classes.inputStyle
                                        }
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel
                                    }}
                                    ></TextField>
                            </Grid>
                        }
                        {interview.interviewMode===3 && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot1"
                                    label={"Slot 1 Date"}
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot1}
                                    onChange={date => updateInterviewData(date, 1)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    label={"Slot 1 Start time"}
                                    value={interviewData.interviewSlot1StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  2)}
                                />
                                <KeyboardTimePicker
                                    label={"Slot 1 End time"}
                                    error={moment(interviewData.interviewSlot1EndTime).format("HH:mm") < moment(interviewData.interviewSlot1StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot1EndTime).format("HH:mm") < moment(interviewData.interviewSlot1StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    value={interviewData.interviewSlot1EndTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  3)}
                                />
                            </Grid>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot2"
                                    label="Slot 2 Data"
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot2}
                                    onChange={date => updateInterviewData(date, 4)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    label="Slot 2 Start Time"
                                    value={interviewData.interviewSlot2StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  5)}
                                />
                                <KeyboardTimePicker
                                    label="Slot 2 End Time"
                                    error={moment(interviewData.interviewSlot2EndTime).format("HH:mm") < moment(interviewData.interviewSlot2StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot2EndTime).format("HH:mm") < moment(interviewData.interviewSlot2StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    value={interviewData.interviewSlot2EndTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  6)}
                                />
                            </Grid>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot3"
                                    label="Slot 3 Data"
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot3}
                                    onChange={date => updateInterviewData(date, 7)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    
                                    label="Slot 3 Start Time"
                                    value={interviewData.interviewSlot3StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 8)}
                                />
                                <KeyboardTimePicker
                                    label="Slot 3 End Time"
                                    value={interviewData.interviewSlot3EndTime}
                                    error={moment(interviewData.interviewSlot3EndTime).format("HH:mm") < moment(interviewData.interviewSlot3StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot3EndTime).format("HH:mm") < moment(interviewData.interviewSlot3StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 9)}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>}
                        {interview.interviewMode===2 && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="startDate"
                                    label={"Start date"}
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewStartDate}
                                    onChange={date => updateInterviewData(date, 10)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="endDate"
                                    label={"End date"}
                                    format="dd-MM-yyyy"
                                    minDate={interviewData.interviewStartDate}
                                    value={interviewData.interviewEndDate}
                                    onChange={date => updateInterviewData(date, 11)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>}
                        {interview.interviewMode===3 && <Grid container justify="space-around">
                            <Select
                                label="Select interview Slot"
                                id="selectSlot"
                                value={selectSlot}
                                variant='outlined'
                                onChange={changeSelectSlot}
                                classes={{root: classes.select}}
                                >
                                <MenuItem value="selectSlot" disabled>
                                    <em>Select Slot</em>
                                </MenuItem>
                                {interviewData.interviewSlot1!==null?
                                <MenuItem value={1}>{`${moment(interviewData.interviewSlot1).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot1StartTime).format("LT")} - ${moment(interviewData.interviewSlot1EndTime).format("LT")}`}</MenuItem>: null}
                                {interviewData.interviewSlot2!==null?
                                <MenuItem value={2}>{`${moment(interviewData.interviewSlot2).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot2StartTime).format("LT")} - ${moment(interviewData.interviewSlot2EndTime).format("LT")}`}</MenuItem>:null}
                                {interviewData.interviewSlot3!==null?
                                <MenuItem value={3}>{`${moment(interviewData.interviewSlot3).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot3StartTime).format("LT")} - ${moment(interviewData.interviewSlot3EndTime).format("LT")}`}</MenuItem>:null}
                            </Select>
                        </Grid>}
                        {(interivewAddressDetails.interviewUrl || (interivewAddressDetails.interviewLat && interivewAddressDetails.interviewLong)) &&
                            <Button color="primary"  onClick={handleInterviewAddress} > Update Interview location</Button>
                        }
                        <Button color="primary"  onClick={handleUpdateSlot} > {interview.interviewMode===3?"Update Slots":"Set Slot"}</Button>
                        {interview.interviewMode===3 && <Button color="primary" disabled={disableSelect}  onClick={handleSelectSlot} > Select Slots</Button>}
                        <Button color="secondary" onClick={handleUpdateSlotClose} >Cancel</Button>
                    </div>
                </Slide>
            </Modal>
        </React.Fragment>
    )
}

export default InterviewModal;
import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../urlLinks';
import Axios from 'axios';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const AssignToJobModal =(props) =>{
    const {showToast, jobList, openAssignJobModal, setOpenAssignJobModal, userIdList, toggleAllRowsSelected, toDifferentJob, currentJob} = props
    const classes = useStyles();
    const [jobValue, setJobValue] = useState()
    const [jobListSelf, setJobListSelf] = useState([])
    console.log({currentJob})
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        // console.log("called");
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                jobDetailId:el.jobDetailId,
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:''
            })
        })
        return list;
    }
    useEffect(() => {
        if(!jobList){
            console.log("Self");
            
            Axios.get(`${urlLinks.baseUrl}extWorker/getAllJobDetails`)
                .then(response =>{
                    setJobListSelf(makeJobList(response.data.response))
                })
        }
    }, [])
    const confirmAssignJob =() =>{
        
        
        if(toDifferentJob){
            const postData={
                existing_job_detail_id : currentJob.jobDetailId,
                user_ids: userIdList.toString(), 
                new_job_detail_id : jobValue.jobDetailId
            }
            Axios.post(`${urlLinks.baseUrl}extWorker/assignCandidateToAnotherJob`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){
                    showToast("success", "Workers assigned successfully")
                    toggleAllRowsSelected(false)
                }
                else{
                    showToast("error", "workers assignment failed")
                }
            })
            .catch(error =>{
                console.log("error", error)
                showToast("error", "workers assignment failed")
            })
        }
        else{
            const postData={
                job_id: jobValue.jobId,
                user_ids: userIdList.toString(), 
                inserted_by: parseInt(localStorage.getItem("userId"))
            }
            Axios.post(`${urlLinks.baseUrl}extWorker/assignExtToJob`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){
                    showToast("success", "Workers assigned successfully")
                    toggleAllRowsSelected(false)
                }
                else{
                    showToast("error", "workers assignment failed")
                }
            })
            .catch(error =>{
                console.log("error", error)
                showToast("error", "workers assignment failed")
            })
        }
        setOpenAssignJobModal(false)
    }

    const handleAssignJobClose =() =>{
        setOpenAssignJobModal(false)
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openAssignJobModal}
            onClose={handleAssignJobClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openAssignJobModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    <Autocomplete
                        id="jobList"
                        options={jobList?jobList:jobListSelf}
                        value={jobValue}
                        onChange={(event, value) => setJobValue(value)}
                        getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`
                            }
                        style={{ width: "450px" }}
                        renderInput={(params) => <TextField {...params} label="Select a job" />}
                    />
                    <Button color="primary" disabled={!jobValue} onClick={confirmAssignJob} >Confirm</Button>
                    <Button color="secondary" onClick={handleAssignJobClose} >Cancel</Button>
                </div>
            </Slide>
        </Modal>
    )
}

export default AssignToJobModal;
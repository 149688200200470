import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles , withStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: 15,
        border: "1px solid #1c75bc !important",
        borderRadius: "15px !important",
        padding: "0px 15px !important",
        color:"black",
        height: "32px",
        textTransform: "capitalize",
        '&:hover': {
            backgroundColor: "rgba(28, 117, 188, 0.1)",
            color: "#1c75bc",
            opacity: 1,
        },
        '&:active': {
            backgroundColor: "rgba(28, 117, 188, 0.1)",
            color: "#1c75bc",
            opacity: 1,
        },
    }
}));
const StyledToggleButton = withStyles(theme => ({
    selected: {
        backgroundColor: "rgba(28, 117, 188, 0.1) !important",
        color: "#1c75bc !important",
        opacity: 1,
    }
}))(ToggleButton);

export default function ToggleButtonsMultiple(props) {
    const classes= useStyles();
    const List= props.data?props.data:["English", "Kannada", "Hindi", "Gujarati", "Marathi", "Bengali", "Tamil", "Telugu", "Malayalam"]
    
    const languagesList= List.map(lan => 
        <StyledToggleButton value={lan} className={classes.chip} >
        {lan}
        </StyledToggleButton>
    )
    const handleFormat = (event, newLanguages) => {
        
        props.setFieldValue(props.name, newLanguages)
    };

    return (
        <ToggleButtonGroup 
            value={props.value} 
            onChange={handleFormat} 
            style={{backgroundColor: "transparent"}}
        >
            {languagesList}
        </ToggleButtonGroup>
    );
}
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

export default function InputAdornments(props) {
  const classes = useStyles();
  const {text = '' , select= "Monthly", nameText, nameSelect, setFieldValue, label, error }= props
  
  return (
   
    <FormControl className={clsx(classes.margin, classes.textField)} className="marginLeft-0" variant="outlined" fullWidth={true}>
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            id={nameText}
            name= {nameText}
            value={text}
            label= {label}
            error={error?error:false}
            onChange={e => setFieldValue(nameText, e.target.value)}
            style={{padding:0}}
            // inputProps={{
            //   style: {
            //     padding: 10
            //   }
            // }}
            endAdornment={
              <InputAdornment position="end">
                <Select
                  value={select}
                  disableUnderline
                  style={{backgroundColor: "#919191", padding: "5px 12px", borderBottomRightRadius: "4px", borderTopRightRadius: "4px"}}
                  onChange={e => setFieldValue(nameSelect, e.target.value)}
                  >
                  <MenuItem value={0}>Monthly</MenuItem>
                  <MenuItem value={1}>Yearly</MenuItem>
                </Select>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        
        
  );
}

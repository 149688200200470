import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// Import react-circular-progressbar module and styles
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Phone, AccountBalanceWalletOutlined, ContactMailOutlined, CheckCircleOutlined } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Dashboard.css';
import WelcomeImg from '../assets/dashboard-welcome.png';
import Trophy from '../assets/trophy.jpg';
import { AccountContext } from '../_services/user.account';
import PhoneCall from '../assets/phone-call.png';
import Wallet from '../assets/wallet.png';
import IdCard from '../assets/id-card.png';
import NounTick from '../assets/noun_tick.png';



const dailyCallsText = <img src={Trophy} alt="trophy" width='45' />
const monthYear = moment().format('MMMM YYYY');
const today = moment().format('dddd Do MMMM, YYYY');


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		flexGrow: 1,
	},
	details: {
		display: 'flex',
		flexDirection: 'column'
	},

	content: {
		flex: '1 0 auto',
		minHeight: 70,
		padding: "10px !important",
	},
	headeing5: {
		fontFamily: 'Open Sans',
		fontSize: 20,
		fontWeight: 600,
		lineHeight: 1.3,
		textAlign: 'left',
		color: '#333333',
		width: "90%",
    	display: "inline-block"
	},

	progressHead: {
		fontFamily: 'Open Sans',
		fontSize: 44,
		fontWeight: 'bold',
		lineHeight: 1.39,
		textAlign: 'left',
		color: '#333333',
	},
	media: {
		padding: '45px 30px 40px',
		backgroundColor: '#1c75bc',
		borderTopRightRadius: '11px',
		borderTopLeftRadius: '11px'
	},
	contentArea: {
		textAlign: 'center',
		backgroundColor: '#e8e8e8'
	},
	mainContent: {
		fontFamily: 'Open Sans',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: 1.08,
		textAlign: 'left',
		color: '#1c75bc',
		padding: '0 3%',
	},
	cardsArea: {
		width: '50%',
		flexBasis: '50%',
		display: 'inline-grid',
		padding: '2%',
	}
}));

const Dashboard = () => {
	const classes = useStyles();
	const history = useHistory();

	let userInf = '';
	if (localStorage.getItem('userInfo') == 'undefined' || localStorage.getItem('userInfo') == null) {
		history.push("/login");
	}else {
		userInf = JSON.parse(localStorage.getItem('userInfo'));
	}

	


	const { getStats } = useContext(AccountContext);

	const [doneCalls, setDoneCalls] = useState(0);
	const [totalCalls, setTotalCalls] = useState(0);
	const [callPercent, setCallPercent] = useState(0);

	const [doneInterview, setDoneInterview] = useState(0);
	const [totalInterview, setTotalInterview] = useState(0);
	const [InterviewPercent, setInterviewPercent] = useState(0);

	const [doneSelection, setDoneSelection] = useState(0);
	const [totalSelection, setTotalSelection] = useState(0);
	const [selectionPercent, setSelectionPercent] = useState(0);
	const [estimatedPayouts, SetEstimatedPayouts] = useState(0);

	useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

		getStats(userInfo.recruiterMasterId).then(data => {
			if (data.response) {
				setDoneCalls(data.response.avg_call_per_day);
				setTotalCalls(data.response.avg_call_per_day_target);
				setCallPercent((data.response.avg_call_per_day / data.response.avg_call_per_day_target) * 100);

				setDoneInterview(data.response.candidate_appeared);
				setTotalInterview(data.response.candidate_appeared_target);
				setInterviewPercent((data.response.candidate_appeared / data.response.candidate_appeared_target) * 100);

				setDoneSelection(data.response.candidate_selected);
				setTotalSelection(data.response.candidate_selection_target);
				setSelectionPercent((data.response.candidate_selected / data.response.candidate_selection_target) * 100);

				SetEstimatedPayouts(data.response.estimated_payout);
			}
		}).catch(err => {
			console.log('err', err);
		});
	}, [])

	return <>
		<div className={`${classes.root} dashboard-body bgcolorf6f6ff`}>
			<Header leftMenu={[{ url: "dashboard", name: "Dashboard" }, { url: "recruiter-dashboard", name: "Track applications" }]} link='true' />
			<Grid container className="dashboard-container">
				<Grid item xs={3}>
					<Card className={classes.root} style={{ minHeight: "226px" }}>
						<CardActionArea>
							<div className={classes.media}>
								<CardMedia
									component="img"
									alt="Contemplative Reptile"
									height="140"
									className='dashboard-profileImage'

									image={WelcomeImg}
									title="Contemplative Reptile"
								/>
							</div>
							<CardContent className={classes.contentArea}>
								<Typography gutterBottom variant="h5" component="h2" className="dashboard-welcome-user">
									{`Welcome ${(userInf) ? userInf.firstName : ''} ${(userInf) ? userInf.lastName : ''}!`}
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									{today}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>

				</Grid>
				<Grid item xs={9}>
					<div className={classes.mainContent}>{monthYear}</div>

					<Cards labelIcon={<img src={PhoneCall} alt="Phone" className='dashboardIcon' />} label="Average daily Calls" progressContent={<><div className={classes.progressHead}>{doneCalls}</div> <span>Out of {totalCalls}</span></>} subLabel="*excluding Sundays">
						<CircularProgressbarWithChildren value={callPercent} styles={buildStyles({ pathColor: "#8de1f6", })}>
							{callPercent === 100 && dailyCallsText}
						</CircularProgressbarWithChildren>

					</Cards>
					{estimatedPayouts && 
					<Cards labelIcon={<img src={Wallet} alt="Wallet" className='dashboardIcon' />} label="Estimated payouts" progressContent={<div className={classes.progressHead}>₹ {estimatedPayouts}</div>} subLabel="*provided upon the hired candidate retainment in job until the expected timeline from the employer">

					</Cards>
					}

					<Cards labelIcon={<img src={IdCard} alt="Id-Card" className='dashboardIcon' />} label="No. of candidates appeared for interview" progressContent={<><div className={classes.progressHead}> {doneInterview} </div> <span>Out of {totalInterview}</span></>} >
						<CircularProgressbar
							value={InterviewPercent}
							styles={buildStyles({
								pathColor: "#ffb900"
							})}
						/>
					</Cards>

					<Cards labelIcon={<img src={NounTick} alt="Noun-Tick" className='dashboardIcon' />} label="No. of candidate selections" progressContent={<><div className={classes.progressHead}>{doneSelection}</div>  <span>Out of {totalSelection}</span></>} >
						<CircularProgressbar
							value={selectionPercent}
							styles={buildStyles({
								pathColor: "#692dff"
							})}
						/>
					</Cards>
				</Grid>
			</Grid>
		</div>
	</>
}



function Cards(props) {
	const classes = useStyles();

	return (
		<Grid item xs={6} className={`${classes.cardsArea} dashboard-content`}>
			<Card className={classes.root} style={{ padding: "10px",borderRadius: "11px", minHeight: "206px", boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 7px 0px rgba(0,0,0,0.12)" }}>
				<div className={classes.details} >
					<CardContent className={classes.content}>
					{props.labelIcon}
						<Typography className={classes.headeing5}>
							<span className="card_head">{props.label}</span>
						</Typography>
						<Typography variant="subtitle1">
							{props.subLabel}
						</Typography>
					</CardContent>
				</div>
				<div className={classes.bottomDetails} >
					<Grid item xs={6} className="cardBottom">
						{props.progressContent}
					</Grid>

					<Grid item xs={6} className="cardBottom">
						<div style={{ width: 111 }}>{props.children}</div>
					</Grid>
				</div>
			</Card>
		</Grid>
	);
}


export default Dashboard;
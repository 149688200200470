import React, {useEffect,useState} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Faq from './Faq'
import TutorialVideo from './TutorialVideos'
import RegistrationImage from '../assets/Group 2971.png'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './index.module.scss'
import FaqIcon from './chat.svg'
import FaqIconActive from './chat_active.svg'
import VideoIcon from './noun_Video.svg'
import VideoIconActive from './noun_Video_active.svg'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
	}),
);

export default function HelpCenter(props) {
    const [currenttab, setCurrenttab] = useState(0)
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const classes = useStyles();

	const email = localStorage.getItem('emailId');
	
	return (
		<div className={`${classes.root}`}>
			<Header />
			<div className="faq_top bgcolorf6f6ff" style={{marginTop: "0"}}>
				<div className=" text-center">
					<span className="faq_head">Help Center</span>
					<p className="faq_title_desc">If you are not finding what you are looking for, email your queries to <u><a href="mailto:contact@okaygo.in">contact@okaygo.in</a></u></p>
				</div>
			</div>
            <Grid item xs={12} className={`padding0 ${styles.tabsContainer}`}>
                <Tabs style={{margin:"40px"}} className={`interviewDashboardTabContainer ${styles.tabContainer}`} selectedIndex={currenttab} onSelect={tabIndex => setCurrenttab(tabIndex)}>
                    <TabList className="interviewDashboardTabs">
                        <Tab><div className={styles.tabHeading}>{currenttab===0?<img src={FaqIconActive} alt="faqIcon" />:<img src={FaqIcon} alt="faqIcon" />}FAQ</div></Tab>
                        {email !== null &&<Tab><div className={styles.tabHeading} style={{width:"210px"}}>{currenttab===1?<img src={VideoIconActive} alt="videoIcon" />:<img src={VideoIcon} alt="videoIcon" />}Tutorial Videos</div></Tab>}
                    </TabList>
                    <TabPanel  >
                        <Faq  />
                    </TabPanel>
                    <TabPanel >
                        <TutorialVideo />
                    </TabPanel>
                </Tabs>
            </Grid>
            {email === null &&	<Grid item xs={12} className="padding0 industryTogethrt alignItemsCenter blueBackground" direction="row">
                    <Grid item xs={4} className="padding0 recruiterImageDiv">
                        <img src={RegistrationImage} alt="regImage" className="recruiterImage" />
                    </Grid>
                    <Grid item xs={4} className="whiteColor fontSize28 fontWeightBold industryTogethrt paddingTopBottom35 textAlignCenter" direction="row" justify="center">
                        <span>Let's transform the recruitment industry together</span>
                    </Grid>
                    <Grid item xs={4} className="padding0 displayFlex" direction="row" justify="center">
                        {props.isLogin ?
                            <Link
                                className="RegisterNowButton"
                                to="/register"
                            >Register Now
                            </Link> : <Link
                                className="RegisterNowButton"
                                to="/login"
                            >Login Now</Link>
                        }
                    </Grid>
                </Grid>}
		    {email === null &&	<Footer />}	
        </div>
	);
}


import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import IconButton from '@material-ui/core/IconButton';
import Axios from 'axios';
import urlLinks from '../../urlLinks'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function UploadButtons(props) {
  const classes = useStyles();
  const {row, updateMyData, showToast} =props
  console.log({props})
  const [showButtons, setShowButtons] = useState(false)
  const [cvData, setCvData] = useState({})
  const handleConfirm =() =>{
    const formData = new FormData();
    formData.append('file', cvData.file)
    formData.append('user_id',row.original.userId)
    
    Axios.post(`${urlLinks.baseUrl}userdocs/getDocLink`,formData,{
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response =>{
        console.log({response})
        if(response.data.code===1000){
          Axios.post(`${urlLinks.baseUrl}workermaster/AddUpdateCVLink?cv_file_name=${cvData.name}&link=${response.data.response}&worker_id=${row.original.workerId}`)
            .then( response =>{
              showToast("success", "CV uploaded successfully");
              setShowButtons(false)
              updateMyData();
            })
            .catch(error =>{
              setShowButtons(false)
              showToast("error", "CV upload failed");
            })
        }
        else{
          showToast("error", "CV conversion failed");
          setShowButtons(false)
        }
      })
      .catch(error =>{
        setShowButtons(false)
        showToast("error", "CV upload failed");
      })
  }
const handleCancel =() =>{
    setShowButtons(false)
    setCvData({})
}
  const handleupload =(e) =>{
    setShowButtons(true)
    setCvData({
      name: e.target.files[0].name,
      file: e.target.files[0]
    })
  }
  return (
    <Grid container className="padding0">
      <Grid item xs={12} className="paddingBottom10Only">
        {row.original.cvLink && <a
            href={row.original.cvLink}
            color="transparent"
            target="_blank"
        >
            <Button 
                className="fitContentWidth marginTopBottom linkButton"
                disabled={!row.original.cvLink}  
            >
                DOWNLOAD CV
            </Button>
        </a>}
      </Grid>
      {/* <Grid item xs={12} className="padding0">
        <input
        accept="image/*,.doc, .docx, .txt,.pdf"
        className={classes.input}
        id={row.original.workerId}
        // multiple
        type="file"
        onChange= {handleupload}
        />
        <label htmlFor={row.original.workerId}>
        <Button className="linkButton" component="span">
            {row.original.cvLink?"Update CV":"Upload CV"}
        </Button>
        </label>
        <input accept="image/* ,.doc, .docx,.txt,.pdf" className={classes.input} id="icon-button-file" type="file" />
      </Grid> */}
      {showButtons && <div>
          <IconButton color="primary" onClick={handleConfirm}>
              <DoneSharpIcon />
          </IconButton>
          <IconButton color="secondary" onClick={handleCancel} >
              <CloseSharpIcon />
          </IconButton>
      </div>}
    </Grid>
  );
}
import React, { createContext } from "react";
import Pool from './UserPool';
import { QAAPIConstants } from '../_constants';

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const AccountContext = createContext();

const Account = props => {

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    const results = {};
                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }
                                    resolve(results);
                                }
                            })
                        })
                        resolve({
                            user,
                            ...session,
                            ...attributes
                        });
                    }
                })
            }
            else {
                reject({ "message": "No Current User!" });
            }
        })
    }


    const authenticate = async (Username, Password) =>{

        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    resolve(data)
                },

                onFailure: err => {
                    reject(err);
                },

                newPasswordRequired: data => {
                    resolve(data)
                }
            });

        });}


    const forgotPassword = async (Username) =>

        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });

            user.forgotPassword({
                onSuccess: data => {
                    resolve(data)
                },

                onFailure: err => {
                    reject(err);

                },

                inputVerificationCode: data => {
                    const codeVerify = true;
                    resolve({ data, codeVerify })
                }
            });

        });

    const resetPassword = async (Username, code, password) =>
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });

            user.confirmPassword(code, password, {
                onSuccess: data => {
                    resolve(data)
                },

                onFailure: err => {
                    reject(err);
                }
            });

        });


    const getUser = async (email, isRegistered = true) => {

        return await new Promise(async (resolve, reject) => {
            if (isRegistered) {
                const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/getRecruiter?email=${email}`);
                if (!response.ok) reject(response);
                else {
                    resolve(response.json())
                }
            } else {
                const response = await fetch(`${QAAPIConstants.QAAPI_URL}/user/?email_id=${email}`);
                if (!response.ok) reject(response);
                else {
                    resolve(response.json())
                }

            }

        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut()
            localStorage.clear();
        }
    }


    const registerUser = async (email) => {

        return await new Promise(async (resolve, reject) => {
            const settings = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/createRecruiterByEmailId?email=${email}`, settings);
            if (!response.ok) reject(response);
            else resolve(response.json())

        })
    };

    const addUpdateUser = async (data) => {
        return await new Promise(async (resolve, reject) => {
            console.log("data", JSON.stringify(data));
            const settings = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/addUpdate`, settings);
            if (!response.ok) reject(response);
            else resolve(response.json())

        })
    };

    const uploadFile = async (data) => {
        return await new Promise(async (resolve, reject) => {
            console.log(data);
            const settings = {
                mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/userdocs/getDocLink`, settings);
            console.log(response);
            if (!response.ok) reject(response);
            else resolve(response.json())

        })
    };

    const removeFile = async (document_id) => {
        return await new Promise(async (resolve, reject) => {
            console.log(document_id);
            const settings = {
                mode: 'no-cors',
                method: 'POST'
            }
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/userdocs/removeDocument?document_id=${document_id}`, settings);
            console.log(response);
            if (response.code !== 1000) reject(response);
            else resolve(response.json())

        })
    };

    const getIndustries = async () => {
        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/getIndustries`);
            if (!response.ok) reject(response);
            else resolve(response.json())
        })

    }

    const getJobs = async () => {

        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/getJobFunctions`);
            if (!response.ok) reject(response);
            else resolve(response.json())
        })
    }

    const getCity = async () => {
        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/city/getCityList`);
            if (!response.ok) reject(response);
            else resolve(response.json());
        });
    }


    const getLanguages = async () => {
        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/configmaster/getLanguages`);
            if (!response.ok) reject(response);
            else resolve(response.json());
        });
    }

    const getQualification = async () => {
        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/configmaster/?sortBy=descending&type_key=qualification_type`);
            if (!response.ok) reject(response);
            else resolve(response.json());
        });
    }

    const getStats = async (recruiterId) => {
        return await new Promise(async (resolve, reject) => {
            const response = await fetch(`${QAAPIConstants.QAAPI_URL}/recruiter/stats?recruiter_id=${recruiterId}`);
            if (!response.ok) reject(response);
            else resolve(response.json());
        });

    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout, getUser, forgotPassword, resetPassword, getIndustries, getJobs, registerUser, getCity, getLanguages, getQualification, addUpdateUser, getStats, uploadFile, removeFile }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };
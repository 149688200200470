import React, { useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Dashboard from '../Dashboard/Dashboard';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import HelpCenter from '../Pages/HelpCenter';
import RegisterSuccess, { EmailVerified, PasswordChanged, ResetSuccess, UnderAssessment } from '../Register/CommonNotification';
import RegisterRejected from '../Register/RegisterRejected';
import Profile from '../Profile/Profile';
// import RecruiterDashboard from '../Dashboard/RecruiterDashboard';
import ChangePassword, { ForgotPassword } from "../Register/ResetPassword";
import { Account } from '../_services/user.account';

import RecruiterDB from '../components/RecruiterDB/RecruiterDashboard';
import RecruitersDashboard from '../components/RecuiterDashboard/RecruiterDashboard'
import NewTabJobView from '../utils/Modals/NewTabJobView'
import NewAddWorker from '../components/worker/AddEditWorker/addEditWorkerBase'
import WorkerSuccess from '../components/worker/AddEditWorker/pageComponents/FormSuccess'
import NewTabExternalWorker from '../components/worker/workerView/newTabExternalWorker'
import NewTabInternalWorker from '../components/worker/workerView/newTabInternalWorker'

import WorkerList from '../components/worker/workerList/workerList';
import AddWorker from '../components/worker/addWorker';
import WorkerOnBoardingReview from '../components/worker/OnBoardReview/WorkerOnBoardingReview';
import WorkerScreening from '../components/worker/Screening/WorkerScreening';
import editWorker from '../components/worker/workerList/editWorker';
import NewWorkerdB from '../components/worker/WorkerDB/workerDB'
import WorkerCommentScreening from '../components/worker/workerList/commentSection';
import AddWorkerCommment from '../components/worker/workerList/AddComment';
import EditWorkerCommment from '../components/worker/workerList/EditComment';
import ExternalWorkers from  '../components/worker/externalWorker/externalWorker'
import UpdateExcelStatus from '../components/worker/externalWorker/UploadStatus'
import viewWorker from '../components/worker/workerList/viewWorker';



const App = (props) => {
	
	return (
		<div className="jumbotron">
			<div className="container">
				<div className="col-sm-8 col-sm-offset-2">
					<Account>
						<Router>
							<Switch>
								<Route exact path='/' component={() => <Login isLogin={true} />}></Route>
								<Route path='/login' component={() => <Login isLogin={true} />}></Route>
								<Route path='/register' component={() => <Login />} ></Route>
								<Route path='/create-account' component={Register}></Route>
								<Route path='/dashboard' component={Dashboard}></Route>
								<Route path='/privacy-policy' component={PrivacyPolicy} ></Route>
								<Route path='/help' component={() => <HelpCenter />} ></Route>
								<Route exact path='/rejected' component={RegisterRejected} ></Route>
								<Route exact path='/success' component={RegisterSuccess} ></Route>
								<Route exact path='/password-success' component={PasswordChanged} ></Route>
								<Route exact path='/profile' component={Profile} ></Route>
								<Route exact path='/recruiter-dashboard' component={RecruiterDB} ></Route>
								<Route exact path='/email-verification' component={EmailVerified} ></Route>
								<Route exact path='/change-password' component={ChangePassword} ></Route>
								<Route exact path='/forgot-password' component={ForgotPassword} ></Route>
								<Route exact path='/reset-success' component={ResetSuccess} ></Route>
								<Route exact path='/recruiter/DB' component={RecruiterDB}></Route>
								<Route exact path='/recruiter/dashboard' component={RecruitersDashboard} />
								<Route path='/worker/newAddWorker' component={NewAddWorker} ></Route>
								<Route path='/under-assessment' component={UnderAssessment} ></Route>
								<Route path='/worker/workersuccess' component={WorkerSuccess} ></Route>
								<Route path='/newtabjobview' component={NewTabJobView} ></Route>
								<Route path='/internalworkerview' component={NewTabInternalWorker} ></Route>
								<Route path='/externalworkerview' component={NewTabExternalWorker} ></Route>
								<Route exact path='/worker/workerList' component={WorkerList}></Route>
								<Route path='/worker/workerList/editWorker' component={editWorker} ></Route>
								<Route path='/worker/workerList/viewWorker' component={viewWorker} ></Route>
								<Route path="/worker/externalWorkers" component={ExternalWorkers} ></Route>
								<Route path="/worker/uploadExcel" component={UpdateExcelStatus} ></Route>
								<Route path='/worker/workerList/commentSection' component={WorkerCommentScreening} ></Route>
								<Route path='/worker/workerList' component={NewWorkerdB} ></Route>
								<Route path='/worker/workerList/addComment' component={AddWorkerCommment} ></Route>
								<Route path='/worker/workerList/editComment' component={EditWorkerCommment} ></Route>
								<Route path='/worker/addWorker' component={AddWorker}></Route>
								<Route path='/worker/onBoarding' component={WorkerOnBoardingReview} ></Route>
								<Route path='/worker/screening' component={WorkerScreening}></Route>
							</Switch>
						</Router>
					</Account>
				</div>
			</div>
		</div>
	);
}


export default App;

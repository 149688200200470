import React, { useState, useEffect } from 'react'
import {Button, Grid} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {  makeStyles} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Axios from 'axios';
import * as moment from 'moment';
import urlLinks from '../../../urlLinks';
import WorkerModalTable from '../../Job/InterviewDashboard/JobTabs/functions/workerModalTable'
import Availabilities from '../../Job/InterviewDashboard/JobTabs/functions/Availabilitylist'
import Header from "../../../Header/Header";

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    minWidth:"1000px",
    width:"52%",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const WorkerModal =(props) =>{
    const [loading, setLoading] = useState(false)
    const {isWorkerHistory, recruiterDashBoardData, interviewDashboardData} = props;
    const [workerDetails, setWorkerDetails] = useState({})
    const [workerHistory, setWorkerHistory] = useState([])
    const [availability, setAvailability] = useState([])
    const [skills, setSkills] = useState([])
    const [data, setData] = useState()
    const [interests, setInterests] = useState([])
    const [experience, setExperience] = useState([])
    console.log(localStorage.getItem("internalWorkerData"))
    const classes = useStyles();

    useEffect(() => {
        const localData=localStorage.getItem("internalWorkerData")?{...JSON.parse(localStorage.getItem("internalWorkerData"))}:props.data
        setData(localData)
        console.log(localStorage.removeItem("internalWorkerData"))
        localStorage.removeItem("internalWorkerData")
    }, [])
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    const makeData =(w) =>{
        const lastJob=w.workerExperiences.length>0?w.workerExperiences[w.workerExperiences.length-1]:null;
        const data = {
            profilePhoto: w.profilePhoto?w.profilePhoto:null,
            name: `${w.firstName} ${w.lastName?w.lastName:null}`,
            age: w.age?`${w.age} yrs`:'N/a',
            gender: parseInt(w.gender)===26?"Male":parseInt(w.gender)===27?"Female":"N/a",
            averageRating: w.averageRating,
            cvLink:w.cv_link,
            qualification: w.qualification?w.qualification:'-',
            englishFluency: w.english_known_level===1? "No English":w.english_known_level===2? "Thoda English":w.english_known_level===3? "Good English":"N/a",
            rating: 3.6,
            lastJobTitle: lastJob?lastJob.jobTypeName:"None",
            lastJobCompany: lastJob?lastJob.companyName:"None",
            lastJobCity: "Gurgaon",
            lastSalary: numberFormat(w.last_salary),
            contactNumber: w.phoneNumber?w.phoneNumber:"N/a",
            email: w.emailId?w.emailId:"N/a",
            address: w.addressLine1?w.addressLine1:"N/a",
            pincode: w.pincode? w.pincode:"N/a",
            city: w.newCity?`${w.newCity}${w.newState?", " :''}${w.newState?w.newState:''}`:"N/a",
            interests: w.jobInterests,
            globalAvailability: w.globalAvailability,
            hasExperience: w.hasExperience
        }
        return data;
    }
    const makeHistory =(workerHistory) =>{
        const data=[];
        const allAdmins= JSON.parse(localStorage.getItem('allAdmins'));
        workerHistory.map(item =>{
            data.unshift({
                event: item.event,
                eventStatus: item.eventStatus,
                date: moment(item.insertedOn).format("DD/MM/YYYY hh:mm a"),
                triggeredBy: allAdmins[item.insertedBy]?allAdmins[item.insertedBy]:item.insertedBy===item.userId?"Worker app":"Employer"
            })
        })
        return data;
    }
    const makeExperience =(data) =>{
        let experience =[];
        if(data.workerExperiences.length===0 && !data.hasExperience)
            return experience;
        data.workerExperiences.map(item =>{
            experience.unshift({
                jobCategory: item.industryName,
                jobRole: item.jobTypeName,
                company: item.companyName,
                experience: `${((item.experienceInMonth/12).toFixed(1))} ${(item.experienceInMonth/12)>1?"years":"year"}`
            })
        })
        return experience;
    }
    const makeAvailability =(data) =>{
        let availability=[];
        if(data.workerAvailabilities.length===0 && data.globalAvailability)
            return availability;
        data.workerAvailabilities.map(item =>{
            availability.unshift({
                workdaysDetails: JSON.parse(item.workdaysDetails),
                startTime: moment(item.loginTime, "hh:mm a").format("hh:mm a"),
                endTime: moment(item.logoutTime, "hh:mm a").format("hh:mm a")
            })
        })
        return availability;

    }
    const makeSkills =(data) => {
        let skills= []
        data.map(el =>{
            skills.push(el.skillsName)
        })
        return skills.join(", ");
    }
    
    useEffect(() => {
        setLoading(true);
        if(data){

            Axios.get(`${urlLinks.baseUrl}workermaster/workerByUserIdWithEid?user_id=${data.userId}`)
            .then(response =>{
                if(response.data.response.content[0]){
                    setWorkerDetails(makeData(response.data.response.content[0])) 
                    setAvailability(makeAvailability(response.data.response.content[0])) 
                    setExperience(makeExperience(response.data.response.content[0])) 
                    setInterests(response.data.response.content[0].interested_cat_name)
                    setSkills(makeSkills(response.data.response.content[0].userSkills))
                    setLoading(false)
                }
            })
            .catch(error =>{  
            })
            if(isWorkerHistory){
                Axios.get(`${urlLinks.baseUrl}tracking/getworkerHistory?jobDetailId=${data.jobDetailsId}&workerId=${data.workerId}`)
                .then(response =>{
                    if(response.data.response){
                        setWorkerHistory(makeHistory(response.data.response)) 
                    }
                })
                .catch(error =>{
                })
            }   
        }
        
    }, [data])
    const JobHistory=isWorkerHistory?<Grid>
        <Grid xs={12} className="headings stickTop">
            Job history
        </Grid>
        <Grid xs={12}>
            <WorkerModalTable
                column="history"
                data={workerHistory}
            />
        </Grid>
    </Grid>:null
    const Experience=<Grid>
        <Grid xs={12} className="headings paddingTop10 stickTop">
            Experience
        </Grid>
        <Grid xs={12}>
            {experience.length===0?
                "Fresher":
                <WorkerModalTable
                    column="exp"
                    data={experience}
                />
            }
        </Grid>
    </Grid>
    
    const Interests=<Grid>
        <Grid xs={12} className="headings paddingTop10">
            Interests
        </Grid>
        <Grid xs={12}>
            {interests?interests:"No interest is mentioned"}
        </Grid>
    </Grid>
    const Skills=<Grid>
        <Grid xs={12} className="headings paddingTop10">
            Skills
        </Grid>
        <Grid xs={12}>
            {skills?skills:"No skill is mentioned"}
        </Grid>
    </Grid>
    const Availability= <Grid>
        <Grid xs={12} className="headings paddingTop10">
            Availability
        </Grid>
        <Grid xs={12} className="rowOrder">
            {availability.length===0?
                "Available all time":
                <Availabilities
                    data={availability}
                />
            }
        </Grid>
    </Grid>

    return (
        <div className={' profile-content recruiterDashboard'}>
            <Header leftMenu={[{ url: "/dashboard", name: "Dashboard" }, { url: "/recruiter-dashboard", name: "Track applications" }]} link='true' />
            <Grid container className="main-content">
                <Grid container>
                {workerDetails && data && <Grid container>
                    <Grid xs={4} className="borderRight padding0 ">
                        <Grid xs={12} className="columnOrder alginItemsCenter borderBottom padding20">
                        <Link
                            to={{
                                pathname: '/worker/newAddWorker', 
                                id: data.userId,
                                edit: true,
                                from: props.from,
                                recruiterDashBoardData: recruiterDashBoardData,
                                interviewDashboardData: interviewDashboardData
                            }}
                        >
                            <Button
                                style={{marginTop: "24px"}}
                                color= "primary"
                                variant= "outlined"
                                onClick={() => localStorage.setItem("recruiterEditWorkerId", workerDetails.workerId)}
                            >
                                Edit
                            </Button>
                        </Link>
                            <img src={workerDetails.profilePhoto} alt= "Worker"/>
                            <span className="WorkerName">{workerDetails.name}</span>
                            <span className="detailValues">{workerDetails.age} &#9679; {workerDetails.gender} &#9679; {workerDetails.qualification} &#9679; {workerDetails.englishFluency}</span>
                            <Rating name="half-rating-read" defaultValue={workerDetails.averageRating} precision={0.5} readOnly />
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <span className="lastExpTitle">Last job experience:</span>
                            <span className="detailValues weight600">{workerDetails.lastJobTitle}</span>
                            <span className="detailValues">{`${workerDetails.lastJobCompany}`}</span>
                            <span className="detailValues">{workerDetails.lastSalary}</span>
                            <a
                                href={workerDetails.cvLink}
                                color="transparent"
                                target="_blank"
                            >
                                <Button 
                                    className="fitContentWidth marginTopBottom"
                                    variant="contained"
                                    disabled={!workerDetails.cvLink}
                                    color= "primary"    
                                >
                                    DOWNLOAD CV
                                </Button>
                            </a>
                            <span className="headings">Contact Details</span>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={3} className="detailValues weight600">
                                Contact
                            </Grid>
                            <Grid xs={9} className="detailValues">
                                {workerDetails.contactNumber}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={3} className="detailValues weight600">
                                Email
                            </Grid>
                            <Grid xs={9} className="detailValues">
                                {workerDetails.email}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={3} className="detailValues weight600">
                                Address
                            </Grid>
                            <Grid xs={9} className="detailValues">
                                {workerDetails.address}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={3} className="detailValues weight600">
                                Pincode
                            </Grid>
                            <Grid xs={9} className="detailValues">
                                {workerDetails.pincode}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={3} className="detailValues weight600">
                                City
                            </Grid>
                            <Grid xs={9} className="detailValues">
                                {workerDetails.city}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid  xs={7} style={{padding:"20px"}}>
                        <Grid className="workerModalRightPanel">
                            {Experience}
                            {isWorkerHistory?JobHistory:null}   
                            {Interests}
                            {Skills}
                            {Availability}
                        </Grid>
                    </Grid>
                </Grid >}
            </Grid>
            </Grid>
        </div>
    )
}
export default WorkerModal;
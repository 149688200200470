import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import BackButton from '../../../utils/BackButton/BackButton';
import urlLinks from '../../../urlLinks';
// var unirest = require('unirest');

class CommentScreening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      companyName: null,
      list: [],
      currentPage: 0,
      count: 0,
      rowsPerPage: 10,
      column: [
        {
          id: '1',
          name: 'cid',
          label: 'Id',
          minWidth: 170
        },
        {
          id: '2',
          name: 'insertedOn',
          label: 'Date',
          minWidth: 170
        },
        {
          id: '3',
          name: 'categoryId',
          label: 'Category',
          minWidth: 170
        },
        {
          id: '4',
          name: 'comment',
          label: 'Comment',
          minWidth: 170
        },
        {
          id: '5',
          name: 'insertedBy',
          label: 'Added By',
          minWidth: 170
        },
        {
          id: '6',
          name: 'actions',
          label: 'Action',
          minWidth: 170
        }
      ]
    };
    this.data = [
      // {   "id":"1",
      //     "date":"12/11/2019 13:00",
      //     "category":"Onboarding Review",
      //     "comment":"Lorem ipsum dolor sit amet consectetur  ....Read More",
      //     "added by":"Sagar Swami",
      //     "actions":"Edit",
      // },
      // {    "id":"2",
      //     "date":"2/11/2019 14:00",
      //     "category":"Sushi Junction",
      //     "comment":"Lorem ipsum dolor sit amet consectetur  ....Read More",
      //     "added by":"Mohit chugh",
      //     "actions":"Edit",
      // },
      // {
      //     "id":"3",
      //     "date":"01/11/19 20:00",
      //     "category":"Sushi Junction",
      //     "comment":"Lorem ipsum dolor sit amet consectetur  ....Read More",
      //     "added by":"Sagar Swami",
      //     "actions":"Edit",
      // }
    ];
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  getRoWData(data, cols) {
    const rowData = data.map((row, index) => {
      // console.log(row);
      return (
        <TableRow key={row.id}>
          <TableCell>{}</TableCell>
          {/* {console.log(row)} */}
          {this.getTableCellElement(row, cols, row.cid)}
        </TableRow>
      );
    });
    return rowData;
  }
  getTableCellElement(row, cols, cid) {
    const lastColumn = cols.length - 1;
    const Tablecells = this.state.column.map((column, index) => {
      // console.log(column.id);
      return index !== lastColumn ? (
        <TableCell key={column.id}>{row[column.name]}</TableCell>
      ) : (
        <TableCell key={column.id} id={column.id}>
          <Link to={{ pathname: '/worker/workerList/editComment', id: cid }}>
            <Button variant='contained' color='primary'>
              Edit
            </Button>
          </Link>

          <Button
            style={{ margin: '10px' }}
            variant='contained'
            onClick={() => this.deleteComment(cid)}
            color='secondary'
          >
            Delete
          </Button>
        </TableCell>
      );
    });

    return Tablecells;
  }

  deleteComment = cid => {
    //   fetch(`${urlLinks.baseUrl}/admin/comment/${cid}`, {
    //   // credentials: 'include',
    //   method: 'DELETE',
    //   headers: {'Content-Type': 'application/json', },
    //   // body: JSON.stringify(sampledata),

    // }).then(result => console.log('success====:', result))
    //   .catch(error => console.log('error============:', error));
    // const headers = {};
    // unirest('DELETE', `${urlLinks.baseUrl}/admin/comment/${cid}`)
    Axios.delete(`${urlLinks.baseUrl}/admin/comment/${cid}`).then(res =>
      this.getCommentsById()
    );
    // Axios.delete()
    //   .then(response => console.log(response))
    //   .catch(err => console.log(err));
  };

  handleChangeRowsPerPage(event) {
    const newrowsPerPage = parseInt(event.target.value, 10);
    if (newrowsPerPage !== this.state.rowsPerPage) {
      this.setState({
        rowsPerPage: newrowsPerPage
      });
    }
  }

  handleChangePage = nextPage => {
    if (nextPage !== this.state.currentPage) {
      this.setState({
        currentPage: nextPage
      });
    }
  };

  getTableHeaderCols(cols) {
    const colData = this.state.column.map(col => {
      return (
        <TableCell key={col.id} align='left'>
          {col.label}
        </TableCell>
      );
    });
    return colData;
  }
  handleClick = () => {
    this.props.history.push('/worker/workerList/addComment');
  };

  getCommentsById = (
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) => {
    Axios.get(
      `${urlLinks.baseUrl}/admin/comment?user_id=${this.props.location.id}&page_no=${nextPage}&rows=${rowsPerPage}`
    ).then(response => {
      const data = this.makeData(response.data.response.content);
      this.setState({
        userId: this.props.location.id,
        list: data,
        count: response.data.response.totalElements,
        currentPage: nextPage,
        rowsPerPage: rowsPerPage
      });
    });
  };

  componentDidMount() {
    // console.log(this.props.location.id);
    if (this.props.location.id) {
      this.getCommentsById();
    } else {
      this.props.history.push('/worker/workerList');
    }
  }

  makeData = data => {
    const categorymap = {
      232: 'Onboarding Review',
      233: 'Screening Review',
      234: 'Rating Review',
      235: 'Inactivity Review',
      236: 'Payments'
    };
    const extractedData = [];
    const allAdmins = JSON.parse(localStorage.getItem('allAdmins'))
    data.map(el => {
      extractedData.push({
        cid: el.cid ? el.cid : '-',
        categoryId: el.categoryId ? categorymap[el.categoryId] : '-',
        comment: el.comment ? el.comment : '-',
        insertedBy: el.insertedBy ? allAdmins[el.insertedBy] : 'Admin',
        insertedOn: el.insertedOn ? el.insertedOn : '-'
      });
    });
    return extractedData;
  };

  render() {
    const classes = this.props;
    const rowData = this.getRoWData(this.state.list, this.state.column);
    const colms = this.getTableHeaderCols(this.state.column);

    return (
      <React.Fragment>
        <BackButton />
        <Card style={{ margin: '20px 0px', padding: '20px' }}>
          <Link
            to={{
              pathname: '/worker/workerList/addComment',
              id: this.state.userId
            }}
          >
            <Button variant='contained' color='primary'>
              Add Comment
            </Button>
          </Link>
        </Card>
        <Paper className={classes.root}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {colms}
              </TableRow>
            </TableHead>
            <TableBody>{rowData}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            rowsPerPage={this.state.rowsPerPage}
            count={this.state.count}
            page={this.state.currentPage}
            onChangePage={(event, nextPage) => {
              this.handleChangePage(nextPage);
            }}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}
export default CommentScreening;

import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import urlLinks from '../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const InterviewModal =(props) =>{
    const {row, showToast, updateMyData,openMarkLeftModal, setOpenMarkLeftModal} = props
    const classes = useStyles();
    let statusUpdateUrl=`${urlLinks.baseUrl}job/assigner/updateStatus`
    const [dateOfLeaving, setDateOfLeaving] = useState(null)
    // console.log({dateOfLeaving})
    const confirmJoinedStatus =() =>{
        const postData={
            interview_id: row.interviewId,
            updated_by: localStorage.getItem('userId')
        }
        postData.date_of_leaving= moment(dateOfLeaving).format("YYYY-MM-DD 00:00:00");
        postData.field="date_of_leaving";
        postData.assign_id= row.assignId;
        postData.fieldStatus= "LEFT_AFTER_JOINING";
        postData.user_id= row.userId;
        postData.worker_id= row.workerId
        postData.job_id= row.jobId;
        postData.job_details_id= row.jobDetailsId;
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData()
                    showToast("success", "Worker marked left")
                    setOpenMarkLeftModal(false)
                } else {
                    showToast("error", "Unable to mark worker left")
                    setOpenMarkLeftModal(false)
                }
            })
    
        }
    const handlejoinedStatusClose =() =>{
        setOpenMarkLeftModal(false)
    }
    
    
    return (
        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openMarkLeftModal}
                onClose={handlejoinedStatusClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openMarkLeftModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                    
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date of leaving"
                        format="dd-MM-yyyy"
                        value={dateOfLeaving}
                        onChange={date => setDateOfLeaving(date)}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    
                    </Grid>
                </MuiPickersUtilsProvider>
                <Button color="primary"  onClick={confirmJoinedStatus} >Confirm Date</Button>
                <Button color="secondary" onClick={handlejoinedStatusClose} >Cancel</Button>
                </div>
                </Slide>
            </Modal>
    )
}

export default InterviewModal;
import React, {Fragment,  useState } from 'react'
import { Field, FieldArray } from "formik";
import Axios from 'axios'
import urlLinks from '../../urlLinks'
import * as moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MuiPickersUtilsProvider, KeyboardDatePicker }  from '@material-ui/pickers';
import {TextField ,Button , MenuItem, Select, InputLabel, IconButton,  Grid, FormControl, InputAdornment} from "@material-ui/core";


const InterviewDetails = formikProps =>{
    const { errors, touched, values, setFieldValue, handleChange , industry,  jobRoles, typeArray} = formikProps;
    const [jobTypesArray, setJobTypesArray] = useState(typeArray?typeArray.length>0?typeArray:[]:[])
    const handleIndustries =(event, index) =>{
        handleChange(event);
        console.log({index, industry, event, jobTypesArray});
        
        const industryObj= industry.find(x=> x.id===event.target.value)
        let tempRoles=jobTypesArray;
        if(!tempRoles[index])
            tempRoles.push(industryObj.typeValue)
        else
            tempRoles[index]=industryObj.typeValue
        console.log({tempRoles});
        setJobTypesArray(tempRoles)
    }
    console.log({typeArray});
    return(
        <Fragment>
            <FieldArray
              name="Experince"
              render={({ insert, remove, push }) => (
                <Grid container spacing={0}>
                  {values.Experince.length > 0 &&
                    values.Experince.map((exp, index) => (
                        console.log("industry",values.Experince[index].industry),
                        <Grid container spacing={0} key={index} style={{border:"1px solid #cccccc", marginBottom: "16px", borderRadius: "4px"}}>
                            <Grid item xs={10} style={{padding: 0, display: "flex", flexFlow: "inherit"}}>
                                <Grid item xs={6} style={{padding: 0}}>
                                    <FormControl fullWidth={true} variant="outlined"  >
                                        <InputLabel id="industry">Industry</InputLabel>
                                        <Select
                                            name= {`Experince.${index}.industry`}
                                            value={values.Experince[index].industry}
                                            label= "Experience"
                                            onChange={event =>handleIndustries(event, index)}
                                        >
                                        {
                                            industry &&
                                            industry.map( j => 
                                                <MenuItem key={j.id} value={j.id}>{j.typeDesc}</MenuItem>
                                            )
                                        }
                                        </Select>
                                    </FormControl>            
                                </Grid>
                                <Grid item xs={5} style={{padding: 0, marginLeft: "10px"}}>
                                <Field
                                    name={`Experince.${index}.company`}
                                    label="Company"
                                    variant= "outlined"
                                    fullWidth={true}
                                    as={TextField}
                                />        
                                </Grid>
                                <Grid item xs={6} style={{padding: 0}}>
                                    <FormControl fullWidth={true} variant="outlined"  >
                                        <InputLabel id="jobrole">Job role</InputLabel>
                                        <Select
                                            name= {`Experince.${index}.jobProfile`}
                                            value={values.Experince[index].jobProfile}
                                            label= "Job role"
                                            onChange={handleChange}
                                        >
                                        {
                                            jobRoles.length>0 && jobTypesArray[index] && 
                                            jobRoles.filter(x=> jobTypesArray[index].includes(x.id)).map( j => 
                                                <MenuItem key={j.id} value={j.id}>{j.categorySubType}</MenuItem>
                                            )
                                        }
                                        </Select>
                                    </FormControl>            
                                </Grid>
                                <Grid xs={12} style={{padding: 0, display: "flex", flexFlow: "inherit"}}>
                                    <Grid xs={5}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                views={["year", "month"]}
                                                label="Start date"
                                                className="TimeField"
                                                format="MM/yyyy"
                                                name={`Experince.${index}.startDate`}
                                                value={values.Experince[index].startDate}
                                                onChange={date =>{
                                                    setFieldValue(`Experince.${index}.startDate`, date)}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid xs={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                views={["year", "month"]}
                                                label="End date"
                                                format="MM/yyyy"
                                                minDate={values.Experince[index]?values.Experince[index].startDate: null}
                                                className="TimeField"
                                                name={`Experince.${index}.endDate`}
                                                value={values.Experince[index].endDate}
                                                onChange={date =>{
                                                    setFieldValue(`Experince.${index}.endDate`, date)}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{padding: "25px 0 0 0"}}>
                                <IconButton
                                    onClick={() =>{
                                        let tempRoles=jobTypesArray;
                                        tempRoles.splice(index, 1)
                                        setJobTypesArray(tempRoles)
                                        remove(index)}
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        color="primary"
                        onClick={() =>{
                            console.log({jobTypesArray});
                            
                            push({company: '', startDate: null, endDate: null})}}
                        >
                        {values.Experince.length > 0?<span>Add more Experience</span>:<span>Add Experience</span>}
                    </Button>
                </Grid>
              )}
            />
        </Fragment>
    )
}

export default InterviewDetails;
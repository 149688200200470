import React, { Component } from 'react';
import { Button, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import utilFunctions from '../../utilMethods';
const styles = () => ({
  imageStyle: {
    height: '120px',
    width: '120px',
    margin: '20px 5px 10px 5px'
  },
  divStyle: {
    display: 'flex',
    flexFlow: 'column',
    height: '200px',
    width: '130px'
  },
  divOnDisplayNone: {
    display: 'flex',
    flexFlow: 'column'
  },
  alignFormat: {
    margin: '0 auto 5px ',
    fontSize: '0.8rem'
  }
});
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
    this.fileName = '';
    this.fileType = '';
    this.handleChange = this.handleChange.bind(this);
    this.updateFileData = this.updateFileData.bind(this);
  }
  handleChange(event) {
    this.fileName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    const reader = new FileReader();
    const fileForUpload = event.target.files[0]
    // console.log(fileForUpload)
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = event => {
      this.setState(
        {
          file: reader.result
        },
        () => {
          this.updateFileData();
        }
      );
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   return nextProps.imageFileLink !== this.props.imageFileLink;
  // }

  // componentDidUpdate(prevProps){
  //   if(prevProps.imageFileLink !== this.props.imageFileLink){
  //     console.log(this.props.imageFileLink);
  //     utilFunctions.getBase64String(this.props.imageFileLink).then(result=>{
  //       const base64= 'data:image;base64,'+result;
  //       this.setState({
  //         file:base64
  //       })});

  //   }
  // }
  componentDidMount() {
    // console.log(this.props.imageFileLink);
    if (this.props.imageFileLink) {
      utilFunctions.getBase64String(this.props.imageFileLink).then(result => {
        const base64 = 'data:image;base64,' + result;
        this.setState({
          file: base64
        });
      });
    }
  }
  updateFileData() {
    this.props.updateProfileImage(
      this.state.file,
      this.fileName,
      // fileForUpload,
      this.fileType
    );
  }
  render() {
    const classes = this.props.classes;
    const divClass = !this.props.display ? 'divStyle' : 'divOnDisplayNone';
    return (
      <div className={divClass}>
        {/* {!this.props.display ? (
          !this.state.file ? (
            // <span style={{ marginLeft: '5px' }}>Field Required*</span>
          ) : null
        ) : null} */}
        {!this.props.display ? (
          <Avatar
            variant='square'
            alt='image'
            src={this.state.file}
            className={classes.imageStyle}
          />
        ) : null}
        {!this.props.display ? (
          <Typography variant='h5' gutterBottom className={classes.alignFormat}>
            {this.state.fileName}{' '}
          </Typography>
        ) : null}
        <Button
          style={{ marginLeft: '18px' }}
          variant='contained'
          component='label'
          className={classes.alignFormat}
        >
          Browse
          <input
            type='file'
            accept='image/png, image/jpeg'
            style={{ display: 'none' }}
            onChange={event => {
              this.handleChange(event);
            }}
          />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ImageUpload);

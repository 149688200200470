import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const InterviewModal =( props) =>{
    const {statusData, setStatusData, updateMyData, showToast, setLoading, openSelectedStatus, setOpenSelectedStatus, interviewId, columnId} = props
    const classes = useStyles();
    const handleSalary =(event) =>{
        setStatusData({...statusData, salary: event.target.value})
    }
    console.log({statusData})
    const handleSelectedStatusClose =() =>{
        setOpenSelectedStatus(false)
    }
    const updateSelectedStatus = (updateData) =>{
        console.log({statusData})
        const postData={
            interview_id: interviewId,
            is_job_offered: 1,
            feedback_type:columnId,
            
            requested_by: parseInt(localStorage.getItem('userId'))
        }
        if(statusData.salary){
            postData.job_offered_amount= statusData.salary
            postData.offered_amount_type= statusData.offeredSalaryType
        }
        if(statusData.offerDate){
            postData.update_offered_date= moment(statusData.offerDate).format("YYYY-MM-DD 00:00:00")
        }
        if(statusData.joiningdate){
            postData.joining_date= moment(statusData.joiningdate).format("YYYY-MM-DD 00:00:00")
        }
        Axios.post(`${urlLinks.baseUrl}interview/offerjob?`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    // updateMyData();
                    showToast("success", "Job offer details updated successfully.");
                    setOpenSelectedStatus(false)
                } else {
                    setOpenSelectedStatus(false)
                    throw showToast("error", response.data.message);
                }
            })
            .catch(error => {
               setOpenSelectedStatus(false)
               showToast("error", error.message);
               

            });
    }
    const confirmSelectedStatus = () =>{
        if(!statusData.salary)
        {
            alert("EnterSalary");
            return
        }
        updateSelectedStatus()
        setOpenSelectedStatus(false)
    }
    
    return (
         <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSelectedStatus}
                onClose={handleSelectedStatusClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openSelectedStatus} mountOnEnter unmountOnExit>
                    <div className={classes.paper}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                            
                            <KeyboardDatePicker
                                margin="normal"
                                id="joining-date"
                                label="Enter Joining Date"
                                format="dd-MM-yyyy"
                                value={statusData.joiningdate}
                                onChange={date => setStatusData({...statusData, joiningdate: `${moment(date).format("YYYY-MM-DD")} 00:00:00`})}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                            />
                            
                            </Grid>
                            <Grid container justify="space-around">
                            
                            <KeyboardDatePicker
                                margin="normal"
                                id="offer-date"
                                label="Enter Offer Date"
                                format="dd-MM-yyyy"
                                value={statusData.offerDate}
                                onChange={date =>  setStatusData({...statusData, offerDate: `${moment(date).format("YYYY-MM-DD HH:mm")}:00`})}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                            />
                            </Grid>
                            <Grid item xs={12} className="padding0" style={{display:"flex", marginLeft: "100px"}}>
                                
                                    <TextField
                                        id="offeredSalary"
                                        value={statusData.salary}
                                        onChange={event => setStatusData({...statusData, salary:event.target.value})}
                                        placeholder="Offered salary"
                                        className="margin16px0"
                                        style={{width: "230px"}}
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            style:{
                                                paddingLeft:"10px"
                                            }
                                        }}
                                        />
                                    <Select
                                        id="salaryType"
                                        variant="filled"
                                        className={`margin16px0 ${classes.input} ${classes.iconFilled}`}
                                        style={{padding: '1px',fontSize: '12px', width: '100px', left: "-100px"}}
                                        InputProps={{
                                            style:{
                                                padding:'0'
                                            }
                                        }}
                                        value={statusData.offeredSalaryType}
                                        disableUnderline
                                        label="Salary type"
                                        onChange={event => setStatusData({...statusData, offeredSalaryType:event.target.value})}
                                    >
                                        <MenuItem id="m" value={0} >Monthly</MenuItem>
                                        <MenuItem id="y" value={1} >Yearly</MenuItem>
                                    </Select>
                                
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Button color="primary"  onClick={confirmSelectedStatus} > Confirm Date</Button>
                        <Button color="secondary" onClick={handleSelectedStatusClose} >Cancel</Button>
                    </div>
                </Slide>
            </Modal>
    )
}

export default InterviewModal;
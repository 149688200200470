import React, {Fragment} from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

export const StepButton = props => {
  const { step, values, emailExist, contactExist , isSubmitting} = props;
  const classes = useStyles();
  const handleSave =() =>{
    localStorage.setItem('addjobform', JSON.stringify(values));
  }
  switch (step) {
    case 1:
      return (
        <Grid container className="stepButtons">
          <Grid item xs={12} className="padding0">
            <div className= "savenext">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => handleSave()}
                disabled={emailExist || contactExist}
                className={classes.button}
              >
                NEXT
              </Button>
             </div> 
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid container className="stepButtons"> 
        <Grid item xs={6} className="padding0">
            <Button
              variant="outlined"
              color="primary"
              onClick={props.handleBack}
              className={classes.button}
            >
              BACK
            </Button>
          </Grid>
          <Grid item xs={6} className="padding0">
            <div className= "savenext">
              {isSubmitting?<span>Please wait</span>:
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => handleSave()}
                  disable={isSubmitting}
                  className={classes.button}
                >
                  Done
                </Button>}
             </div> 
          </Grid>
        </Grid>
      );
    
    default:
      return <div></div>;
  }
};

import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Hourglass from '../assets/Hourglass-512.png';
function RegisterRejected() {


    return (
        <>
            <div className={' profile-content'}>
                <Header link='true' />
                <Grid container className="main-content">
                    <div className="success-page">
                        <h2 style={{ width: 32, margin: '0 auto' }} ><img src={Hourglass} alt="Hourglass" style={{ width: 32, margin: '0 auto' }} /></h2>
                        <Grid item xs={12} className="padding0 " >
                            <h4 className="success-h4">Please wait!</h4>
                            <p className="success-p">Currently there are null matches for vacancies for you to recruit.</p>
                            <p className="success-p">  We will notify through email if any new vacancies for you.</p>
                            <p className="success-p">Looking forward to work with you in the future!</p>
                        </Grid>
                    </div>
                </Grid>
            </div>
            <Footer />
        </>
    );
}



export default RegisterRejected

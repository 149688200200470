import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import * as moment from 'moment'
import './filter.css'
const useStyles = makeStyles({
 
});

export default function DateFilter(props) {
    const {column, row, filters, setFilters}= props
    const id=column.id;
    const classes = useStyles();
    const [value, setValue] = React.useState(filters[id]);
    
    
    const handleDateChange = (date,pos) => {
        let dateData= value
        if(pos===1)
            dateData[0]=date
        if(pos===2)
            dateData[1]=date
        
        setValue([...dateData])
        setFilters({...filters, [id]: dateData})
    };


    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}  style={{ width: "100px"}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        id={`{id}To`}
                        placeholder="From"
                        format="dd-MM-yyyy"
                        clearable
                        className="fromDate"
                        value={value[0]}
                        onChange={date => handleDateChange(date,1)}
                        InputProps={{
                            style:{color: "white"}
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            style:{color: "white"}
                        }}
                    />
                    <br />
                    <KeyboardDatePicker
                        id={id}
                        placeholder="To"
                        format="dd-MM-yyyy"
                        style={{margin:"0 0 10px 0"}}
                        clearable
                        className="toDate"
                        value={value[1]}
                        minDate={value[0]}
                        onChange={date => handleDateChange(date, 2)}
                        InputProps={{
                            style:{color: "white"}
                        }}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        style:{color: "white"}
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
  );
}

import React, { useState, useEffect, useMemo } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import styled from 'styled-components'
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../utils/react-table/pagination'
import EditableDropdown from '../Job/InterviewDashboard/JobTabs/functions/EditableDropdown'
import SliderFilter from '../Job/InterviewDashboard/JobTabs/Filters/SliderFilter'
import SelectFilter from '../Job/InterviewDashboard/JobTabs/Filters/SelectFilter'
import InputTextFilter from '../Job/InterviewDashboard/JobTabs/Filters/inputTextFilter'
import DateFilter from '../Job/InterviewDashboard/JobTabs/Filters/DateFilter'
import {Grid, Button,  Checkbox,  TableSortLabel} from '@material-ui/core';
import '../Job/InterviewDashboard/JobTabs/tables.css'
import urlLinks from '../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ColumnModal from '../../utils/react-table/columnModal'
import WorkerModal from '../Job/InterviewDashboard/JobTabs/functions/workerModal'
import ExternalWorkerModal from '../worker/externalWorker/externalWorkerModal'
import Filters from './filterModal'
import AssignToJobModal from  '../../utils/Modals/assignToJobModal'
import SubmitResponseModel from '../../utils/Modals/submitResponseModel'
import SendLinkModal from  '../../utils/Modals/messageTriggerModal'
import UploadDownloadCV from '../../utils/upload/CvuploadDownload'
import RemarkStatusModal from '../../utils/Modals/remarkStatusModal'
import EditableInterviewStatus from '../Job/InterviewDashboard/JobTabs/functions/EditableInterviewStatus'
import EditableTextInput from '../Job/InterviewDashboard/JobTabs/functions/EditableTextInput'
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:700px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #cfcfcf;
                border-right: 1px solid #cfcfcf;
                background-color: white;
                nth-last-child(2) {
                    border-right: 0;
                } 
                :last-child {
                    border-right: 0;
                }

                input {
                    font-size: 1rem;
                    padding: 0;
                    margin: 0;
                    border: 0;
                }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)

const ActiveApplications =(props) =>{
    const {jobValue, recruiterId, getVacancyStatus, jobInterviewType, disableQuestionnaireButton, quesLength, jobList} = props
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [oldJob, setOldJob] = useState()
    const [perPage, setPerPage] = useState(props.rows?props.rows:10);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(props.pageNo>=0?props.pageNo:1);
    const [openWorkerModal, setOpenWorkerModal] = useState(false)
    const [openExternalWorkerModal, setOpenExternalWorkerModal] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [openSendLinkModal, setOpenSendLinkModal] = useState(false)
    const [filterUrl, setFilterUrl] = useState('')
    const [sortUrl, setSortUrl] = useState('')
    const [userIdList, setUserIdList] = useState([])
    const [openRemarkModal, setOpenRemarkModal] = useState(false)
    const [statusRemarkData, setStatusRemarkData] = useState()
    const [workerDetails, setWorkerDetails] = useState({})
    const [openAssignJobModal, setOpenAssignJobModal] = useState(false)
    const [userId, setUserId] = useState()
    const [toggleAllColumn, setToggleAllColumn] = useState(true)
    const [openColumnModal, setOpenColumnModal] = useState(false)
    const [openSubmitResponseModel, setOpenSubmitResponseModel] = useState(false)
    const [getQuestion, setGetQuestion]= useState();
    const [rowData, setRowData]=useState();
    
    console.log({props})
    // const interviewAddress={
    //     lat: props.data.interviewLat ,
    //     lng: props.data.interviewLong ,
    //     url: props.data.interviewUrl ,
    // }

    
    const [filters, setFilters] = useState({
        intExt: '',
        workerId: '',
        applicationDate: [null, null],
        score: 0,
        callStatus:[""],
        approvalStatus:[""],
        applicationStage:[""],
        interviewStatus:'',
        preferredInterviewDate: [null, null],
        interviewSelectionDate:[null, null],
        interviewDate:[null, null],
        interviewFollowUp:[""],
        recruiterFeedback:[""],
        employerFeedback:[""],
        appliedBy:[""],
        finalFeedback:[""],
        selectionDate:[null, null],
        joiningDate:[null, null],
        offeredSalary:0,
        joiningStatus:[""],
        dateOfLeaving:[null, null],
        name: '',
        phoneNumber: '',
        remark_status:[],
        preferred_job_type_id:[],
        low_salary:0,
        preferred_location_id:[],
        skill_mismatch: null,
        communication_issue:null,
        call_status_date: [null, null],
        profile_shared_date: [null, null],
        recruiter_assigned_date: [null, null]
    })
    const handleWorkerDetail=(row) =>{
        console.log(row.row.original);
        if(row.row.original.intExt==="INTERNAL"){
            setWorkerDetails({
                userId:row.row.original.userId,
                workerId:row.row.original.workerId,
                jobDetailsId: row.row.original.jobDetailsId
            })
            setTimeout(() => {
                
                setOpenWorkerModal(true);
            }, 500);
        }
        else{
            setUserId(row.row.original.userId)
            setOpenExternalWorkerModal(true)
        }
    }



    const openSubmitResponse = (row) =>{
        var job_id = row.cell.row.original.jobId;
        var user_id = row.cell.row.original.userId;
        console.log(job_id,user_id);
        const url=`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${job_id}`
        Axios.get(url).then(response=>{
            console.log(response.data);
            setRowData(row.cell.row.original)
            setGetQuestion(response.data);
            
        })
        setOpenSubmitResponseModel(true);
        console.log("hello open submit called");
        console.log(row);
        
    }

    const openEditResponse = (row) =>{

        var job_id = row.cell.row.original.jobId;
        var user_id = row.cell.row.original.userId;
        console.log(job_id,user_id);
        const url=`${urlLinks.baseUrl}question_controller/getWorkerJobQuestion?job_id=${job_id}&user_id=${user_id}`
        Axios.get(url).then(response=>{
            console.log(response.data);
            setRowData(row.cell.row.original)
            setGetQuestion(response.data.response);
        })
        setOpenSubmitResponseModel(true);
        console.log("edit open submit called");
        console.log(row);
    }

    const checkButton=(id)=>{
        var status=false;
        const url=`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${id}`
        Axios.get(url).then(response=>{
            console.log(response.data);
            if(response.data.length>0){
                console.log("*****************************")
                
                 return false;
            }else{
                
                 return true;
            }
        })
        // console.log(status);
        // return status;
    }


    const handleStatusRemark =(row) =>{
        console.log({row})
        const data={
            remark_status: row.remark_status,
            low_salary: row.low_salary,
            preferred_job_type: row.preferred_job_type,
            preferred_job_type_id: row.preferred_job_type_id,
            preferred_location: row.preferred_location,
            preferred_location_id: row.preferred_location_id,
            other: row.other,
            skill_mismatch: row.skill_mismatch,
            communication_issue: row.communication_issue,
        }
        setStatusRemarkData(data);
        setOpenRemarkModal(true)
    }
    const handleSendLink = (row) =>{
        setWorkerDetails(row)
        setOpenSendLinkModal(true)
    }
    const columns = useMemo(
        () => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} disabled />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.joiningStatus==="JOINED"} />
            </div>
          ),
        },
        {
            Header: "Int/Ext",
            accessor: "intExt",
            defaultCanFilter :false,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Worker Id",
            accessor: "workerIdLabel",
            defaultCanFilter :false,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: row => {
                return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
            defaultCanFilter :true,
            Filter: InputTextFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Assigned to",
            accessor: "recruiterName",
            Cell : row =>{
                return <div><div><span>{row.row.original.recruiterName}</span></div><div><span>{row.row.original.recruiterAssignedDate}</span></div></div>
            },
            defaultCanFilter :false,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "CV available",
            accessor: "cvAvailable",
            Cell: UploadDownloadCV,
            defaultCanFilter :false,      //needed filter 
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Ph. no.",
            accessor: "phoneNumber",
            defaultCanFilter :true,
            Filter: InputTextFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Score",
            accessor: "score",
            defaultCanFilter :true,
            Filter: SliderFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Application date",
            accessor: "applicationDate",
            defaultCanFilter :true,  //needed filter
            Filter: DateFilter,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Call status",
            accessor: "callStatus",
            Cell: EditableDropdown,
            defaultCanFilter :true,   //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header:"Screening Questions",
            accessor:"submitResponse",
            Cell: row => { console.log({disableQuestionnaireButton})
                return row.row.original.submitResponse===1? <Button variant="outlined" color="primary" onClick={() =>openEditResponse(row)}>
                CHECK / EDIT RESPONSE
                </Button>:<Button variant="contained" color="primary" onClick={() =>openSubmitResponse(row)} disabled={disableQuestionnaireButton} >
                   SUBMIT RESPONSE
                </Button>},
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Question Score",
            accessor: "quesScore",
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Approval status",
            accessor: "approvalStatus",
            Cell: EditableDropdown,
            defaultCanFilter :true,     //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Application stage",
            accessor: "applicationStage",
            defaultCanFilter :true,      //needed filter
            Filter: SelectFilter, 
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Applied by",
            accessor: "appliedByName",
            defaultCanFilter :true,
            Filter: SelectFilter,      //needed filter 
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Interview Mode",
            accessor: "interviewMode",
            Cell: EditableDropdown,
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Interview status",
            accessor: "interviewStatus",
            Cell: EditableInterviewStatus,
            defaultCanFilter :false,
            Filter: InputTextFilter ,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Preferred interview date",
            accessor: "preferredInterviewDate",
            defaultCanFilter :true,
            Filter: DateFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Interview selection date",
            accessor: "interviewSelectionDate",
            defaultCanFilter :true,             //needed filter
            Filter: DateFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Interview date",
            accessor: "interviewDate",
            defaultCanFilter :true,    
            Filter: DateFilter ,           //add datefilter
            defaultCanSort:false,
            isVisible: true
        },
        {
        Header: "OTP",
            accessor: "otp",
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: jobInterviewType!==2
        },
        {
            Header: "Interview follow up",
            accessor: "interviewFollowUp",
            Cell: EditableDropdown,
            defaultCanFilter :true,             //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Rectruiter feedback",
            accessor: "recruiterFeedback",
            Cell: EditableDropdown,
            defaultCanFilter :true,             //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Employer feedback",
            accessor: "employerFeedback",
            Cell: EditableDropdown,
            defaultCanFilter :true,             //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Final feedback",
            accessor: "finalFeedback",
            Cell: EditableDropdown,
            defaultCanFilter :true,             //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Selection date",
            accessor: "selectionDate",             //needed filter
            defaultCanFilter :true,
            Filter: DateFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Joining date",
            accessor: "joiningDate",
            Cell: EditableTextInput,
            defaultCanFilter :true,             //add datefilter
            Filter: DateFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Offered salary",
            accessor: "offeredSalary",
            Cell: EditableTextInput,
            defaultCanFilter :true,
            Filter: SliderFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Worker Response",
            accessor: "isOfferAccepted",
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Joining status",
            accessor: "joiningStatus",
            Cell: EditableDropdown, 
            defaultCanFilter :true,             //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Date of leaving",
            accessor: "dateOfLeaving",             //needed filter
            defaultCanFilter :true,
            Filter: DateFilter ,
            defaultCanSort:true,
            isVisible: true
        },
        {
            Header: "Remarks",
            accessor: "remarks",
            Cell: EditableTextInput,
            defaultCanFilter :false,
            Filter: InputTextFilter ,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Status remark",
            accessor: "statusRemark",
            Cell: row => { 
                return <Button className="linkButton" onClick={() =>handleStatusRemark(row.row.original)}>
                    show details
                </Button>},
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Link triggers",
            accessor: "whatsApp_trigger",
            Cell: row => { 
                return <Button className="linkButton" onClick={()=>handleSendLink(row.row.original)} disabled={row.row.original.appliedBy && !row.row.original.interviewId}>
                    Send link
                </Button>},
            defaultCanFilter :false,
            Filter: InputTextFilter ,
            defaultCanSort:false,
            isVisible: true
        }
        ], 
        []
    );
    const GoodupdateMyData = (rowIndex, columnId, value) => {
        setData(old =>
        old.map((row, ) => {
            if (row.workerId === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                }
            }
            return row
        })
        )
    }
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    
    const updateMyData = () => {
        getActiveApplicationList(perPage, currentPage);
    }
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: {hiddenColumns, selectedRowIds, sortBy}} = useTable({columns, data, updateMyData, showToast, setLoading, filters, setFilters,  manualSortBy: true,  }, useFilters, useSortBy,  useRowSelect)
    
    useEffect(() => {
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
    }, [setHiddenColumns, columns]);
    const ApplyFilter =() =>{

        let url='';
        if(filters["name"])
            url=`${url}&name=${filters["name"]}`
        if(filters["phoneNumber"])
            url=`${url}&number=${filters["phoneNumber"]}`
        if(filters["score"])
            url=`${url}&actualScore=${filters["score"]}`
        if(!filters["callStatus"].includes(""))
            url=`${url}&callStatus=${filters["callStatus"]}`
        if(!filters["approvalStatus"].includes(""))
            url=`${url}&approvalStatus=${filters["approvalStatus"]}`
        if(filters["applicationStage"]!==""){
            console.log("value of filter",filters["applicationStage"])
            url=`${url}${filters["applicationStage"]}`
        }
        if(!filters["appliedBy"].includes(""))
            url=`${url}&appliedBy=${filters["appliedBy"]}`
        if(!filters["interviewFollowUp"].includes(""))
            url=`${url}&status=${filters["interviewFollowUp"]}`
        if(!filters["recruiterFeedback"].includes(""))
            url=`${url}&reFeedback=${filters["recruiterFeedback"]}`
        if(!filters["employerFeedback"].includes(""))
            url=`${url}&empFeedback=${filters["employerFeedback"]}`
        if(!filters["finalFeedback"].includes(""))
            url=`${url}&finalStatus=${filters["finalFeedback"]}`
        if(filters["remark_status"].length!==0){
            let statusList = []
            filters["remark_status"].map(status => statusList.push(status.nameId))
            if(statusList.includes("skill_mismatch"))
                url=`${url}&skill_mismatch=1`
            if(statusList.includes("communication_issue"))
                url=`${url}&communication_issue=1`
            if(statusList.includes("low_salary") && filters["low_salary"])
                url=`${url}&low_salary=${filters.low_salary}`
            if(statusList.includes("preferred_job_location_id") && filters["preferred_job_location_id"].length!==0){
                let cityIds = []
                filters["preferred_job_location_id"].map(city => cityIds.push(city.id))
                url=`${url}&preferred_job_location_id=${cityIds}`
            }
            if(statusList.includes("preferred_job_type_id") && filters["preferred_job_type_id"].length!==0){
                let jobIds = []
                filters["preferred_job_type_id"].map(job => jobIds.push(job.id))
                url=`${url}&preferred_job_type_id=${jobIds}`
            }
        }
        if(filters["offeredSalary"])
            url=`${url}&salary=${filters["offeredSalary"]}`
        if(!filters["joiningStatus"].includes(""))
            url=`${url}&joining=${filters["joiningStatus"]}`
        if(filters["applicationDate"][0] && !filters["applicationDate"][1])
            url=`${url}&applied_from_date=${moment(filters["applicationDate"][0]).format("YYYY-MM-DD")}&applied_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["applicationDate"][0] && filters["applicationDate"][1])
            url=`${url}&applied_from_date=2019-01-01&applied_to_date=${moment(filters["applicationDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["applicationDate"][0] && filters["applicationDate"][1])
            url=`${url}&applied_from_date=${moment(filters["applicationDate"][0]).format("YYYY-MM-DD")}&applied_to_date=${moment(filters["applicationDate"][1]).format("YYYY-MM-DD")} 23:59:59`

        if(filters["preferredInterviewDate"][0] && !filters["preferredInterviewDate"][1])
            url=`${url}&pref_from_date=${moment(filters["preferredInterviewDate"][0]).format("YYYY-MM-DD")}&pref_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["preferredInterviewDate"][0] && filters["preferredInterviewDate"][1])
            url=`${url}&pref_from_date=2019-01-01&pref_to_date=${moment(filters["preferredInterviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["preferredInterviewDate"][0] && filters["preferredInterviewDate"][1])
            url=`${url}&pref_from_date=${moment(filters["preferredInterviewDate"][0]).format("YYYY-MM-DD")}&pref_to_date=${moment(filters["preferredInterviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["interviewSelectionDate"][0] && !filters["interviewSelectionDate"][1])
            url=`${url}&slot_selection_from_date=${moment(filters["interviewSelectionDate"][0]).format("YYYY-MM-DD")}&slot_selection_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["interviewSelectionDate"][0] && filters["interviewSelectionDate"][1])
            url=`${url}&slot_selection_from_date=2019-01-01&slot_selection_to_date=${moment(filters["interviewSelectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["interviewSelectionDate"][0] && filters["interviewSelectionDate"][1])
            url=`${url}&slot_selection_from_date=${moment(filters["interviewSelectionDate"][0]).format("YYYY-MM-DD")}&slot_selection_to_date=${moment(filters["interviewSelectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["interviewDate"][0] && !filters["interviewDate"][1])
            url=`${url}&interviews_from_date=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["interviewDate"][0] && filters["interviewDate"][1])
            url=`${url}&interviews_from_date=2019-01-01&interviews_to_date=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["interviewDate"][0] && filters["interviewDate"][1])
            url=`${url}&interviews_from_date=${moment(filters["interviewDate"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(filters["interviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["selectionDate"][0] && !filters["selectionDate"][1])
            url=`${url}&selection_from_date=${moment(filters["selectionDate"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["selectionDate"][0] && filters["selectionDate"][1])
            url=`${url}&selection_from_date=2019-01-01&selection_to_date=${moment(filters["selectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["selectionDate"][0] && filters["selectionDate"][1])
            url=`${url}&selection_from_date=${moment(filters["selectionDate"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(filters["selectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["joiningDate"][0] && !filters["joiningDate"][1])
            url=`${url}&joining_from_date=${moment(filters["joiningDate"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["joiningDate"][0] && filters["joiningDate"][1])
            url=`${url}&joining_from_date=2019-01-01&joining_to_date=${moment(filters["joiningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["joiningDate"][0] && filters["joiningDate"][1])
            url=`${url}&joining_from_date=${moment(filters["joiningDate"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(filters["joiningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["dateOfLeaving"][0] && !filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=${moment(filters["dateOfLeaving"][0]).format("YYYY-MM-DD")}&leaving_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["dateOfLeaving"][0] && filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=2019-01-01&leaving_to_date=${moment(filters["dateOfLeaving"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["dateOfLeaving"][0] && filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=${moment(filters["dateOfLeaving"][0]).format("YYYY-MM-DD")}&leaving_to_date=${moment(filters["dateOfLeaving"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["call_status_date"][0] && !filters["call_status_date"][1])
            url=`${url}&call_status_from_date=${moment(filters["call_status_date"][0]).format("YYYY-MM-DD")}&call_status_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["call_status_date"][0] && filters["call_status_date"][1])
            url=`${url}&call_status_from_date=2019-01-01&call_status_to_date=${moment(filters["call_status_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["call_status_date"][0] && filters["call_status_date"][1])
            url=`${url}&call_status_from_date=${moment(filters["call_status_date"][0]).format("YYYY-MM-DD")}&call_status_to_date=${moment(filters["call_status_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if(filters["profile_shared_date"][0] && !filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=${moment(filters["profile_shared_date"][0]).format("YYYY-MM-DD")}&profile_shared_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["profile_shared_date"][0] && filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=2019-01-01&profile_shared_to_date=${moment(filters["applicationDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["profile_shared_date"][0] && filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=${moment(filters["profile_shared_date"][0]).format("YYYY-MM-DD")}&profile_shared_to_date=${moment(filters["profile_shared_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if(filters["recruiter_assigned_date"][0] && !filters["recruiter_assigned_date"][1])
            url=`${url}&recruiter_assigned_from_date=${moment(filters["recruiter_assigned_date"][0]).format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["recruiter_assigned_date"][0] && filters["applicationDate"][1])
            url=`${url}&recruiter_assigned_from_date=2019-01-01&recruiter_assigned_to_date=${moment(filters["recruiter_assigned_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["recruiter_assigned_date"][0] && filters["recruiter_assigned_date"][1])
            url=`${url}&recruiter_assigned_from_date=${moment(filters["recruiter_assigned_date"][0]).format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment(filters["recruiter_assigned_date"][1]).format("YYYY-MM-DD")} 23:59:59`
 
        setFilterUrl(url)
    }
    
    
    useEffect(() => {
        ApplyFilter();
    }, [filters])

    const resetFilters=() =>{
        const filtersTemp={
            intExt: '',
            workerId: '',
            applicationDate: [null, null],
            score: 0,
            callStatus:[""],
            approvalStatus:[""],
            applicationStage:[""],
            interviewStatus:'',
            preferredInterviewDate: [null, null],
            interviewSelectionDate:[null, null],
            interviewDate:[null, null],
            interviewFollowUp:[""],
            recruiterFeedback:[""],
            employerFeedback:[""],
            appliedBy:[""],
            finalFeedback:[""],
            selectionDate:[null, null],
            joiningDate:[null, null],
            offeredSalary:0,
            joiningStatus:[""],
            dateOfLeaving:[null, null],
            score: 0,
            name: '',
            phoneNumber: '',
            remark_status:[],
            preferred_job_type_id:[],
            low_salary:0,
            preferred_location_id:[],
            skill_mismatch: null,
            communication_issue:null,
            call_status_date: [null, null],
            profile_shared_date: [null, null],
            recruiter_assigned_date: [null, null]
        }
        setFilters(filtersTemp)
        return filtersTemp;
    }
    const ApplySort = () =>{
        let url=''
        if(sortBy.length>0){
            switch (sortBy[0].id) {
                case "intExt":
                    url='&orderBy=worker_master.int_ext'
                    break;
                case "workerId":
                    url='&orderBy=worker_master.worker_id'
                    break;
                case "name":
                    url='&orderBy=user_master.first_name'
                    break;
                case "phoneNumber":
                    url='&orderBy=user_master.phone_number'
                    break;
                case "score":
                    url='&orderBy=job_assign_request.actual_score'
                    break;
                case "applicationDate":
                    url='&orderBy= job_assign_request.applied_on'
                    break;
                case "callStatus":
                    url='&orderBy=job_assign_request.call_status'
                    break;
                case "approvalStatus":
                    url='&orderBy= job_assign_request.approval_status'
                    break;
                case "applicationStage":
                    url='&orderBy=event_status'
                    break;
                case "preferredInterviewDate":
                    url='&orderBy=job_assign_request.preferred_interview_date'
                    break;
                case "interviewSelectionDate":
                    url='&orderBy=interviews.accepted_on'
                    break;
                case "interviewDate":
                    url='&orderBy=c.company_name'
                    break;
                case "interviewFollowUp":
                    url='&orderBy=interviews.status'
                    break;
                case "recruiterFeedback":
                    url='&orderBy=interviews.recruiter_feedback	'
                    break;
                case "employerFeedback":
                    url='&orderBy=interviews.employer_feedback'
                    break;
                case "finalFeedback":
                    url='&orderBy=interviews.feedback_status'
                    break;
                case "selectionDate":
                    url='&orderBy=interviews.offered_date'
                    break;
                case "joiningDate":
                    url='&orderBy=interviews.joining_date'
                    break;
                case "offeredSalary":
                    url='&orderBy=interviews.job_offered_amount'
                    break;
                case "joiningStatus":
                    url='&orderBy=interviews.joining_status'
                    break;
                case "dateOfLeaving":
                    url='&orderBy=interviews.date_of_leaving'
                    break;
                default:
                    url=''
                    break;
            }
            if(!sortBy[0].desc)
                url=`${url}&sortBy=ascending`
            setSortUrl(url)
        }
        else
            setSortUrl('')
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy])
    const setInterviewDate = (el) =>{
        
        if(el.interview_mode===3){

            if(el.whichSlot===0)    
                return "";
            else{
                if(el.whichSlot===1){
                    if(el.interviewStartTime)
                        return `${moment(el.interviewStartTime).format("DD/MM/YYYY")} ${moment(el.interviewStartTime).format("hh:mm a")} - ${moment(el.interviewEndTime).format("hh:mm a")}`;
                    else 
                        return '';
                } 
                if(el.whichSlot===2)    
                return `${moment(el.interviewStartTime2).format("DD/MM/YYYY")} ${moment(el.interviewStartTime2).format("hh:mm a")} - ${moment(el.interviewEndTime2).format("hh:mm a")}`;
                if(el.whichSlot===3)    
                return `${moment(el.interviewStartTime3).format("DD/MM/YYYY")} ${moment(el.interviewStartTime3).format("hh:mm a")} - ${moment(el.interviewEndTime3).format("hh:mm a")}`;
            }
        }
        else if(el.interview_mode===2){
            if(el.from_date)
                return `${moment(el.from_date).format("DD/MM/YYYY")} - ${moment(el.to_date).format("DD/MM/YYYY")}`
            else 
                return ''
        }
    }
    const applicationStage = (name, status) =>{
        switch (status) {
            case "INTERESTED":
                return "Candidate interested";
                break;
            case "NOT_INTERESTED":
                return "Candidate not interested";
                break;
            case "APPROVED":
                return "Approved";
                break;
            case "APPLIED":
                return "Applied";
                break;
            case "NOT_APPROVED":
                return "Not approved";
                break;
            case "SHORTLISTED":
                return "Shortlisted";
                break;
            case "REJECTED":
                if(name==="APPLIED JOB")
                    return "Candidate rejected";
                else if(name==="INTERVIEW STATUS")
                    return "Rejected";
                else if(name==="RECRUITER_FEEDBACK")
                    return "Rejected";
                else if(name==="EMPLOYER_FEEDBACK")
                    return "Rejected";
                else if(name==="OFFER JOB")
                    return "Offer rejected";
                break;
            case "NO SHOW":
            case "NOT_GOING":
            case "NO_RESPONSE":
            case "NOT_DROPPED":
            case "CANDIDATE_DROPPED":
                return "Candidate dropped";
                break;
            case "GOING":
            case "SLOTS CREATED":
            case "SLOT SELECTED":
            case "SLOT UPDATED":
            case "RESCHEDULE":
                return "Interviewing";
                break;
            case "APPEARED":
            case "ROUNDS_PENDING":
            case "RESULT_AWAITED":
                return "Results awaited";
                break;
            case "SELECTED":
                return "Selected";
                break;
            case "ACCEPTED":
                return "Offer accepted";
                break;
            case "UPDATE JOINING DATE":
                return "Updated joining date";
                break;
            case "JOINED":
                return "Joined";
                break;
            case "NOT_APPEARED":
                return "Not appeared";
                break;
            case "LEFT_AFTER_JOINING":
                return "Left after joining";
                break;
            case "OFFERED":
                return "Job offered";
                break;
            case "POSTPONED":
                return "Postponed";
                break;
            case "NOT_JOINING":
                return "Candidate not joining";
                break;
            default:
                return "null"
                break;
        }
    }
    const getUserFromId = (id) =>{
        const users= JSON.parse(localStorage.getItem('allAdmins'));
        
        const name=users[id];
        if(name){
            return name;
        }
        else return "Self";
    }
    const makeData= (response) =>{
        console.log(response.content)
        
        let extractedData = [];
        // let index= response.pageable.offset+1;
        response.content.map(el => {
            console.log({el})
            extractedData.push({
                intExt:el.int_ext?el.int_ext:'',
                assignId: el.assign_id?el.assign_id:'',
                jobInterviewType: jobInterviewType,
                workerIdLabel: el.worker_id?el.int_ext==="INTERNAL"?el.worker_id:`E-${el.worker_id}`:'',
                workerId: el.worker_id?el.worker_id:'',
                recruiterId: el.recruiter_id,
                recruiterName: el.recruiter_name,
                jobDetailsId: jobValue.jobDetailId,
                cvLink: el.cv_link,
                jobId: jobValue.jobId,
                quesScore: `${el.question_score?el.question_score:0} / ${quesLength}`,
                appliedBy: el.appliedBy,
                appliedByName: el.appliedByName,
                cvAvailable: el.cv_link?"Yes":"No",
                userId: el.user_id?el.user_id:'',
                email: el.emailId?el.emailId:'',
                name: el.first_name === null ? '-' : `${el.first_name} ${el.last_name?el.last_name:''}`,
                submitResponse:el.question_submitted,
                phoneNumber: el.phone_number?el.phone_number:'',
                score: el.actual_score?el.actual_score:'',
                applicationDate:  el.appliedOn?moment(el.appliedOn).format("DD/MM/YYYY"):'',
                callStatus:  el.call_status?el.call_status:'',
                approvalStatus:  el.approval_status?el.approval_status:'',
                interviewMode: el.interview_mode,
                startDate: el.from_date?el.from_date:null,
                endDate: el.to_date?el.to_date:null,
                applicationStage:  el.event_status==="NO_RESPONSE"? applicationStage(el.lastEvent, el.lastEvent_status) :applicationStage(el.event, el.event_status),
                interviewStatus:  el.interviewStatus?el.interviewStatus:'',
                interviewSelectionDate: el.accepted_on?moment(el.accepted_on).format("DD/MM/YYYY"):'',
                preferredInterviewDate:  el.preferred_interview_date?moment(el.preferred_interview_date).format("DD/MM/YYYY"):null, 
                interviewDate:  setInterviewDate(el),
                interviewStartTime: el.interviewStartTime?el.interviewStartTime:null,
                interviewStartTime2: el.interviewStartTime2?el.interviewStartTime2:null,
                interviewStartTime3: el.interviewStartTime3?el.interviewStartTime3:null,
                interviewEndTime: el.interviewEndTime?el.interviewEndTime:null,
                interviewEndTime2: el.interviewEndTime2?el.interviewEndTime2:null,
                interviewEndTime3: el.interviewEndTime3?el.interviewEndTime3:null,
                interviewType: el.interview_type===1?"Face to face":el.interview_type===2?"Telephonic":el.interview_type===3?"Profile shared":'',
                otp: el.otp?el.otp:'',
                interviewFollowUp:  el.status?el.status:'',
                isOfferAccepted:    el.is_offer_accepted===1?"Offer accepted":el.is_offer_accepted===2?"Offer rejected":'',
                recruiterFeedback:  el.recruiter_feedback?el.recruiter_feedback:'',
                employerFeedback:  el.employer_feedback?el.employer_feedback:'',
                finalFeedback:  el.feedbackStatus?el.feedbackStatus:'', 
                selectionDate:  el.offeredDate?moment(el.offeredDate).format("DD/MM/YYYY"):'', 
                joiningDate:  el.joining_date?moment(el.joining_date).format("DD/MM/YYYY"):'',
                offeredSalary:  el.job_offered_amount?el.job_offered_amount:'', 
                joiningStatus:  el.joiningStatus?el.joiningStatus:'',
                dateOfLeaving:  el.date_of_leaving?moment(el.date_of_leaving).format("DD/MM/YYYY"):'',
                remarks:  el.remark?el.remark:'',
                interviewId: el.interviewId?el.interviewId:'',
                whichSlot: el.whichSlot?el.whichSlot:0,
                interviewSlots:1,
                remark_status: el.remark_status==="NOT_INTERESTED"?"Not interested" :el.remark_status==="NOT_APPROVED"?"Not approved":"None",
                low_salary: el.low_salary,
                preferred_job_type: el.preferred_job_type,
                preferred_job_type_id: el.preferred_job_type_id,
                preferred_location: el.preferred_location,
                preferred_location_id: el.preferred_location_id,
                callStatusDate: el.callStatusDate? moment(el.callStatusDate).format("DD/MM/YYYY"): '',
                profileSharedDate: el.profileSharedDate?moment(el.profileSharedDate).format("DD/MM/YYYY"): '',
                recruiterAssignedDate: el.recruiterAssignedDate?moment(el.recruiterAssignedDate).format("DD/MM/YYYY"):'',
                other: el.other,
                skill_mismatch: el.skill_mismatch?"Yes":"No",
                communication_issue: el.communication_issue?"Yes":"No",
            });
            console.log({extractedData});
        });
        return extractedData;
    }
    const getActiveApplicationList = (rows, page) =>{
        const url=`${urlLinks.baseUrl}extWorker/getAssignedWorkerRecruiter?job_details_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recuiter_id=${recruiterId}&page_no=${page-1}&rows=${rows}${filterUrl}${sortUrl}`
        Axios.get(url)
            .then(response =>{
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    console.log("Hello setData ended");
                    
                    setLoading(false)
                    console.log("Hello setLoading ended");
                }
                else{
                    setLoading(false)

                    throw toast.error("No data inside from API")
                }
            })
            .catch(error =>{
                setLoading(false)
                throw toast.error("No data from API")
            })
    }

    

    useEffect(() => {
        setLoading(true)
        // if(props.data.jobId && props.data.jobDetailsId)
            getVacancyStatus();
            if(props.rows && props.pageNo>=0)
                getActiveApplicationList(props.rows, props.pageNo);
            else 
                getActiveApplicationList(perPage, 1);
    }, [filterUrl, sortUrl, jobValue])
    const handleExternalFilters = (list) =>{
        setFilters({...filters, ...list})
    }
    const generateInvoice = () => {
        let rowIds=Object.keys(selectedRowIds)
        let ids=rowIds.map(id => { return rows[id].original.interviewId})
        
        if (ids.length > 0) {
        Axios.get(
            `${urlLinks.baseUrl }/transactions/getInvoiceIdByInterviewId?interviewIds=${ids.join('~')}&user_id=${props.data.userId}`
        )
            .then(response => {
                toast.success(`Invoices Generated Successfully For : ${ids.join(',')}`)
                this.getHiredWorkerList()
            })
            .catch(error => toast.error(error));
        } else {
        toast.error(`Select Workers To Generate Invoice`);
        }
    };

    const handleRequestData =() =>{
        Axios.post(`${urlLinks.baseUrl}extWorker/getCandidateData?job_detail_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recruiter_id=${recruiterId}`)
            .then(response =>{
                if(response.data.code===1000)
                    showToast("success", "Data requested Successfully")
                else
                    showToast("error", "Data request failed")
            })
            .catch(error =>{
                showToast("error", "Data request failed")
            })
    }
    const handleAssignCandidate =() =>{
        
    }
    const handleColumnsModalClose =() =>{
        setOpenColumnModal(false);
    }
    const HandleColumns =() =>{
        setOpenColumnModal(true)
    }
    const idToUserId =() =>{
        console.log({selectedRowIds});
        const indexes= Object.keys(selectedRowIds)
        let idList=[]
        indexes.map(id => {
            idList.push(data[id].userId)
        })
        
        console.log(idList.toString())
        return idList;
    }
    useEffect(() => {
        let userIdList= []
        if(openAssignJobModal )
            setUserIdList(idToUserId())
    }, [openAssignJobModal])
    
    const handlePageChange = page => {
        setLoading(true)
        getActiveApplicationList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getActiveApplicationList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const loader=<div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
    
    const table=<div className="tableContainer">
                    <table {...getTableProps()}  className="table">
                        <thead className="thead">
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (  
                                <th {...column.getHeaderProps()} 
                                    className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                        column.render('Header')==="Name"?"stickWorkerNameMatchNew zIndex1080 header":
                                            column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                    
                                    <span {...column.getSortByToggleProps()}>
                                        {column.id !== 'selection' ? 
                                        <div>
                                            {column.render('Header')}
                                            <TableSortLabel
                                                style={{color:"white"}}
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'asc' : 'desc'}
                                            />
                                        </div> : column.render('Header')}
                                    </span>
                                    <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        {
                            loading?
                                loader:
                                <tbody {...getTableBodyProps()}> 
                                    {rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="row">
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}  
                                                    className={cell.column.Header==="Worker Id"?"stickWorkerIdnewMatch  zIndex540":
                                                    cell.column.Header==="Name"?"stickWorkerNameMatchNew zIndex540":
                                                        cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"}>{cell.render('Cell')}</td>
                                            })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>


    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>
            {openWorkerModal?<WorkerModal
                data={workerDetails}
                openWorkerModal={openWorkerModal}
                setOpenWorkerModal={setOpenWorkerModal}
                isWorkerHistory={true}
                recruiterDashBoardData={{jobValue: jobValue, recruiterId: recruiterId, perPage: perPage, pageNo: currentPage}}
            />: null}
            {openExternalWorkerModal?<ExternalWorkerModal
                userId= {userId}
                openExternalWorkerModal={openExternalWorkerModal}
                setOpenExternalWorkerModal={setOpenExternalWorkerModal}
                recruiterDashBoardData={{jobValue: jobValue, recruiterId: recruiterId, perPage: perPage, pageNo: currentPage}}
            />: null}
            {openRemarkModal?<RemarkStatusModal
                openRemarkModal= {openRemarkModal}
                setOpenRemarkModal= {setOpenRemarkModal}
                values={statusRemarkData}
                />: null
            }

            <Grid container >
                <Grid item xs={12} className="buttonList paddingHorizontal" >
                    <Grid item xs={10} style={{padding:0}}>
                        <Button
                            variant="contained"
                            className="dataButton"
                            // disabled={Object.keys(selectedRowIds).length===0}
                            disabled={true}
                            onClick={() => setOpenAssignJobModal(true)}
                        >
                            Assign Candidate
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled
                            onClick={() => console.log("Add candidate")}
                        >
                            Add candidate
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                            onClick={handleRequestData}
                        >
                            get candidate data
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled
                            onClick={() => setOpenAssignJobModal(true)}
                        >
                            send job link
                        </Button>
                    </Grid>
                    <Grid item xs={2} style={{padding:0}} className="buttonList marginRightForChildExceptLast " direction="row-reverse">

                        <Button
                            variant="outlined"
                            className="filterButton marginRight20 marginLight20 paddingHorizontal"
                            onClick={HandleColumns}
                        >
                            Columns
                        </Button>
                        <Filters 
                            filters={filters}
                            updateFilters={handleExternalFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                        />
                    {/* <Select 
                            options={sortOptions}
                            value={sortValue}
                            setValue={setSortValue}
                            label="Sort By:"
                        />
                        {sortValue?<ToggleButton
                            selected={sortDirection}
                            onChange={handleSortDirection}
                        >
                            {sortDirection?"A-Z":'Z-A'}
                        </ToggleButton>: null}

                        <Filters 
                            filters={filters}
                            updateFilters={updateFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                        />*/}
                    </Grid>
                </Grid> 
                <Grid item xs={12}  style={{padding:0}}>
                    {table}
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>
            </Grid>
            {openColumnModal?<ColumnModal
                allColumns={allColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                openColumnModal={openColumnModal}
                hiddenColumns={hiddenColumns}
                setToggleAllColumn={setToggleAllColumn}
                handleColumnsModalClose={handleColumnsModalClose}
            />:null}
            {openAssignJobModal?<AssignToJobModal
                openAssignJobModal={openAssignJobModal}
                setOpenAssignJobModal={setOpenAssignJobModal}
                toDifferentJob={true}
                currentJob={jobValue}
                jobList={jobList}
                selectedRowIds={selectedRowIds}
                userIdList={userIdList}
                toggleAllRowsSelected= {toggleAllRowsSelected}
                showToast={showToast}
            />:null}
            {openSendLinkModal && <SendLinkModal
                    openSendLinkModal= {openSendLinkModal} 
                    setOpenSendLinkModal= {setOpenSendLinkModal}
                    showToast={showToast}
                    data={workerDetails}
                />
            }
            {openSubmitResponseModel && getQuestion && <SubmitResponseModel
                openJobModal={openSubmitResponseModel}
                setOpenJobModal={setOpenSubmitResponseModel}
                questionValues={getQuestion}
                data={rowData}
                updateMyData={updateMyData}
            />}
        </Styles>
    )
}
 export default ActiveApplications;
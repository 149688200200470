import React from 'react'
import styled from 'styled-components'
import { useTable, useBlockLayout } from 'react-table'


const Styles = styled.div`


  .table {
    display: inline-block;
    border-spacing: 0;
    font-size: 14px;
    
    .borderdouble{
      border-bottom-style: double;
      border-bottom-color: #747474;
    }
    .tableHeader{
      top: 23px;
      position: sticky;
      background-color: white;
    }
    .headingColor{
        color:#3f51b5;
        font-weight: 600;
    }
    .borderNone{
      border-bottom: unset !important;
    }
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 4px;
      border-bottom: 1px solid #747474;
      border-right: 1px solid #747474;
      overflow-wrap: break-word;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout
  )

  // Render the UI for your table
  return (
    <div {...getTableProps()} className="table">
      <div className="tableHeader">
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr borderdouble">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th borderNone headingColor">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      
      <div {...getTableBodyProps()} className="tablerows">
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )}
        )}
      </div>
    </div>
  )
}

function App(props) {
  const columns1 = React.useMemo(
    () => [
        {
            Header: 'Event',
            accessor: 'event',
        },
        {
            Header: 'Event status',
            accessor: 'eventStatus',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Triggered by',
            accessor: 'triggeredBy',
        }
    ],
    []
  )
  const columns2 = React.useMemo(
    () => [
        {
            Header: 'Job category',
            accessor: 'jobCategory',
        },
        {
            Header: 'Job role',
            accessor: 'jobRole',
        },
        {
            Header: 'Company',
            accessor: 'company',
        },
        {
            Header: 'Experience',
            accessor: 'experience',
        }
    ],
    []
  )

  return (
    <Styles>
      <Table columns={props.column==="history"?columns1:columns2} data={props.data} />
    </Styles>
  )
}

export default App

import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RegistrationImage from '../assets/Group 2971.png'
import UrlLinks from '../urlLinks'
import SingleVideo from './SingleVideo'
import { Grid } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
	}),
);

export default function Faq(props) {
    const [videoList, setVideoList] = useState([])
    
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const classes = useStyles();
    const createVideoList =(data) =>{
        let list=[];
        data.map(video =>{
            list.push(
                <SingleVideo id={video.url} title={video.title} />
            )
            return true;
        })
        return list;
    }
    console.log({videoList})
    useEffect(() => {
        Axios.get(`${UrlLinks.baseUrl}youtube/getRecruiterVideos`)
            .then(async (response) =>{
                await setVideoList(createVideoList(response.data.response))
            })
    }, [])
	
	return (
		<div >
            {videoList.length>0?videoList:null}
		</div>
	);
}


import Axios from 'axios';
const cors = 'https://cors-anywhere.herokuapp.com/';
const top = 29.5022587; // North lat
const left = 76.2152403; //West lng
const bottom = 27.7022587; //South lat
const right = 78.1152403; //east lng
const utilFunctions = {
  downLoadFile: (url, name) => {
    Axios({
      url:  url,
      method: 'GET',
      responseType: 'blob' // important
    })
      .then(response => {
        console.log({response});
        
        const fileType = response.data.type.split('/')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.' + fileType);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.log('Something went wrong');
      });
  },

  checkValidExpiry: async () => {
    // console.log(url);
    try {
      if(localStorage.getItem('tokenid')) {
        if (new Date().getTime() / 1000 > localStorage.getItem('expiry')) {
          console.log(`Runned`)
          await fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
            headers: {
              "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
              "Content-Type": "application/x-amz-json-1.1",
            },
            mode: 'cors',
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
              ClientId: "6smf3dhff7pt8ede6rm51insed",
              AuthFlow: 'REFRESH_TOKEN_AUTH',
              AuthParameters: {
                REFRESH_TOKEN: localStorage.getItem('refreshToken'),
                //SECRET_HASH: "your_secret", // In case you have configured client secret
              }
            }),
          }).then((res) => {
            res.json().then(data => {
              localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
              localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
              // console.log(data.AuthenticationResult.AccessToken)
              Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
              return true;
            }); // this will give jwt id and access tokens
          });
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getBase64String: async url => {
    // console.log(url);
    delete Axios.defaults.headers.common['Authorization'];
    delete Axios.defaults.headers.common["Access-Control-Allow-Origin"];
    try {
      // let file = null;
      const response = await Axios({
        url: url,
        method: 'GET',
        responseType: 'arraybuffer'
      });
      let binary = '';
      let bytes = new Uint8Array(response.data);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let stringData = window.btoa(binary);
      Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
      Axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
      return stringData;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  checkValidLatLng: (latitude, longitude) => {
    /* Check latitude bounds first. */
    if (top >= latitude && latitude >= bottom) {
      /* If your bounding box doesn't wrap 
         the date line the value
         must be between the bounds.
         If your bounding box does wrap the 
         date line it only needs to be  
         higher than the left bound or 
         lower than the right bound. */
      if (left <= right && left <= longitude && longitude <= right) {
        return true;
      } else if (left > right && (left <= longitude || longitude <= right)) {
        return true;
      }
    }
    return false;
  },
  roundTimeQuarterHour: (date) => {
    if(date) {
      date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    } else {
      return null;
    }
  }
};


export default utilFunctions;

import React, { useState} from 'react'
import { Grid, Button } from '@material-ui/core'
import '../worker/externalWorker/modals.css'
import RecruiterInstructionModal from '../../utils/Modals/RecruiterInstructionModal'
const VacancyRecruiterStatus =(props) =>{
    const {values, data} = props
    const [openInstructionModal, setOpenInstructionModal] = useState(false)
    console.log({data})
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    return(
        <Grid container className="background_color_eeeeee marginHorizontal20 padding20" >
            <Grid item xs={12} className="padding0">
                <span className="fontSize16 fontWeightBold underLineText">Vacancy details</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" >
                <Grid item xs={4} className="padding0">
                    <span className="fontSize16 fontWeightBold">Status: </span><span>Active</span>
                </Grid>
                <Grid item xs={4} className="padding0">
                    <span className="fontSize16 fontWeightBold">Recruitment manager: </span><span>{values.recruiter_manager}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" >
                <Grid item xs={4} className="padding0">
                    <span className="fontSize16 fontWeightBold">Data assigned: </span><span>{values.assigned_count}</span>
                </Grid>
                <Grid item xs={8} className="padding0">
                    <Grid item xs={12} className="padding0">
                        <Button     
                            className="linkButton"
                            onClick= {() =>setOpenInstructionModal(true)}
                        >
                            Recruiter's pitch
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span className="fontSize16 fontWeightBold">Payout amount: </span><span>{numberFormat(data.recruiterPayoutAmount)}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                    <span className="fontSize16 fontWeightBold">Payout details: </span><span  style={{whiteSpace: "pre-line"}}>{data.recruiterPayoutDetails}</span>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="paddingTop20Other0">
                <span className="fontSize16 fontWeightBold underLineText ">Data status</span>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" >
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Calls: </span><span>{values.call_count}</span>
                </Grid>
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Interviews scheduled: </span><span>{values.interview_scheduled_count}</span>
                </Grid>
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Interviews appeared: </span><span>{values.interview_appeared_count}</span>
                </Grid>
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Feedback pending: </span><span>{values.feedbackpending_count}</span>
                </Grid>
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Selection: </span><span>{values.selection_count}</span>
                </Grid>
                <Grid item xs className="padding0">
                    <span className="fontSize16 fontWeightBold">Joining: </span><span>{values.joined_count}</span>
                </Grid>
            </Grid>
            {openInstructionModal && <RecruiterInstructionModal
                openInstructionModal={openInstructionModal}
                setOpenInstructionModal= {setOpenInstructionModal}
                data={data}
                />
            }
        </Grid>
    )
}

export default  VacancyRecruiterStatus
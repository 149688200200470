import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
import DatePicker from '../DatePicker/DatePicker';
import TextField from '@material-ui/core/TextField';
import Select from '../simpleSelect/simpleSelect';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultValue: [{ city: [] }, { worker_status: [] }],
      open: this.props.isOpen,
      anchorEl: <b></b>,
      rating: '',
      experience: '',
      name:'',
      number:''
    };

    this.option = [
      {
        id: 1,
        rating: 1,
        label: '>1'
      },
      {
        id: 2,
        rating: 2,
        label: '>2'
      },
      {
        id: 3,
        rating: 3,
        label: '>3'
      },
      {
        id: 4,
        rating: 4,
        label: '>4'
      },
      {
        id: 5,
        rating: 5,
        label: '>5'
      }
    ];

    this.label = 'Rating';

    this.defaultValue = this.state.defaultValue;
    this.getFilterElemnts = this.getFilterElemnts.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  handleSelectChange = val => {
    this.setState({ rating: val });
  };

  handleExperienceChange = val => {
    this.setState({ experience: +val });
  };

  handleNameChange = val => {
    this.setState({ name: val },()=>console.log(this.state.name));
  };

  handleNumberChange = val => {
    this.setState({ number: val },()=>console.log(this.state.number));
  };

  handleInputChange(name, data) {
    for (let el of this.defaultValue) {
      if (el[name]) {
        el[name] = data;
      }
    }
    // console.log(this.defaultValue);
    this.setState({
      defaultValue: this.defaultValue
    });
  }

  handleRequestClose(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }

  async resetFilters() {
    // for (let el of this.defaultValue) {
    //   const key = Object.keys(el)[0];
    //   el[key] = [];
    // }
    this.setState(
      {
        defaultValue: [{ city: [] }, { worker_status: [] }],
        rating: '',
        experience: '',
        name:'',
        number:''
      },
      () =>
        this.props.updateFilters(
          this.state.defaultValue,
          this.state.rating,
          this.state.experience,
          this.state.name,
          this.state.number
        )
    );
  }

  getFilterElemnts(classes) {
    const filters = this.props.filters.map((element, index) => {
      return (
        <Grid item xs={6} key={element.id} className={classes.gridItemStyle}>
          <CustomMultiSelectDropDown
            default={this.state.defaultValue[index][element.name]}
            updatedDefault={element.default}
            data={element[element.name]}
            name={element.name}
            label={element.label}
            updateChange={this.handleInputChange}
          ></CustomMultiSelectDropDown>
        </Grid>
      );
    });
    return filters;
  }

  componentDidMount() {
    this.setState({
      defaultValue: [{ city: [] }, { worker_status: [] }],
      rating: '',
      experience: '',
      name:'',
      number:''
    });
  }

  render() {
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 2,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >
                {this.getFilterElemnts(this.props.classes)}

                <Grid item xs={6}>
                  <Select
                    ratingValue={this.state.rating}
                    option={this.option}
                    label={this.label}
                    handleChange={this.handleSelectChange}
                  ></Select>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Experience'
                    type='number'
                    value={this.state.experience}
                    onChange={event =>
                      this.handleExperienceChange(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Name'
                    value={this.state.name}
                    onChange={event =>
                      this.handleNameChange(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Contact No'
                    value={this.state.number}
                    onChange={event =>
                      this.handleNumberChange(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.resetFilters(true);
                      // this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      // this.resetFilters();
                      // console.log(this.defaultValue);
                      this.props.updateFilters(
                        this.state.defaultValue,
                        this.state.rating,
                        this.state.experience,
                        this.state.name,
                        this.state.number
                      );
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);

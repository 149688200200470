import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide,  Grid, TextField,Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '../SelectComponent/Select'
import urlLinks from '../../urlLinks';
import Axios from 'axios';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';
import locationIds from '../filters/locationIds'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const MessageTriggerModal =(props) =>{
    const {showToast, openSendLinkModal, setOpenSendLinkModal,  data} = props
    const classes = useStyles();
    const [triggerRadio, setTriggerRadio] = useState("email")
    const [emailId, setEmailId] = useState(data.email)
    const [templateText, setTemplateText] = useState("")
    const [template, setTemplate] = useState("")
    const [error, setError] = React.useState(data.email?false:true);
    console.log({data}) 
    const validateEmail =(email) =>{
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(email) === false) 
            return true;
        return false;
    }
    const handleEmail =(event) =>{
        console.log(validateEmail(event.target.value), error, "error")
        setError(validateEmail(event.target.value))
        setEmailId(event.target.value);
    }
    useEffect(() => {
        
        if(data.finalFeedback==='SELECTED' && data.offeredSalary){
            setTemplate("job_offer_details")
            setTemplateText("Job offer details")
        }
        else if(data.interviewId && data.whichSlot!==0 && data.interviewFollowUp!=='APPEARED'){
            setTemplate("interview_details")
            setTemplateText("Interview details")
        }
        else if(data.interviewId && data.whichSlot===0){
            setTemplate("select_interview_slot")
            setTemplateText("Interview slot details")
        }
        else if(data.interviewFollowUp!=='APPEARED'){
            setTemplate("download_app_and_apply_for_job")
            setTemplateText("Download app and apply for job")
        }
    }, [])
    const handleMessageTrigger=()=>{
        
        let workerId= data.workerId.toString().match(/\d+/g)
        if (data!=null && template) {
           if(triggerRadio==="whatsapp"){
                Axios.post(`${urlLinks.baseUrl}whatsapp/sendMessage?job_id=${data.jobId}&phone_number=${data.phoneNumber}&template_name=${template}&user_id=${data.userId}&worker_id=${workerId}&inserted_by=${localStorage.getItem("userId")}`)
                    .then( response => {
                        if(response.data.code===1000){
                            showToast("success",`WhatsApp Trigger Generated Successfully For : ${data.name}`)
                            setOpenSendLinkModal(false)
                        }else{
                            showToast("error",response.message);
                            setOpenSendLinkModal(false)
                        }
                        
                        // this.getHiredWorkerList()
                    })
                    .catch(error => {
                        showToast("error",error.message)
                        setOpenSendLinkModal(false)
                    });
            }
            else if(triggerRadio==="email"){
                Axios.post(`${urlLinks.baseUrl}/extWorker/sendEmail?job_id=${data.jobId}&email=${emailId}&template_name=${template}&user_id=${data.userId}&worker_id=${workerId}&inserted_by=${localStorage.getItem("userId")}`)
                    .then( response => {
                        if(response.data.code===1000){
                            showToast("success",`Email sent Successfully For : ${data.name}`)
                            setOpenSendLinkModal(false)
                        }else{
                            showToast("error",response.message);
                            setOpenSendLinkModal(false)
                        }
                        
                        // this.getHiredWorkerList()
                    })
                    .catch(error => {showToast("error",error.message)});
            }
        } 
        else {
            showToast("error",`You are not on right stage To Generate WhatsApp Trigger`);
        }
    }
    const handleRemarkModalClose =() =>{
        setOpenSendLinkModal(false)
    }
    
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openSendLinkModal}
            onClose={handleRemarkModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openSendLinkModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    <Grid container>
                        <span className="fontSize18 fontWeightBold">Send link</span>
                    </Grid>
                    
                    <Grid container>
                        <RadioGroup aria-label="gender" name="gender1" value={triggerRadio} onChange={(e) => setTriggerRadio(e.target.value)}>
                            <FormControlLabel value="email" control={<Radio />} label="Email" />
                            <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" disabled={data.interviewMode===2} />
                        </RadioGroup>
                    </Grid>
                    {templateText && <Grid container>
                        <span className="fontSize16"><b>Context: </b>{templateText}</span>
                    </Grid>}
                    <Grid container>
                        {triggerRadio==="email" && <Grid item xs={12} >
                                <TextField error={error}  label="Email id" value={emailId} onChange={handleEmail} /> 
                            </Grid>}
                        <Button color="primary" disabled={error && triggerRadio==="email"} onClick={handleMessageTrigger} >{triggerRadio==="email"?"Send email":"Send WhatsApp"}</Button>
                        <Button color="primary" variant ="outlined" onClick={handleRemarkModalClose} >Cancel</Button>
                    </Grid>
                </div>
            </Slide>
        </Modal>
    )
}

export default MessageTriggerModal;
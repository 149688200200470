import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import '../worker/externalWorker/modals.css'
import urlLinks from '../../urlLinks'
import JobModal from '../../utils/Modals/jobModal'
import { ExcelRenderer } from "react-excel-renderer";
import showjoblinks from '../RecruiterDB/RecruiterDBComponents/showJobLinkModal'
import qs from "querystring"
import Axios from 'axios'
import RecruiterInstructionModal from '../../utils/Modals/RecruiterInstructionModal'
import UploadBulkDialog from './UploadBulkDialog'
const VacancyRecruiterDetails = (props) => {
    const { values, data, jobValue, filters, setFilters, recruiterData, showToast, updateMyData, link } = props
    const [openInstructionModal, setOpenInstructionModal] = useState(false)
    const [openJobModal, setOpenJobModal] = useState(false)
    const buttonCheck = {
        call_connected: false,
        candidates_interested: false,
        candidates_approved: false,
        interviews_scheduled: false,
        candidate_appeared: false,
        candidates_selected: false,
        candidate_joined: false,
        calls_pending: false,
        followup_calls: false
    }
    const [openBulkUploadModal, setopenBulkUploadModal] = useState(false)
    // console.log({data}, "Values Data")
    // console.log({link}, "Values Links")
    const handleGetCandidateData = () => {
        Axios.get(`${urlLinks.baseUrl}recruiter/getCandidateData?job_detail_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recruiter_id=${recruiterData.userID}`)
            .then(response => {
                // console.log({response})
                if (response?.data?.code && response?.data?.code === 1000) {
                    showToast("success", "candidates assigned successfully")
                }
                else if (response?.data?.message) {
                    showToast("error", response.data.message)
                }
                updateMyData()
            })
            .catch(error => {
                // console.log({error})
            })
    }
    const showJobDetails = () => {
        localStorage.setItem("jobDetails", JSON.stringify(values))
        window.open("/newtabjobview", '_blank')
    }
    const handleAddNewCandidate = () => {
        console.log(JSON.stringify({ jobId: jobValue.jobId, jobDetailsId: jobValue.jobDetailId, recruiterId: recruiterData.userID }))
        localStorage.setItem("recNewAddCandidate", JSON.stringify({ jobId: jobValue.jobId, jobDetailsId: jobValue.jobDetailId, recruiterId: recruiterData.userID }))
        window.open("/worker/newAddWorker", '_blank')
    }
    const numberFormat = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(value);
    }
    const handleBulkUpload = (event) => {
        // handlebulkUploadExcel(event)
        console.log("-recruiterId-----> " + recruiterData.userID + " -jobId--------->" + jobValue.jobId)
        openBulkModal()


    }
    const openBulkModal=()=>{
        setopenBulkUploadModal(true)
    }
    const closeBulkModal=()=>{
        setopenBulkUploadModal(false)
    }
    const handlebulkUploadExcel = (event) => {
        var fileObj = event.target.files[0];
        if (event.target.files[0]) {
            ExcelRenderer(fileObj, (err, resp) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log({ resp })
                    const formData = new FormData();
                    formData.append('file', fileObj)
                    Axios.post(`${urlLinks.baseUrl}/workermaster/uploadInvoice`, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            updateMyData()
                            console.log(response)
                            // openResponseModal()
                            // modifyResponse(response.data.response)
                            // AlertDialog(true)

                        })

                    showToast("success", "Uploaded Sucessfully")
                }
            })
        }
        event.target.value = null//this was missing

    }

    return (
        <Grid container className="background_color_e8e8e8  padding20" style={{ fontFamily: 'Roboto' }}>
            <Grid item xs={12} className="flexDisplay borderBottom padding0" direction="row" justify="space-between" style={{ marginBottom: '15px !important' }}>
                <Grid>
                    <Grid item xs={12} className="padding0">
                        <Button className="fontSize16 fontWeightBold linkButton textCapitalize jobTitle" style={{ paddingLeft: "0px", fontSize: "18px" }} onClick={showJobDetails}>{`${jobValue.jobTitle} - ${jobValue.brandName}, ${jobValue.city}`}</Button>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span className="fontSize14 fontWeightBold greenFlag marginBottom12" style={{ paddingLeft: "0px", fontSize: "18px" }}>{data.jobStatus === 1 ? "Active Vacancy" : "Inactive Vacancy"}</span>
                    </Grid>
                </Grid>
                <Grid style={{ borderRadius: '2px' }} item  className="flexDisplay paddingTop20Other0" direction="row" justify="flex-end">
                    <Button
                        color="primary"
                        style={{ marginBottom: "10px" }}
                        className="blueOutlineButton"
                        onClick={() => { navigator.clipboard.writeText(link); showToast("success", "Job link copied successfully") }}
                    >
                        Copy Job Link
                        </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0 paddingTop20Other0" direction="row" justify="flex-start">
                <Grid item xs={12} className="flexDisplay padding0" direction="column" justify="flex-start" style={{ alignSelf: "flex-start" }}>
                    <Grid item xs={12} className="padding0 marginBottom12">
                        <span className="fontSize16 fontWeightBold">Replacement period: </span>
                        {<span>{values.replacementPeriod && values.replacementPeriod}</span>}
                    </Grid>
                    <Grid item xs={12} className="padding0 marginBottom12">
                        <span className="fontSize16 fontWeightBold">Recruitment manager: </span>
                        {<span>{data.recruiter_manager}</span>}
                    </Grid>
                    <Grid item xs={12} className="padding0 marginBottom12">
                        <span className="fontSize16 fontWeightBold">Data assigned: </span>
                        <span>{data.assigned_count}</span>
                    </Grid>
                    {/* <Grid item xs={12} className="padding0">
                            <span className="fontSize16 fontWeightBold">Payout amount: </span>
                            {<span>{numberFormat(data.jobPayout.remoteRecPayoutAmount)}</span>}
                    </Grid> */}
                </Grid>
                <Grid item xs={12} className="flexDisplay padding0 marginBottom12" direction="column" justify="flex-start" style={{ alignSelf: "flex-start", marginBottom: '26px' }}>
                    <Grid item xs={12} className="padding0">
                        <Grid item xs={12} className="padding0 flexDisplay" direction="column">
                            <Grid item xs={10} className="padding0 marginBottom12">
                                <span className="fontSize16 fontWeightBold">Payout amount: </span>
                            </Grid>
                            <Grid item xs={10} className="padding0 payoutData marginBottom12">
                                <span className="fontSize14 fontWeightBold">OkayGo data: </span>
                                {<span style={{whiteSpace: "pre-line"}}>{values.recruiterPayoutAmount}</span>}
                            </Grid>
                            <Grid item xs={10} className="padding0 leaderPayoutData marginBottom12">
                                <span className="fontSize14 fontWeightBold">Self data: </span>
                                {<span style={{whiteSpace: "pre-line"}}>{values.leaderPayoutAmount}</span>}
                            </Grid>
                        </Grid>
                        {(data.jobPayout && data.jobPayout.remoteRecPayout_details) && <Grid item xs={12} className="padding0 flexDisplay marginBottom12" direction="column">
                            <span className="fontSize16 fontWeightBold">Payout details: </span>
                            {<span style={{ whiteSpace: "pre-line" }}>{data.jobPayout.remoteRecPayout_details}</span>}
                        </Grid>}
                        {/* <Grid item xs={12} className="padding0">
                            <Button     
                                className="linkButton"
                                onClick= {() =>setOpenInstructionModal(true)}
                            >
                                Recruiter's pitch
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} className="flexDisplay padding0 marginBottom36" direction="column" justify="flex-end" style={{ alignSelf: "flex-start" }}>
                    <Grid item xs={12} className="marginBottom12 padding0">
                        <Button
                            className="blueOutlineButton"
                            onclick={handleGetCandidateData}
                            style={{ borderRadius: '2px !important' }}
                            onClick={handleGetCandidateData}
                        >
                            GET CANDIDATE DATA
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="marginBottom12 padding0">
                        <Button
                            className="blueOutlineButton"
                            onClick={handleAddNewCandidate}
                            style={{ borderRadius: '2px !important' }}
                        >
                            ADD CANDIDATE
                        </Button>
                    </Grid>
                    {/* Addec by Vaibhav */}

                    <Grid item xs={12} className="marginBottom12 padding0">
                        {/* <input */}
                        {/* style={{ d/isplay: "none" }} */}
                        {/* // id="contained-button-file" */}
                        {/* // type="file" */}
                        {/* // onChange={handleBulkUpload} */}
                        {/* // /> */}
                        {/* <label htmlFor="contained-button-file"> */}
                        <Button
                            className="blueOutlineButton"
                            style={{ borderRadius: "2px !important" }}
                            component="span"
                            onClick={handleBulkUpload}
                        >
                            UPLOAD BULK DATA
                    </Button>
                        {/* </label> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="space-between">
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, call_connected: !filters.call_connected })}
                    className={`candidateStatusButton ${filters.call_connected ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.call_count}
                        </Grid>
                        <Grid item xs className={`padding0 fontSize13 textAlignLeft`}>
                            Calls Connected
                            </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, candidates_interested: !filters.candidates_interested })}
                    className={`candidateStatusButton ${filters.candidates_interested ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.call_interested_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            <span>Candidates Interested</span>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, candidates_approved: !filters.candidates_approved })}
                    className={`candidateStatusButton ${filters.candidates_approved ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.approved_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            Candidates Approved
                            </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, interviews_scheduled: !filters.interviews_scheduled })}
                    className={`candidateStatusButton ${filters.interviews_scheduled ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.interview_scheduled_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            Interviews Scheduled
                            </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, candidate_appeared: !filters.candidate_appeared })}
                    className={`candidateStatusButton ${filters.candidate_appeared ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.interview_appeared_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            Candidates Appeared
                            </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, candidates_selected: !filters.candidates_selected })}
                    className={`candidateStatusButton ${filters.candidates_selected ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.selection_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            Candidates Selected
                            </Grid>
                    </Grid>
                </Button>
                <Button
                    onClick={() => setFilters({ ...filters, ...buttonCheck, candidate_joined: !filters.candidate_joined })}
                    className={`candidateStatusButton ${filters.candidate_joined ? "greenColorBorder" : ""}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs className="padding0 fontSize32">
                            {data.joined_count}
                        </Grid>
                        <Grid item xs className="padding0 fontSize13 textAlignLeft">
                            Candidates Joined
                            </Grid>
                    </Grid>
                </Button>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start">
                <Grid item xs={2} className="flexDisplay paddingTop20Other0" direction="column" justify="flex-start">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setFilters({ ...filters, ...buttonCheck, calls_pending: !filters.calls_pending })}
                        className={`redButton marginBottom12 ${filters.calls_pending ? "greenColorBorder" : ""}`}
                    >
                        {`${data.call_pending_count} Calls Pending`}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setFilters({ ...filters, ...buttonCheck, followup_calls: !filters.followup_calls })}
                        className={`redButton marginBottom12 ${filters.followup_calls ? "greenColorBorder" : ""}`}
                    >
                        {`${data.follow_up_count} Follow-up Calls`}
                    </Button>
                </Grid>
            </Grid>
            {/* Added By Vaibhav */}
            {openBulkUploadModal && <UploadBulkDialog 
            closeBulkModal={closeBulkModal}
            open={true}
            updateMyData={updateMyData}
            userID={recruiterData.userID} 
            jobId={jobValue.jobId}
            showToast={showToast}
            />}
            {
                openInstructionModal && <RecruiterInstructionModal
                    openInstructionModal={openInstructionModal}
                    setOpenInstructionModal={setOpenInstructionModal}
                    data={data}
                />
            }
            {
                openJobModal && <JobModal
                    openJobModal={openJobModal}
                    setOpenJobModal={setOpenJobModal}
                    values={values}
                    updateMyData={updateMyData}
                />

            }
        </Grid >
    )
}

export default VacancyRecruiterDetails
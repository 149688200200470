import React, { Component } from 'react';
import Constant from '../../../constant';
import { Card, Grid } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilterWorkerList';
import WorkerListUtils from './workerListConstant';
import FilterBase from '../../../utils/filters/filterbase'
import CustomTable from '../../../utils/Table/Table';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { RingLoader } from 'react-spinners';
import Axios from 'axios';
import urls from '../../../urlLinks';
import { mapFiltersToLink } from '../mapFiltersToUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
// import SearchBar from '../../../utils/SearchBar/SearchBar';
import Select from '../../../utils/simpleSelect/nativeSelect'
import Header from "../../../Header/Header";

const style = {
  SearchBar: {
    marginTop: '-1%',
    marginLeft: '25%'
  }
};

class WorkerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type:'',
      label:'',
      workerList: [],
      filters: WorkerListUtils.WorkerListFilterList,
      isLoading: true,
      paramsAvailable: false,
      url: `${urls.baseUrl}${urls.workerUrls.getWorkerList}`,
      currentPage: 0,
      count: 0,
      rowsPerPage: 10,
      isTableUpdating: false,
      open: false,
      columns: [
        {
          id: '1',
          name: 'status',
          label: Constant.worker.status,
          isColumnVisible: true
        },
        {
          id: '2',
          name: 'appRegistered',
          label: Constant.worker.appRegistered,
          isColumnVisible: true
        },
        {
          id: '3',
          name: 'workerId',
          label: Constant.worker.workerId,
          isColumnVisible: true
        },
        {
          id: '4',
          name: 'name',
          label: Constant.worker.name,
          isColumnVisible: true
        },
        {
          id: '5',
          name: 'contactNumber',
          label: Constant.worker.contactNumber,
          isColumnVisible: true
        },
         {
          id: '51',
          name: 'gender',
          label: "Gender",
          isColumnVisible: true
        },
        {
          id: '50',
          name: 'insertedOn',
          label: Constant.worker.insertedOn,
          isColumnVisible: true
        },
        {
          id: '6',
          name: 'address',
          label: Constant.worker.address,
          isColumnVisible: true
        },
        {
          id: '7',
          name: 'city',
          label: Constant.worker.city,
          isColumnVisible: true
        },
        {
          id: '71',
          name: 'skills',
          label: "Skills",
          isColumnVisible: true
        },
        {
          id: '8',
          name: 'totalGigs',
          label: Constant.worker.totalGigs,
          isColumnVisible: true
        },
        {
          id: '9',
          name: 'averageRating',
          label: Constant.worker.averageRating,
          isColumnVisible: true
        },
        {
          id: '10',
          name: 'addedBy',
          label: Constant.worker.addedBy,
          isColumnVisible: true
        },
        {
          id: '11',
          name: 'jobType',
          label: Constant.worker.jobType,
          isColumnVisible: true
        },
        {
          id: '12',
          name: 'industryType',
          label: Constant.worker.industryType,
          isColumnVisible: true
        },
        {
          id: '13',
          name: 'experience',
          label: Constant.worker.experience,
          isColumnVisible: true
        },
        {
          id: '14',
          name: 'lastUpdatedOn',
          label: Constant.worker.lastUpdatedOn,
          isColumnVisible: true
        },
        {
          id: '14',
          name: 'expected_salary',
          label: "Expected Salary",
          isColumnVisible: true
        },
        {
          id: '15',
          name: 'preferred_job_type',
          label: "Preferred Job type",
          isColumnVisible: true
        },
        {
          id: '16',
          name: 'preferred_remark_location',
          label: "Preferred Location",
          isColumnVisible: true
        },
        {
          id: '17',
          name: 'action',
          label: Constant.worker.action,
          isColumnVisible: true
        }
      ],
      filters: {
        location:[],
        worker_status:[""],
        rating: 0,
        exp:0,
        name: '',
        phoneNumber:'',
        preferred_job_type:[],
        expected_salary:0,
        preferred_remark_location:[],
        skills:[]
      },
      filterUrl:'',
      sortAccessed: false,
      sortParam:'' ,
      sortingDirection: true
    };
    this.data = [];
    this.option = [
      {
        id: 1,
        type: 1,//integer
        label: 'WorkerID',
        eValue:'workerId'
      },
      {
        id:2,
        type: 3,//date
        label: 'OnBoarding Date',
        eValue:'registrationDate'
       
      },
      {
        id:3,
        type:3,
        label:'Last Updated On',
        eValue:'updatedOn'
      },
      {
        id: 4,
        type: 2,//integer
        label: 'First Name',
        eValue:'firstName'
      },
      {
        id:5,
        type: 1,//date
        label: 'City',
        eValue:'city'
       
      },
      {
        id:6,
        type:1,
        label:'Status',
        eValue:'status'
      },
      {
        id: 7,
        type: 2,//integer
        label: 'Industry Experience',
        eValue:'industryType'
      },
      {
        id:8,
        type: 1,//date
        label: 'Job Experience',
        eValue:'jobType'
       
      },
      {
        id:9,
        type:1,
        label:'Experience',
        eValue:'experience'
      },
      {
        id: 10,
        type: 1,//integer
        label: 'OD jobs',
        eValue:'totalGigs'
      },
      {
        id:11,
        type: 1,//date
        label: 'Average Rating',
        eValue:'averageRating'
       
      }
    ];
    this.rowsOptions = [5, 10,  50, 100, 250, 500];
    this.updateColumnFileds = this.updateColumnFileds.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateDataOnTablePagination = this.updateDataOnTablePagination.bind(
      this
    );
    this.getWorkerList = this.getWorkerList.bind(this);
  }
  updateColumnFileds(columnsToHide) {
    const upadtedColumns = this.state.columns.map(col => {
      if (columnsToHide.includes(col.id)) {
        col.isColumnVisible = true;
      } else {
        col.isColumnVisible = false;
      }
      return col;
    });
    this.setState({
      column: upadtedColumns
    });
  }
  updateFilters(filters) {
    console.log({filters});
    
    let url='';
    if(filters["location"].length!==0){
      let cityIds = []
      filters["location"].map(city => cityIds.push(city.id))
      url=`${url}&city_id=${cityIds}`
    }
    if(filters["preferred_job_type"].length!==0){
      let jobIds = []
      filters["preferred_job_type"].map(job => jobIds.push(job.id))
      url=`${url}&preferred_job_type=${jobIds}`
    }
    if(filters["preferred_remark_location"].length!==0){
      let cityIds = []
      filters["preferred_remark_location"].map(city => cityIds.push(city.id))
      url=`${url}&preferred_remark_location=${cityIds}`
    }
    if(filters["skills"].length!==0){
      let cityIds = []
      filters["skills"].map(city => cityIds.push(city.id))
      url=`${url}&skills=${cityIds}`
  }
    if(!filters["worker_status"].includes(""))
      url=`${url}&worker_status=${filters["worker_status"]}`
    if(filters["rating"])
      url=`${url}&rating=${filters["rating"]}`
    if(filters["exp"])
      url=`${url}&experience=${filters["exp"]}`
    if(filters["name"])
      url=`${url}&name=${filters["name"]}`
    if(filters["phoneNumber"])
      url=`${url}&phoneNumber=${filters["phoneNumber"]}`
    if(filters["expected_salary"])
      url=`${url}&expected_salary=${filters["expected_salary"]}`
    console.log({url});
    
    this.setState({filters: filters, currentPage: 0, filterUrl: url, },  () => this.getWorkerList())
      
  }
  resetFilters =() =>{
    const filters={location:[], worker_status:[""], rating: 0, exp: 0,  name: '',phoneNumber:'', preferred_job_type:[""], expected_salary:0, preferred_remark_location:[""],skills: []}
    this.setState({filterUrl: '', currentPage: 0, filters: filters}, () => this.getWorkerList())
    return filters;
  }

  updateDataOnTablePagination(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    this.setState({ isTableUpdating: true }, () => {
      this.getWorkerList(rowsPerPage, nextPage);
    });
  }
  getWorkerList(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    let url = '';
    url = `${this.state.url}?page_no=${nextPage}&rows=${rowsPerPage}${this.state.filterUrl}`;
    if(this.state.sortAccessed && this.state.sortingDirection)
    url=`${url}${this.state.sortParam}&sortBy=ascending`
    if(this.state.sortAccessed && !this.state.sortingDirection)
    url=`${url}${this.state.sortParam}`
    console.log(url);
    Axios.get(url)
      .then(response => {
        console.log(response.data.response.content);
        this.setState({
          workerList: this.makedata(response.data.response.content),
          count: response.data.response.totalElements,
          currentPage: nextPage,
          rowsPerPage: rowsPerPage,
          isLoading: false,
          isTableUpdating: false
        });
      })
      .catch(error => {
        toast.error('Error While loading Worker List');
      });
  }
  fixSkills = data => {
    let skills=[]
        console.log({data})
        if(data?.userSkills?.length>0){
            console.log("If check")
          data.userSkills.map(skill =>{
            console.log("map check")
            skills.push(skill.skillsName)
          })
        }
        const res= skills.join(", ");
        return res
  }

  makedata = data => {
    const allAdmins = JSON.parse(localStorage.getItem('allAdmins'));
    const extractedData = [];
    data.map(el => {
      console.log({el})
      extractedData.push({
        appRegistered:  el.insertedBy
        ? allAdmins[el.insertedBy]
          ? 'No'
          : 'Yes'
        : '-',
        status: el.status ? el.status==="Activated"?"OnBoarded":el.status==="Deactivated"?"Not OnBoarded" : '-':'-',
        userId: el.userId ? el.userId : '-',
        workerId: el.workerId ? el.workerId : '-',
        name: el.name ? el.name : '-',
        gender:el.gender?el.gender==26?'Male':'Female':'--',
        contactNumber: el.contactNumber ? el.contactNumber : '-',
        insertedOn: el.insertedOn.toString()
        .split('-')
        .reverse()
        .join('-') || '-',
        email: el.email ? el.email : '-',
        address: el.address ? el.address : '-',
        city: el.newCity?`${el.newCity}${el.newState?", " :''}${el.newState?el.newState:''}`: '-',
        totalGigs: el.totalGigs ? el.totalGigs : 0,
        averageRating: el.averageRating ? el.averageRating : 0,
        // addedBy: el.addedBy
        //   ? el.addedBy.trim().toLowerCase() === 'admin'
        //     ? 'Admin'
        //     : 'Self'
        //   : 'Admin',
        addedBy: el.insertedBy
          ? allAdmins[el.insertedBy]
            ? allAdmins[el.insertedBy]
            : 'Self'
          : '-',
        jobType: el.jobType ? el.jobType : '-',
        expected_salary: el.expected_salary? el.expected_salary:'-',
        preferred_job_type: el.expected_salary? el.expected_salary:'-',
        preferred_remark_location: el.expected_salary? el.expected_salary:'-',
        industryType: el.industryType ? el.industryType : '-',
        experience: el.experience ? el.experience : 0,
        lastUpdatedOn: el.lastUpdatedOn
          ? el.lastUpdatedOn
              .substring(0, 10)
              .toString()
              .split('-')
              .reverse()
              .join('-')
          : '-',
        skills: this.fixSkills(el)
      });
    });
    return extractedData;
  };

  componentDidMount() {
    this.getWorkerList();
  }


  handleChange= (event) =>{
    
    let value=event.target.value.eValue;
    let sortParam='';
    if(value==='workerId') sortParam='&orderBy=wm.user_id'
    if(value==='registrationDate') sortParam='&orderBy=wm.inserted_on'
    if(value==='updatedOn') sortParam='&orderBy=wm.updated_on'
    if(value==='firstName') sortParam='&orderBy=um.first_name'
    if(value==='city') sortParam='&orderBy= um.city_id'
    if(value==='status') sortParam='&orderBy=status'
    if(value==='industryType') sortParam='&orderBy=industry_type'
    if(value==='jobType') sortParam='&orderBy=job_type'
    if(value==='experience') sortParam='&orderBy=experience_in_yrs'
    if(value==='totalGigs') sortParam='&orderBy=wm.total_gigs'
    if(value==='averageRating') sortParam='&orderBy= wm.average_rating'
    console.log(value);


    this.setState(
      {
        label:event.target.value,
        type:event.target.value.type, 
        sortAccessed: true , 
        sortParam: sortParam
      }, 
      () => {
        this.getWorkerList();
      }
    )
  }  


  render() {
    console.log(this.state.workerList);
    
    const { classes } = this.props;
    const tablePagination = {
      currentPage: this.state.currentPage,
      count: this.state.count,
      changePage: this.updateDataOnTablePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage,
      tableUpdating: this.state.isTableUpdating
    };
    return this.state.isLoading ? (
      <RingLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
        color={'#194D33'}
      ></RingLoader>
    ) : (
      <div className={' profile-content recruiterDashboard workerList'}>
            <Header leftMenu={[{ url: "/dashboard", name: "Dashboard" }, { url: "/recruiter-dashboard", name: "Track applications" }]} link='true' />
            <Grid container className="main-content">
        
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Card style={{ margin: '20px 0px', padding: '20px', width:"100%" }}>
          <Grid container>
            <Grid item sm={3}>
              <HideUnhideColumns
                columns={this.state.columns}
                toggleColumn={this.updateColumnFileds}
              ></HideUnhideColumns>
            </Grid>
            <Grid item sm={3}>
              <FilterBase
                filters={this.state.filters}
                updateFilters={this.updateFilters}
                resetFilters={this.resetFilters}
                page="workerDB"
              />
            </Grid>
            <Grid item sm={2} >
              <Select
              val={this.state.label}
              type={this.state.type}
              option={this.option}
              handleChange={this.handleChange}>
              </Select>
            </Grid>
            <Grid item sm={1} >
             {this.state.sortAccessed && 
                <ToggleButton
                    className="sortDir"
                    value="check"
                    selected={this.state.sortingDirection}
                    onChange={() => {
                      this.setState({sortingDirection: !this.state.sortingDirection}, 
                        () => this.getWorkerList()
                      )
                    }}
                  >
                    {this.state.sortingDirection? 'A to Z':'Z to A' }
                </ToggleButton>
              }
            </Grid>
          </Grid>
        </Card>
        <CustomTable
          name={Constant.worker.idName}
          columns={this.state.columns}
          data={this.state.workerList}
          numericIndex={true}
          links={WorkerListUtils.WorkerListAction}
          title='Worker List'
          tablePagination={tablePagination}
        ></CustomTable>
    </Grid>
    </div>
    );
  }
}

export default withStyles(style)(WorkerList);

import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Select, MenuItem, } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import './filter.css'
const useStyles = makeStyles({
 
});

export default function SelectFilter(props) {
    const {column, row, filters, setFilters}= props
    const id=column.id;
    const classes = useStyles();
    const [value, setValue] = React.useState(filters[id]);
    
    const optionsCallStatus =[
        ["", "All"],
        ["INTERESTED", "Interested"],
        ["NOT_INTERESTED", "Not interested"],
        ["CALL_NOT_RECIEVED", "Call not Recieved"],
        ["BUSY", "Busy"],
        ["CALL_LATER", "Call later"],
        ["INVALID_NUMBER", "Invalid number"],
        ["SWITCHED_OFF", "Switched off"]  ,
        ["0", "No status"]  ,
        ["1", "Not null"]

    ]
    const optionsApprovalStatus =[
        ["", "All"],
        ["APPROVED", "Approved"],
        ["NOT_APPROVED", "Not approved"]  ,
        ["0", "No status"]  ,
        ["1", "Not null"]
    ]
    const optionsInterviewFollowUp =[
        ["", "All"],
        ["GOING", "Going"],
        ["NOT_GOING", "Not going"],
        ["RESCHEDULE", "Reschedule"],
        ["NO_RESPONSE", "No Response"],
        ["APPEARED", "Appeared"]    ,
        ["0", "No status"]  ,
        ["1", "Not null"]
    ]
    const optionsApplicationStage =[
        ["", "All"],
        ["APPLIED", "Applied"],
        ["SHORTLISTED", "Shortlisted"],
        ["REJECTED", "Candidate rejected"],
        ["INTERVIEWING", "Interviewing"],
        ["IREJECTED", "Rejected"],
        ["SELECTED", "Selected"],
        ["ACCEPTED", "Offer accepted"],
        ["UPDATE_JOINING_DATE", "Updated joining date"],
        ["OREJECTED", "Offer rejected"],
        ["JOINED", "Joined"],  
        ["LEFT_AFTER_JOINING", "Left after joining"],
        ["POSTPONED", "Postponed"],
        ["NOT_JOINING", "Candidate not joining"],
        ["DROPPED", "Candidate dropped"],
        ["AWAITED", "Result awaited"],
    ]
    
    const optionsFeedback =[
        ["", "All"],
        ["SELECTED", "Selected"],
        ["REJECTED", "Rejected"],
        ["ROUNDS_PENDING", "Rounds pending"],
        ["RESULT_AWAITED", "Result awaited"],
        ["CANDIDATE_DROPPED", "Candidate dropped"],
        ["NOT_APPEARED", "Not appeared"]   ,
        ["CONFLICT", "Conflict"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["LEFT_AFTER_JOINING", "Left after joining"],
        ["0", "No status"]  ,
        ["1", "Not null"]
    ]
    const optionsJoiningStatus =[
        ["", "All"],
        ["JOINED", "Joined"],
        ["NOT_JOINING", "Not joining"],
        ["POSTPONED", "Postponed"],
        ["NO_RESPONSE", "No response"],
        ["LEFT_AFTER_JOINING", "Left after joining"],
        ["0", "No status"]  ,
        ["1", "Not null"]
    ]
    const allAdmins=JSON.parse(localStorage.getItem('allAdmins'));    
    let optionsAppliedBy=Object.entries(allAdmins);
    optionsAppliedBy.push(["-1","Self"]);
    optionsAppliedBy.unshift(["", "All"]);
    
    let options=null
    switch (id) {
        case "callStatus":
            options= optionsCallStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0] }>{option[1]}</MenuItem>
            })
            break;
        case "approvalStatus":
            options= optionsApprovalStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "applicationStage":
            options= optionsApplicationStage.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "interviewFollowUp":
            options= optionsInterviewFollowUp.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "recruiterFeedback":
        case "employerFeedback":
        case "finalFeedback":
            options= optionsFeedback.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "joiningStatus":
            options= optionsJoiningStatus.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        case "appliedBy":
            options= optionsAppliedBy.map(option =>{
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        
        default:
            options=null
            break;
    }
    const handleChange = (event) => {
        if(value.includes("") && event.target.value.length>1)
            setValue(event.target.value.filter(x => x!==""));
        else if(event.target.value.includes("") || event.target.value.length===0)
            setValue([""])
        else
            setValue(event.target.value);
    };
    const applicationStageFilter =(array) =>{
        let eventName=[];
        let eventStatus=array;
        if(array.includes(""))
            return "";
        if(array.includes("REJECTED")){
            if(!eventName.includes("APPLIED_JOB"))
                eventName.push("APPLIED_JOB")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="REJECTED")
        }
        if(array.includes("INTERVIEWING")){
            eventStatus.push("GOING", "SLOTS_CREATED", "SLOT_SELECTED", "SLOT_UPDATED", "RESCHEDULE", "APPEARED");
            eventStatus.filter(x=> x!=="INTERVIEWING")
        }
        if(array.includes("IREJECTED")){
            if(!eventName.includes("INTERVIEW_STATUS"))
                eventName.push("INTERVIEW_STATUS")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="IREJECTED")
        }
        if(array.includes("OREJECTED")){
            if(!eventName.includes("OFFER_JOB"))
                eventName.push("OFFER_JOB")
            if(!eventStatus.includes("REJECTED"))
                eventStatus.push("REJECTED")
            eventStatus.filter(x=> x!=="OREJECTED")
        }
        if(array.includes("DROPPED")){
            eventStatus.push("NO_SHOW", "NOT_GOING", "NO_RESPONSE", "CANDIDATE_DROPPED")
        }
        if(array.includes("AWAITED")){
            eventStatus.push("ROUNDS_PENDING", "RESULT_AWAITED")
        }

        let interviewStageName = eventName.length>0?`&interviewStageName=${eventName}`:''
        let interviewStage = eventStatus.length>0?`&interviewStage=${eventStatus}`:''
        
        return `${interviewStage}${interviewStageName}`
    }
    const handleBlur = (event) => {
        if(id==="applicationStage"){
            setFilters({...filters, [id]: applicationStageFilter(value)})
        }

        else
            setFilters({...filters, [id]: value})
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}  style={{color:"white", marginTop: 1}}>
                <Select
                    id={id}
                    multiple
                    value={value}
                    onChange={handleChange}
                    style={{color: "white", width: 200}}
                    onBlur={handleBlur}
                    >
                    {options}
                </Select>
            </Grid>
        </Grid>
  );
}

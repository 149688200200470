import React, {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './weekbuttongroup.css'
import Grid from '@material-ui/core/Grid';
import { ErrorMessage } from 'formik';

const StyledToggleButtonGroup = withStyles(theme => ({
    
    grouped: {
        width: "fit-content",
        height:"fit-content",
        color:'#8f8f8f',
        '&:focus':{
            outline:'none',
        },
        '&:hover':{

            border: "1px solid #238ce8 !important",
            color: '#238ce8'
        },
        
    },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(theme => ({
    selected: {
        border:'1px solid #238ce8 !important',
        color: '#238ce8 !important',
        backgroundColor: "unset !important",
        height:"fit-content",
    }
}))(ToggleButton);

export default function WeekTogglebuttons(props) {
   
    const [weekdays, setWeekdays] = React.useState(props.value);
    return (
        <div>
            <Grid container className= "padding0">
                <StyledToggleButtonGroup value={weekdays} 
                    style={{backgroundColor: "unset", textAlign: "left"}}
                    >
                    <StyledToggleButton value="monday" className="weekbutton"  disabled={ props.isdisabled===false? props.isdisabled:true}>
                        M
                    </StyledToggleButton>
                    <StyledToggleButton value="tuesday"  className="weekbutton" disabled={ props.isdisabled===false? props.isdisabled:true}>
                        T
                    </StyledToggleButton>
                    <StyledToggleButton value="wednesday" className="weekbutton" disabled={ props.isdisabled===false? props.isdisabled:true}>
                        W
                    </StyledToggleButton>
                    <StyledToggleButton value="thursday" className="weekbutton" disabled={ props.isdisabled===false? props.isdisabled:true}>
                        T
                    </StyledToggleButton>
                    <StyledToggleButton value="friday"  className="weekbutton" disabled={ props.isdisabled===false? props.isdisabled:true}>
                        F
                    </StyledToggleButton>
                    <StyledToggleButton value="saturday" className="weekbutton" disabled ={ props.isdisabled===false? props.isdisabled:true}>
                        S
                    </StyledToggleButton>
                    <StyledToggleButton value="sunday" className="weekbutton" disabled ={ props.isdisabled===false? props.isdisabled:true}>
                        S
                    </StyledToggleButton>
                    
                </StyledToggleButtonGroup>
            </Grid> 
        </div>
    );
}

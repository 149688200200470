import React, { useState, useEffect } from 'react'
import ModalVideo from 'react-modal-video'
import Styles from './index.module.scss'
import 'react-modal-video/scss/modal-video.scss';
import thumbnail from './Group 3716.svg'
const SingleVideo =(props) =>{
    const [open, setOpen] = useState(false)
    return (
        <div>
            <ModalVideo channel='custom' autoplay isOpen={open} url={props.id} onClose={() => setOpen(false)} />
            <div onClick={() => setOpen(true)} className={Styles.videoContainer} >
                <div>
                    <img src={thumbnail} alt="thumbnail" className={Styles.videoThumbnail} />
                </div>
                <div style={{padding: "20px"}}>
                    <span className={Styles.videoTitle}>{props.title}</span>
                </div>
            </div>
        </div>
    )
}
export default SingleVideo;
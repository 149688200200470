import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button, Grid, TextField, FormControl, InputLabel } from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Styles from '../index.module.scss'
import { Link } from "react-router-dom";
// import InterviewModal from './interviewModal'
// import FeedbackModal from './FeedbackModal'
// import JoinedModal from './JoinedModal'
import EditableTextInput from './EditableTextInput'
import DashboardRemarkModal from '../../../utils/Modals/dashboardRemarkModal'
// import  '../tables.css'
import ApprovalConfirmationModal from '../../../utils/Modals/ApprovalConfirmationModal'
import PostModal from '../RecruiterDBComponents/PostModal'
import RescheduleModal from '../../../utils/Modals/Reschedule'
import urlLinks from '../../../urlLinks';
import ProfileRejectModal from './ProfileRejectModal'
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root": {
        //  border:"1px solid grey",
        marginLeft:'0',
        "&.Mui-focused fieldset": {
          border: "1px solid grey"
        }
      }
    },
    input: {
      // padding:0,
      '& [class*="MuiFilledInput-input"]': {
        padding: "16px 17px 10px",
        width: "58px"
      },
      '& [class*="MuiFilledInput-input"]': {
        padding: "16px 17px 10px 4px",
        width: "68px"
      }
    },
    iconFilled:{
        right: "-5px"
    },
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            padding: "8px 10px"
        }
    }
  }));


const EditableDropdownSubComponent =( props) =>{
    const {row, columnId, initialValue, updateMyData, showToast, setLoading, interviewAddress, quesList, setOpenMarkLeftModal, jobDetails} = props
    let statusUpdateUrl=`${urlLinks.baseUrl}job/assigner/updateStatus`
    // console.log({initialValue})
    const classes = useStyles();
    const [value, setValue] = useState(initialValue);
    const [openSelectedStatus, setOpenSelectedStatus] = useState(false)
    const [profileReject, setProfileReject] = useState(0)
    const [openUpdateSlots, setOpenUpdateSlots] = useState(false)
    const [openJoinedStatus, setOpenJoinedStatus] = useState(false)
    const [openRemarkModal, setOpenRemarkModal] = useState(false)
    const [rescheduleModal, setRescheduleModal] = useState(false)
    const [openPostponadModal, setOpenPostPonadModal] = useState(false)
    const [openProfileRejectModal, setOpenProfileRejectModal] = useState(false)
    const [openPostonedModal, setOpenPostonedModal] = useState(false)
    const [f2fInterviewData , setF2fInterviewData] = useState({
        interviewDate: row.interviewStartTime, 
        startTime: row.interviewStartTime,
        endTime: row.interviewEndTime,
        interviewDateError: false,
        startTimeError : false,
        endTimeError: false
    })
    const [teleInterviewData , setTeleInterviewData] = useState({
        startDate: row.startDate,
        endDate: row.endDate,
        startDateError: false,
        endDateError: false
    })
    const [selectionDate, setSelectionDate] = useState(row.selectionDate?moment(row.selectionDate, "YYYY-MM-DD 00:00:00"):null)
    const [joiningDate, setJoiningDate] = useState(row.joiningDate?moment(row.joiningDate, "YYYY-MM-DD 00:00:00"):null)
    const [offeredSalary, setOfferedSalary] = useState(row.offeredSalary)
    const [offeredSalaryType, setOfferedSalaryType] = useState(row.offered_amount_type?row.offered_amount_type:0)
    const [isofferdetailEmpty, setIsofferdetailEmpty] = useState(false)
    const [approvalConfirmationModal, setApprovalConfirmationModal] = useState(false)
    // console.log({selectionDate,joiningDate, offeredSalary ,offeredSalaryType})
    let label=""
    // console.log({f2fInterviewData})
    useEffect(() => {
        if(!selectionDate || !joiningDate || !offeredSalary)
            setIsofferdetailEmpty(true)
    }, [])
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        if(columnId==="interviewMode" && e.target.value===4){
            setOpenProfileRejectModal(true)
        }
        else{
            setShowButtons(true)
        }
        if(columnId==="callStatus" && e.target.value==="NOT_INTERESTED"){
            setOpenRemarkModal(true)
        }
        if(columnId==="approvalStatus" && e.target.value==="APPROVED" && row.questionnaireScore!==quesList){
            setApprovalConfirmationModal(true)
        }
        if(columnId==="approvalStatus" && e.target.value==="NOT_APPROVED" && row.questionnaireScore===quesList){
            setOpenRemarkModal(true)
        }
        if(columnId==="interviewFollowUp" && e.target.value==="RESCHEDULE"){
            setShowButtons(false)
            setRescheduleModal(true)
        } 
        if(columnId==="finalFeedback" && e.target.value==="SELECTED")
            setOpenSelectedStatus(true)
        if(columnId==="joiningStatus" && (e.target.value==="JOINED" || e.target.value==="POSTPONED")){
            
        }
        if(columnId==="joiningStatus" && e.target.value==="POSTPONED"){
            console.log({columnId})
            setShowButtons(false)
            setOpenPostonedModal(true);
        }
        if(columnId==="joiningStatus" && e.target.value==="LEFT_AFTER_JOINING"){
            console.log({columnId})
            setShowButtons(false)
            setOpenMarkLeftModal(true);
        }
        setValue(e.target.value)
    }
    
    const updateStatus = () =>{
        setLoading(true)
        // console.log("entered", value)

        const postData={
            interview_id: row.interviewId,
            updated_by: localStorage.getItem('userId')
        }
        if(columnId==="interviewFollowUp")
        {
            statusUpdateUrl=`${urlLinks.baseUrl}tracking/updateInterviewStatus`
            postData.interview_status= value
        }
        else if(columnId==="joiningStatus" && (value!=="POSTPONED"))
        {
            statusUpdateUrl=`${urlLinks.baseUrl}tracking/updateJoiningStatus`
            postData.joining_status= value
            if(isofferdetailEmpty){
                if(joiningDate)
                postData.update_joining_date= moment(joiningDate).format("YYYY-MM-DD 00:00:00")
                if(selectionDate)
                    postData.update_offered_date= moment(selectionDate).format("YYYY-MM-DD 00:00:00")
                if(offeredSalary){
                    postData.offered_amount_type= offeredSalaryType
                    postData.job_offered_amount= offeredSalary
                }
            }
            
        }
        else{
            if(columnId==="callStatus")
                postData.field="call_status";
            else if(columnId==="approvalStatus")
                postData.field="approval_status";
            else if(columnId==="interviewMode")
                postData.field="interview_mode";
            else if(columnId==="recruiterFeedback")
                postData.field="recruiter_feedback";
            else if(columnId==="employerFeedback")
                postData.field="employer_feedback";
            
            postData.assign_id= row.assignId;
            postData.fieldStatus= profileReject===4?profileReject:value;
            postData.user_id= row.userId;
            postData.worker_id= parseInt((row.workerId).toString().replace(/[a-zA-Z\-]/g, ''))
            postData.job_id= row.jobId;
            postData.job_details_id= row.jobDetailsId;
        }
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData()
                    setLoading(false);
                } else {
                    showToast(response.data.message);
                    setLoading(false);
                }
            })
        

    }
    const updateInterviewData =() =>{
        if(value===2 || value===3){

            const postData={
                requested_by: localStorage.getItem('userId'),
                job_detail_id: row.jobDetailsId,
                user_id: row.userId,
                interview_status:1,
            }
            if(row.interviewId)
                postData.interview_id=row.interviewId
            if(value===2){
                postData.from_date= moment(teleInterviewData.startDate).format("YYYY-MM-DD");
                postData.to_date= moment(teleInterviewData.endDate).format("YYYY-MM-DD");
            }
            if(value===3){
                // console.log({f2fInterviewData})
                postData.interview_start_time= `${moment(f2fInterviewData.interviewDate).format("YYYY-MM-DD")} ${moment(f2fInterviewData.startTime).format("HH:mm:ss")}`;
                postData.interview_end_time= `${moment(f2fInterviewData.interviewDate).format("YYYY-MM-DD")} ${moment(f2fInterviewData.endTime).format("HH:mm:ss")}`;
            }
            postData.interview_mode=value
            // console.log({postData})
            Axios.post(`${urlLinks.baseUrl}interview/schedule`,qs.stringify(postData))
                .then(response => {
                    if (response.data.code === 1000) {
                        showToast("success", 'Status updated Successfully');
                        updateMyData()
                    } else {
                        throw showToast("error", response.data.message);
                    }
                })
                .catch(error => {
                    showToast("error", "Status update failed");
                    setOpenUpdateSlots(false)
                });
        }
        else
            updateStatus();
            
            
            
    }
    const handleAgainCallStatus = () =>{
        setLoading(true)
        // console.log("entered", value)

        const postData={
            interview_id: row.interviewId,
            updated_by: localStorage.getItem('userId')
        }
            postData.field="call_status";
            postData.assign_id= row.assignId;
            postData.fieldStatus= profileReject===4?profileReject:value;
            postData.user_id= row.userId;
            postData.worker_id= parseInt((row.workerId).toString().replace(/[a-zA-Z\-]/g, ''))
            postData.job_id= row.jobId;
            postData.job_details_id= row.jobDetailsId;
        
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData()
                    setLoading(false);
                } else {
                    showToast(response.data.message);
                    setLoading(false);
                }
            })
        
    }
    const updateFeedbackData =() =>{

        const postData={
            interview_id: row.interviewId,
            feedback_status: value,
            updated_by: parseInt(localStorage.getItem('userId'))
        }
        if(joiningDate)
        postData.update_joining_date= moment(joiningDate).format("YYYY-MM-DD 00:00:00")
        if(selectionDate)
            postData.update_offered_date= moment(selectionDate).format("YYYY-MM-DD 00:00:00")
        if(offeredSalary){
            postData.offered_amount_type= offeredSalaryType
            postData.job_offered_amount= offeredSalary
        }
        postData.is_job_offered= 1
        // console.log({postData})
        Axios.post(`${urlLinks.baseUrl}recruiter/updateFeedbackStatus`, qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    updateMyData();
                    showToast("success", "Status updated successfully")
                } else {
                    throw showToast("error", response.data.message);
                }
            })
            .catch(error => {
               showToast("error", error.message);
               

            });
    }
    const updateOfferStatus =() =>{
        const postData={
            interview_id: row.interviewId,
            requested_by: parseInt(localStorage.getItem('userId'))
        }
        if(value==="ACCEPTED")
            postData.is_offer_accepted=1
        if(value==="REJECTED")
            postData.is_offer_rejected=1
        // console.log({postData})
        Axios.post(`${urlLinks.baseUrl}interview/`, qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    updateMyData();
                    setShowButtons(false)
                    showToast("success", "Offer status updated successfully")
                } else {
                    setShowButtons(false)
                    throw showToast("error", response.data.message);
                }
            })
            .catch(error => {
               showToast("error", error.message);
            });
    }
    const handleConfirm =() =>{
        setLoading(true)
        updateStatus();
        
    }
    const handleOfferAccept =() =>{
        updateOfferStatus()

    }
    // console.log({selectionDate, joiningDate})
    const handleInterviewSchedule =() =>{
        if(value===2){
            if(teleInterviewData.startDate && teleInterviewData.endDate){
                setLoading(true)
                updateInterviewData();
            }
            else{
                if(!teleInterviewData.startDate){
                    setTeleInterviewData({...teleInterviewData, startDateError: true})
                }
                if(!teleInterviewData.endDate){
                    setTeleInterviewData({...teleInterviewData, endDateError: true})
                }
            }

        }
        else if(value===3){
            if(f2fInterviewData.interviewDate && f2fInterviewData.startTime && f2fInterviewData.endTime ){
                setLoading(true)
                updateInterviewData();
            }
            else{
                if(!f2fInterviewData.startTime){
                    setF2fInterviewData({...f2fInterviewData, startTimeError: true})
                }
                if(!f2fInterviewData.endTime){
                    setF2fInterviewData({...f2fInterviewData, endTimeError: true})
                }
                if(!f2fInterviewData.interviewDate){
                    setF2fInterviewData({...f2fInterviewData, interviewDateError: true})
                }
            }
        }
        else {
            setLoading(true)
            updateInterviewData();
        }
    }
    const handleFeedbackConfirm =() =>{
        setLoading(true)
        updateFeedbackData();
    }
    const handleInterviewData =(date, location) =>{
        // console.log({location})
        switch (location) {
            case 1:
                setTeleInterviewData({...teleInterviewData, startDate: moment(date).format("YYYY-MM-DD") , startDateError: false})
                break;
            case 2:
                setTeleInterviewData({...teleInterviewData, endDate: moment(date).format("YYYY-MM-DD"), endDateError: false })
                break;
            case 3:
                // console.log({date, location})
                setF2fInterviewData({...f2fInterviewData, interviewDate: moment(date).format("YYYY-MM-DD HH:mm:ss"), interviewDateError: false })
                break;
            case 4:
                setF2fInterviewData({...f2fInterviewData, startTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), startTimeError: false })
                break;
            case 5:
                setF2fInterviewData({...f2fInterviewData, endTime: moment(date).format("YYYY-MM-DD HH:mm:ss"), endTimeError: false })
                break;
            default:
                break;
        }
        // console.log({f2fInterviewData})
        
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    
    useEffect(() => {
        if(profileReject===4)
            updateStatus();
    }, [profileReject])
    React.useEffect(() => {
        setValue(initialValue)
        // console.log({initialValue})
    }, [initialValue])
    let dropdown=<div></div>
    if(columnId==="callStatus")
        dropdown=<div className="index">
            <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
                <InputLabel htmlFor="callStatus">Call status</InputLabel>
            <Select
                id="callStatus"
                value={value}
                label="Call status"
                disableUnderline
                variant= "outlined"
                placeholder="Call status"
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
            >
                
                <MenuItem value='INTERESTED'>Interested</MenuItem>
                <MenuItem value='NOT_INTERESTED'>Not interested</MenuItem>
                <MenuItem value='CALL_NOT_RECIEVED'>Call not Recieved</MenuItem>
                <MenuItem value='BUSY'>Busy</MenuItem>
                <MenuItem value='CALL_LATER'>Call later</MenuItem>
                <MenuItem value='INVALID_NUMBER'>Invalid number</MenuItem>
                <MenuItem value='SWITCHED_OFF'>Switched off</MenuItem>
                {/* <MenuItem value='NON_RESPONSIVE'>Non responsive</MenuItem> */}
            </Select>
            </FormControl>
            {(row.callStatus && row.callStatus!=='INTERESTED' && row.callStatus!=='NOT_INTERESTED' &&  row.callStatus!=='NON_RESPONSIVE') && 
                <Button
                    className="linkButton fontSize13 textCapitalize"
                    style={{paddingLeft:0}}
                    onClick={handleAgainCallStatus}    
                >
                    {row.callStatus==='CALL_NOT_RECIEVED'?"Call not received again"
                        :row.callStatus==='BUSY'?"Busy again"
                            :row.callStatus==='CALL_LATER'?"Call later again"
                                :row.callStatus==='INVALID_NUMBER'?"Invalid number again":"Switched off again"}
                </Button>
            }
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            
        </div>
    else if(columnId==="interviewMode"  && row.approvalStatus==="APPROVED")
        dropdown=<div className="index columnOrder">
            <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
                <InputLabel htmlFor={`${row.workerID} interviewMode`}>Interview mode</InputLabel>
            <Select
                id={`${row.workerID} interviewMode`}
                label="Interview mode"
                value={value}
                variant= "outlined"
                disableUnderline
                disabled={row.interviewMode===2 || row.interviewMode===3}
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
            >
                <MenuItem value={1}>Profile shared</MenuItem>
                <MenuItem value={2}>Telephonic</MenuItem>
                <MenuItem value={3}>Face to face</MenuItem>
                {(row.interviewMode===1 || row.interviewMode===4) && <MenuItem value={4} >Profile rejected</MenuItem>}
            </Select>
            </FormControl>
            {value===2  &&
                <Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <Grid item xs={12} className="padding0">
                            <KeyboardDatePicker
                                // margin="normal"
                                className="padding0 marginVertical20"
                                id="teleStartDate"
                                label="Start date"
                                inputVariant="outlined"
                                minDate={new Date()}
                                error={teleInterviewData.startDateError}
                                disabled={row.interviewMode===2 || row.interviewMode===3}
                                format="dd-MM-yy"
                                // inputVariant="outlined"
                                style={{height:"36px"}}
                                value={teleInterviewData.startDate}
                                onChange={date => handleInterviewData(date, 1)}
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                            />
                            <KeyboardDatePicker
                                // margin="normal"
                                className="padding0 marginVertical20"
                                id="teleEndDate"
                                label="End date"
                                error={teleInterviewData.endDateError}
                                inputVariant="outlined"
                                disabled={row.interviewMode===2 || row.interviewMode===3}
                                style={{height:"36px"}}
                                // inputVariant="outlined"
                                format="dd-MM-yy"
                                minDate={teleInterviewData.startDate?teleInterviewData.startDate:new Date()}
                                value={teleInterviewData.endDate}
                                onChange={date => handleInterviewData(date, 2)}
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    
                </MuiPickersUtilsProvider>
                </Grid>
            }
            {value===3 &&
                <Grid container>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <Grid item xs={12} className="padding0">
                            <KeyboardDatePicker
                                margin="normal"
                                id="interviewSlot3"
                                label="Interview date"
                                disabled={row.interviewMode===2 || row.interviewMode===3}
                                format="dd-MM-yyyy"
                                className="padding0 marginVertical20"
                                inputVariant="outlined"
                                value={f2fInterviewData.interviewDate}
                                minDate={new Date()}
                                error={f2fInterviewData.interviewDateError}
                                onChange={date => handleInterviewData(date, 3)}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="padding0" style={{display: "flex", justifyContent:"space-between"}} >
                            <KeyboardTimePicker
                                label={"Start time"}
                                className="padding0 marginVertical20"
                                error={f2fInterviewData.startTimeError}
                                inputVariant="outlined"
                                disabled={row.interviewMode===2 || row.interviewMode===3}
                                value={f2fInterviewData.startTime}
                                minDate={new Date()}
                                onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  4)}
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="padding0" style={{display: "flex", justifyContent:"space-between"}} >
                            <KeyboardTimePicker
                                label={"End time"}
                                inputVariant="outlined"
                                className="padding0 marginVertical20"
                                disabled={row.interviewMode===2 || row.interviewMode===3}
                                error={(moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm") || f2fInterviewData.endDateError)}
                                helperText={moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm") && "Enter interview end time after start time"}
                                value={f2fInterviewData.endTime}
                                minDate={f2fInterviewData.startTime?f2fInterviewData.startTime: new Date()}
                                onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  5)}
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    
                </MuiPickersUtilsProvider>
                </Grid>
            }
            {
                value===4 && 
                <Grid container className={Styles.rejectedBy}>
                    <span>Profile rejected by - {row.rejectedByRole==="admin"?"Admin":row.rejectedByRole==="employer"?"Employer":"other"}</span>
                </Grid>
            }
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleInterviewSchedule}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    else if(columnId==="approvalStatus" && row.callStatus==="INTERESTED"){
        dropdown=<div className="index">
            <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
            <InputLabel htmlFor="approvalStatus">Approval status</InputLabel>
                <Select
                id="approvalStatus"
                value={value}
                disableUnderline
                label="Approval status"
                variant= "outlined"
                // disabled={row.approvalStatus}
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value='APPROVED'>Approved</MenuItem>
                <MenuItem value='NOT_APPROVED'>Not approved</MenuItem>
            </Select>
            </FormControl>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="interviewFollowUp" && row.whichSlot!==0){
        dropdown=<div className="index">
        {/* {value==="RESCHEDULE" &&
        <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                    <Grid item xs={12} className="padding0">
                        <KeyboardDatePicker
                            margin="normal"
                            id="interviewSlot3"
                            label="Interview date"
                            format="dd-MM-yy"
                            value={teleInterviewData.startDate}
                            onChange={date => handleInterviewData(date, 1)}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            margin="normal"
                            id="interviewSlot3"
                            label="Interview date"
                            format="dd-MM-yy"
                            value={teleInterviewData.startDate}
                            onChange={date => handleInterviewData(date, 2)}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </Grid>   
            </MuiPickersUtilsProvider>
        </Grid>
    }
    {value==="RESCHEDULE" &&
        <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                    <Grid item xs={12} className="padding0">
                        <KeyboardDatePicker
                            margin="normal"
                            id="interviewSlot3"
                            label="Interview date"
                            format="dd-MM-yyyy"
                            value={f2fInterviewData.interivewDate}
                            onChange={date => handleInterviewData(date, 3)}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <KeyboardTimePicker
                            label={"Start time"}
                            value={f2fInterviewData.startTime}
                            onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  4)}
                        />
                        <KeyboardTimePicker
                            label={"End time"}
                            error={moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm")}
                            helperText={moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm") && "Enter interview end time after start time"}
                            value={f2fInterviewData.endTime}
                            onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  5)}
                        />
                    </Grid>
                </Grid>  
            </MuiPickersUtilsProvider>
        </Grid>
    } */}
        <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
        <InputLabel htmlFor="interviewFollowUp">Interview follow up</InputLabel>
            
            <Select
                id="interviewFollowUp"
                value={value}
                label="Interview follow up"
                variant= "outlined"
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value='GOING'>Going</MenuItem>
                <MenuItem value='NOT_GOING'>Not going</MenuItem>
                <MenuItem value='RESCHEDULE'>Reschedule</MenuItem>
                <MenuItem value='NO_RESPONSE'>No Response</MenuItem>
                {((row.interviewMode===2 && moment(row.from_date).format("DD/MM/YYYY") <=moment().format("DD/MM/YYYY")) || (row.interviewMode===3 && moment(row.interviewStartTime).format("DD/MM/YYYY") <=moment().format("DD/MM/YYYY")) ) && <MenuItem value='APPEARED'>Appeared</MenuItem>}
            </Select>
            </FormControl>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="recruiterFeedback" && (row.interviewFollowUp==="APPEARED" || row.interviewFollowUp==="GOING")){
        dropdown=<div className="index">
        <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
        <InputLabel htmlFor="recruiterFeedback">Recruiter feedback</InputLabel>
            
            <Select
                id="recruiterFeedback"
                value={value}
                disableUnderline
                label="Recruiter feedback"
                variant= "outlined"
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value='SELECTED'>Selected</MenuItem>
                <MenuItem value='REJECTED'>Rejected</MenuItem>
                <MenuItem value='ROUNDS_PENDING'>Rounds pending</MenuItem>
                <MenuItem value='RESULT_AWAITED'>Result awaited</MenuItem>
                <MenuItem value='CANDIDATE_DROPPED'>Candidate dropped</MenuItem>
                <MenuItem value='NOT_APPEARED'>Not appeared</MenuItem>
            </Select>
            </FormControl>
            {
                value==="SELECTED" && <Grid container>
                    <Grid item xs={12} className="padding0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {!(!selectionDate && row.recruiterFeedback==="SELECTED") && <Grid item xs={12} className="padding0">
                                <KeyboardDatePicker
                                    margin="normal"
                                    inputVariant="outlined"
                                    id="selectionDate"
                                    label="Selection date"
                                    format="dd-MM-yyyy"
                                    value={selectionDate}
                                    minDate={new Date()}
                                    className="margin16px0"
                                    onChange={date => setSelectionDate(date)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                    inputProps={{
                                        style:{
                                            height: "20px",
                                            paddingLeft:"10px"
                                        }
                                    }}
                                />
                            </Grid>}
                        
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {!(!joiningDate && row.recruiterFeedback==="SELECTED") && <Grid item xs={12} className="padding0">
                                <KeyboardDatePicker
                                    margin="normal"
                                    inputVariant="outlined"
                                    id="joiningDate"
                                    label="Joining date"
                                    format="dd-MM-yyyy"
                                    className="margin16px0"
                                    minDate={new Date()}
                                    value={joiningDate}
                                    onChange={date => setJoiningDate(date)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                    inputProps={{
                                        style:{
                                            height: "20px",
                                            paddingLeft:"10px"
                                        }
                                    }}
                                />
                            </Grid>}
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {!(!offeredSalary && row.recruiterFeedback==="SELECTED") && <Grid item xs={12} className="padding0" style={{display:"flex", justifyContent: "space-between"}}>
                        <Grid item xs={5} className="padding0">
                            <TextField
                                id="offeredSalary"
                                value={offeredSalary}
                                onChange={event => setOfferedSalary(event.target.value)}
                                placeholder="Offered salary"
                                className="margin16px0"
                                style={{width: "190px"}}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                                />
                        </Grid>
                        <Grid item xs={5} className="padding0">
                            <Select
                                id="salaryType"
                                variant="filled"
                                className={`margin16px0 ${classes.input} ${classes.iconFilled}`}
                                style={{padding: '0px',fontSize: '12px', width: '74px', right: '-6px'}}
                                inputProps={{
                                    style:{
                                        padding:'0'
                                    }
                                }}
                                value={offeredSalaryType}
                                disableUnderline
                                label="Salary type"
                                onChange={event  => setOfferedSalaryType(event.target.value)}
                            >
                                <MenuItem id="m" value={0} >Monthly</MenuItem>
                                <MenuItem id="y" value={1} >Yearly</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>}

                    
                </Grid>
            }
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleFeedbackConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="offerAccepted" && row.recruiterFeedback==="SELECTED"){
        dropdown=<div className="index">
        <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
        <InputLabel htmlFor="workerResponse">Worker response</InputLabel>
            
            <Select
                id="workerResponse"
                value={value}
                disableUnderline
                label="Worker response"
                variant= "outlined"
                // disabled={row.approvalStatus}
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value='ACCEPTED'>Accepted</MenuItem>
                <MenuItem value='REJECTED'>Rejected</MenuItem>
            </Select>
            </FormControl>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleOfferAccept}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    
    else if(columnId==="joiningStatus" && row.workerResponse===1){
        dropdown=
        <div>
        <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
        <InputLabel htmlFor="joiningStatus">Joining status</InputLabel>
            
            <Select
                id="joiningStatus"
                value={value}
                label="Joining status"
                placeholder="Joining status"
                    disableUnderline
                    onChange={onChange}
                    inputProps={{
                        classes: {
                            icon: "selectIcon",
                        },
                    }}
                >
                <MenuItem value='JOINED'>Joined</MenuItem>
                <MenuItem value='NOT_JOINING'>Not joining</MenuItem>
                <MenuItem value='POSTPONED'>Postponed</MenuItem>
                <MenuItem value='NO_RESPONSE'>No response</MenuItem>
                <MenuItem value='LEFT_AFTER_JOINING'>Left after joining</MenuItem>
            </Select>
            </FormControl>
            {
                (value==="JOINED"  && (!row.selectionDate || !row.joiningDate || !row.offeredSalary)) && <Grid container>
                    <Grid item xs={12} className="padding0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} className="padding0">
                                <KeyboardDatePicker
                                    margin="normal"
                                    // inputVariant="outlined"
                                    id="selectionDate"
                                    label="Selection date"
                                    format="dd-MM-yyyy"
                                    value={selectionDate}
                                    className="margin16px0"
                                    minDate={new Date()}
                                    onChange={date => setSelectionDate(date)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                    inputProps={{
                                        style:{
                                            height: "20px",
                                            paddingLeft:"10px"
                                        }
                                    }}
                                />
                            </Grid>
                        
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} className="padding0">
                                <KeyboardDatePicker
                                    margin="normal"
                                    // inputVariant="outlined"
                                    id="joiningDate"
                                    label="Joining date"
                                    format="dd-MM-yyyy"
                                    className="margin16px0"
                                    minDate={new Date()}
                                    value={joiningDate}
                                    maxDate={new Date()}
                                    onChange={date => setJoiningDate(date)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                    inputProps={{
                                        style:{
                                            height: "20px",
                                            paddingLeft:"10px"
                                        }
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} className="padding0" style={{display:"flex", justifyContent: "space-between"}}>
                        <Grid item xs={5} className="padding0">
                            <TextField
                                id="offeredSalary"
                                value={offeredSalary}
                                onChange={event => setOfferedSalary(event.target.value)}
                                placeholder="Offered salary"
                                className="margin16px0"
                                
                                style={{width: "190px"}}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    style:{
                                        height: "20px",
                                        paddingLeft:"10px"
                                    }
                                }}
                                />
                        </Grid>
                        <Grid item xs={5} className="padding0">
                            <Select
                                id="salaryType"
                                variant="filled"
                                className={`margin16px0 ${classes.input} ${classes.iconFilled}`}
                                style={{padding: '3px',fontSize: '12px', width: '74px'}}
                                inputProps={{
                                    style:{
                                        padding:'0'
                                    }
                                }}
                                value={offeredSalaryType}
                                disableUnderline
                                label="Salary type"
                                onChange={event  => setOfferedSalaryType(event.target.value)}
                            >
                                <MenuItem id="m" value={0} >Monthly</MenuItem>
                                <MenuItem id="y" value={1} >Yearly</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                    
                </Grid>
            }
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    

    return (
        <React.Fragment>
            {dropdown}
            {openPostonedModal && <PostModal    
                    row={row}
                    openPostponadModal={openPostonedModal}
                    setOpenPostPonadModal= {setOpenPostonedModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />

            }
            {
                rescheduleModal && <RescheduleModal
                    reModal = {rescheduleModal}
                    setReModal = {setRescheduleModal}
                    row={row}
                    showToast={showToast}
                    updateMyData={updateMyData}
                 />
            }
            {/* {openUpdateSlots && <InterviewModal
                interview={row}
                updateMyData={updateMyData}
                setLoading= {setLoading}
                showToast={showToast}
                type="interviewRescheduleStatus"
                interviewAddress={interviewAddress}
                openUpdateSlots={openUpdateSlots}
                setOpenUpdateSlots={setOpenUpdateSlots}
            />}
            {openSelectedStatus && <FeedbackModal
                statusData={statusData}
                setStatusData={setStatusData}
                openSelectedStatus={openSelectedStatus}
                setOpenSelectedStatus= {setOpenSelectedStatus}
                updateMyData={updateMyData}
                showToast={showToast}
                setLoading= {setLoading}
                interviewId={row.interviewId}
            />}
            {openJoinedStatus && <JoinedModal
                statusData={statusData}
                setStatusData={setStatusData}
                openJoinedStatus={openJoinedStatus}
                setOpenJoinedStatus= {setOpenJoinedStatus}
                updateMyData={updateMyData}
                showToast={showToast}
                setLoading= {setLoading}
                interivewId={row.interivewId}
                label={label}
            />} */} 
            {openRemarkModal && <DashboardRemarkModal
                openRemarkModal={openRemarkModal}
                setOpenRemarkModal={setOpenRemarkModal}
                handleCancel= {handleCancel}
                showToast={showToast}
                status={value}
                row={row}
                setShowButtons={setShowButtons}
                quesList={quesList}
            />}
            {
                approvalConfirmationModal && <ApprovalConfirmationModal
                    approvalConfirmationModal={approvalConfirmationModal}
                    setApprovalConfirmationModal={setApprovalConfirmationModal}
                    row={row}
                    handleCancel= {handleCancel}
                    updateStatus={updateStatus}
                    quesList={quesList}
                    status={value}
                    setShowButtons={setShowButtons}

                />
            }
            {
                openProfileRejectModal && <ProfileRejectModal 
                    open={openProfileRejectModal}
                    setOpen={setOpenProfileRejectModal}
                    type={columnId==="interviewMode"?"interviewMode":columnId==="employerFeedback"?"employerFeedback":""}
                    row={row}
                    jobDetails={jobDetails}
                    updateStatus={updateStatus}
                    showToast={showToast}
                />
            }

        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;
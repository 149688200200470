import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
const useStyles = {
  formControl: {
    margin: 1
  },
  selectEmpty: {
    marginTop: 2
  }
};

class SimpleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: this.props.ratingValue
    };
    this.option = this.props.option;
  }
  handleChange = event => {
    this.setState({ val: event.target.value }, () =>
      this.props.handleChange(this.state.val)
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel
            id='demo-simple-select-label'
            style={{ fontSize: '0.8rem' }}
          >
            {this.props.label}
          </InputLabel>
          <Select
            style={{ width: '200px' }}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={this.state.val}
            onChange={this.handleChange}
          >
            {this.option.map(option => (
              <MenuItem key={option.id} value={option.rating}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(useStyles)(SimpleSelect);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide,  Grid, TextField, Checkbox, RadioGroup, FormControl, FormControlLabel, InputLabel} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, FieldArray } from "formik";
import TextFieldWithDropdown from '../../utils/TextFieldWithDropDown/textFieldWithDropdown'
import Select from '../SelectComponent/Select'
import urlLinks from '../../urlLinks';
import * as moment from 'moment';
import Axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width : "800px",
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const WorkerExp =(props) =>{
    const {editIndex, openWorkerExpModal, setOpenWorkerExpModal,  values, setFieldValue, lastExp, newExp, Editing, locationList, editFlag, handleOtherExperiences, errors, touched} = props
    const classes = useStyles();
    const [jobTypeList, setJobTypeList] = useState([])
    const [jobRoleList, setJobRoleList] = useState([])
    const [errorText, setErrorText] = useState("")
    const [industryList, setIndustryList] = useState([])
    const [savedExp, setSavedExp] = useState()
    const [editableExp, setEditableExp] = useState({})
    console.log({props})
    const handleWorkerExpModalClose =() =>{
        setOpenWorkerExpModal(false)
    }

    const makeJobTypeData = (data) =>{
        const result=[]
        data.map(el =>
            result.push({
                id: el.id,
                name: el.typeDesc,
                typeValue: el.typeValue
            })
        )
        return result;
    }
    const makeJobRoleData = (data) =>{
        const result=[]
        data.map(el =>
            result.push({
                id: el.id,
                name: el.categorySubType
            })
        )
        return result;
    }
    const makeIndustryData = (data) =>{
        const result=[]
        data.map(el =>
            result.push({
                id: el.industry_id,
                name: el.industry_name
            })
        )
        return result;
    }
    useEffect(() => {
        if(lastExp){
            if(!values?.lastExperience?.jobStartDate)
                setFieldValue(`lastExperience.jobStartDate`, null)
            if(!values?.lastExperience?.jobEndDate)
                setFieldValue(`lastExperience.jobEndDate`, null)
            setSavedExp(values.lastExperience)
        }
        else if(editIndex>=0){
            setSavedExp(values.experienceList)
            setEditableExp(values.experienceList[editIndex])
        }

        if(Editing){

        }
        
        Axios.get(`${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&inserted_by=0&rows=20&status=1`)
            .then(response => {
                setJobTypeList(makeJobTypeData(response.data.response.content)) ;
            });
        Axios.get(`${urlLinks.baseUrl}/admin/getIndustryMaster`)
            .then(response => {
                setIndustryList(makeIndustryData(response.data.response)) ;
            });
    }, [])
    const handleJobType = (value) =>{
        
        Axios.get(`${urlLinks.baseUrl}/configmaster/inquery?inquery=${value.typeValue}`)
        .then(response => {
          setJobRoleList(makeJobRoleData(response.data.response.content)) ;  
        });
        
    }
    const handleCancelExp =()=>{
        if(editIndex>=0){
            setEditableExp({})
        }
        else if(lastExp){
            setFieldValue("lastExperience", savedExp)
        }
        handleWorkerExpModalClose();
    }
    const handleNewAddExp =() =>{
        if(newExp){
            let otherExpList= values.experienceList
            otherExpList.push(values.newExp)
            setFieldValue("experienceList", otherExpList)
            setFieldValue("newExp", {})
            handleWorkerExpModalClose();
        }
        else if (lastExp){
            
                setFieldValue("lastExperienceView", values.lastExperience)
                handleWorkerExpModalClose();
            
        }
        else{
            let temp= savedExp;
            savedExp[editIndex]=editableExp
            setSavedExp(temp)
            console.log({temp})
            setFieldValue(`experienceList.${editIndex}`, editableExp)
            // handleOtherExperiences(savedExp);
            handleWorkerExpModalClose();
        }

    }
    console.log({editableExp, editIndex, lastExp, newExp})
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openWorkerExpModal}
            onClose={handleWorkerExpModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Slide direction="up" in={openWorkerExpModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    {((editIndex>=0 && editableExp) || lastExp || newExp) && <Grid container>
                        <Grid item xs={12} className="padding0">
                            <span className="fontSize24 textUnderline fontWeightBold">{lastExp?"Last/ current job": "Other experience"}</span>
                        </Grid>
                        <Grid container className="padding0 ">
                            <Grid item xs={12} sm={6} className="padding0 "> 
                                <Autocomplete
                                    id={lastExp?"lastExperience.industry":newExp?"newExp.industry":`editableExp.industry`}
                                    options={industryList.sort((a, b) => -b.name.localeCompare(a.name))}
                                    groupBy={(option) => option.name[0]}
                                    value ={lastExp?values.lastExperience.industry:newExp?values.newExp.industry:editableExp.industry}
                                    name={lastExp?`lastExperience.industry`:newExp?`newExp.industry`:`editableExp.industry`}
                                    fullWidth ={true}
                                    onChange={(event, value) =>{
                                        console.log(event.target.name)
                                        if(lastExp)
                                            setFieldValue(`lastExperience.industry`, value)
                                        else if(newExp)
                                            setFieldValue(`newExp.industry`, value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, industry: value})
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} variant = "outlined"  label="Industry" className="marginLeft-0 margin20 jobPopupInput" />}
                                />     
                            </Grid>
                            <Grid item xs={12} sm={6} className="padding0 paddingLeft20only">
                                <Field
                                    name={lastExp?"lastExperience.company":newExp?"newExp.company":`editableExp.company`}
                                    label="Company"
                                    variant= "outlined"
                                    fullWidth={true}
                                    value ={lastExp?values.lastExperience.company:newExp?values.newExp.company:editableExp.company}
                                    onChange={ (event) =>{
                                        if(lastExp)
                                            setFieldValue(`lastExperience.company`, event.target.value)
                                        else if(newExp)
                                            setFieldValue(`newExp.company`, event.target.value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, company: event.target.value})
                                    }}
                                    as={TextField}
                                    className="margin20 jobPopupInput"
                                />       
                            </Grid>
                        </Grid>
                        {((values?.lastExperience?.industry?.id===15) || (values?.newExp?.industry?.id===15) || (editableExp.id===15)) && <Grid container className="padding0 columnOrder">
                            <Grid item xs={12} sm={6} className="padding0">
                                <Field
                                    name={lastExp?"lastExperience.industryOther":newExp?"newExp.industryOther":`editableExp.industryOther`}
                                    label="Other industry"
                                    variant= "outlined"
                                    className="marginLeft-0"
                                    fullWidth={true}
                                    value ={lastExp?values.lastExperience.industryOther:newExp?values.newExp.industryOther:editableExp.industryOther}
                                    onChange={ (event) =>{
                                        if(lastExp)
                                            setFieldValue(`lastExperience.industryOther`, event.target.value)
                                        else if(newExp)
                                            setFieldValue(`newExp.industryOther`, event.target.value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, industryOther: event.target.value})
                                    }}
                                    as={TextField}
                                    className="margin20 jobPopupInput"
                                />       
                            </Grid>
                        </Grid>}
                        <Grid container className="padding0">
                            <Grid item xs={12} sm={6} className="padding0">     
                                <Autocomplete
                                    id={lastExp?"lastExperience.job_category":newExp?"newExp.job_category":`editableExp.job_category`}
                                    options={jobTypeList.sort((a, b) => -b.name.localeCompare(a.name))}
                                    groupBy={(option) => option.name[0]}
                                    value ={lastExp?values.lastExperience.job_category:newExp?values.newExp.job_category:editableExp.job_category}
                                    name={lastExp?`lastExperience.job_category`:newExp?`newExp.job_category`:`editableExp.job_category`}
                                    fullWidth ={true}
                                    onChange={(event, value) =>{
                                        console.log(event.target.name)
                                        handleJobType(value);
                                        if(lastExp)
                                            setFieldValue(`lastExperience.job_category`, value)
                                        else if(newExp)
                                            setFieldValue(`newExp.job_category`, value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, job_category: value})
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    // style={{ width: "250px" }}
                                    renderInput={(params) => <TextField {...params} variant = "outlined"  label="Job category" className="marginLeft-0 margin20 jobPopupInput" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="paddingLeft20only">
                                <Autocomplete
                                    id={lastExp?"lastExperience.job_role":newExp?"newExp.job_role":`editableExp.job_role`}
                                    options={jobRoleList.sort((a, b) => -b.name.localeCompare(a.name))}
                                    groupBy={(option) => option.name[0]}
                                    value ={lastExp?values.lastExperience.job_role:newExp?values.newExp.job_role:editableExp.job_role}
                                    name={lastExp?`lastExperience.job_role`:newExp?`newExp.job_role`:`editableExp.job_role`}
                                    fullWidth ={true}
                                    onChange={(event, value) =>{
                                        console.log(event.target.name)
                                        handleJobType(value);
                                        if(lastExp)
                                            setFieldValue(`lastExperience.job_role`, value)
                                        else if(newExp)
                                            setFieldValue(`newExp.job_role`, value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, job_role: value})
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    // style={{ width: "250px" }}
                                    renderInput={(params) => <TextField {...params}  variant = "outlined" label="Job role" className="marginLeft-0 margin20 jobPopupInput" />}
                                />    
                            </Grid>
                        </Grid>
                        <Grid container className="padding0 ">
                            <Grid item xs={12} sm={6} className="padding0">
                                <Field
                                    name={lastExp?"lastExperience.designation":newExp?"newExp.designation":`editableExp.designation`}
                                    label="Designation"
                                    variant= "outlined"
                                    className="marginLeft-0 margin20 jobPopupInput"
                                    value ={lastExp?values.lastExperience.designation:newExp?values.newExp.designation:editableExp.designation}
                                    fullWidth={true}
                                    onChange={ (event) =>{
                                        if(lastExp)
                                            setFieldValue(`lastExperience.designation`, event.target.value)
                                        else if(newExp)
                                            setFieldValue(`newExp.designation`, event.target.value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, designation: event.target.value})
                                    }}
                                    as={TextField}
                                    // error={touched.pincode && errors.pincode}
                                    // helperText={touched.pincode && errors.pincode}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} className="padding0 paddingLeft20only">
                                <Field
                                    name={lastExp?"lastExperience.city":newExp?"newExp.city":`editableExp.city`}
                                    label="city"
                                    variant= "outlined"
                                    fullWidth={true}
                                    value ={lastExp?values.lastExperience.city:newExp?values.newExp.city:editableExp.city}
                                    onChange={ (event) =>{
                                        if(lastExp)
                                            setFieldValue(`lastExperience.city`, event.target.value)
                                        else if(newExp)
                                            setFieldValue(`newExp.city`, event.target.value)
                                        else if(editIndex>=0)
                                            setEditableExp({...editableExp, city: event.target.value})
                                    }}
                                    as={TextField}
                                    className="margin20 jobPopupInput"
                                    // error={touched.pincode && errors.pincode}
                                    // helperText={touched.pincode && errors.pincode}
                                />       
                            </Grid>
                        </Grid>
                        <Grid container className="padding0 ">
                            {/* <Grid item xs={12} sm={6} className="padding0 rowOrder flexWrap">
                                <Grid item xs={6}  className="padding0">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Job start date *"
                                            className="marginLeft-0"
                                            format="dd/MM/yyyy"
                                            name={lastExp?"lastExperience.jobStartDate":newExp?"newExp.jobStartDate":`editableExp.jobStartDate`}
                                            value ={lastExp?values.lastExperience.jobStartDate:newExp?values.newExp.jobStartDate:editableExp.jobStartDate}
                                            onChange={ (date) =>{
                                                if(lastExp)
                                                    setFieldValue(`lastExperience.jobStartDate`, moment(date).add(30, 'minutes').startOf('hour'))
                                                else if(newExp)
                                                    setFieldValue(`newExp.jobStartDate`, moment(date).add(30, 'minutes').startOf('hour'))
                                                else if(editIndex>=0)
                                                    setEditableExp({...editableExp, jobStartDate: moment(date).add(30, 'minutes').startOf('hour')})
                                            }}
                                            // error={touched.startDate && errors.startDate}
                                            // helperText={touched.startDate && errors.startDate}
                                        />  
                                    </MuiPickersUtilsProvider>    
                                </Grid>     
                                <Grid item xs={6}  className="padding0">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Job end date *"
                                            format="dd/MM/yyyy"
                                            name={lastExp?"lastExperience.jobEndDate":newExp?"newExp.jobEndDate":`editableExp.jobEndDate`}
                                            value ={lastExp?values.lastExperience.jobEndDate:newExp?values.newExp.jobEndDate:editableExp.jobEndDate}
                                            onChange={ (date) =>{
                                                setFieldValue("currentlyWorking", false)
                                                if(lastExp)
                                                    setFieldValue(`lastExperience.jobEndDate`, moment(date).add(30, 'minutes').startOf('hour'))
                                                else if(newExp)
                                                    setFieldValue(`newExp.jobEndDate`, moment(date).add(30, 'minutes').startOf('hour'))
                                                else if(editIndex>=0)
                                                    setEditableExp({...editableExp, jobEndDate: moment(date).add(30, 'minutes').startOf('hour')})
                                            }}
                                            // error={touched.startDate && errors.startDate}
                                            // helperText={touched.startDate && errors.startDate}
                                        />  
                                    </MuiPickersUtilsProvider>   
                                </Grid>     
                            </Grid> */}
                            {lastExp && <Grid item xs={6}  className="padding0" style={{alignSelf: "center"}}>
                                <FormControlLabel
                                    control={
                                        <Field 
                                            checked={values.currentlyWorking} 
                                            onChange={ (event) =>{
                                                setFieldValue('currentlyWorking', event.target.checked);
                                            }}
                                            name="currentlyWorking" 
                                            component={Checkbox}
                                            color="primary"
                                        />
                                    }
                                    label="Currently works here"
                                />     
                            </Grid>} 
                            {lastExp && <Grid item xs={12} sm={6} className="padding0 paddingLeft20only">
                                <Field
                                    name="notice_period"
                                    label="Notice period (in days)"
                                    variant= "outlined"
                                    className="marginLeft-0"
                                    fullWidth={true}
                                    as={TextField}
                                    className="margin20 jobPopupInput"
                                    // error={touched.notice_period && errors.notice_period}
                                /> 
                                {/* errors.notice_period && <span style={{color:"red"}}>Enter numbers only</span> */}
                            </Grid>}
                        </Grid>
                        {lastExp && <Grid item xs={12} className="padding0">
                            <Grid item xs={12} sm={6} className="padding0 marginVertical20">
                                <Field
                                    name="salary"
                                    label="Salary"
                                    variant= "outlined"
                                    nameText="last_salary"
                                    nameSelect= "last_salary_type"
                                    error={errors.last_salary}
                                    text={values.last_salary}
                                    select = {values.last_salary_type}
                                    setFieldValue= {setFieldValue}
                                    fullWidth={true}
                                    as={TextFieldWithDropdown}
                                    className="margin20 jobPopupInput"

                                /> 
                                {errors.last_salary && <span style={{color:"red"}}>Enter salary as numeric value</span>}       
                            </Grid>
                        </Grid>}
                        <Grid
                            container
                            direction="row-reverse"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Button
                                className="buttonLink"
                                onClick={handleCancelExp}
                                >
                                cancel
                            </Button>
                            <Button
                                className="buttonLink"
                                onClick={handleNewAddExp}
                                disabled={errors.last_salary || errors.notice_period}
                                >
                                {Editing?"save changes":"Add Experience"}
                            </Button>
                        </Grid>
                    </Grid>}
                </div>
            </Slide>
        </Modal>
    )
}

export default WorkerExp;
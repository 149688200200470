import React, { useState, useEffect, useMemo , useCallback} from 'react'
import { useTable, useRowSelect, useFilters, useSortBy } from 'react-table'
import { Checkbox, TableSortLabel, Grid} from '@material-ui/core';
import styled from 'styled-components'
import ScaleLoader from "react-spinners/ScaleLoader";
import './tables.css'
import WorkerModal from '../Modals/workerModal'
import Pagination from './pagination'
import AssignToJobModal from  '../Modals/assignToJobModal'
import AssignToRecruiterModal from '../Modals/assignToRecruiter'
import ColumnModal from './columnModal'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:700px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`

const ReactTable =(props) =>{
    const {columns, data, perPage, currentPage, totalRows, showToast, updateMyData, totalPages,jobList, openAssignJobModal, jobValue,  setOpenAssignJobModal, openAssignRecruiterModal, setOpenAssignRecruiterModal, handlePageChange, openColumnModal, setSelectedRows, setOpenColumnModal, handlePerRowsChange, loading, setLoading, setRowIds}= props
    const [openWorkerModal, setOpenWorkerModal] = useState(false)
    const [userIdList, setUserIdList] = useState([])
    const [hiddenCols, setHiddenCols] = useState([])
    const [toggleAllColumn, setToggleAllColumn] = useState(true)
    
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: {hiddenColumns,  selectedRowIds, sortBy}} = useTable({columns, data, manualSortBy: true }, useFilters, useSortBy, useRowSelect)
    // const columnModalData = useMemo(() =>
    // <div className="columnList">
    //         <div>
    //             <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
    //             All
    //         </div>
    //         {allColumns.map(column => (
    //             column.id==="selection"?null:
    //             <div key={column.id}>
    //                 <label>
    //                 <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
    //                     {column.Header}
    //                 </label>
    //                 </div>
    //         ))}
    //     </div>, [hiddenColumns])
    const handleColumnsModalClose =() =>{
        setOpenColumnModal(false);
        setHiddenCols(hiddenColumns)
    }
    useEffect(() => {
        if(!toggleAllColumn && hiddenColumns.length===0)
            setHiddenColumns(hiddenCols)
    }, [hiddenColumns])
    const idToUserId =() =>{
        console.log({selectedRowIds});
        const indexes= Object.keys(selectedRowIds)
        let idList=[]
        indexes.map(id => {
            idList.push(data[id].userId)
        })
        
        console.log(idList.toString())
        return idList;
    }
    const table =<div className="tableContainer">
                    {data.length>0?
                        <table {...getTableProps()}  className="table">
                            <thead className="thead">
                                {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (  
                                    <th {...column.getHeaderProps()} 
                                        className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                            (props.table==="internalWorker" && column.render('Header')==="Name")?"stickWorkerNameMatchNew zIndex1080 header":
                                                column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                                <span {...column.getSortByToggleProps()}>
                                                    {column.id !== 'selection' ? 
                                                    <div>
                                                        {column.render('Header')}
                                                        <TableSortLabel
                                                            active={column.isSorted}
                                                            direction={column.isSortedDesc ? 'asc' : 'desc'}
                                                        />
                                                    </div> : column.render('Header')}
                                                </span>
                                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                                    ))}
                                </tr>
                                ))}
                            </thead>
                            {
                                loading?
                                    <div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
                                    <ScaleLoader
                                        height={70}
                                        width={10}
                                        color={"#1c75bc"}    
                                    />
                                    </div>:
                                    <tbody {...getTableBodyProps()}> 
                                        {rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="row">
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}  
                                                    className={cell.column.Header==="Worker Id"?"stickWorkerIdnewMatch":
                                                    (props.table==="internalWorker" &&  cell.column.Header==="Name")?"stickWorkerNameMatchNew":
                                                        cell.column.id==="selection"?"stickCheckbox":"cell"}>{cell.render('Cell')}</td>
                                            })}
                                            </tr>
                                        )
                                        })}
                                    </tbody>
                            }
                        </table>
                        : null}
                    </div>
    useEffect(() => {
        let userIdList= []
        if(openAssignJobModal || openAssignRecruiterModal)
            setUserIdList(idToUserId())
    }, [openAssignJobModal, openAssignRecruiterModal])
    useEffect(() => {
        if(selectedRowIds && setSelectedRows)
            setSelectedRows(Object.entries(selectedRowIds).length)
        if(setRowIds)
            setRowIds(Object.entries(selectedRowIds))
    }, [selectedRowIds])
    return (
        <Styles>
            <Grid container className="padding0">
                <Grid item xs={12}  style={{padding:0}}>
                    {table}
                </Grid>
            </Grid>
                
            {openWorkerModal?<WorkerModal
                openWorkerModal={openWorkerModal}
                setOpenWorkerModal={setOpenWorkerModal}
                isWorkerHistory={false}
            />: null}
            {props.isPagination && <Pagination 
                rowsperpage={perPage}
                pageNo={currentPage}
                totalElements={totalRows}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
            />}
            {openAssignJobModal?<AssignToJobModal
                openAssignJobModal={openAssignJobModal}
                setOpenAssignJobModal={setOpenAssignJobModal}
                selectedRowIds={selectedRowIds}
                jobList={jobList}
                userIdList={userIdList}
                toggleAllRowsSelected= {toggleAllRowsSelected}
                showToast={showToast}
            />:null}
            {openAssignRecruiterModal?<AssignToRecruiterModal
                openAssignRecruiterModal={openAssignRecruiterModal}
                setOpenAssignRecruiterModal={setOpenAssignRecruiterModal}
                selectedRowIds={selectedRowIds}
                updateMyData= {updateMyData}
                toggleAllRowsSelected={toggleAllRowsSelected}
                showToast={showToast}
                userIdList={userIdList}
                currentJobId= {jobValue.jobId}
            />:null}
            {openColumnModal?<ColumnModal
                allColumns={allColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                openColumnModal={openColumnModal}
                hiddenColumns={hiddenColumns}
                setToggleAllColumn={setToggleAllColumn}
                handleColumnsModalClose={handleColumnsModalClose}
            />:null}
        </Styles>
    )
}
export default ReactTable;
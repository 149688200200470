import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../urlLinks';

import Axios from 'axios';
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const AssignToRecruiterModal =(props) =>{
    const {showToast, openAssignRecruiterModal, setOpenAssignRecruiterModal, updateMyData, toggleAllRowsSelected, userIdList, currentJobId} = props
    const classes = useStyles();
    
    const [recruiterList, setRecruiterList] = useState()
    const [recruiterData, setRecruiterData] = useState()
    console.log({props})
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                setRecruiterList(response.data.response)
            })
    }, [])
    const handleAssignCandidateToRecruiter =() =>{
        const postData={
            recruiter_id: recruiterData.userID,
            user_ids: userIdList.toString(),
            job_id:currentJobId,
            inserted_by: localStorage.getItem("userId")
        }
        console.log({postData})
        Axios.post(`${urlLinks.baseUrl}extWorker/assignExtToRecruiter`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){
                    showToast("success", "Recruiter assigned successfully")
                    updateMyData();
                    toggleAllRowsSelected(false)
                }
                else{
                    showToast("error", "Recruiter inside assignment failed")
                }
            })
            .catch(error =>{
                console.log(error, "error")
                showToast("error", "Recruiter assignment failed")
            })
    }
    const handleAssignVacancyToRecruiter =() =>{
        const postData={
            recruiter_id: recruiterData.userID,
            job_id:currentJobId, 
            inserted_by: parseInt(localStorage.getItem("userId"))
        }
        Axios.post(`${urlLinks.baseUrl}extWorker/assignJobToRecruiter`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){

                    showToast("success", "Recruiter assigned successfully")
                }
                else{
                    showToast("error", "Recruiter inside assignment failed")
                }
            })
            .catch(error =>{
                console.log(error, "error")
                showToast("error", "Recruiter assignment failed")
            })
    }
    const confirmAssignRecruiter =() =>{
        
        if(props.toVacancy)
            handleAssignVacancyToRecruiter();
        else 
            handleAssignCandidateToRecruiter();
        setOpenAssignRecruiterModal(false)
    }

    const handleAssignJobClose =() =>{
        setOpenAssignRecruiterModal(false)
    }
    return (
        <Modal
            className={classes.modal}
            open={openAssignRecruiterModal}
            onClose={handleAssignJobClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openAssignRecruiterModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    <Autocomplete
                        id="jobList"
                        options={recruiterList}
                        value={recruiterData}
                        onChange={(event, value) => setRecruiterData(value)}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName?option.lastName:''}`
                            }
                        style={{ width: "450px" }}
                        renderInput={(params) => <TextField {...params} label="Select a Recruiter" />}
                    />
                    {!recruiterData && <span style={{color: "red"}}>Select one recruiter</span>}
                    {!currentJobId && <span style={{color: "red"}}>Select one vacancy</span>}
                    <Button color="primary" disabled={(!recruiterData && !currentJobId)} onClick={confirmAssignRecruiter} >Confirm</Button>
                    <Button color="secondary" onClick={handleAssignJobClose} >Cancel</Button>
                </div>
            </Slide>
        </Modal>
    )
}

export default AssignToRecruiterModal;
import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';

class BackButton extends Component {
  static contextTypes = {
    router: () => true // replace with PropTypes.object if you use them
  };

  render() {
    return (
      <IconButton
        aria-label='delete'
        onClick={this.props.history.goBack}
        style={{ marginRight: '10px', width: '50px', height: '50px' }}
        size='medium'
      >
        <ArrowBackIosIcon fontSize='inherit' />
      </IconButton>
    );
  }
}

export default withRouter(BackButton);

import React, { useState, useEffect } from 'react'
import {Grid, Button, TextField} from '@material-ui/core'
import Axios from 'axios'
import urlLinks from '../../../urlLinks';
import Note from './note'
import qs from 'querystring';

const txtField = {
    borderRadius: '9px',
    border: 'solid 1px #cccccc',
    backgroundColor: '#ffffff',
},
postBtn = {
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#ffffff',
    fontWeight: 'bold',
    margin: '12px'
},
cancelBtn = {
    color: '#1c75bc',
    fontWeight: 'bold',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#f9f9f9',
    margin: '12px'
};

const Notes =(props) =>{
    const {row, showToast, getAllReminders} =props
    const [notesList, setNotesList] = useState()
    const [valueError, setValueError] = useState("")
    const [value, setValue] = useState("")
    const makeNotesList =(data) =>{
        let list=[]
        data.map(note =>{
            let temp=<Note data={note} row={row} showToast={showToast}  getAllReminders={getAllReminders} />
            list.push(temp)
        })
        setNotesList(list)
    }
    const getNotesList =() =>{
        Axios.get(`${urlLinks.baseUrl}recruiter/getNotesTimeLine?jobId=${row.original.jobId}&recruiterId=${row.original.recruiterId}&userId=${row.original.userId}`)
            .then(response =>{
                makeNotesList(response.data.response);
            })
    }
    // console.log({row})
    const handleNewPost =() =>{
        if(value){
            setValueError("")
            let postData={
                insertedBy:localStorage.getItem("userId"),
                jobId: row.original.jobId,
                // jobId: 13,
                note:value,
                recruiterId : row.original.recruiterId, 
                // recruiterId : 300, 
                userId: row.original.userId
                // userId: 300
            }
            Axios.post(`${urlLinks.baseUrl}recruiter/addEditNote`, qs.stringify(postData))
            .then(response =>{
                if (response.data.code === 1000) {
                    setValue("")
                    getNotesList()
                } else {
                    showToast("error", "Unable to retrieve Notes.")
                }
            })
        }
        else{
            setValueError("Enter Notes")
        }
    }
    
    useEffect(() => {
        getNotesList();
    }, [])

    return (
        <Grid container style={{width:"556px",padding: "0 15px"}}>
            <Grid item xs={12} className="padding0" style={{marginTop:"20px"}}>
                <TextField
                    // style={txtField}
                    multiline
                    rows={8}
                    fullWidth   
                    className="margin0"
                    variant= "outlined"
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                />
            </Grid>
            <Grid item xs={12} className="padding0">
                <Button
                    style={postBtn}
                    onClick={handleNewPost}
                >
                    Post
                </Button>
                <Button
                    style={cancelBtn}
                    onClick={() => setValue("")}
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={12} className="padding0">
                <span style={{color: "red"}}>{valueError}</span>
            </Grid>
            {notesList}
        </Grid>
    )
}

export default Notes;
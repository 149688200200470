import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius:0,
    width: 150,
    justifyContent: "space-between"
  },
}));

export default function ChipsArray(props) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState(props.chips?props.chips:[]);

  const handleDelete = (chipToDelete) => () => {
    let remainingChips= chipData.filter((chip) => chip.id !== chipToDelete.id)
    setChipData(remainingChips);
    props.setFieldValue([props.name], remainingChips)
  };

  return (
    <Grid component="ul" className={classes.root}>
      {chipData.map((data) => {
        let icon;

        // if (data.name === 'React') {
        //   icon = <TagFacesIcon />;
        // }

        return (
          <li id={data.id}>
            <Chip
              icon={icon}
              label={data.name}
              onDelete={handleDelete(data)}
              deleteIcon={<CloseIcon />}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Grid>
  );
}

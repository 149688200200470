import React, {useState, useEffect} from "react"
import { Grid, Button, Modal, Backdrop, Select, MenuItem , FormControl, TextField} from "@material-ui/core"
import Axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import user from '../../assets/user-regular.png'
import urlLinks from '../../urlLinks'
import * as moment from "moment"

const flexDisplay = {
    margin: 'auto',
  },
  noteBorder = {
    width: '100%',
    border: 'solid 1px #e8e8e8',
    borderRadius: '2px',
    padding: '8px 12px',
    whiteSpace: "pre-line"
  },
  titleBorder = {
    width: '297px',
    border: 'solid 1px #e8e8e8',
    borderRadius: '2px',
    padding: '10px 27px 20px 27px'
  },
  Selected = {
    width: '297px',
    height: '35px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    padding: '12px'
  },
  itemsMenu = {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    textAlign: 'left',
  },
  ipNo = {
    width: '149px',
    height: '35px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    margin: '0 25px'
  },
  ipEmail = {
    width: '297px',
    height: '35px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
  },
  topMargin = {
    margin: '15px 0'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    margin: '20px 10px 10px 10px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right',
    margin: '20px 10px 10px 10px'
  },
  noteFtn = {
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      width: '660px'
    },
    select:{
      "& .MuiOutlinedInput-inputMarginDense":{
          padding: "8px 10px"
      }
    }
  }));


const ShareApp = (props) => {
  const classes = useStyles()
  const {shareModal, setShareModal, userData, showToast} = props
  const [templateText, setTemplateText] = useState("Download app and apply for job")
  const [template, setTemplate] = useState("") 
  const [lastMessage, setLastMessage] = useState( )
  const [modalData, setModalData] = useState()
  const [dropDown, setDropDown] = useState("email")
  const [email, setEmail] = useState(userData.email)
  const [errors, setErrors] = useState({
    emailError: false,
    numberError: false
  })
  const [number, setNumber] = useState(userData.whatsappNumber)
  const validateEmail =(email) =>{
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      return reg.test(email)
    }
    
  const handleModal = () => {
    setShareModal(false)
  }
  const handleLastMessage = () =>{
    if(userData.joiningStatus==='LEFT_AFTER_JOINING'){
      setLastMessage("Left after joining")
    }
    else if(userData.joiningStatus==='JOINED'){
        setLastMessage("Job offer details")
    }
    else if(userData.recruiterFeedback==='SELECTED' && userData.offeredSalary){
        setLastMessage("Job offer details")
    }
    else if(userData.recruiterFeedback==='REJECTED'){
        setLastMessage("Rejected for job")
    }
    else if(userData.interviewFollowUp==='CANDIDATE_DROPPED'){
        setLastMessage("Candidate dropped")
    }
    else if(userData.interviewMode===4){
        setLastMessage("Profile rejected")
    }
    else if(userData.interviewMode===1){
        setLastMessage("Profile shared")
    }
    else if(userData.approvalStatus==="NOT_APPROVED"){
        setLastMessage("Candidate not approved")
    }
    else if(userData.callStatus==="NOT_INTERESTED"){
        setLastMessage("Not interested")
    }
    else if(userData.callStatus!=="INTERESTED" && userData.callStatus!=="NOT_INTERESTED"){
        setLastMessage("Call status")
    }
    else if(userData.assignId){
        setLastMessage("Assigned to Job")
    }


    else if(userData.callStatus!=="INTERESTED" && userData.callStatus!=="NOT_INTERESTED"){
      setLastMessage("Call status")
    }
    else if(userData.interviewFollowUp!="Appeared"){
        setLastMessage("Assigned to Job")
    }
    else 
      setLastMessage("Downlaod app and apply for job")
  }
  useEffect(() => {
    handleLastMessage()
  }, [])
  const handleShareApp = () => {
    if(dropDown && template) {
        Axios.get(`${urlLinks.baseUrl}recruiter/getShareAppInfo?jobId=${userData.jobId}&shareVia=${dropDown==="email"?"EMAIL":"WHATSAPP"}&userId=${userData.userId}&workerId=${userData.workerId}&template=${template}`)
              .then(response => {
                
                  setModalData(response.data.response)
              })
    }
  }
  useEffect(() => {
    handleShareApp();
  }, [template, dropDown])
  const handleConfirmModal =() =>{
    if (userData!=null && template) {
      if(dropDown==="whatsapp"){
        if(number){

          Axios.post(`${urlLinks.baseUrl}whatsapp/sendMessage?job_id=${userData.jobId}&phone_number=${number}&template_name=${template}&user_id=${userData.userId}&worker_id=${userData.workerId}&inserted_by=${localStorage.getItem("userId")}`)
          .then( response => {
            if(response.data.code===1000){
              showToast("success",`WhatsApp Trigger Generated Successfully For : ${userData.name}`)
              setShareModal(false)
            }else{
              showToast("error",response.message);
              setShareModal(false)
            }
            
            // this.getHiredWorkerList()
          })
          .catch(error => {
            showToast("error",error.message)
            setShareModal(false)
          });
        }
        else{
          setErrors({...errors, numberError:true})
        }
       }
       else if(dropDown==="email"){
         if(email){
           Axios.post(`${urlLinks.baseUrl}extWorker/sendEmail?job_id=${userData.jobId}&email=${email}&template_name=${template}&user_id=${userData.userId}&worker_id=${userData.workerId}&inserted_by=${localStorage.getItem("userId")}`)
           .then( response => {
             if(response.data.code===1000){
               showToast("success",`Email sent Successfully For : ${userData.name}`)
               setShareModal(false)
              }else{
                showToast("success","Email sent successfully");
                setShareModal(false)
              }
              
              
              // this.getHiredWorkerList()
            })
            .catch(error => {showToast("error",error.message); setShareModal(false)});
          }
          else{
            setErrors({...errors, emailError: true})
          }
        } 
      } 
   else {
       showToast("error",`You are not on right stage To Generate WhatsApp Trigger`);
   }
  }
  const handleEmail =(e) =>{
    if(validateEmail(e.target.value)){
      setErrors({...errors, emailError: false}); 
      setEmail(e.target.value)
    }
    else
    {
      setErrors({...errors, emailError: true}); 
      setEmail(e.target.value)
    }
  }
  const handleWhatsapp =(e) =>{
    if(e.target.value.length!==10 || !(/(\d{10})$/.test(e.target.value))){
      setErrors({...errors, numberError: true}); 
      setNumber(e.target.value)
    }
    else
    {
      setErrors({...errors, numberError: false}); 
      setNumber(e.target.value)
    }
  }
  useEffect(() => {
    if(userData.interviewFollowUp!=='APPEARED' && userData.interviewMode===2){
        setTemplate("interview_reminder_tele")
        setTemplateText("Interview reminder")
    }
    else if(userData.interviewFollowUp!=='APPEARED' && userData.interviewMode===3){
      setTemplate("interview_reminder_f2f")
      setTemplateText("Interview reminder")
  }
    // else if(userData.joiningStatus==='JOINED'){
    //     setTemplate("job_joined")
    //     setTemplateText("Job offer details")
    // }
    // else if(userData.recruiterFeedback==='SELECTED' && userData.offeredSalary){
    //     setTemplate("job_offer_details")
    //     setTemplateText("Job offer details")
    // }
    // else if(userData.recruiterFeedback==='REJECTED'){
    //     setTemplate("rejected")
    //     setTemplateText("Rejected for job")
    // }
    // else if(userData.interviewFollowUp==='CANDIDATE_DROPPED'){
    //     setTemplate("candidate_dropped")
    //     setTemplateText("Candidate dropped")
    // }
    // else if(userData.interviewMode===4){
    //     setTemplate("profile_reject")
    //     setTemplateText("Profile rejected")
    // }
    // else if(userData.interviewMode===1){
    //     setTemplate("profile_shared")
    //     setTemplateText("Profile shared")
    // }
    // else if(userData.approvalStatus==="NOT_APPROVED"){
    //     setTemplate("not_shortlisted")
    //     setTemplateText("Candidate not approved")
    // }
    // else if(userData.callStatus==="NOT_INTERESTED"){
    //     setTemplate("call_status_based")
    //     setTemplateText("Not interested")
    // }
    // else if(userData.callStatus!=="INTERESTED" && userData.callStatus!=="NOT_INTERESTED"){
    //     setTemplate("call_status_based")
    //     setTemplateText("Call status")
    // }
    else if(!userData.interviewId){
        setTemplate("download_app_and_apply_for_job")
        setTemplateText("Download app and apply for job")
    }
}, [])

  const [selectedDate, handleDateChange] = useState(new Date());
  return (

    <Modal
        className={classes.modal}
        open={shareModal}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                <h1>Share Application</h1>
                {/* <Button style={close} onClick={handleModal}>X</Button> */}
            </Grid>
            <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
                    <Select
                        id="shareJobDetails"
                        value={dropDown}
                        IconComponent= {() => <ExpandMoreIcon />}
                        onChange={(e) => setDropDown(e.target.value)}
                        className={classes.select}
                    >
                        <MenuItem value="email" id= "emailOption">Share via Email</MenuItem>
                        <MenuItem value="whatsapp" id= "whatsappOption">Share via Whatsapp</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {modalData && <Grid container style={{padding:"16px 0"}}>
              <Grid className="padding0 marginBottom20Only" item xs={12} style={{display:"flex", alignItems:"center" }}>
                  <Grid item xs={6} className="padding0 displayFlex">
                    <img src={user} style={{paddingRight: "15px"}} />
                    <span>{userData.name}</span>
                  </Grid>
                  {dropDown==="whatsapp" && <Grid item xs={6} className="padding0">
                    <TextField 
                      value={number}
                      fullWidth
                      error={errors.numberError}
                      className="margin0"
                      placeholder="Whatsapp number"
                      variant="outlined"
                      onChange={handleWhatsapp}
                      inputProps={{
                        style:{padding: "6px 10px"}
                      }}
                    />
                  </Grid>}
              </Grid>
              {dropDown==="email" && <Grid item xs={12} className="padding0">
                    <TextField 
                      fullWidth
                      value={email}
                      error={errors.emailError}
                      variant="outlined"
                      className="marginBottom20Only"
                      placeholder="Email"
                      onChange={handleEmail}
                      inputProps={{
                        style:{padding: "6px 10px"}
                      }}
                    />
              </Grid>}
            <Grid item xs={12} className="padding0">
              <div style={noteBorder}>
                  <strong>
                    {templateText}
                  </strong>
              </div>
            </Grid>
            <Grid item xs={12} className="padding0 marginVertical20">
                <div style={noteBorder}>
                  {modalData.genrated_message.response_id.replaceAll("<br>", "\r\n").replaceAll("<tab>", "\r\n")}
                </div>
            </Grid>
            {/* <Grid item xs={12} className="padding0  displayFlex" style={{flexWrap: "wrap"}}>
                <span style={noteFtn}>Last message sent:&nbsp; </span>
                <span><b> {lastMessage}&nbsp; </b></span> 
                <span> on:&nbsp; </span>
                <span><b> {moment(modalData.last_message_date).format("DD/MM/YYYY")} </b></span>
            </Grid> */}
          </Grid>}
          <Grid item xs={12} className="padding0" direction="row">
                <Button style={cancel} onClick={handleModal}>CANCEL</Button>
                <Button style={confirm} onClick={handleConfirmModal} disabled={dropDown==="whatsapp"?errors.numberError:dropDown==="email"?errors.emailError:false}>OK</Button>
          </Grid>
        </Grid>
        </div>
    </Modal>
  );
};

export default ShareApp;

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Tabs } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import { Link, useHistory } from 'react-router-dom';
import OkayGoLogo from '../assets/OkayGo website 6.png';
import ProfileImages from '../assets/users/userplaceholder.jpg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { AccountContext } from '../_services/user.account';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		marginRight: theme.spacing(2),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	logo: {
		width: 135,
		height: 43.54
	},
	header: {
		backgroundColor: "#ffffff",
		boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.07)"
	},
	headerText: {
		fontFamily: "Open Sans",
		fontSize: 20,
		fontWeight: "normal",
		lineHeight: 0.95,
		textAlign: "left",
		color: "#455a64"
	}
}));

const Header = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const history = useHistory();
	const { logout, getSession, getUser} = useContext(AccountContext);
	const [currentPath, setCurrentPath] = useState(history.location.pathname.split('/')[1]);
	const [profileImage, setProfileImage] = useState(ProfileImages);
	const [userStatus, setUserStatus] = useState("INACTIVE");
	const [userInfo, setUserInfo] = useState();


	useEffect(() => {
		if (localStorage.getItem('userInfo') === 'undefined' || localStorage.getItem('userInfo') === null) {
			if (`${currentPath}` === 'help' || `${currentPath}` === 'privacy-policy' || `${currentPath}` === 'email-verification' || `${currentPath}` === 'forgot-password' || `${currentPath}` === 'reset-success' || `${currentPath}` === 'password-success') {

			} else {
				history.push("/login");
			}
		}

		const defaultUser = JSON.parse(localStorage.getItem('userInfo'));
		const email = localStorage.getItem('emailId');
		getUser(email).then(data => {
			if (defaultUser.recruiterStatus !== data.response.content[0].recruiterStatus) {
				localStorage.setItem('userInfo', JSON.stringify(data.response.content[0]));
				switch (data.response.content[0].recruiterStatus) {
					case ("Active").toUpperCase(): history.push({
						pathname: "/dashboard",
						state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
					});
						break;
					case ("INCOMPLETE REGISTRATION").toUpperCase(): history.push({
						pathname: "/create-account",
						state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
					});
						break;
					default: history.push({
						pathname: "/success",
						state: { userId: data.response.content[0].userId, emailId: data.response.content[0].emailId, userStatus: data.response.content[0].recruiterStatus }
					});
						break;
				}
			}

		}).catch(err => {
			console.log('notLogged');
		});




		setUserInfo(defaultUser);
		if (defaultUser != null) {
			if (defaultUser.documents && defaultUser.documents.length > 0) {
				defaultUser.documents.map((files, index) => {
					if (files.document_type_id === 8) {
						setProfileImage(files.file_path);
					}
				});
			}
			switch (defaultUser.recruiterStatus) {
				case ("INCOMPLETE REGISTRATION").toUpperCase():
					setUserStatus("PENDING")
					break;
				case ("REGISTERED").toUpperCase():
				case ("UNDER ASSESMENT").toUpperCase():
				case ("Approved").toUpperCase():
				case ("PENDING FOR ONBOARDING").toUpperCase():
				case ("On Hold").toUpperCase():
				case ("REJECTED").toUpperCase():
					setUserStatus("PROGRESS")
					break;
				case ("Active").toUpperCase():
					setUserStatus("ACTIVE")
					break;
				default:
					break;
			}
		}


		localStorage.setItem('userEmail', email);
	}, []);


	const LogoAction = () => {

		if (userInfo && userInfo.recruiterStatus) {
			switch (userInfo.recruiterStatus) {
				case ("Active").toUpperCase(): history.push({
					pathname: "/dashboard"
				});
					break;
				case ("INCOMPLETE REGISTRATION").toUpperCase(): history.push({
					pathname: "/create-account"
				});
					break;
				case ("INACTIVE").toUpperCase(): history.push({
					pathname: "/login"
				});
					break;
				default: history.push({
					pathname: "/success",
					state: { userStatus: userInfo.recruiterStatus }
				});
					break;
			}
		} else {
			history.push({
				pathname: "/login"
			});
		}
	}


	const LogoutUser = () => {
		getSession()
			.then(session => {
				logout();

				history.push("/login");
			}).catch(err => {
				history.push("/login");
			})
	}


	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const TabComponent = (props) => {
		let activeMenu = '';
		if (`/${currentPath}` == props.url) {
			activeMenu = "activeLink";
		}
		return (
			<Tab className={activeMenu}
				label={<Link to={`${props.url}`} className="menuBar" >
					{`${props.name}`}
				</Link>}
			/>
		)
	}


	const LeftMenuTabs = () => {

		return (userStatus !== "INACTIVE") ? (<Tabs
			onChange={(e, v) => setValue(v)}
			value={value}
			aria-label="Navigation Tabs"
			className="header-right"
			justify={"center"}
		>
			<div className="header-image">
				<div className="mini-Profile" onClick={handleToggle} >
					<img src={profileImage} alt="Profile" width={35} height={35}
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
					/>
					<ArrowDropDownIcon color='disabled' />
				</div>

				<Popper className="ABS" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
					{({ TransitionProps, placement }) => (
						<Grow className="ABSD"
							{...TransitionProps}
						>
							<Paper className="ABSF">
								<ClickAwayListener onClickAway={handleClose} className="ABSJ">
									<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
										{(userStatus === "ACTIVE" || userStatus === "PROGRESS") && <Link to="/profile" ><MenuItem className={currentPath === 'profile' && `profile-active`} onClick={handleClose}>My Profile</MenuItem></Link>}
										<Link to="/help" ><MenuItem className={currentPath === 'faq' && `profile-active`} onClick={handleClose}>FAQ</MenuItem></Link>
										<Link to="/change-password" ><MenuItem className={currentPath == 'change-password' && `profile-active`} onClick={handleClose}>Change Password</MenuItem></Link>
										<Link to="#" onClick={LogoutUser} ><MenuItem onClick={handleClose}>Logout</MenuItem></Link>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</Tabs>) : ''

	}

	return (
		<div className={classes.root}>
			<AppBar className={`${classes.header} top-header`} position="static">
				<Toolbar>

					<Tabs
						onChange={(e, v) => setValue(v)}
						value={value}
						aria-label="Navigation Tabs"
						className="header-left"
						style={{ float: "left" }}
					>
						<Tab className="logotop" label={<img className={`makeStyles-logo-2`} src={OkayGoLogo} alt="Bosch Logo" onClick={LogoAction} />}
						/>

						{userStatus === "ACTIVE" ? <>
							<TabComponent url="/dashboard" name="Dashboard" />
							<TabComponent url="/recruiter-dashboard" name="Track applications" /></> : ''
						}

					</Tabs>
					<LeftMenuTabs />
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default Header;

import 'date-fns';
import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, TextField } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Register.css';
import { AccountContext } from '../_services/user.account';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


const ChangePassword = (props) => {
    const history = useHistory();

    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const { authenticate, getSession, logout } = useContext(AccountContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [helperTextInfo, setHelperTextInfo] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const showHelperText = () =>{
        setHelperTextInfo(!helperTextInfo)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorMessage("");
        setIsError(false);

        if (!oldPassword || !newPassword || !confirmPassword) {
            setErrorMessage("Password details are missing");
        }
        else if (newPassword != confirmPassword) {
            setErrorMessage("Password does not match.");
        } else if (!strongRegex.test(newPassword)) {
            setErrorMessage("The entered password does not meet the password policy.");
        }
        else {
            const email = localStorage.getItem("userEmail");
            authenticate(email, oldPassword)
                .then(data => {
                    getSession()
                        .then(({ user, email }) => {
                            user.changePassword(oldPassword, newPassword, (err, result) => {
                                if (err) {
                                    setErrorMessage("The old password you have entered is incorrect");
                                } else {
                                    logout();
                                    history.push("/password-success");
                                }
                            })
                        });
                }).catch(err => {
                    setErrorMessage("The old password you have entered is incorrect");
                })
        }
    }

    return (
        <>
            <div className={' profile-content'}>
                <Header leftMenu={[{ url: "dashboard", name: "Dashboard" }, { url: "recruiter-dashboard", name: "Track applications" }]} link='true' />
                <Grid container className="main-content">
                    <form className="reset-password" onSubmit={handleSubmit} >
                        <Grid item xs={12} className="padding0" >
                            <div className="reset-password-title">Reset your Password</div>
                        </Grid>
                        {errorMessage ?
                            <Alert variant="filled" severity={isError ? "success" : "error"}>
                                {errorMessage}
                            </Alert> : ''}
                        <Grid item className='field-content'  >
                            <Grid item xs={12} className="padding0 " >
                                <span className="form-label">Enter Old Password</span>
                            </Grid>
                            <Grid item xs={12} className={'form-group'} >
                                <TextField
                                    fullWidth
                                    className={` Rectangle-995`}
                                    variant="outlined"
                                    placeholder="Enter old password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={oldPassword}
                                    onChange={event => setOldPassword(event.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item className='field-content'  >
                            <Grid item xs={12} className="padding0 " >
                                <span className="form-label">Enter New Password <InfoIcon className="helperInfo" onClick={showHelperText} /></span>
                            </Grid>
                            <Grid item xs={12} className={'form-group'} >
                                <TextField
                                    fullWidth
                                    className={` Rectangle-995`}
                                    variant="outlined"
                                    placeholder="Enter new password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    helperText={helperTextInfo && "Password must contain minimum one lowercase, uppercase, symbol, number and minimum 8 characters"}
                                    onChange={event => setNewPassword(event.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item className='field-content'  >
                            <Grid item xs={12} className="padding0 " >
                                <span className="form-label">Confirm Password</span>
                            </Grid>
                            <Grid item xs={12} className="padding0 " >
                                <TextField
                                    fullWidth
                                    className={` Rectangle-995`}
                                    variant="outlined"
                                    placeholder="Retype password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={event => setConfirmPassword(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                            <Button
                                type="submit"
                                className="LoginButton"
                            >Reset Password</Button>
                        </Grid>
                    </form>
                </Grid>
            </div>
        </>
    );
}

const ForgotPassword = (props) => {
    const history = useHistory();
    const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const { forgotPassword, getSession, resetPassword } = useContext(AccountContext);
    const [showPassword, setShowPassword] = useState(false);
    const [helperTextInfo, setHelperTextInfo] = useState(false);

    const showHelperText = () =>{
        setHelperTextInfo(!helperTextInfo)
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);




    const sendCode = event => {
        event.preventDefault();
        setErrorMessage("");
        setIsError(false);
        setCode('');
        setPassword('');
        setConfirmPassword('');
        if (!email) {
            setErrorMessage("Please enter registered email id first.");
        } else if (!pattern.test(email)) {
            setErrorMessage("Please enter valid email address.");
        } else {
            forgotPassword(email.toLowerCase()).then(data => {
                if (data.codeVerify) {
                    setErrorMessage(" Enter verification code sent to your email to reset password");
                    setIsError(true);
                    setStage(2);
                } else {
                    setErrorMessage("Please enter registered email id.");
                }
            }).catch(err => {
                setErrorMessage('User not found.');
            })
        }
    }

    const verifyPassword = event => {

        event.preventDefault();
        setErrorMessage("");
        setIsError(false);

        if (!password || !confirmPassword || !code) {
            setErrorMessage("Code or Passwords are empty");
        } else if (password !== confirmPassword) {
            setErrorMessage("Password does not match.");
        } else if (!strongRegex.test(password)) {
            setErrorMessage("The entered password does not meet the password policy.");
        } else {

            resetPassword(email.toLowerCase(), code, password).then(data => {
                setErrorMessage("Your password has been reset. Please login.");
                history.push("/reset-success");
                setIsError(true);
            }).catch(err => {
                setErrorMessage(err.message);
            })
        }

    }

    return (
        <>
            <div className={' profile-content'}>
                <Header />
                <Grid container className="main-content">
                    {stage === 1 && (
                        <form className="reset-password" onSubmit={sendCode}>
                            <Grid item xs={12} className="padding0" >
                                <div className="reset-password-title">Forgot Password</div>
                            </Grid>
                            {errorMessage ?
                                <Alert variant="filled" severity={isError ? "success" : "error"}>
                                    {errorMessage}
                                </Alert> : ''}
                            <Grid item className='field-content'  >
                                <Grid item xs={12} className="padding0 text-center forgot-label-text" >
                                    <span className="form-label">Enter the registered email address to receive a password reset code</span>
                                </Grid>
                                <Grid item xs={12} className={'form-group'} >
                                    <TextField
                                        fullWidth
                                        className={` Rectangle-995`}
                                        variant="outlined"
                                        placeholder="you@example.com"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                                <Button
                                    type="submit"
                                    className="LoginButton"
                                >Request Reset Code</Button>
                            </Grid>
                        </form>
                    )}

                    {stage === 2 && (
                        <form className="reset-password" onSubmit={verifyPassword}>
                            <Grid item xs={12} className="padding0" >
                                <div className="reset-password-title">Set New Password</div>
                            </Grid>
                            {errorMessage ?
                                <Alert variant="filled" severity={isError ? "success" : "error"}>
                                    {errorMessage}
                                </Alert> : ''}
                            <Grid item className='field-content'  >
                                <Grid item xs={12} className="padding0 text-left" >
                                    <span className="form-label">Verification Code</span>
                                </Grid>
                                <Grid item xs={12} className={'form-group'} >
                                    <TextField
                                        fullWidth
                                        className={` Rectangle-995`}
                                        variant="outlined"
                                        placeholder="Enter verification code"
                                        value={code}
                                        onChange={event => setCode(event.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Link className="resendLink"
                                                    onClick={sendCode}
                                                >Resend Code</Link>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className='field-content'  >
                                <Grid item xs={12} className="padding0 text-left" >
                                    <span className="form-label">Password <InfoIcon className="helperInfo" onClick={showHelperText} /></span>
                                </Grid>
                                <Grid item xs={12} className={'form-group'} >
                                    <TextField
                                        fullWidth
                                        className={` Rectangle-995`}
                                        variant="outlined"
                                        placeholder="XXXXXXXX"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        helperText={helperTextInfo && "Password must contain minimum one lowercase, uppercase, symbol, number and minimum 8 characters"}
                                        onChange={event => setPassword(event.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className='field-content'  >
                                <Grid item xs={12} className="padding0 text-left" >
                                    <span className="form-label">Confirm Password</span>
                                </Grid>
                                <Grid item xs={12} className={'form-group'} >
                                    <TextField
                                        fullWidth
                                        className={` Rectangle-995`}
                                        variant="outlined"
                                        placeholder="XXXXXXXX"
                                        type='password'
                                        value={confirmPassword}
                                        onChange={event => setConfirmPassword(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                                <Button
                                    type="submit"
                                    className="LoginButton resendButton"
                                >Reset Password</Button>

                            </Grid>
                        </form>
                    )}
                    <Grid item xs={12} className="padding0 marginTop20 textAlignCenter" >
                        <span className="fontSize20">Continue to <Link className="createAccount" to="/login" >Login</Link>?
                    </span>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default ChangePassword;
export { ForgotPassword }
import React, { useState, useEffect, useMemo } from 'react'
import UploadExcel from '../../../utils/uploadExcel/uploadExcel'
import {Button, Grid, IconButton, Checkbox, TextField } from '@material-ui/core'
import Table from  '../../../utils/react-table/table'
import LoadingOverlay from 'react-loading-overlay';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../../urlLinks'
import { Link } from "react-router-dom";
import Filters from './filterModal'
import Axios from 'axios'
import * as moment from 'moment'
import Select from '../../../utils/SelectComponent/Select'
import './externalWorker.css';
import { ToastContainer, toast } from 'react-toastify';
import ExternalWorkerModal from './externalWorkerModal'
import ToggleButton from '@material-ui/lab/ToggleButton';
import Header from "../../../Header/Header";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <React.Fragment>
          <Checkbox ref={resolvedRef} {...rest} color= "primary" />
        </React.Fragment>
      )
    }
  )
  
const ExternalWorker =(props) => {
    const [data, setData] = useState([])
    const [excelData, setExcelData] = useState([])
    const [openAssignRecruiterModal, setOpenAssignRecruiterModal] = useState(false)
    const [openAssignJobModal, setOpenAssignJobModal] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openExternalWorkerModal, setOpenExternalWorkerModal] = useState(false)
    const [externalWorkerModalData, setExternalWorkerModalData] = useState({})
    const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [jobList, setJobList] = useState([]) 
    const [userId, setUserId] = useState()
    const [jobValue, setJobValue] = useState()  
    const [openColumnModal, setOpenColumnModal] = useState(false)
    const [sortValue, setSortValue] = useState("")
    const [sortDirection, setSortDirection] = useState(false)
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [sortUrl, setSortUrl] = useState("")
    const [filterUrl, setFilterUrl] = useState("")
    const [filters, setFilters] = useState({
        source:[""],
        gender:[""],
        newCity:[],
        assignedTo:{},
        addedBy:[],
        locality:'',
        year_of_passing:"",
        experience:'',
        qualification:[""],
        preferred_location:'',
        jobRole:'',
        salary:'',
        notice_period:'',
        added_on:[null, null],
        last_activity:[null, null],
        name:'',
        phoneNumber:'',
        preferred_job_type:[],
        expected_salary:0,
        preferred_remark_location:[],
        skills: []
    })
    const sortOptions= [
        ["added_on", "Added on"],
        ["last_activity", "Last activity"],
        ["total_experience", "Total experience"],
        ["name", "Name"],
        ["age", "Age"],
        ["qualification", "Qualification"],
        ["last_salary", "Last Salary"]
    ]
    const ApplySort = () =>{
        let url=''
        switch (sortValue) {
            case "added_on":
                url='&orderBy=wm.inserted_on'
                break;
            case "last_activity":
                url='&orderBy=ew.last_activity'
                break;
            case "total_experience":
                url='&orderBy=we.experience_in_month'
                break;
            case "name":
                url='&orderBy=um.first_name'
                break;
            case "age":
                url='&orderBy=um.date_of_birth'
                break;
            case "qualification":
                url='&orderBy=wm.qualification_id'
                break;
            case "last_salary":
                url='&orderBy=wm.last_salary'
                break;
            
            default:
                url=''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortValue])
    const updateFilters= (filters) => {
        console.log({filters});
        
        let url='';

        if(!filters["source"].includes(""))
            url=`${url}&source=${filters["source"]}`
        if(!filters["gender"].includes(""))
            url=`${url}&gender=${filters["gender"]}`        
        if(filters["year_of_passing"])
            url=`${url}&year_of_passing=${filters["year_of_passing"]}`
        if(!filters["qualification"].includes(""))
            url=`${url}&qualification=${filters["qualification"]}`
        if(filters["newCity"].length!==0){
            let cityIds = []
            filters["newCity"].map(city => cityIds.push(city.id))
            url=`${url}&city_id=${cityIds}`
        }
        if(filters["skills"].length!==0){
            let cityIds = []
            filters["skills"].map(city => cityIds.push(city.id))
            url=`${url}&skills=${cityIds}`
        }
        if(Object.keys(filters["assignedTo"]).length>0){
            url=`${url}&recruiter_id=${filters["assignedTo"].id}`
        }
        if(Object.keys(filters["addedBy"]).length>0){
            let cityIds = []
            filters["addedBy"].map(city => cityIds.push(city.id))
            url=`${url}&added_by=${cityIds}`
        }
        if(filters["preferred_job_type"].length!==0){
            let jobIds = []
            filters["preferred_job_type"].map(job => jobIds.push(job.id))
            url=`${url}&preferred_job_type=${jobIds}`
          }
          if(filters["preferred_remark_location"].length!==0){
            let cityIds = []
            filters["preferred_remark_location"].map(city => cityIds.push(city.id))
            url=`${url}&preferred_remark_location=${cityIds}`
          }
        if(filters["expected_salary"])
            url=`${url}&expected_salary=${filters["expected_salary"]}`
        if(filters["experience"])
            url=`${url}&experience_in_months=${filters["experience"]}`
        if(filters["salary"])
            url=`${url}&salary=${filters["salary"]}`
        if(filters["jobRole"])
            url=`${url}&job_type=${filters["jobRole"]}`
        if(filters["locality"])
            url=`${url}&locality=${filters["locality"]}`
        if(filters["preferred_location"])
            url=`${url}&preferred_location=${filters["preferred_location"]}`
        if(filters["notice_period"])
            url=`${url}&notice_period=${filters["notice_period"]}`
        if(filters["name"])
            url=`${url}&name=${filters["name"]}`
        if(filters["phoneNumber"])
            url=`${url}&phoneNumber=${filters["phoneNumber"]}`
        if(filters["added_on"][0] && !filters["added_on"][1])
            url=`${url}&added_on_from_date=${moment(filters["added_on"][0]).format("YYYY-MM-DD")}&added_on_to_date=${moment(new Date()).format("YYYY-MM-DD")}`
        if(!filters["added_on"][0] && filters["added_on"][1])
            url=`${url}&added_on_from_date=2019-01-01&added_on_to_date=${moment(filters["added_on"][1]).format("YYYY-MM-DD")}`
        if(filters["added_on"][0] && filters["added_on"][1])
            url=`${url}&added_on_from_date=${moment(filters["added_on"][0]).format("YYYY-MM-DD")}&added_on_to_date=${moment(filters["added_on"][1]).format("YYYY-MM-DD")}`
        setFilters(filters)
        setIsFilterApplied(true)
        setFilterUrl(url)
        setCurrentPage(1)
      }
    const resetFilters =() =>{
        const filterList={
            source:[""],
            gender:[""],
            newCity:[],
            assignedTo:{},
            addedBy:[],
            locality:'',
            year_of_passing:"",
            experience:'',
            qualification:[""],
            preferred_location:'',
            jobRole:'',
            salary:'',
            notice_period:'',
            added_on:[null, null],
            last_activity:[null, null],
            name:'',
            phoneNumber:'',
            preferred_job_type:[],
            expected_salary:0,
            preferred_remark_location:[],
            skills:[]
        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(true)
        setCurrentPage(1)
        return filterList;
    }
    const handleSortDirection =() =>{
        console.log({sortDirection})
        if(!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    const handleEditWorker =(row) =>{

    }
    const handleWorkerDetail =(row) =>{
        setUserId(row.row.original.userId)
        setOpenExternalWorkerModal(true)
    }
    const columns = useMemo(
        () => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
            Header: "Assigned to",
            accessor: "assignedTo",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Source",
            accessor: "source",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Added By",
            accessor: "addedBy",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: row => {
                return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Phone no.",
            accessor: "phoneNumber",
            defaultCanFilter :false,      //needed filter 
            defaultCanSort:false
        },
        {
            Header: "Gender",
            accessor: "gender",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Age",
            accessor: "age",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "City",
            accessor: "newCity",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "English Fluency",
            accessor: "english_known_level",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Qualification",
            accessor: "qualification",
            defaultCanFilter :false,   
            defaultCanSort:false
        },
        {
            Header: "Specialization",
            accessor: "specialisation",
            defaultCanFilter :false,   
            defaultCanSort:false
        },
        {
            Header: "Total Experience (in months)",
            accessor: "total_experince_in_month",
            defaultCanFilter :false, 
            defaultCanSort:false
        },
        {
            Header: "Job role",
            accessor: "role_type",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Job category",
            accessor: "job_category",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Skills",
            accessor: "skills",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Last salary (monthly)",
            accessor: "last_salary",
            defaultCanFilter :false,   
            defaultCanSort:false
        },
        {
            Header: "Last company",
            accessor: "company_name",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Last activity",
            accessor: "last_activity",
            defaultCanFilter :false,
            defaultCanSort:false
        },{
            Header: "Jobs matched for",
            accessor: "jobs_matched_to",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Year of Passing",
            accessor: "year_of_passing",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Expected Salary",
            accessor: "expected_salary",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Preferred Job type",
            accessor: "preferred_job_type",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Preferred Location",
            accessor: "preferred_remark_location",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Added on",
            accessor: "insertedOn",
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Edit worker",
            Cell: row => <Link
                            to={{
                                pathname: '/worker/newAddWorker', 
                                id: row.row.original.userId,
                                edit: true
                            }}
                        >
                            <Button
                                style={{marginTop: "24px"}}
                                color= "primary"
                                variant= "outlined"
                            >
                                Edit
                            </Button>
                        </Link>
        }
        ], 
        [handleEditWorker]
    );
    const HandleColumns =() =>{
        setOpenColumnModal(true)
    }
    const getRecruiterName =(data)  =>{
        if(jobValue){
            const obj= data.jobDetailList.find( x => x.jobId === jobValue.jobId)
            console.log({jobValue, obj})
            if(obj.recruiter_name)
                return obj.recruiter_name;
            else
                return "Not assigned"
        }
        else{
            if(data?.jobDetailList?.length>0) {
                const name= data.jobDetailList.some(x => x.recruiter_name)
                if(name)
                    return "Assigned with recruiter"
                else 
                    return "Assigned without recruiter"
            }
            else 
                return "Not Assigned"
        }
    }
    const fixSkills = data => {
        let skills=[]
        console.log({data})
        if(data?.userSkills?.length>0){
            console.log("If check")
          data.userSkills.map(skill =>{
            console.log("map check")
            skills.push(skill.skillsName)
          })
        }
        const res= skills.join(", ");
        return res
      }
    const makeData= (response) =>{
        console.log({response})
        let extractedData = [];
        let index= response.pageable.offset+1;
        response.content.map(el => {
            extractedData.push({
                ...el,
                no: index++,
                name: el.user_name,
                gender: parseInt(el.gender)===26?"Male":parseInt(el.gender)===27?"Female":'N/a',
                assignedTo: getRecruiterName(el),
                insertedOn: moment(el.insertedOn).format("DD/MM/YYYY hh:mm a"),
                skills: fixSkills(el),
                addedBy: el.added_by_name || ""


                
            });
        });
        console.log({extractedData});
        
        return extractedData;
    }
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        // console.log("called");
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:'',
            })
        })
        return list;
    }
    const getExternalWorkerList =(rows, pageNo) =>{
        console.log({pageNo});
        setLoading(true)
        let jobUrl=""
        if(jobValue){
            jobUrl=`&job_id=${jobValue.jobId}`
        }
        Axios.get(`${urlLinks.baseUrl}extWorker/getExtWorkerDb?page_no=${pageNo-1}&rows=${rows}${jobUrl}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response =>{
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if(currentPage>response.data.response.totalPages)
                        setCurrentPage(totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }

            })
    }
    useEffect(() => {
        getExternalWorkerList(perPage, 1);
        
    }, [filterUrl, sortUrl,sortDirectionUrl, jobValue])

    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}extWorker/getAllJobDetails`)
            .then(response =>{
                setJobList(makeJobList(response.data.response))
            })
    }, [])
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        console.log("changePAge", page)
        setCurrentPage(page);
    };
    
    const handlePerRowsChange =  (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    console.log({data})

    return (
        <LoadingOverlay
            active={uploadLoading}
            spinner
            style={{position: "fixed"}}
            text={uploadLoading?'Excel is uploading...':''}
            >
            <div className={' profile-content recruiterDashboard workerList'}>
            <Header leftMenu={[{ url: "/dashboard", name: "Dashboard" }, { url: "/recruiter-dashboard", name: "Track applications" }]} link='true' />
            <Grid container className="main-content">
            <Grid container >
                <ToastContainer
                    position='top-right'
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    autoClose={2000}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                ></ToastContainer>
                <Grid item xs={12} className="buttonList" alignItems='flex-start'>
                    <Grid item xs={2} style={{padding:0}}>
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={selectedRows===0}
                            onClick={() => setOpenAssignJobModal(true)}
                        >
                            Assign to job
                        </Button>
                        
                    </Grid>
                    <Grid item xs={8} style={{padding:0}} className="buttonList marginRightForChildExceptLast">
                        <Grid item className="padding0" xs={4} >    
                        <UploadExcel
                                showToast={showToast}
                                setUploadLoading={setUploadLoading}
                            />
                        </Grid>
                        <Filters 
                            filters={filters}
                            updateFilters={updateFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            jobValue={jobValue}
                        />  
                        <Button
                            variant="outlined"
                            className="filterButton marginRight20 marginLight20 paddingHorizontal"
                            onClick={HandleColumns}
                        >
                            Columns
                        </Button>
                        <Grid item className="padding0" xs={4} >
                            <Select 
                                options={sortOptions}
                                value={sortValue}
                                setValue={setSortValue}
                                label="Sort By:"
                            />
                        </Grid>
                        {sortValue?<ToggleButton
                            selected={sortDirection}
                            onChange={handleSortDirection}
                        >
                            {sortDirection?"A-Z":'Z-A'}
                        </ToggleButton>: null}

                    </Grid>
                </Grid>
                <Grid item xs={12} className="buttonList" alignItems='flex-start'>
                    <span className="fontSize16 fontWeightBold">Vacancy filter</span>
                    <Autocomplete
                        id="jobList"
                        options={jobList}
                        value={jobValue}
                        onChange={(event, value) => setJobValue(value)}
                        style={{ width: "450px", margin: "0 50px 0 30px"}}
                        getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                        renderInput={(params) => <TextField {...params}  className="margin0 borderLightGrey "/>}
                    />
                    <Button
                        variant="contained"
                        className="dataButton"
                        disabled={(selectedRows===0 || !jobValue)}
                        onClick={() => setOpenAssignRecruiterModal(true)}
                    >
                        Assign to recruiter
                    </Button>
                </Grid>
                <Grid item xs={12} className= "padding0">
                    <Table
                        perPage={perPage}
                        currentPage={currentPage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                        columns={columns}
                        data={data}
                        isPagination={true}
                        jobValue= {jobValue}
                        openAssignJobModal={openAssignJobModal} 
                        setOpenAssignJobModal ={setOpenAssignJobModal}
                        openAssignRecruiterModal= {openAssignRecruiterModal} 
                        setOpenAssignRecruiterModal = {setOpenAssignRecruiterModal}
                        jobList={jobList}
                        loading={loading}
                        updateMyData = {updateMyData}
                        setLoading={setLoading}
                        showToast={showToast}
                        openColumnModal={openColumnModal}
                        setSelectedRows={setSelectedRows}
                        setOpenColumnModal={setOpenColumnModal}
                    />
                </Grid>
                {openExternalWorkerModal?<ExternalWorkerModal
                    openExternalWorkerModal={openExternalWorkerModal}
                    setOpenExternalWorkerModal={setOpenExternalWorkerModal}
                    userId={userId}
                />:null}
            </Grid>
            </Grid>
            </div>
        </LoadingOverlay>
    )
}

export default ExternalWorker;



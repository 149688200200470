
 const locationIds= [
    {
      "id": 1,
      "city_name": "Nicobar",
      "state_name": "Andaman and Nico.In."
    },
    {
      "id": 2,
      "city_name": "North and middle andaman",
      "state_name": "Andaman and Nico.In."
    },
    {
      "id": 3,
      "city_name": "South andaman",
      "state_name": "Andaman and Nico.In."
    },
    {
      "id": 4,
      "city_name": "Ananthapur",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 5,
      "city_name": "Bapatla",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 6,
      "city_name": "Chittoor",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 7,
      "city_name": "East godavari",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 8,
      "city_name": "Guntur",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 9,
      "city_name": "Hindupur",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 10,
      "city_name": "Kadapa",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 11,
      "city_name": "Kovvur",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 12,
      "city_name": "Krishna",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 13,
      "city_name": "Kurnool",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 14,
      "city_name": "Machilipatnam",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 15,
      "city_name": "Madanapalle",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 16,
      "city_name": "Mahabub nagar",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 17,
      "city_name": "Nellore",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 18,
      "city_name": "Prakasam",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 19,
      "city_name": "Srikakulam",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 20,
      "city_name": "Visakhapatnam",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 21,
      "city_name": "Vizianagaram",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 22,
      "city_name": "Warangal",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 23,
      "city_name": "West godavari",
      "state_name": "Andhra Pradesh"
    },
    {
      "id": 24,
      "city_name": "Changlang",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 25,
      "city_name": "Dibang valley",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 26,
      "city_name": "East kameng",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 27,
      "city_name": "East siang",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 28,
      "city_name": "Kurung kumey",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 29,
      "city_name": "Lohit",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 30,
      "city_name": "Lower dibang valley",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 31,
      "city_name": "Lower subansiri",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 32,
      "city_name": "Papum pare",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 33,
      "city_name": "Tawang",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 34,
      "city_name": "Tirap",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 35,
      "city_name": "Upper siang",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 36,
      "city_name": "Upper subansiri",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 37,
      "city_name": "West kameng",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 38,
      "city_name": "West siang",
      "state_name": "Arunachal Pradesh"
    },
    {
      "id": 39,
      "city_name": "Baksa",
      "state_name": "Assam"
    },
    {
      "id": 40,
      "city_name": "Barpeta",
      "state_name": "Assam"
    },
    {
      "id": 41,
      "city_name": "Bongaigaon",
      "state_name": "Assam"
    },
    {
      "id": 42,
      "city_name": "Cachar",
      "state_name": "Assam"
    },
    {
      "id": 43,
      "city_name": "Darrang",
      "state_name": "Assam"
    },
    {
      "id": 44,
      "city_name": "Dhemaji",
      "state_name": "Assam"
    },
    {
      "id": 45,
      "city_name": "Dhubri",
      "state_name": "Assam"
    },
    {
      "id": 46,
      "city_name": "Dibrugarh",
      "state_name": "Assam"
    },
    {
      "id": 47,
      "city_name": "Dima hasso - north cachar hill",
      "state_name": "Assam"
    },
    {
      "id": 48,
      "city_name": "Goalpara",
      "state_name": "Assam"
    },
    {
      "id": 49,
      "city_name": "Golaghat",
      "state_name": "Assam"
    },
    {
      "id": 50,
      "city_name": "Hailakandi",
      "state_name": "Assam"
    },
    {
      "id": 51,
      "city_name": "Jorhat",
      "state_name": "Assam"
    },
    {
      "id": 52,
      "city_name": "Kamrup",
      "state_name": "Assam"
    },
    {
      "id": 53,
      "city_name": "Kamrup metropolitan",
      "state_name": "Assam"
    },
    {
      "id": 54,
      "city_name": "Karbi anglong",
      "state_name": "Assam"
    },
    {
      "id": 55,
      "city_name": "Karimganj",
      "state_name": "Assam"
    },
    {
      "id": 56,
      "city_name": "Kokrajhar",
      "state_name": "Assam"
    },
    {
      "id": 57,
      "city_name": "Lakhimpur",
      "state_name": "Assam"
    },
    {
      "id": 58,
      "city_name": "Marigaon",
      "state_name": "Assam"
    },
    {
      "id": 59,
      "city_name": "Nagaon",
      "state_name": "Assam"
    },
    {
      "id": 60,
      "city_name": "Nalbari",
      "state_name": "Assam"
    },
    {
      "id": 61,
      "city_name": "Sibsagar",
      "state_name": "Assam"
    },
    {
      "id": 62,
      "city_name": "Sonitpur",
      "state_name": "Assam"
    },
    {
      "id": 63,
      "city_name": "Tinsukia",
      "state_name": "Assam"
    },
    {
      "id": 64,
      "city_name": "Udalguri",
      "state_name": "Assam"
    },
    {
      "id": 65,
      "city_name": "Araria",
      "state_name": "Bihar"
    },
    {
      "id": 66,
      "city_name": "Arwal",
      "state_name": "Bihar"
    },
    {
      "id": 67,
      "city_name": "Aurangabad",
      "state_name": "Bihar"
    },
    {
      "id": 68,
      "city_name": "Banka",
      "state_name": "Bihar"
    },
    {
      "id": 69,
      "city_name": "Begusarai",
      "state_name": "Bihar"
    },
    {
      "id": 70,
      "city_name": "Bhagalpur",
      "state_name": "Bihar"
    },
    {
      "id": 71,
      "city_name": "Bhojpur",
      "state_name": "Bihar"
    },
    {
      "id": 72,
      "city_name": "Buxar",
      "state_name": "Bihar"
    },
    {
      "id": 73,
      "city_name": "Darbhanga",
      "state_name": "Bihar"
    },
    {
      "id": 74,
      "city_name": "East champaran",
      "state_name": "Bihar"
    },
    {
      "id": 75,
      "city_name": "Gaya",
      "state_name": "Bihar"
    },
    {
      "id": 76,
      "city_name": "Gopalganj",
      "state_name": "Bihar"
    },
    {
      "id": 77,
      "city_name": "Jamui",
      "state_name": "Bihar"
    },
    {
      "id": 78,
      "city_name": "Jehanabad",
      "state_name": "Bihar"
    },
    {
      "id": 79,
      "city_name": "Kaimur bhabua",
      "state_name": "Bihar"
    },
    {
      "id": 80,
      "city_name": "Katihar",
      "state_name": "Bihar"
    },
    {
      "id": 81,
      "city_name": "Khagaria",
      "state_name": "Bihar"
    },
    {
      "id": 82,
      "city_name": "Kishanganj",
      "state_name": "Bihar"
    },
    {
      "id": 83,
      "city_name": "Lakhisarai",
      "state_name": "Bihar"
    },
    {
      "id": 84,
      "city_name": "Madhepura",
      "state_name": "Bihar"
    },
    {
      "id": 85,
      "city_name": "Madhubani",
      "state_name": "Bihar"
    },
    {
      "id": 86,
      "city_name": "Munger",
      "state_name": "Bihar"
    },
    {
      "id": 87,
      "city_name": "Muzaffarpur",
      "state_name": "Bihar"
    },
    {
      "id": 88,
      "city_name": "Nalanda",
      "state_name": "Bihar"
    },
    {
      "id": 89,
      "city_name": "Nawada",
      "state_name": "Bihar"
    },
    {
      "id": 90,
      "city_name": "Patna",
      "state_name": "Bihar"
    },
    {
      "id": 91,
      "city_name": "Purnia",
      "state_name": "Bihar"
    },
    {
      "id": 92,
      "city_name": "Rohtas",
      "state_name": "Bihar"
    },
    {
      "id": 93,
      "city_name": "Saharsa",
      "state_name": "Bihar"
    },
    {
      "id": 94,
      "city_name": "Samastipur",
      "state_name": "Bihar"
    },
    {
      "id": 95,
      "city_name": "Saran",
      "state_name": "Bihar"
    },
    {
      "id": 96,
      "city_name": "Sheikhpura",
      "state_name": "Bihar"
    },
    {
      "id": 97,
      "city_name": "Sheohar",
      "state_name": "Bihar"
    },
    {
      "id": 98,
      "city_name": "Sitamarhi",
      "state_name": "Bihar"
    },
    {
      "id": 99,
      "city_name": "Siwan",
      "state_name": "Bihar"
    },
    {
      "id": 100,
      "city_name": "Supaul",
      "state_name": "Bihar"
    },
    {
      "id": 101,
      "city_name": "Vaishali",
      "state_name": "Bihar"
    },
    {
      "id": 102,
      "city_name": "West champaran",
      "state_name": "Bihar"
    },
    {
      "id": 103,
      "city_name": "Chandigarh",
      "state_name": "Chandigarh"
    },
    {
      "id": 104,
      "city_name": "Balod",
      "state_name": "Chattisgarh"
    },
    {
      "id": 105,
      "city_name": "Baloda Bazar",
      "state_name": "Chattisgarh"
    },
    {
      "id": 106,
      "city_name": "Balrampur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 107,
      "city_name": "Bastar",
      "state_name": "Chattisgarh"
    },
    {
      "id": 108,
      "city_name": "Bemetara",
      "state_name": "Chattisgarh"
    },
    {
      "id": 109,
      "city_name": "Bijapur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 110,
      "city_name": "Bilaspur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 111,
      "city_name": "Dantewada",
      "state_name": "Chattisgarh"
    },
    {
      "id": 112,
      "city_name": "Dhamtari",
      "state_name": "Chattisgarh"
    },
    {
      "id": 113,
      "city_name": "Durg",
      "state_name": "Chattisgarh"
    },
    {
      "id": 114,
      "city_name": "Gariaband",
      "state_name": "Chattisgarh"
    },
    {
      "id": 115,
      "city_name": "Jagdalpur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 116,
      "city_name": "Janjgir-champa",
      "state_name": "Chattisgarh"
    },
    {
      "id": 117,
      "city_name": "Janjgirchampa",
      "state_name": "Chattisgarh"
    },
    {
      "id": 118,
      "city_name": "Jaspur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 119,
      "city_name": "Kanker",
      "state_name": "Chattisgarh"
    },
    {
      "id": 120,
      "city_name": "Kawardha",
      "state_name": "Chattisgarh"
    },
    {
      "id": 121,
      "city_name": "Korba",
      "state_name": "Chattisgarh"
    },
    {
      "id": 122,
      "city_name": "Koriya",
      "state_name": "Chattisgarh"
    },
    {
      "id": 123,
      "city_name": "Mahasamund",
      "state_name": "Chattisgarh"
    },
    {
      "id": 124,
      "city_name": "Mungeli",
      "state_name": "Chattisgarh"
    },
    {
      "id": 125,
      "city_name": "Narayanpur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 126,
      "city_name": "Raigarh",
      "state_name": "Chattisgarh"
    },
    {
      "id": 127,
      "city_name": "Raipur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 128,
      "city_name": "Rajnandgaon",
      "state_name": "Chattisgarh"
    },
    {
      "id": 129,
      "city_name": "Surajpur",
      "state_name": "Chattisgarh"
    },
    {
      "id": 130,
      "city_name": "Surguja",
      "state_name": "Chattisgarh"
    },
    {
      "id": 131,
      "city_name": "Dadra  nagar haveli",
      "state_name": "Dadra and Nagar Hav."
    },
    {
      "id": 132,
      "city_name": "Basti",
      "state_name": "Daman and Diu"
    },
    {
      "id": 133,
      "city_name": "Daman",
      "state_name": "Daman and Diu"
    },
    {
      "id": 134,
      "city_name": "Diu",
      "state_name": "Daman and Diu"
    },
    {
      "id": 135,
      "city_name": "Central delhi",
      "state_name": "Delhi"
    },
    {
      "id": 136,
      "city_name": "East delhi",
      "state_name": "Delhi"
    },
    {
      "id": 137,
      "city_name": "New delhi",
      "state_name": "Delhi"
    },
    {
      "id": 138,
      "city_name": "North delhi",
      "state_name": "Delhi"
    },
    {
      "id": 139,
      "city_name": "North east delhi",
      "state_name": "Delhi"
    },
    {
      "id": 140,
      "city_name": "North west delhi",
      "state_name": "Delhi"
    },
    {
      "id": 141,
      "city_name": "Shahdara",
      "state_name": "Delhi"
    },
    {
      "id": 142,
      "city_name": "South delhi",
      "state_name": "Delhi"
    },
    {
      "id": 143,
      "city_name": "South west delhi",
      "state_name": "Delhi"
    },
    {
      "id": 144,
      "city_name": "West delhi",
      "state_name": "Delhi"
    },
    {
      "id": 145,
      "city_name": "Darbhanga",
      "state_name": "Goa"
    },
    {
      "id": 146,
      "city_name": "North goa",
      "state_name": "Goa"
    },
    {
      "id": 147,
      "city_name": "South goa",
      "state_name": "Goa"
    },
    {
      "id": 148,
      "city_name": "Ahmedabad",
      "state_name": "Gujarat"
    },
    {
      "id": 149,
      "city_name": "Ahmedabad city",
      "state_name": "Gujarat"
    },
    {
      "id": 150,
      "city_name": "Amreli",
      "state_name": "Gujarat"
    },
    {
      "id": 151,
      "city_name": "Anand",
      "state_name": "Gujarat"
    },
    {
      "id": 152,
      "city_name": "Aravalli",
      "state_name": "Gujarat"
    },
    {
      "id": 153,
      "city_name": "Banaskantha",
      "state_name": "Gujarat"
    },
    {
      "id": 154,
      "city_name": "Bharuch",
      "state_name": "Gujarat"
    },
    {
      "id": 155,
      "city_name": "Bhavnagar",
      "state_name": "Gujarat"
    },
    {
      "id": 156,
      "city_name": "Botab",
      "state_name": "Gujarat"
    },
    {
      "id": 157,
      "city_name": "Chhotaudepur",
      "state_name": "Gujarat"
    },
    {
      "id": 158,
      "city_name": "Dahod",
      "state_name": "Gujarat"
    },
    {
      "id": 159,
      "city_name": "Dangs",
      "state_name": "Gujarat"
    },
    {
      "id": 160,
      "city_name": "Devbhoomi dwerka",
      "state_name": "Gujarat"
    },
    {
      "id": 161,
      "city_name": "Gandhinagar",
      "state_name": "Gujarat"
    },
    {
      "id": 162,
      "city_name": "Gir somnath",
      "state_name": "Gujarat"
    },
    {
      "id": 163,
      "city_name": "Jamnagar",
      "state_name": "Gujarat"
    },
    {
      "id": 164,
      "city_name": "Junagadh",
      "state_name": "Gujarat"
    },
    {
      "id": 165,
      "city_name": "Kachchh",
      "state_name": "Gujarat"
    },
    {
      "id": 166,
      "city_name": "Kheda",
      "state_name": "Gujarat"
    },
    {
      "id": 167,
      "city_name": "Mahesana",
      "state_name": "Gujarat"
    },
    {
      "id": 168,
      "city_name": "Mahisagar",
      "state_name": "Gujarat"
    },
    {
      "id": 169,
      "city_name": "Morbi",
      "state_name": "Gujarat"
    },
    {
      "id": 170,
      "city_name": "Narmada",
      "state_name": "Gujarat"
    },
    {
      "id": 171,
      "city_name": "Navsari",
      "state_name": "Gujarat"
    },
    {
      "id": 172,
      "city_name": "Panch mahals",
      "state_name": "Gujarat"
    },
    {
      "id": 173,
      "city_name": "Patan",
      "state_name": "Gujarat"
    },
    {
      "id": 174,
      "city_name": "Porbandar",
      "state_name": "Gujarat"
    },
    {
      "id": 175,
      "city_name": "Rajkot",
      "state_name": "Gujarat"
    },
    {
      "id": 176,
      "city_name": "Sabarkantha",
      "state_name": "Gujarat"
    },
    {
      "id": 177,
      "city_name": "Surat",
      "state_name": "Gujarat"
    },
    {
      "id": 178,
      "city_name": "Surendra nagar",
      "state_name": "Gujarat"
    },
    {
      "id": 179,
      "city_name": "Tapi",
      "state_name": "Gujarat"
    },
    {
      "id": 180,
      "city_name": "Vadodara",
      "state_name": "Gujarat"
    },
    {
      "id": 181,
      "city_name": "Valsad",
      "state_name": "Gujarat"
    },
    {
      "id": 182,
      "city_name": "Ambala",
      "state_name": "Haryana"
    },
    {
      "id": 183,
      "city_name": "Bhiwani",
      "state_name": "Haryana"
    },
    {
      "id": 184,
      "city_name": "Faridabad",
      "state_name": "Haryana"
    },
    {
      "id": 185,
      "city_name": "Fatehabad",
      "state_name": "Haryana"
    },
    {
      "id": 186,
      "city_name": "Gurgaon",
      "state_name": "Haryana"
    },
    {
      "id": 187,
      "city_name": "Hisar",
      "state_name": "Haryana"
    },
    {
      "id": 188,
      "city_name": "Jhajjar",
      "state_name": "Haryana"
    },
    {
      "id": 189,
      "city_name": "Jind",
      "state_name": "Haryana"
    },
    {
      "id": 190,
      "city_name": "Kaithal",
      "state_name": "Haryana"
    },
    {
      "id": 191,
      "city_name": "Karnal",
      "state_name": "Haryana"
    },
    {
      "id": 192,
      "city_name": "Kurukshetra",
      "state_name": "Haryana"
    },
    {
      "id": 193,
      "city_name": "Mahendragarh",
      "state_name": "Haryana"
    },
    {
      "id": 194,
      "city_name": "Mewat",
      "state_name": "Haryana"
    },
    {
      "id": 195,
      "city_name": "Panchkula",
      "state_name": "Haryana"
    },
    {
      "id": 196,
      "city_name": "Panipat",
      "state_name": "Haryana"
    },
    {
      "id": 197,
      "city_name": "Rewari",
      "state_name": "Haryana"
    },
    {
      "id": 198,
      "city_name": "Rohtak",
      "state_name": "Haryana"
    },
    {
      "id": 199,
      "city_name": "Sirsa",
      "state_name": "Haryana"
    },
    {
      "id": 200,
      "city_name": "Sonipat",
      "state_name": "Haryana"
    },
    {
      "id": 201,
      "city_name": "Yamunanagar",
      "state_name": "Haryana"
    },
    {
      "id": 202,
      "city_name": "Bilaspur",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 203,
      "city_name": "Chamba",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 204,
      "city_name": "Hamirpurhp",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 205,
      "city_name": "Kangra",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 206,
      "city_name": "Kinnaur",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 207,
      "city_name": "Kullu",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 208,
      "city_name": "Lahul  spiti",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 209,
      "city_name": "Mandi",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 210,
      "city_name": "Shimla",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 211,
      "city_name": "Sirmaur",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 212,
      "city_name": "Solan",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 213,
      "city_name": "Una",
      "state_name": "Himachal Pradesh"
    },
    {
      "id": 214,
      "city_name": "Ananthnag",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 215,
      "city_name": "Bandipur",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 216,
      "city_name": "Baramulla",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 217,
      "city_name": "Budgam",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 218,
      "city_name": "Doda",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 219,
      "city_name": "Ganderbal",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 220,
      "city_name": "Jammu",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 221,
      "city_name": "Kargil",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 222,
      "city_name": "Kathua",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 223,
      "city_name": "Kulgam",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 224,
      "city_name": "Kupwara",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 225,
      "city_name": "Leh",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 226,
      "city_name": "Poonch",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 227,
      "city_name": "Pulwama",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 228,
      "city_name": "Rajauri",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 229,
      "city_name": "Ramban",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 230,
      "city_name": "Reasi",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 231,
      "city_name": "Samba",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 232,
      "city_name": "Shopian",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 233,
      "city_name": "Srinagar",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 234,
      "city_name": "Udhampur",
      "state_name": "Jammu and Kashmir"
    },
    {
      "id": 235,
      "city_name": "Bokaro",
      "state_name": "Jharkhand"
    },
    {
      "id": 236,
      "city_name": "Chatra",
      "state_name": "Jharkhand"
    },
    {
      "id": 237,
      "city_name": "Deoghar",
      "state_name": "Jharkhand"
    },
    {
      "id": 238,
      "city_name": "Dhanbad",
      "state_name": "Jharkhand"
    },
    {
      "id": 239,
      "city_name": "Dumka",
      "state_name": "Jharkhand"
    },
    {
      "id": 240,
      "city_name": "East singhbhum",
      "state_name": "Jharkhand"
    },
    {
      "id": 241,
      "city_name": "Garhwa",
      "state_name": "Jharkhand"
    },
    {
      "id": 242,
      "city_name": "Giridh",
      "state_name": "Jharkhand"
    },
    {
      "id": 243,
      "city_name": "Godda",
      "state_name": "Jharkhand"
    },
    {
      "id": 244,
      "city_name": "Gumla",
      "state_name": "Jharkhand"
    },
    {
      "id": 245,
      "city_name": "Hazaribag",
      "state_name": "Jharkhand"
    },
    {
      "id": 246,
      "city_name": "Hazaribagh",
      "state_name": "Jharkhand"
    },
    {
      "id": 247,
      "city_name": "Jamtara",
      "state_name": "Jharkhand"
    },
    {
      "id": 248,
      "city_name": "Khunti",
      "state_name": "Jharkhand"
    },
    {
      "id": 249,
      "city_name": "Koderma",
      "state_name": "Jharkhand"
    },
    {
      "id": 250,
      "city_name": "Latehar",
      "state_name": "Jharkhand"
    },
    {
      "id": 251,
      "city_name": "Lohardaga",
      "state_name": "Jharkhand"
    },
    {
      "id": 252,
      "city_name": "Pakur",
      "state_name": "Jharkhand"
    },
    {
      "id": 253,
      "city_name": "Palamau",
      "state_name": "Jharkhand"
    },
    {
      "id": 254,
      "city_name": "Ramgarh",
      "state_name": "Jharkhand"
    },
    {
      "id": 255,
      "city_name": "Ranchi",
      "state_name": "Jharkhand"
    },
    {
      "id": 256,
      "city_name": "Sahibganj",
      "state_name": "Jharkhand"
    },
    {
      "id": 257,
      "city_name": "Seraikela Kharsawan",
      "state_name": "Jharkhand"
    },
    {
      "id": 258,
      "city_name": "Simdega",
      "state_name": "Jharkhand"
    },
    {
      "id": 259,
      "city_name": "West singhbhum",
      "state_name": "Jharkhand"
    },
    {
      "id": 260,
      "city_name": "Athani",
      "state_name": "Karnataka"
    },
    {
      "id": 261,
      "city_name": "Bagalkot",
      "state_name": "Karnataka"
    },
    {
      "id": 262,
      "city_name": "Bailhongal",
      "state_name": "Karnataka"
    },
    {
      "id": 263,
      "city_name": "Bangalore",
      "state_name": "Karnataka"
    },
    {
      "id": 264,
      "city_name": "Belagavi",
      "state_name": "Karnataka"
    },
    {
      "id": 265,
      "city_name": "Bellary",
      "state_name": "Karnataka"
    },
    {
      "id": 266,
      "city_name": "Bengaluru",
      "state_name": "Karnataka"
    },
    {
      "id": 267,
      "city_name": "Bengaluru rural",
      "state_name": "Karnataka"
    },
    {
      "id": 268,
      "city_name": "Bidar",
      "state_name": "Karnataka"
    },
    {
      "id": 269,
      "city_name": "Bijapur",
      "state_name": "Karnataka"
    },
    {
      "id": 270,
      "city_name": "Chamrajnagar",
      "state_name": "Karnataka"
    },
    {
      "id": 271,
      "city_name": "Chickmagalur",
      "state_name": "Karnataka"
    },
    {
      "id": 272,
      "city_name": "Chikkaballapur",
      "state_name": "Karnataka"
    },
    {
      "id": 273,
      "city_name": "Chitradurga",
      "state_name": "Karnataka"
    },
    {
      "id": 274,
      "city_name": "Dakshina kannada",
      "state_name": "Karnataka"
    },
    {
      "id": 275,
      "city_name": "Davangere",
      "state_name": "Karnataka"
    },
    {
      "id": 276,
      "city_name": "Dharwad",
      "state_name": "Karnataka"
    },
    {
      "id": 277,
      "city_name": "Gadag",
      "state_name": "Karnataka"
    },
    {
      "id": 278,
      "city_name": "Hassan",
      "state_name": "Karnataka"
    },
    {
      "id": 279,
      "city_name": "Haveri",
      "state_name": "Karnataka"
    },
    {
      "id": 280,
      "city_name": "Hubballi",
      "state_name": "Karnataka"
    },
    {
      "id": 281,
      "city_name": "Joida",
      "state_name": "Karnataka"
    },
    {
      "id": 282,
      "city_name": "Kalaburagi",
      "state_name": "Karnataka"
    },
    {
      "id": 283,
      "city_name": "Kodagu",
      "state_name": "Karnataka"
    },
    {
      "id": 284,
      "city_name": "Kolar",
      "state_name": "Karnataka"
    },
    {
      "id": 285,
      "city_name": "Koppal",
      "state_name": "Karnataka"
    },
    {
      "id": 286,
      "city_name": "Madikeri",
      "state_name": "Karnataka"
    },
    {
      "id": 287,
      "city_name": "Mandya",
      "state_name": "Karnataka"
    },
    {
      "id": 288,
      "city_name": "Mysuru",
      "state_name": "Karnataka"
    },
    {
      "id": 289,
      "city_name": "Nanjangud",
      "state_name": "Karnataka"
    },
    {
      "id": 290,
      "city_name": "Raichur",
      "state_name": "Karnataka"
    },
    {
      "id": 291,
      "city_name": "Ramanagar",
      "state_name": "Karnataka"
    },
    {
      "id": 292,
      "city_name": "Saraswathipuram",
      "state_name": "Karnataka"
    },
    {
      "id": 293,
      "city_name": "Shivamogga",
      "state_name": "Karnataka"
    },
    {
      "id": 294,
      "city_name": "Tumakuru",
      "state_name": "Karnataka"
    },
    {
      "id": 295,
      "city_name": "Udupi",
      "state_name": "Karnataka"
    },
    {
      "id": 296,
      "city_name": "Uttara kannada",
      "state_name": "Karnataka"
    },
    {
      "id": 297,
      "city_name": "Yadgir",
      "state_name": "Karnataka"
    },
    {
      "id": 298,
      "city_name": "Alappuzha",
      "state_name": "Kerala"
    },
    {
      "id": 299,
      "city_name": "Ernakulam",
      "state_name": "Kerala"
    },
    {
      "id": 300,
      "city_name": "Idukki",
      "state_name": "Kerala"
    },
    {
      "id": 301,
      "city_name": "Kannur",
      "state_name": "Kerala"
    },
    {
      "id": 302,
      "city_name": "Kasaragod",
      "state_name": "Kerala"
    },
    {
      "id": 303,
      "city_name": "Kollam",
      "state_name": "Kerala"
    },
    {
      "id": 304,
      "city_name": "Kottayam",
      "state_name": "Kerala"
    },
    {
      "id": 305,
      "city_name": "Kozhikode",
      "state_name": "Kerala"
    },
    {
      "id": 306,
      "city_name": "Mahe",
      "state_name": "Kerala"
    },
    {
      "id": 307,
      "city_name": "Malappuram",
      "state_name": "Kerala"
    },
    {
      "id": 308,
      "city_name": "Palakkad",
      "state_name": "Kerala"
    },
    {
      "id": 309,
      "city_name": "Pathanamthitta",
      "state_name": "Kerala"
    },
    {
      "id": 310,
      "city_name": "Thiruvananthapuram",
      "state_name": "Kerala"
    },
    {
      "id": 311,
      "city_name": "Thrissur",
      "state_name": "Kerala"
    },
    {
      "id": 312,
      "city_name": "Wayanad",
      "state_name": "Kerala"
    },
    {
      "id": 313,
      "city_name": "Kochi",
      "state_name": "Lakshadweep"
    },
    {
      "id": 314,
      "city_name": "Lakshadweep",
      "state_name": "Lakshadweep"
    },
    {
      "id": 315,
      "city_name": "Agar malwa",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 316,
      "city_name": "Alirajpur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 317,
      "city_name": "Anuppur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 318,
      "city_name": "Ashok nagar",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 319,
      "city_name": "Balaghat",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 320,
      "city_name": "Barwani",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 321,
      "city_name": "Betul",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 322,
      "city_name": "Bhind",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 323,
      "city_name": "Bhopal",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 324,
      "city_name": "Burhanpur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 325,
      "city_name": "Chhatarpur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 326,
      "city_name": "Chhindwara",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 327,
      "city_name": "Damoh",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 328,
      "city_name": "Datia",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 329,
      "city_name": "Dewas",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 330,
      "city_name": "Dhar",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 331,
      "city_name": "Dindori",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 332,
      "city_name": "Guna",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 333,
      "city_name": "Gwalior",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 334,
      "city_name": "Harda",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 335,
      "city_name": "Hoshangabad",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 336,
      "city_name": "Indore",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 337,
      "city_name": "Jabalpur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 338,
      "city_name": "Jhabua",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 339,
      "city_name": "Katni",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 340,
      "city_name": "Khandwa",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 341,
      "city_name": "Khargone",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 342,
      "city_name": "Mandla",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 343,
      "city_name": "Mandsaur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 344,
      "city_name": "Morena",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 345,
      "city_name": "Narsinghpur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 346,
      "city_name": "Neemuch",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 347,
      "city_name": "Panna",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 348,
      "city_name": "Raisen",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 349,
      "city_name": "Rajgarh",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 350,
      "city_name": "Ratlam",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 351,
      "city_name": "Rewa",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 352,
      "city_name": "Sagar",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 353,
      "city_name": "Satna",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 354,
      "city_name": "Sehore",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 355,
      "city_name": "Seoni",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 356,
      "city_name": "Shahdol",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 357,
      "city_name": "Shajapur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 358,
      "city_name": "Sheopur",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 359,
      "city_name": "Shivpuri",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 360,
      "city_name": "Sidhi",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 361,
      "city_name": "Singrauli",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 362,
      "city_name": "Tikamgarh",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 363,
      "city_name": "Ujjain",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 364,
      "city_name": "Umaria",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 365,
      "city_name": "Vidisha",
      "state_name": "Madhya Pradesh"
    },
    {
      "id": 366,
      "city_name": "Ahmednagar",
      "state_name": "Maharashtra"
    },
    {
      "id": 367,
      "city_name": "Akola",
      "state_name": "Maharashtra"
    },
    {
      "id": 368,
      "city_name": "Amravati",
      "state_name": "Maharashtra"
    },
    {
      "id": 369,
      "city_name": "Aurangabad",
      "state_name": "Maharashtra"
    },
    {
      "id": 370,
      "city_name": "Beed",
      "state_name": "Maharashtra"
    },
    {
      "id": 371,
      "city_name": "Bhandara",
      "state_name": "Maharashtra"
    },
    {
      "id": 372,
      "city_name": "Buldhana",
      "state_name": "Maharashtra"
    },
    {
      "id": 373,
      "city_name": "Chandrapur",
      "state_name": "Maharashtra"
    },
    {
      "id": 374,
      "city_name": "Dhule",
      "state_name": "Maharashtra"
    },
    {
      "id": 375,
      "city_name": "Gadchiroli",
      "state_name": "Maharashtra"
    },
    {
      "id": 376,
      "city_name": "Gondia",
      "state_name": "Maharashtra"
    },
    {
      "id": 377,
      "city_name": "Hingoli",
      "state_name": "Maharashtra"
    },
    {
      "id": 378,
      "city_name": "Jalgaon",
      "state_name": "Maharashtra"
    },
    {
      "id": 379,
      "city_name": "Jalna",
      "state_name": "Maharashtra"
    },
    {
      "id": 380,
      "city_name": "Kolhapur",
      "state_name": "Maharashtra"
    },
    {
      "id": 381,
      "city_name": "Latur",
      "state_name": "Maharashtra"
    },
    {
      "id": 382,
      "city_name": "Malegaon",
      "state_name": "Maharashtra"
    },
    {
      "id": 383,
      "city_name": "Miraj",
      "state_name": "Maharashtra"
    },
    {
      "id": 384,
      "city_name": "Mumbai",
      "state_name": "Maharashtra"
    },
    {
      "id": 385,
      "city_name": "Mumbai suburban",
      "state_name": "Maharashtra"
    },
    {
      "id": 386,
      "city_name": "Nagpur",
      "state_name": "Maharashtra"
    },
    {
      "id": 387,
      "city_name": "Nanded",
      "state_name": "Maharashtra"
    },
    {
      "id": 388,
      "city_name": "Nandurbar",
      "state_name": "Maharashtra"
    },
    {
      "id": 389,
      "city_name": "Nashik",
      "state_name": "Maharashtra"
    },
    {
      "id": 390,
      "city_name": "Osmanabad",
      "state_name": "Maharashtra"
    },
    {
      "id": 391,
      "city_name": "Palghar",
      "state_name": "Maharashtra"
    },
    {
      "id": 392,
      "city_name": "Parbhani",
      "state_name": "Maharashtra"
    },
    {
      "id": 393,
      "city_name": "Pune",
      "state_name": "Maharashtra"
    },
    {
      "id": 394,
      "city_name": "Raigarh",
      "state_name": "Maharashtra"
    },
    {
      "id": 395,
      "city_name": "Ratnagiri",
      "state_name": "Maharashtra"
    },
    {
      "id": 396,
      "city_name": "Sangli",
      "state_name": "Maharashtra"
    },
    {
      "id": 397,
      "city_name": "Satara",
      "state_name": "Maharashtra"
    },
    {
      "id": 398,
      "city_name": "Sindhudurg",
      "state_name": "Maharashtra"
    },
    {
      "id": 399,
      "city_name": "Solapur",
      "state_name": "Maharashtra"
    },
    {
      "id": 400,
      "city_name": "Thane",
      "state_name": "Maharashtra"
    },
    {
      "id": 401,
      "city_name": "Wardha",
      "state_name": "Maharashtra"
    },
    {
      "id": 402,
      "city_name": "Washim",
      "state_name": "Maharashtra"
    },
    {
      "id": 403,
      "city_name": "Yavatmal",
      "state_name": "Maharashtra"
    },
    {
      "id": 404,
      "city_name": "Bishnupur",
      "state_name": "Manipur"
    },
    {
      "id": 405,
      "city_name": "Chandel",
      "state_name": "Manipur"
    },
    {
      "id": 406,
      "city_name": "Churachandpur",
      "state_name": "Manipur"
    },
    {
      "id": 407,
      "city_name": "Imphal east",
      "state_name": "Manipur"
    },
    {
      "id": 408,
      "city_name": "Imphal west",
      "state_name": "Manipur"
    },
    {
      "id": 409,
      "city_name": "Senapati",
      "state_name": "Manipur"
    },
    {
      "id": 410,
      "city_name": "Tamenglong",
      "state_name": "Manipur"
    },
    {
      "id": 411,
      "city_name": "Thoubal",
      "state_name": "Manipur"
    },
    {
      "id": 412,
      "city_name": "Ukhrul",
      "state_name": "Manipur"
    },
    {
      "id": 413,
      "city_name": "West tripura",
      "state_name": "Manipur"
    },
    {
      "id": 414,
      "city_name": "East garo hills",
      "state_name": "Megalaya"
    },
    {
      "id": 415,
      "city_name": "East khasi hills",
      "state_name": "Megalaya"
    },
    {
      "id": 416,
      "city_name": "Jaintia hills",
      "state_name": "Megalaya"
    },
    {
      "id": 417,
      "city_name": "Na",
      "state_name": "Megalaya"
    },
    {
      "id": 418,
      "city_name": "Ri bhoi",
      "state_name": "Megalaya"
    },
    {
      "id": 419,
      "city_name": "Shillong",
      "state_name": "Megalaya"
    },
    {
      "id": 420,
      "city_name": "South garo hills",
      "state_name": "Megalaya"
    },
    {
      "id": 421,
      "city_name": "Tura",
      "state_name": "Megalaya"
    },
    {
      "id": 422,
      "city_name": "West garo hills",
      "state_name": "Megalaya"
    },
    {
      "id": 423,
      "city_name": "West khasi hills",
      "state_name": "Megalaya"
    },
    {
      "id": 424,
      "city_name": "Aizawl",
      "state_name": "Mizoram"
    },
    {
      "id": 425,
      "city_name": "Champhai",
      "state_name": "Mizoram"
    },
    {
      "id": 426,
      "city_name": "Kolasib",
      "state_name": "Mizoram"
    },
    {
      "id": 427,
      "city_name": "Lawngtlai",
      "state_name": "Mizoram"
    },
    {
      "id": 428,
      "city_name": "Lunglei",
      "state_name": "Mizoram"
    },
    {
      "id": 429,
      "city_name": "Mammit",
      "state_name": "Mizoram"
    },
    {
      "id": 430,
      "city_name": "Na",
      "state_name": "Mizoram"
    },
    {
      "id": 431,
      "city_name": "Saiha",
      "state_name": "Mizoram"
    },
    {
      "id": 432,
      "city_name": "Serchhip",
      "state_name": "Mizoram"
    },
    {
      "id": 433,
      "city_name": "Dimapur",
      "state_name": "Nagaland"
    },
    {
      "id": 434,
      "city_name": "Kiphire",
      "state_name": "Nagaland"
    },
    {
      "id": 435,
      "city_name": "Kohima",
      "state_name": "Nagaland"
    },
    {
      "id": 436,
      "city_name": "Longleng",
      "state_name": "Nagaland"
    },
    {
      "id": 437,
      "city_name": "Mokokchung",
      "state_name": "Nagaland"
    },
    {
      "id": 438,
      "city_name": "Mon",
      "state_name": "Nagaland"
    },
    {
      "id": 439,
      "city_name": "Na",
      "state_name": "Nagaland"
    },
    {
      "id": 440,
      "city_name": "Peren",
      "state_name": "Nagaland"
    },
    {
      "id": 441,
      "city_name": "Phek",
      "state_name": "Nagaland"
    },
    {
      "id": 442,
      "city_name": "Tuensang",
      "state_name": "Nagaland"
    },
    {
      "id": 443,
      "city_name": "Wokha",
      "state_name": "Nagaland"
    },
    {
      "id": 444,
      "city_name": "Zunhebotto",
      "state_name": "Nagaland"
    },
    {
      "id": 445,
      "city_name": "Angul",
      "state_name": "Odisha"
    },
    {
      "id": 446,
      "city_name": "Balangir",
      "state_name": "Odisha"
    },
    {
      "id": 447,
      "city_name": "Baleswar",
      "state_name": "Odisha"
    },
    {
      "id": 448,
      "city_name": "Bargarh",
      "state_name": "Odisha"
    },
    {
      "id": 449,
      "city_name": "Bhadrak",
      "state_name": "Odisha"
    },
    {
      "id": 450,
      "city_name": "Boudh",
      "state_name": "Odisha"
    },
    {
      "id": 451,
      "city_name": "Cuttack",
      "state_name": "Odisha"
    },
    {
      "id": 452,
      "city_name": "Debagarh",
      "state_name": "Odisha"
    },
    {
      "id": 453,
      "city_name": "Dhenkanal",
      "state_name": "Odisha"
    },
    {
      "id": 454,
      "city_name": "Gajapati",
      "state_name": "Odisha"
    },
    {
      "id": 455,
      "city_name": "Ganjam",
      "state_name": "Odisha"
    },
    {
      "id": 456,
      "city_name": "Jagatsinghapur",
      "state_name": "Odisha"
    },
    {
      "id": 457,
      "city_name": "Jajapur",
      "state_name": "Odisha"
    },
    {
      "id": 458,
      "city_name": "Jharsuguda",
      "state_name": "Odisha"
    },
    {
      "id": 459,
      "city_name": "Kalahandi",
      "state_name": "Odisha"
    },
    {
      "id": 460,
      "city_name": "Kandhamal",
      "state_name": "Odisha"
    },
    {
      "id": 461,
      "city_name": "Kendrapara",
      "state_name": "Odisha"
    },
    {
      "id": 462,
      "city_name": "Kendujhar",
      "state_name": "Odisha"
    },
    {
      "id": 463,
      "city_name": "Khorda",
      "state_name": "Odisha"
    },
    {
      "id": 464,
      "city_name": "Koraput",
      "state_name": "Odisha"
    },
    {
      "id": 465,
      "city_name": "Malkangiri",
      "state_name": "Odisha"
    },
    {
      "id": 466,
      "city_name": "Mayurbhanj",
      "state_name": "Odisha"
    },
    {
      "id": 467,
      "city_name": "Nabarangapur",
      "state_name": "Odisha"
    },
    {
      "id": 468,
      "city_name": "Nayagarh",
      "state_name": "Odisha"
    },
    {
      "id": 469,
      "city_name": "Nuapada",
      "state_name": "Odisha"
    },
    {
      "id": 470,
      "city_name": "Puri",
      "state_name": "Odisha"
    },
    {
      "id": 471,
      "city_name": "Rayagada",
      "state_name": "Odisha"
    },
    {
      "id": 472,
      "city_name": "Sambalpur",
      "state_name": "Odisha"
    },
    {
      "id": 473,
      "city_name": "Sonapur",
      "state_name": "Odisha"
    },
    {
      "id": 474,
      "city_name": "Sundergarh",
      "state_name": "Odisha"
    },
    {
      "id": 475,
      "city_name": "Pondicherry",
      "state_name": "Pondicherry"
    },
    {
      "id": 476,
      "city_name": "Amritsar",
      "state_name": "Punjab"
    },
    {
      "id": 477,
      "city_name": "Bathinda",
      "state_name": "Punjab"
    },
    {
      "id": 478,
      "city_name": "Faridkot",
      "state_name": "Punjab"
    },
    {
      "id": 479,
      "city_name": "Fatehgarh sahib",
      "state_name": "Punjab"
    },
    {
      "id": 480,
      "city_name": "Fazilka",
      "state_name": "Punjab"
    },
    {
      "id": 481,
      "city_name": "Firozpur",
      "state_name": "Punjab"
    },
    {
      "id": 482,
      "city_name": "Gurdaspur",
      "state_name": "Punjab"
    },
    {
      "id": 483,
      "city_name": "Hoshiarpur",
      "state_name": "Punjab"
    },
    {
      "id": 484,
      "city_name": "Jalandhar",
      "state_name": "Punjab"
    },
    {
      "id": 485,
      "city_name": "Kapurthala",
      "state_name": "Punjab"
    },
    {
      "id": 486,
      "city_name": "Ludhiana",
      "state_name": "Punjab"
    },
    {
      "id": 487,
      "city_name": "Mansa",
      "state_name": "Punjab"
    },
    {
      "id": 488,
      "city_name": "Moga",
      "state_name": "Punjab"
    },
    {
      "id": 489,
      "city_name": "Mohali",
      "state_name": "Punjab"
    },
    {
      "id": 490,
      "city_name": "Muktsar",
      "state_name": "Punjab"
    },
    {
      "id": 491,
      "city_name": "Nawanshahr",
      "state_name": "Punjab"
    },
    {
      "id": 492,
      "city_name": "Pathankot",
      "state_name": "Punjab"
    },
    {
      "id": 493,
      "city_name": "Patiala",
      "state_name": "Punjab"
    },
    {
      "id": 494,
      "city_name": "Ropar",
      "state_name": "Punjab"
    },
    {
      "id": 495,
      "city_name": "Rupnagar",
      "state_name": "Punjab"
    },
    {
      "id": 496,
      "city_name": "Sangrur",
      "state_name": "Punjab"
    },
    {
      "id": 497,
      "city_name": "Tarn taran",
      "state_name": "Punjab"
    },
    {
      "id": 498,
      "city_name": "Ajmer",
      "state_name": "Rajasthan"
    },
    {
      "id": 499,
      "city_name": "Alwar",
      "state_name": "Rajasthan"
    },
    {
      "id": 500,
      "city_name": "Banswara",
      "state_name": "Rajasthan"
    },
    {
      "id": 501,
      "city_name": "Baran",
      "state_name": "Rajasthan"
    },
    {
      "id": 502,
      "city_name": "Barmer",
      "state_name": "Rajasthan"
    },
    {
      "id": 503,
      "city_name": "Bharatpur",
      "state_name": "Rajasthan"
    },
    {
      "id": 504,
      "city_name": "Bhilwara",
      "state_name": "Rajasthan"
    },
    {
      "id": 505,
      "city_name": "Bikaner",
      "state_name": "Rajasthan"
    },
    {
      "id": 506,
      "city_name": "Bundi",
      "state_name": "Rajasthan"
    },
    {
      "id": 507,
      "city_name": "Chittorgarh",
      "state_name": "Rajasthan"
    },
    {
      "id": 508,
      "city_name": "Churu",
      "state_name": "Rajasthan"
    },
    {
      "id": 509,
      "city_name": "Dausa",
      "state_name": "Rajasthan"
    },
    {
      "id": 510,
      "city_name": "Dholpur",
      "state_name": "Rajasthan"
    },
    {
      "id": 511,
      "city_name": "Dungarpur",
      "state_name": "Rajasthan"
    },
    {
      "id": 512,
      "city_name": "Hanumangarh",
      "state_name": "Rajasthan"
    },
    {
      "id": 513,
      "city_name": "Jaipur",
      "state_name": "Rajasthan"
    },
    {
      "id": 514,
      "city_name": "Jaisalmer",
      "state_name": "Rajasthan"
    },
    {
      "id": 515,
      "city_name": "Jalor",
      "state_name": "Rajasthan"
    },
    {
      "id": 516,
      "city_name": "Jhalawar",
      "state_name": "Rajasthan"
    },
    {
      "id": 517,
      "city_name": "Jhujhunu",
      "state_name": "Rajasthan"
    },
    {
      "id": 518,
      "city_name": "Jodhpur",
      "state_name": "Rajasthan"
    },
    {
      "id": 519,
      "city_name": "Karauli",
      "state_name": "Rajasthan"
    },
    {
      "id": 520,
      "city_name": "Kota",
      "state_name": "Rajasthan"
    },
    {
      "id": 521,
      "city_name": "Nagaur",
      "state_name": "Rajasthan"
    },
    {
      "id": 522,
      "city_name": "Pali",
      "state_name": "Rajasthan"
    },
    {
      "id": 523,
      "city_name": "Pratapghar",
      "state_name": "Rajasthan"
    },
    {
      "id": 524,
      "city_name": "Rajsamand",
      "state_name": "Rajasthan"
    },
    {
      "id": 525,
      "city_name": "Sawai madhopur",
      "state_name": "Rajasthan"
    },
    {
      "id": 526,
      "city_name": "Sikar",
      "state_name": "Rajasthan"
    },
    {
      "id": 527,
      "city_name": "Sirohi",
      "state_name": "Rajasthan"
    },
    {
      "id": 528,
      "city_name": "Sri ganganagar",
      "state_name": "Rajasthan"
    },
    {
      "id": 529,
      "city_name": "Sriganganagar",
      "state_name": "Rajasthan"
    },
    {
      "id": 530,
      "city_name": "Tonk",
      "state_name": "Rajasthan"
    },
    {
      "id": 531,
      "city_name": "Udaipur",
      "state_name": "Rajasthan"
    },
    {
      "id": 532,
      "city_name": "East sikkim",
      "state_name": "Sikkim"
    },
    {
      "id": 533,
      "city_name": "South sikkim",
      "state_name": "Sikkim"
    },
    {
      "id": 534,
      "city_name": "West sikkim",
      "state_name": "Sikkim"
    },
    {
      "id": 535,
      "city_name": "Ariyalur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 536,
      "city_name": "Chennai",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 537,
      "city_name": "Coimbatore",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 538,
      "city_name": "Cuddalore",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 539,
      "city_name": "Dharmapuri",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 540,
      "city_name": "Dindigul",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 541,
      "city_name": "Erode",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 542,
      "city_name": "Kanchipuram",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 543,
      "city_name": "Kanyakumari",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 544,
      "city_name": "Karaikal",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 545,
      "city_name": "Karur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 546,
      "city_name": "Krishnagiri",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 547,
      "city_name": "Madurai",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 548,
      "city_name": "Nagapattinam",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 549,
      "city_name": "Namakkal",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 550,
      "city_name": "Nilgiris",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 551,
      "city_name": "Perambalur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 552,
      "city_name": "Pudukkottai",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 553,
      "city_name": "Ramanathapuram",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 554,
      "city_name": "Salem",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 555,
      "city_name": "Sivaganga",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 556,
      "city_name": "Thanjavur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 557,
      "city_name": "Theni",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 558,
      "city_name": "Tiruchirappalli",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 559,
      "city_name": "Tirunelveli",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 560,
      "city_name": "Tiruppur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 561,
      "city_name": "Tiruvallur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 562,
      "city_name": "Tiruvannamalai",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 563,
      "city_name": "Tiruvarur",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 564,
      "city_name": "Tuticorin",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 565,
      "city_name": "Vellore",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 566,
      "city_name": "Villupuram",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 567,
      "city_name": "Virudhunagar",
      "state_name": "Tamil Nadu"
    },
    {
      "id": 568,
      "city_name": "Adilabad",
      "state_name": "Telangana"
    },
    {
      "id": 569,
      "city_name": "Bhongir",
      "state_name": "Telangana"
    },
    {
      "id": 570,
      "city_name": "Gadwal",
      "state_name": "Telangana"
    },
    {
      "id": 571,
      "city_name": "Hanamkonda",
      "state_name": "Telangana"
    },
    {
      "id": 572,
      "city_name": "Hyderabad",
      "state_name": "Telangana"
    },
    {
      "id": 573,
      "city_name": "Jagtial",
      "state_name": "Telangana"
    },
    {
      "id": 574,
      "city_name": "Jangaon",
      "state_name": "Telangana"
    },
    {
      "id": 575,
      "city_name": "Jayashankar",
      "state_name": "Telangana"
    },
    {
      "id": 576,
      "city_name": "Kamareddy",
      "state_name": "Telangana"
    },
    {
      "id": 577,
      "city_name": "Karim nagar",
      "state_name": "Telangana"
    },
    {
      "id": 578,
      "city_name": "Khammam",
      "state_name": "Telangana"
    },
    {
      "id": 579,
      "city_name": "Kothagudem",
      "state_name": "Telangana"
    },
    {
      "id": 580,
      "city_name": "Kothagudem colls",
      "state_name": "Telangana"
    },
    {
      "id": 581,
      "city_name": "Mahabuababad",
      "state_name": "Telangana"
    },
    {
      "id": 582,
      "city_name": "Mahabub nagar",
      "state_name": "Telangana"
    },
    {
      "id": 583,
      "city_name": "Mancherial",
      "state_name": "Telangana"
    },
    {
      "id": 584,
      "city_name": "Medak",
      "state_name": "Telangana"
    },
    {
      "id": 585,
      "city_name": "Nagar kurnool",
      "state_name": "Telangana"
    },
    {
      "id": 586,
      "city_name": "Nalgonda",
      "state_name": "Telangana"
    },
    {
      "id": 587,
      "city_name": "Nirmal",
      "state_name": "Telangana"
    },
    {
      "id": 588,
      "city_name": "Nizamabad",
      "state_name": "Telangana"
    },
    {
      "id": 589,
      "city_name": "Parkal",
      "state_name": "Telangana"
    },
    {
      "id": 590,
      "city_name": "Peddapalli",
      "state_name": "Telangana"
    },
    {
      "id": 591,
      "city_name": "Ranga reddy",
      "state_name": "Telangana"
    },
    {
      "id": 592,
      "city_name": "Sangareddy",
      "state_name": "Telangana"
    },
    {
      "id": 593,
      "city_name": "Siddipet",
      "state_name": "Telangana"
    },
    {
      "id": 594,
      "city_name": "Sircilla",
      "state_name": "Telangana"
    },
    {
      "id": 595,
      "city_name": "Stn. jadcherla",
      "state_name": "Telangana"
    },
    {
      "id": 596,
      "city_name": "Suryapet",
      "state_name": "Telangana"
    },
    {
      "id": 597,
      "city_name": "Trimulgherry",
      "state_name": "Telangana"
    },
    {
      "id": 598,
      "city_name": "Vikarabad",
      "state_name": "Telangana"
    },
    {
      "id": 599,
      "city_name": "Wanaparthy",
      "state_name": "Telangana"
    },
    {
      "id": 600,
      "city_name": "Warangal",
      "state_name": "Telangana"
    },
    {
      "id": 601,
      "city_name": "Warangal rural",
      "state_name": "Telangana"
    },
    {
      "id": 602,
      "city_name": "Agartala",
      "state_name": "Tripura"
    },
    {
      "id": 603,
      "city_name": "Dhalai",
      "state_name": "Tripura"
    },
    {
      "id": 604,
      "city_name": "Dharmanagar",
      "state_name": "Tripura"
    },
    {
      "id": 605,
      "city_name": "Gomati",
      "state_name": "Tripura"
    },
    {
      "id": 606,
      "city_name": "North tripura",
      "state_name": "Tripura"
    },
    {
      "id": 607,
      "city_name": "Sepahijala",
      "state_name": "Tripura"
    },
    {
      "id": 608,
      "city_name": "South tripura",
      "state_name": "Tripura"
    },
    {
      "id": 609,
      "city_name": "West tripura",
      "state_name": "Tripura"
    },
    {
      "id": 610,
      "city_name": "Agra",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 611,
      "city_name": "Aligarh",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 612,
      "city_name": "Allahabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 613,
      "city_name": "Ambedkar nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 614,
      "city_name": "Amethi",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 615,
      "city_name": "Amroha",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 616,
      "city_name": "Auraiya",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 617,
      "city_name": "Azamgarh",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 618,
      "city_name": "Bagpat",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 619,
      "city_name": "Bahraich",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 620,
      "city_name": "Ballia",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 621,
      "city_name": "Balrampur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 622,
      "city_name": "Banda",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 623,
      "city_name": "Barabanki",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 624,
      "city_name": "Bareilly",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 625,
      "city_name": "Basti",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 626,
      "city_name": "Bijnor",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 627,
      "city_name": "Budaun",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 628,
      "city_name": "Bulandshahr",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 629,
      "city_name": "Chandauli",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 630,
      "city_name": "Chitrakoot",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 631,
      "city_name": "Deoria",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 632,
      "city_name": "Etah",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 633,
      "city_name": "Etawah",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 634,
      "city_name": "Faizabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 635,
      "city_name": "Farrukhabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 636,
      "city_name": "Fatehpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 637,
      "city_name": "Firozabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 638,
      "city_name": "Gautam buddha nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 639,
      "city_name": "Ghaziabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 640,
      "city_name": "Ghazipur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 641,
      "city_name": "Gonda",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 642,
      "city_name": "Gorakhpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 643,
      "city_name": "Hamirpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 644,
      "city_name": "Hapur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 645,
      "city_name": "Hardoi",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 646,
      "city_name": "Haridwar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 647,
      "city_name": "Hathras",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 648,
      "city_name": "Jalaun",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 649,
      "city_name": "Jaunpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 650,
      "city_name": "Jhansi",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 651,
      "city_name": "Kannauj",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 652,
      "city_name": "Kanpur dehat",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 653,
      "city_name": "Kanpur nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 654,
      "city_name": "Kanshiram nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 655,
      "city_name": "Kaushambi",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 656,
      "city_name": "Kheri",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 657,
      "city_name": "Kushinagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 658,
      "city_name": "Lalitpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 659,
      "city_name": "Lucknow",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 660,
      "city_name": "Maharajganj",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 661,
      "city_name": "Mahoba",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 662,
      "city_name": "Mahrajganj",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 663,
      "city_name": "Mainpuri",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 664,
      "city_name": "Mathura",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 665,
      "city_name": "Mau",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 666,
      "city_name": "Meerut",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 667,
      "city_name": "Mirzapur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 668,
      "city_name": "Moradabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 669,
      "city_name": "Muzaffarnagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 670,
      "city_name": "Pilibhit",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 671,
      "city_name": "Pratapgarh",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 672,
      "city_name": "Prayagraj allahabad",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 673,
      "city_name": "Raebareli",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 674,
      "city_name": "Rampur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 675,
      "city_name": "Saharanpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 676,
      "city_name": "Sambhal",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 677,
      "city_name": "Sant kabir nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 678,
      "city_name": "Sant ravidas nagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 679,
      "city_name": "Shahjahanpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 680,
      "city_name": "Shrawasti",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 681,
      "city_name": "Siddharthnagar",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 682,
      "city_name": "Sitapur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 683,
      "city_name": "Sonbhadra",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 684,
      "city_name": "Sultanpur",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 685,
      "city_name": "Unnao",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 686,
      "city_name": "Varanasi",
      "state_name": "Uttar Pradesh"
    },
    {
      "id": 687,
      "city_name": "Almora",
      "state_name": "Uttarakhand"
    },
    {
      "id": 688,
      "city_name": "Bageshwar",
      "state_name": "Uttarakhand"
    },
    {
      "id": 689,
      "city_name": "Chamoli",
      "state_name": "Uttarakhand"
    },
    {
      "id": 690,
      "city_name": "Champawat",
      "state_name": "Uttarakhand"
    },
    {
      "id": 691,
      "city_name": "Dehradun",
      "state_name": "Uttarakhand"
    },
    {
      "id": 692,
      "city_name": "Haridwar",
      "state_name": "Uttarakhand"
    },
    {
      "id": 693,
      "city_name": "Nainital",
      "state_name": "Uttarakhand"
    },
    {
      "id": 694,
      "city_name": "Pauri garhwal",
      "state_name": "Uttarakhand"
    },
    {
      "id": 695,
      "city_name": "Pithoragarh",
      "state_name": "Uttarakhand"
    },
    {
      "id": 696,
      "city_name": "Rudraprayag",
      "state_name": "Uttarakhand"
    },
    {
      "id": 697,
      "city_name": "Tehri garhwal",
      "state_name": "Uttarakhand"
    },
    {
      "id": 698,
      "city_name": "Udham singh nagar",
      "state_name": "Uttarakhand"
    },
    {
      "id": 699,
      "city_name": "Uttarkashi",
      "state_name": "Uttarakhand"
    },
    {
      "id": 700,
      "city_name": "Bankura",
      "state_name": "West Bengal"
    },
    {
      "id": 701,
      "city_name": "Bardhaman",
      "state_name": "West Bengal"
    },
    {
      "id": 702,
      "city_name": "Birbhum",
      "state_name": "West Bengal"
    },
    {
      "id": 703,
      "city_name": "Cooch behar",
      "state_name": "West Bengal"
    },
    {
      "id": 704,
      "city_name": "Darjeeling",
      "state_name": "West Bengal"
    },
    {
      "id": 705,
      "city_name": "East midnapore",
      "state_name": "West Bengal"
    },
    {
      "id": 706,
      "city_name": "Hooghly",
      "state_name": "West Bengal"
    },
    {
      "id": 707,
      "city_name": "Howrah",
      "state_name": "West Bengal"
    },
    {
      "id": 708,
      "city_name": "Jalpaiguri",
      "state_name": "West Bengal"
    },
    {
      "id": 709,
      "city_name": "Kolkata",
      "state_name": "West Bengal"
    },
    {
      "id": 710,
      "city_name": "Malda",
      "state_name": "West Bengal"
    },
    {
      "id": 711,
      "city_name": "Murshidabad",
      "state_name": "West Bengal"
    },
    {
      "id": 712,
      "city_name": "Nadia",
      "state_name": "West Bengal"
    },
    {
      "id": 713,
      "city_name": "North 24 parganas",
      "state_name": "West Bengal"
    },
    {
      "id": 714,
      "city_name": "North dinajpur",
      "state_name": "West Bengal"
    },
    {
      "id": 715,
      "city_name": "North sikkim",
      "state_name": "West Bengal"
    },
    {
      "id": 716,
      "city_name": "Puruliya",
      "state_name": "West Bengal"
    },
    {
      "id": 717,
      "city_name": "South 24 parganas",
      "state_name": "West Bengal"
    },
    {
      "id": 718,
      "city_name": "South dinajpur",
      "state_name": "West Bengal"
    },
    {
      "id": 719,
      "city_name": "West midnapore",
      "state_name": "West Bengal"
    },
    {
      "id": 720,
      "city_name": "Noida",
      "state_name": "Uttar Pradesh"
    }
  ]

export default locationIds
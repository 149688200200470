import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import WeekToggle from '../../../JobList/newJobViewComponents/weektoggleButton'
import './modals.css'
const Availabilities =(props) =>{
    const list=[]
    const weekTodays =(week) =>{
        const days=[]
        if(week["2"]===true)
        days.push("monday")
        if(week["3"]===true)
        days.push("tuesday")
        if(week["4"]===true)
        days.push("wednesday")
        if(week["5"]===true)
        days.push("thursday")
        if(week["6"]===true)
        days.push("friday")
        if(week["7"]===true)
        days.push("saturday")
        if(week["1"]===true)
        days.push("sunday")
        if(week["0"]===true)
        days.push("sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday")
        return days;
    }
    const availabilities= props.data==="All"?
        "Available all time":
        props.data.map((a, index) =>{
            return (
                <Grid xs={12} className={`slotContainer`}>
                    <span className="slotHeading">Slot {index+1}:</span>
                    <WeekToggle
                        isdisabled={true}
                        value={weekTodays(a.workdaysDetails)}
                    />
                    <span>{`${a.startTime} to ${a.endTime}`}</span>
                </Grid>
            )
        })
    return (
        [availabilities]
    )
}
export default Availabilities;
import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import user from '../../assets/user.svg'
import clock from '../../assets/clock.svg'

const flexDisplay = {
    margin: 'auto',
  },
  noteBorder = {
    border: 'solid 1px #e8e8e8',
    borderRadius: '2px',
    padding: '10px 27px 20px 27px'
  },
  NameNum = {
    objectFit: 'contain',
    fontSize: '16px',
    fontWeight: 'light',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    lineHeight: '2.5'
  },
  blueFont = {
    color: '#1c75bc'
  },
  Timing = {
    objectFit: 'contain',
    fontSize: '16px',
    fontWeight: 'light',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    lineHeight: '2.5'
  },
  txtArea = {
    border: 'none',
    borderBottom: 'solid 1px #1c75bc',
    fontSize: '14px',
    width: '320px',
    margin: '0 55px'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    margin: '20px 10px 10px 10px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right',
    margin: '20px 10px 10px 10px'
  },
  imgSpace = {
    marginRight: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '390px'
    },
  }));

const CallDisplayModal = (props) => {
  const classes = useStyles();

  const {opModal, setOpModal} = props;
  const handleModal = () => {
    setOpModal(false)
  }

  const [selectedDate, handleDateChange] = useState(new Date());
  return (

    <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opModal}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <div className={classes.paper}>
        <Grid container>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
              <h1>Call Reminder</h1>
              {/* <Button onClick={handleModal}>X</Button> */}
          </Grid>
          <Grid>
          <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
              <span style={NameNum}> <img src={user} style={imgSpace} /> Nikhil Chopra - <strong style={blueFont}>998231344</strong> </span>
          </Grid>
          <Grid>
              <span style={Timing}> <img src={clock} style={imgSpace} /> 12:00 pm, Saturday, 28 February 2020 </span>
          </Grid>
          </Grid>
          <Grid>
            <div style={noteBorder}>
                <span>Notes</span>
                <p>Please submit your CV in a .pdf format. </p>
            </div>
          </Grid>
          <Grid item xs={12} className="padding0" direction="row">
                <Button style={cancel}>SNOOZE</Button>
                <Button style={confirm}>OK</Button>
          </Grid>
        </Grid>
        </div>
    </Modal>
  );
};

export default CallDisplayModal;

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Layer2 from '../assets/Layer 2.png';
import FacebookIcon from '../assets/Page-1.png';
import InstaIcon from '../assets/Insta-1.png'
import LinkedInIcon from '../assets/LinkedIn-1.png'
import { Phone, Email, LocationOn } from '@material-ui/icons';
import { Link } from 'react-router-dom';


function Copyright() {
  return (
    <>
      <div className="body3">
        {'2020 © IJ Bemus Pvt. Ltd. '}
      </div>
      <div className="body3">
        <Link to="/privacy-policy" className='link-white'> {'Privacy Policy '}</Link>
      </div>
      <div className="body3 icons">
        <div className="social-icon">
          <img src={FacebookIcon}
            
            class="Page-1" alt="Facebook" />
          <img src={InstaIcon}
            class="Page-1" alt="Instagram" />
          <img src={LinkedInIcon}
            class="Page-1" alt="Linked IN" />

        </div>
      </div>

    </>
  );
}


export default function Footer() {

  return (
    <div className='footer-container'>
      <CssBaseline />
      <Container component="main" maxWidth="sm">

        <div className='layer'>
          <img src={Layer2} alt="layer2" />
        </div>
        <Typography gutterBottom>
          <span className="icon"><LocationOn className="svg-disabled" />
          </span> <span className="text"> {'7th Floor, Paras Downtown Centre, Golf Course Road, DLF Phase V, Gurgaon, Haryana, 122003 India'} </span>
        </Typography>
        <Typography gutterBottom>
          <span className="icon"><Phone className="svg-disabled" /></span> <span className="text">{'8882866060'}</span>
        </Typography>
        <Typography gutterBottom>
          <span className="icon"> <Email className="svg-disabled" /></span> <span className="text">{'contact@okaygo.in'}</span>
        </Typography>
        <Typography variant="body1" className="paddingBottom50"><Link to="/help" className='link-white faq marginBottom35'>FAQ</Link></Typography>
      </Container>
      <div className="footer-devider"></div>
      <footer >
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
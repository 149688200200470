/* eslint-disable no-use-before-define */
import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  },
  textField: {
    width: '100px'
  },
  textSize: {
    fontSize: '0.8rem'
  },
  listbox: {
    fontSize: '0.8rem'
  }
});

class CustomMultiSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.data,
      updatedValue:this.props.updatedDefault
    };
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onChangeInput(valueElement, name) {
    this.props.updateChange(name, valueElement);
  }

  componentWillMount() {
    console.log("true",this.props.updatedDefault);
    
    this.props.updateChange(this.props.name, this.props.updatedDefault);
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.root}>
        <Autocomplete
          multiple
          classes={{
            paper: classes.listbox
          }}
          name={this.props.name}
          onChange={(event, value) => {
            this.onChangeInput(value, this.props.name);
          }}
          options={this.state.value}
          getOptionLabel={option => option}
          defaultValue={this.props.default}
          value={this.props.default}
          renderInput={params => {
            return (
              <TextField
                {...params}
                variant='standard'
                label={this.props.label}
                style={{ width: '200px' }}
                InputLabelProps={{
                  className: classes.textSize
                }}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomMultiSelectDropDown);

import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App/App';

render(<>
    <App />
</>,
document.getElementById('root'));


import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Grid , Button, TextField, Input, Select, MenuItem, FormControl, InputLabel, Checkbox, Drawer, IconButton, Badge, ListItemIcon  } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components'
import { useTable, useRowSelect, useFilters } from 'react-table'
import { useHistory } from "react-router-dom";
import VacancyRecruiterDetails from '../RecuiterDashboard/VacancyRecruterDetails'
import Action from './RecruiterDBComponents/Action'
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDebounce } from "use-debounce";
import ApplicationStatus from './RecruiterDBComponents/ApplicationStatuses'
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ApplicationUpdates from './RecruiterDBComponents/ApplicationUpdates'
import Drawers from './RecruiterDBComponents/Drawers'
import FilterIcon from '../../assets/icons8-filter-100.png'
// import Drawers from './RecruiterDBComponents/Drawers'
import ToggleButton from '@material-ui/lab/ToggleButton';
import CallModal from '../../utils/Modals/CallModal'
import CallDisplayModal from '../../utils/Modals/CallDisplayModal'
import ShareApp from '../../utils/Modals/ShareApp'
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import Reschedule from '../../utils/Modals/Reschedule'
import qs from "querystring"
import { ToastContainer, toast } from 'react-toastify';
import CandidateDetails from './RecruiterDBComponents/CandidateDetails'
import SelectVacancy from '../RecuiterDashboard/SelectVacancyPage'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from 'axios'
import Pagination from '../../utils/react-table/pagination'
import Filters from './FilterModal'
import JobLinkModal from './RecruiterDBComponents/showJobLinkModal'
import SelectInput from './RecrutierDBElements/Select'
import urlLinks from '../../urlLinks'
import ShowReminder from './RecruiterDBComponents/showReminderComponent'
import {experience, ageBracket, weekTodays, setqualification, setcuisine, setgender} from "../Job/JobList/newJobViewComponents/jobRequirement"
import * as moment from 'moment'
import JobModal from '../../utils/Modals/jobModal'
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AssignToVacancy from './RecruiterDBComponents/AssignToOtherVacancyModal' 
import calender from '../../assets/calender.svg'
import phone from '../../assets/phone.svg'
import file from '../../assets/file.svg'
import filter from '../../assets/filter.svg'
import { NONAME } from 'dns';
import Header from "../../Header/Header";
import "./common.css";

const Styles = styled.div`
        padding: 0 !important;
        margin-top: 20px !important;
        width: 100%;
        .tableContainer{
            border: none;
            border-radius: 5px; 
        }
        table {
            border-spacing: 0;
            
            vertical-align: baseline;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
                vertical-align: baseline;
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #EEEEEE !important;
                color: #333333 !important;

            }
            th,td{
                padding:5px 15px;
                vertical-align: baseline;
            }
            
            td {
                vertical-align: baseline;
                margin: 0;
                border-bottom: 1px solid #cfcfcf;
                vertical-align: top;
                background-color: white;
                nth-last-child(2) {
                    border-right: 0;
                } 
                :last-child {
                    border-right: 0;
                }

                input {
                    font-size: 1rem;
                    padding: 0;
                    margin: 0;
                    border: 0;
                }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`

const CustomAutocomplete = withStyles({
  
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0'
      }
    }
  })(Autocomplete);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root": {
        //  border:"1px solid grey",
        marginLeft:'0',
        "&.Mui-focused fieldset": {
          border: "1px solid grey"
        }
      }
    },
    input: {
      // padding:0,
      '& [class*="MuiOutlinedInput-input"]': {
        padding: "8px",
        // borderLeft: "1px solid grey",
        borderRadius: "0px"
      }
    },
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            padding: "8px 10px"
        }
    }
  }));
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox style={{borderRadius: '2px',border: 'solid 1px #cccccc'}} ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)
const RecruiterDashboard =() =>{
    const [openReminderModal, setOpenReminderModal] = useState(false);
    const [opModal, setOpModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [reModal, setReModal] = useState(false);
    const classes = useStyles();
    const [nameNumberSearch, setNameNumberSearch] = useState(localStorage.getItem("showingReminderRecord")?JSON.parse(localStorage.getItem("showingReminderRecord")).phoneNumber:undefined)
    const history= useHistory();
    const [jobDetails, setJobDetails] = useState()
    const [sortBy, setSortBy] = useState("")
    const [sortDirection, setSortDirection] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [jobList, setJobList] = useState([])
    const [openJobLink, setOpenJobLink] = useState(false)
    const [perPage, setPerPage] = useState(10);
    const [openAssignVacancyModal, setOpenAssignVacancyModal] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [filterUrl, setFilterUrl] = useState('')
    const [intervalHook, setIntervalHook] = useState()
    const [sortUrl, setSortUrl] = useState('')
    const [shortLink, setShortLink] = useState("")
    const [reminderList, setReminderList] = useState()
    const [displayReminders, setDisplayReminders] = useState([])
    const [copyLink, setCopyLink] = useState("")
    const [quesList, setQuesList] = useState(0)
    const [disableQuestionnaireButton, setDisableQuestionnaireButton] = useState(1)
    const [recruiterList, setRecruiterList] = useState([])
    // const [recruiterData, setRecruiterData] = useState((localStorage.getItem('roleType')!== "Super Admin" || localStorage.getItem('roleType')!== "Operational Admin")?{userID: localStorage.getItem('userId')}: history?.location?.recruiterDashBoardData?.recruiterId?{userID: history?.location?.recruiterDashBoardData?.recruiterId}:undefined)
    const [recruiterData, setRecruiterData] = useState((localStorage.getItem('roleType')!== "Super Admin" && localStorage.getItem('roleType')!== "Operational Admin")?
                                                            {userID: localStorage.getItem('userId')}:localStorage.getItem("showingReminderRecord")?
                                                            Object.keys(JSON.parse(localStorage.getItem("showingReminderRecord")).recruiterData).length>0 ?
                                                                JSON.parse(localStorage.getItem("showingReminderRecord")).recruiterData:undefined:undefined)
    const [jobValue, setJobValue] = useState(localStorage.getItem("showingReminderRecord")?JSON.parse(localStorage.getItem("showingReminderRecord")).jobValue:undefined)  
    const [openJobModal, setOpenJobModal] = useState(false)
    const [vacancyStatusData, setVacancyStatusData] = useState()
    const [userIdList, setUserIdList] = useState([])
    const [filterDrawer, setFilterDrawer] = useState(false)
    const [debouncedText] = useDebounce(nameNumberSearch, 350);
    const [refreshToken, setRefreshToken] = useState(0)
    const cpLinkRef = useRef(null);
    
    const [filters, setFilters] = useState({
        callStatus:[""],
        lastUpdatedOn:"",
        dateAssignedOn:"",
        interviewDate:"",
        datesLastUpdatedOn:[null, null],
        datesInterviewDate:[null, null],
        datesAssignedOn:[null, null],
        intExt: '',
        workerId: '',
        callStatusDate: [null, null],
        score: 0,
        approvalStatus:[""],
        applicationStage:[""],
        interviewMode:"",
        interviewStatus:'',
        preferredInterviewDate: [null, null],
        interviewSelectionDate:[null, null],
        interviewFollowUp:[""],
        recruiterFeedback:[""],
        employerFeedback:[""],
        appliedBy:[""],
        finalFeedback:[""],
        selectionDate:[null, null],
        joiningDate:[null, null],
        offeredSalary:0,
        joiningStatus:[""],
        dateOfLeaving:[null, null],
        nameNumber: '',
        remark_status:[],
        preferred_job_type_id:[],
        low_salary:0,
        preferred_location_id:[],
        skill_mismatch: null,
        communication_issue:null,
        call_status_date: [null, null],
        profile_shared_date: [null, null],
        recruiter_assigned_date: [null, null],
        calls_connected: false,
        candidates_interested: false,
        candidates_approved: false,
        interviews_scheduled: false,
        candidate_appeared: false,
        candidates_selected: false,
        candidate_joined: false,
        calls_pending: false,
        followup_calls: false

    })
    // console.log((localStorage.getItem('roleType')!== "Super Admin" || localStorage.getItem('roleType')!== "Operational Admin"))
    
    // console.log({nameNumberSearch})
    // console.log(JSON.parse(localStorage.getItem("showingReminderRecord")))
    const handleJobLink =() =>{
        let postData= {
            app_name: "WORKER",
            job_detail_id : jobValue.jobDetailId,
            job_id : jobValue.jobId
        }
        Axios.post(`${urlLinks.baseUrl}deeplink/generateDeepLink`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){
                    // console.log({response})
                    setShortLink(response.data.response.shortLink)
                    // setOpenJobLink(true)
                }
                else{
                    showToast("error", "Unable to generate Job link")
                }
            })
            .catch(error =>{
                showToast("error", "Unable to generate Job link")
            })
    }
    useEffect(() => {
        setFilters({...filters, nameNumber: debouncedText })
        // console.log({debouncedText})
    }, [debouncedText])
    
    const optionsSortBy=[
        ["dateAssigned", "Date assigned"],
        ["lastUpdated", "Last updated"],
        ["profileSharedDate", "Profile shared date"],
        ["interviewSelectionDate", "Interview selection date"],
        // ["interviewDate", "Interview date"],
        ["selectionDate", "Selection date"],
        ["joiningDate", "Joining date"]
    ]
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const columns = useMemo(
        () => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ background: "unset", borderRadius: "2px", padding: 0, marginLeft: "10px"}} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{paddingTop: 0}} />
            </div>
          ),
        },
        {
            Header: "Candidate details",
            accessor: "candidateDetails",
            Cell: CandidateDetails,
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Application Status",
            accessor: "applicationStaus",
            Cell: ApplicationStatus,
            defaultCanFilter :false,
            minWidth: 250,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Application updates",
            accessor: "applicationUpdates",
            Cell: ApplicationUpdates,
            defaultCanFilter :false,
            minWidth: 250,
            maxWidth:300,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: Action,
            minWidth: 250,
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        }
        ], 
        []
    );
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const idToUserId =() =>{
        // console.log({selectedRowIds});
        const indexes= Object.keys(selectedRowIds)
        let idList=[]
        indexes.map(id => {
            idList.push(data[id].userId)
        })
        
        // console.log(idList.toString())
        return idList;
    }
    useEffect(() => {
        let userIdList= []
        if(openAssignVacancyModal )
            setUserIdList(idToUserId())
    }, [openAssignVacancyModal])
    const updateMyData = () => {
        getActiveApplicationList(perPage, currentPage);
        getVacancyStatus()
    }
    const getAllReminders = () =>{
        console.log("reminder list called")
        if(localStorage.getItem("roleType")==="recruiter"){
            Axios.get(`${urlLinks.baseUrl}recruiter/getAllReminders?recruiterId=${localStorage.getItem('userId')}`)
                .then(response =>{
                    clearInterval(intervalHook)
                    setIntervalHook(null);
                    setReminderList(makeReminderList(response.data.response))
                })
        }
    }
    useEffect(()=>{
        setDisableQuestionnaireButton(0)
        // console.log("function called",{ jobValue});
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${jobValue.jobId}`)
            .then(response=>{
                let disable= response?.data?.length
                setQuesList(disable)
                setDisableQuestionnaireButton(disable>0?2:1);
            })
            handleJobLink();
        }
        if(!localStorage.getItem("showingReminderRecord"))
            setNameNumberSearch("");
        setFilters({
            callStatus:[""],
            lastUpdatedOn:"",
            dateAssignedOn:"",
            interviewDate:"",
            datesLastUpdatedOn:[null, null],
            datesInterviewDate:[null, null],
            datesAssignedOn:[null, null],
            intExt: '',
            workerId: '',
            callStatusDate: [null, null],
            score: 0,
            approvalStatus:[""],
            applicationStage:[""],
            interviewMode:[""],
            interviewStatus:'',
            preferredInterviewDate: [null, null],
            interviewSelectionDate:[null, null],
            interviewFollowUp:[""],
            recruiterFeedback:[""],
            employerFeedback:[""],
            appliedBy:[""],
            finalFeedback:[""],
            selectionDate:[null, null],
            joiningDate:[null, null],
            offeredSalary:0,
            joiningStatus:[""],
            dateOfLeaving:[null, null],
            nameNumber: localStorage.getItem("showingReminderRecord")?JSON.parse(localStorage.getItem("showingReminderRecord")).phoneNumber:"",
            remark_status:[],
            preferred_job_type_id:[],
            low_salary:0,
            preferred_location_id:[],
            skill_mismatch: null,
            communication_issue:null,
            call_status_date: [null, null],
            profile_shared_date: [null, null],
            recruiter_assigned_date: [null, null],
            calls_connected: false,
            candidates_interested: false,
            candidates_approved: false,
            interviews_scheduled: false,
            candidate_appeared: false,
            candidates_selected: false,
            candidate_joined: false,
            calls_pending: false,
            followup_calls: false
        })
    },[jobValue])

    const ApplySort = () =>{
        let url=''
        if(sortBy){
            switch (sortBy) {
                case "dateAssigned":
                    url='&orderBy=recruiter_assigned_date'
                    break;
                case "lastUpdated":
                    url='&orderBy=captured_on'
                    break;
                case "profileSharedDate":
                    url='&orderBy=profile_shared_date'
                    break;
                case "interviewSelectionDate":
                    url='&orderBy=interviews.accepted_on'
                    break;
                case "joiningDate":
                    url='&orderBy=interviews.joining_date'
                    break;
                case "selectionDate":
                    url='&orderBy=interviews.offered_date'
                    break;
                default:
                    url=''
                    break;
            }
            if(sortDirection)
                url=`${url}&sortBy=ascending`
            setSortUrl(url)
        }
        else
            setSortUrl('')
    }
    const checkFunction =() =>{
        console.log("check");
        return "hello";
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy, sortDirection])
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: {hiddenColumns, selectedRowIds}} = useTable({columns, data, updateMyData, showToast, jobDetails, setLoading, disableQuestionnaireButton, quesList, filters, setFilters, getAllReminders,  manualSortBy: true,  }, useFilters,   useRowSelect)
    const makeJobData =(data) =>{
        let exp="None";
        let qualification="None";
        let cuisine="None";
        // console.log({data});
        let recruiterPayout="";
        let leaderPayout=""
        if(data?.detailsJobs[0]?.jobPayout?.leaderPayoutAmount && data?.detailsJobs[0]?.jobPayout?.remoteRecPayoutAmount){
            leaderPayout=`${numberFormat(data.detailsJobs[0].jobPayout.leaderPayoutAmount)} per joining`;
            recruiterPayout=`${numberFormat(data.detailsJobs[0].jobPayout.remoteRecPayoutAmount)} per joining`
        }
        else{
            if(data?.detailsJobs[0]?.jobPayout?.okaygoCommissionTerm===0){
                leaderPayout=`${numberFormat(data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining * 0.5)} per joining`;
                recruiterPayout=`${numberFormat(data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining * 0.3)} per joining`;
            }
            else if(data?.detailsJobs[0]?.jobPayout?.okaygoCommissionTerm===1){
                leaderPayout=`${data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining * 0.5}% of annual CTC`;
                recruiterPayout= `${data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining * 0.3}% of annual CTC`;
            }
        }
        if(data.detailsJobs && data.detailsJobs[0] && data.detailsJobs[0].detailSkills.length>0){
            data.detailsJobs[0].detailSkills.map(skill =>{
                if(skill.skillsTypeId>0 && skill.skillsTypeId<8 )
                qualification=skill.skillsTypeId; 
                else if(skill.skillsTypeId>39 && skill.skillsTypeId<44 )
                exp=skill.skillsTypeId;
                else if((skill.skillsTypeId>31 && skill.skillsTypeId<36)|| skill.skillsTypeId===115)
                cuisine=skill.skillsTypeId;
                else{
                }
            })
        }
        console.log("first")
        const startTime=data.loginTime?moment(data.loginTime, "hh:mm a").format("hh:mm A"):null;
        const endTime=data.logoutTime?moment(data.logoutTime, "hh:mm a").format("hh:mm A"):null;
        const startDate= data.startDate? data.startDate:null;
        let perkArray=[]
        if(data.company.is_2meal)
            perkArray.push("Meals provided")
        if(data.company.is_conveyance)
            perkArray.push("Conveyance provided")
        if(data.company.is_pf_esic)
            perkArray.push("Pf/Esic provided")
        if(data.company.is_rooms)
            perkArray.push("Accommodation provided")
            console.log("second")
        const jobData={
            brandName: data.brandName?data.brandName:data.companyName,
            encryptId: data.detailsJobs[0].jobDetailsId?convertJobIdtoHex(data.detailsJobs[0].jobDetailsId):'-',
            jobInterviewType: data.detailsJobs[0].jobInterviewType,
            companyDetails: data.company.shortDescription,
            
            perks: perkArray,
            jobTitle: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'-',
            workType:data.workType,
            vacancyType: data.detailsJobs[0].vacancyType==="entry"?
                "Entry level"
                :data.detailsJobs[0].vacancyType==="mid"?
                    "Mid level"
                    :data.detailsJobs[0].vacancyType==="senior"?
                        "Senior level"
                        :'N/a',
            payrollType: data.detailsJobs[0].payrollType==="company"?
                "Company"
                :data.detailsJobs[0].payrollType==="third"?
                    "Third Party"
                    :data.detailsJobs[0].payrollType==="contract"?
                        "Contract":'N/a',
            noOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'-',
            city: data.city_name?`${data.city_name}${data.state_name?", " :''}${data.state_name?data.state_name:''}`:'-',
            ExactJobAddress: data.address?data.address:'-',
            
            userId:data.userId,
            accountManager:data.accountManager,
            lat:data.jobLocationLat?data.jobLocationLat:null,
            long:data.jobLocationLong?data.jobLocationLong:null,
            salary: data.workType===3?`${numberFormat(data.detailsJobs[0].amount)}`:(data.detailsJobs[0].minSalary===data.detailsJobs[0].maxSalary)?`${numberFormat(data.detailsJobs[0].minSalary)}`:`${numberFormat(data.detailsJobs[0].minSalary)} - ${numberFormat(data.detailsJobs[0].maxSalary)}`,
            salaryType: data.detailsJobs[0].amountPer,
            jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'-',
            noOfDays: data.workdaysCount?data.workdaysCount:'-',
            noOfHrs: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:'-',
            weekdays: data.workdaysDetails?weekTodays(JSON.parse(data.workdaysDetails)): [],
            startTime: startTime,
            endTime: endTime,
            startDate: startDate,
            jobRole:data.detailsJobs[0].jobTypeId,
            salaryStructure:data.detailsJobs[0].salaryCompensaction, 
            check: checkFunction(),
            exp: experience(exp),
            jobInterviewType: data?.jobInterviewType,
            // check: checkFunction(),
            gender:data.detailsJobs[0].gender===26?"Male":data.detailsJobs[0].gender===27?"Female":"Any",
            qualification:  setqualification(qualification),
            cuisine: setcuisine(data.detailsJobs[0].cuisine===null? cuisine:data.detailsJobs[0].cuisine),
            specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification: "N/a",
            engFluency: data.detailsJobs[0].englishFluency===1?
                "No English"
                :data.detailsJobs[0].englishFluency===2?
                    "Thoda English"
                    :data.detailsJobs[0].englishFluency===3?
                        "Good English":"-",
            ageBracket: ageBracket(data.detailsJobs[0].minAge, data.detailsJobs[0].maxAge),
            cvRequired: data.detailsJobs[0].cvRequired===0?
                "Not Required"
                :data.detailsJobs[0].cvRequired===1?
                    "Optional"
                    :data.detailsJobs[0].cvRequired===2?
                        "Mandatory":"-",
            interviewAddress: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewAddress?
                    data.detailsJobs[0].jobInterviewDetails.interviewAddress:'-'
                :'-' ,
            interviewProcess: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewProcess?
                    data.detailsJobs[0].jobInterviewDetails.interviewProcess:'-'
                :'-',
            lat:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLat?
                    data.detailsJobs[0].jobInterviewDetails.interviewLat:null
                :null,
            long:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLong?
                    data.detailsJobs[0].jobInterviewDetails.interviewLong:null
                :null,
            // interviewSlots:  interviewArraytoSlots(JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays)),
            interviewSlots:  data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays? 
                    JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
                :[],
            interviewDesc: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
                    data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:'-'
                :'-',
            
            pocName:data.contactCandidateName?data.contactCandidateName:'-' ,
            recruiterPayoutAmount:recruiterPayout,
            leaderPayoutAmount:leaderPayout,
            instructionsCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
            instructionsRecruiter:data.detailsJobs[0].instructionsRecruiter?data.detailsJobs[0].instructionsRecruiter:'', 
            replacementPeriod:  data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.okaygoReplacementClause?
                `${data.detailsJobs[0].jobPayout.okaygoReplacementClause} days`:null
                :null,
            recruiterPitch: data.detailsJobs[0].recruiterPitch?data.detailsJobs[0].recruiterPitch:'',
            recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.remoteRecPayout_details?
                    data.detailsJobs[0].jobPayout.remoteRecPayout_details:'':'',

        }
        console.log({jobData})
        return jobData;
    }
    const getJobDetails =() =>{
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${jobValue.jobId}`)
                .then(response =>{
                    if(response){
                        setJobDetails(makeJobData(response.data.response.content[0]))
                    }
                })
        }
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                jobDetailId: el.jobDetailId,
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:''
            })
        })
        return list;
    }

    const getVacancyStatus =() =>{
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}recruiter/getRecruiterDashboardData?job_details_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recuiter_id=${recruiterData.userID}`)
                .then(response =>{
                    if(response){
                        // console.log({response})
                        setVacancyStatusData(response.data.response)
                    }
                })
        }
    }
    const applicationStage = (name, status) =>{
        switch (status) {
            case "INTERESTED":
                return "Candidate interested";
                break;
            case "NOT_INTERESTED":
                return "Candidate not interested";
                break;
            case "APPROVED":
                return "Approved";
                break;
            case "APPLIED":
                return "Applied";
                break;
            case "NOT_APPROVED":
                return "Not approved";
                break;
            case "SHORTLISTED":
                return "Shortlisted";
                break;
            case "REJECTED":
                if(name==="APPLIED JOB")
                    return "Candidate rejected";
                else if(name==="INTERVIEW STATUS")
                    return "Rejected";
                else if(name==="RECRUITER_FEEDBACK")
                    return "Rejected";
                else if(name==="EMPLOYER_FEEDBACK")
                    return "Rejected";
                else if(name==="OFFER JOB")
                    return "Offer rejected";
                break;
            case "NO SHOW":
            case "NOT_GOING":
            case "NO_RESPONSE":
            case "NOT_DROPPED":
            case "CANDIDATE_DROPPED":
                return "Candidate dropped";
                break;
            case "GOING":
            case "SLOTS CREATED":
            case "SLOT SELECTED":
            case "SLOT UPDATED":
            case "RESCHEDULE":
                return "Interviewing";
                break;
            case "APPEARED":
            case "ROUNDS_PENDING":
            case "RESULT_AWAITED":
                return "Results awaited";
                break;
            case "SELECTED":
                return "Selected";
                break;
            case "ACCEPTED":
                return "Offer accepted";
                break;
            case "UPDATE JOINING DATE":
                return "Updated joining date";
                break;
            case "JOINED":
                return "Joined";
                break;
            case "NOT_APPEARED":
                return "Not appeared";
                break;
            case "LEFT_AFTER_JOINING":
                return "Left after joining";
                break;
            case "OFFERED":
                return "Job offered";
                break;
            case "POSTPONED":
                return "Postponed";
                break;
            case "NOT_JOINING":
                return "Candidate not joining";
                break;
            default:
                return "null"
                break;
        }
    }
    const setInterviewDate = (el) =>{
        
        if(el.interview_mode===3){

            if(el.whichSlot===0)    
                return "";
            else{
                if(el.whichSlot===1){
                    if(el.interviewStartTime)
                        return `${moment(el.interviewStartTime).format("DD/MM/YYYY")} ${moment(el.interviewStartTime).format("hh:mm a")} - ${moment(el.interviewEndTime).format("hh:mm a")}`;
                    else 
                        return '';
                } 
                if(el.whichSlot===2)    
                return `${moment(el.interviewStartTime2).format("DD/MM/YYYY")} ${moment(el.interviewStartTime2).format("hh:mm a")} - ${moment(el.interviewEndTime2).format("hh:mm a")}`;
                if(el.whichSlot===3)    
                return `${moment(el.interviewStartTime3).format("DD/MM/YYYY")} ${moment(el.interviewStartTime3).format("hh:mm a")} - ${moment(el.interviewEndTime3).format("hh:mm a")}`;
            }
        }
        else if(el.interview_mode===2){
            if(el.from_date)
                return `${moment(el.from_date).format("DD/MM/YYYY")} - ${moment(el.to_date).format("DD/MM/YYYY")}`
            else 
                return ''
        }
    }

    const makeData= (response) =>{
        console.log(response.content)
        
        let extractedData = [];
        // let index= response.pageable.offset+1;
        response.content.map(el => {
            // console.log({el})
            extractedData.push({
                intExt:el.int_ext?el.int_ext:'',
                assignId: el.assign_id?el.assign_id:'',
                dataSource: el.added_by===parseInt(recruiterData.userID)?"Added by Self":"Added by OkayGo",
                // jobInterviewType: jobInterviewType,
                workerIdLabel: el.worker_id?el.int_ext==="INTERNAL"?`I-${el.worker_id}`:`E-${el.worker_id}`:'',
                workerId: el.worker_id?el.worker_id:'',
                recruiterId: el.recuiter_id,
                recruiterName: el.recruiter_name,
                recruiterPayout:el.recruiter_payout==null?null:numberFormat(el.recruiter_payout),
                isRecruiterPaid:el.is_recruiter_paid,
                jobDetailsId: jobValue.jobDetailId,
                cvLink: el.cv_link,
                jobId: jobValue.jobId,
                quesScore: `${el.question_score?el.question_score:0} / `,
                questionnaireScore: el.question_score?el.question_score:0,
                appliedBy: el.appliedBy,
                appliedByName: el.appliedByName,
                cvAvailable: el.cv_link?"Yes":"No",
                userId: el.user_id?el.user_id:'',
                email: el.emailId?el.emailId:'',
                name: el.first_name === null ? '-' : `${el.first_name} ${el.last_name?el.last_name:''}`,
                submitResponse:el.question_submitted,
                phoneNumber: el.phone_number?el.phone_number:'',
                whatsappNumber: el.whatsappNumber?el.whatsappNumber:'',
                score: el.actual_score?el.actual_score:'',
                applicationDate:  el.appliedOn?moment(el.appliedOn).format("DD/MM/YYYY"):'',
                callStatus:  el.call_status?el.call_status:'',
                approvalStatus:  el.approval_status?el.approval_status:'',
                interviewMode: el.interview_mode,
                startDate: el.from_date?el.from_date:null,
                endDate: el.to_date?el.to_date:null,
                applicationStage:  el.event_status==="NO_RESPONSE"? applicationStage(el.lastEvent, el.lastEvent_status) :applicationStage(el.event, el.event_status),
                interviewStatus:  el.interviewStatus?el.interviewStatus:'',
                interviewSelectionDate: el.accepted_on?moment(el.accepted_on).format("DD/MM/YYYY"):'',
                preferredInterviewDate:  el.preferred_interview_date?moment(el.preferred_interview_date).format("DD/MM/YYYY"):null, 
                interviewDate:  setInterviewDate(el),
                interviewStartTime: el.interviewStartTime?el.interviewStartTime:null,
                interviewStartTime2: el.interviewStartTime2?el.interviewStartTime2:null,
                interviewStartTime3: el.interviewStartTime3?el.interviewStartTime3:null,
                interviewEndTime: el.interviewEndTime?el.interviewEndTime:null,
                interviewEndTime2: el.interviewEndTime2?el.interviewEndTime2:null,
                interviewEndTime3: el.interviewEndTime3?el.interviewEndTime3:null,
                interviewType: el.interview_type===1?"Face to face":el.interview_type===2?"Telephonic":el.interview_type===3?"Profile shared":'',
                otp: el.otp?el.otp:'',
                interviewFollowUp:  el.status?el.status:'',
                isOfferAccepted:    el.is_offer_accepted===1?"Offer accepted":el.is_offer_accepted===2?"Offer rejected":'',
                recruiterFeedback:  el.recruiter_feedback?el.recruiter_feedback:'',
                employerFeedback:  el.employer_feedback?el.employer_feedback:'',
                finalFeedback:  el.feedbackStatus?el.feedbackStatus:'', 
                selectionDate:  el.offeredDate, 
                joiningDate:  el.joining_date,
                offeredSalary:  el.job_offered_amount?el.job_offered_amount:'', 
                offered_amount_type: el.offered_amount_type,
                joiningStatus:  el.joiningStatus?el.joiningStatus:'',
                dateOfLeaving:  el.date_of_leaving,
                remarks:  el.remark?el.remark:'',
                interviewId: el.interviewId?el.interviewId:'',
                whichSlot: el.whichSlot?el.whichSlot:0,
                interviewSlots:1,
                remark_status: el.remark_status==="NOT_INTERESTED"?"Not interested" :el.remark_status==="NOT_APPROVED"?"Not approved":"None",
                low_salary: el.low_salary,
                preferred_job_type: el.preferred_job_type,
                preferred_job_type_id: el.preferred_job_type_id,
                preferred_location: el.preferred_location,
                preferred_location_id: el.preferred_location_id,
                callStatusDate: el.callStatusDate? moment(el.callStatusDate).format("DD/MM/YYYY"): '',
                profileSharedDate: el.profileSharedDate?moment(el.profileSharedDate).format("DD/MM/YYYY"): '',
                recruiterAssignedDate: el.recruiterAssignedDate?moment(el.recruiterAssignedDate).format("DD/MM/YYYY"):'',
                other: el.other,
                skill_mismatch: el.skill_mismatch?"Yes":"No",
                communication_issue: el.communication_issue?"Yes":"No",
                workerResponse: el.is_offer_accepted,
                rejectedByRole: el.rejectedByRole,
                firstEvent :el.preLastEvent,
                firstEventStatus :el.preLastEvent_status,
                firstEventDate :el.preLastCaptured_on,
                firstEventRoleType: el.pre_last_event_by_role ,
                firstEventName: el.pre_last_event_by ,
                firstEventValue: el.preNextStatusValue2,
                secondEvent :el.lastEvent,
                secondEventStatus :el.lastEvent_status,
                secondEventDate :el.lastCaptured_on,
                secondEventRoleType: el.last_event_by_role ,
                secondEventName: el.last_event_by ,
                secondEventValue: el.preNextStatusValue1,
                thirdEvent :el.event,
                thirdEventStatus :el.event_status,
                thirdEventDate :el.capturedOn,
                thirdEventRoleType: el.event_by_role ,
                thirdEventName: el.event_by ,
                thirdEventValue: el.preNextStatusValue,



            });
            console.log({extractedData});
        });
        return extractedData;
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                setRecruiterList(response.data.response)
                // console.log("recruiter", history?.location?.recruiterDashBoardData?.recruiterId, recruiterData)
                if(history?.location?.recruiterDashBoardData?.recruiterId && localStorage.getItem('roleType')!=="recruiter"){
                    const recruiter=response.data.response.find(x => x.userID === history.location.recruiterDashBoardData.recruiterId)
                    setRecruiterData(recruiter)
                }
            })
    }, [])

    useEffect(() => {
        // if(recruiterData?.userID)
            Axios.get(`${urlLinks.baseUrl}extWorker/getJobRecruiter?recruiter_id=${recruiterData.userID}`)
                .then(response =>{
                    setJobList(makeJobList(response.data.response))
                })
    }, [recruiterData])

    const ApplyFilter =() =>{

        let url='';
        if(filters.call_connected){
            url=`${url}&call_count=1`
        }
        if(filters.candidates_interested){
            url=`${url}&call_interested_count=1`
        }
        if(filters.candidates_approved){
            url=`${url}&approved_count=1`
        }
        if(filters.interviews_scheduled){
            url=`${url}&interview_scheduled_count=1`
        }
        if(filters.candidate_appeared){
            url=`${url}&interview_appeared_count=1`
        }
        if(filters.candidates_selected){
            url=`${url}&selection_count=1`
        }
        if(filters.candidate_joined){
            url=`${url}&joined_count=1`
        }
        if(filters.calls_pending){
            url=`${url}&call_pending_count=1`
        }
        if(filters.followup_calls){
            url=`${url}&follow_up_count=1`
        }
        if(filters["nameNumber"])
            url=`${url}&name=${filters["nameNumber"]}`
        if(filters["dateAssignedOn"]==="today")
            url=`${url}&recruiter_assigned_from_date=${moment().format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["dateAssignedOn"]==="last2Days")
            url=`${url}&recruiter_assigned_from_date=${moment().subtract(1,"days").format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["dateAssignedOn"]==="last4Days")
            url=`${url}&recruiter_assigned_from_date=${moment().subtract(3,"days").format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["dateAssignedOn"]==="customDates"){
            if(filters["datesAssignedOn"][0] && !filters["datesAssignedOn"][1])
                url=`${url}&recruiter_assigned_from_date=${moment(filters["datesAssignedOn"][0]).format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
            if(!filters["datesAssignedOn"][0] && filters["datesAssignedOn"][1])
                url=`${url}&recruiter_assigned_from_date=2019-01-01&recruiter_assigned_to_date=${moment(filters["datesAssignedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
            if(filters["datesAssignedOn"][0] && filters["datesAssignedOn"][1])
                url=`${url}&recruiter_assigned_from_date=${moment(filters["datesAssignedOn"][0]).format("YYYY-MM-DD")}&recruiter_assigned_to_date=${moment(filters["datesAssignedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
        }

        if(filters["lastUpdatedOn"]==="today")
            url=`${url}&last_updated_from_date=${moment().format("YYYY-MM-DD")}&last_updated_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["lastUpdatedOn"]==="last2Days")
            url=`${url}&last_updated_from_date=${moment().subtract(1,"days").format("YYYY-MM-DD")}&last_updated_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["lastUpdatedOn"]==="last4Days")
            url=`${url}&last_updated_from_date=${moment().subtract(3,"days").format("YYYY-MM-DD")}&last_updated_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["lastUpdatedOn"]==="customDates"){
            if(filters["datesLastUpdatedOn"][0] && !filters["datesLastUpdatedOn"][1])
                url=`${url}&last_updated_from_date=${moment(filters["datesLastUpdatedOn"][0]).format("YYYY-MM-DD")}&last_updated_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
            if(!filters["datesLastUpdatedOn"][0] && filters["datesLastUpdatedOn"][1])
                url=`${url}&last_updated_from_date=2019-01-01&last_updated_to_date=${moment(filters["datesLastUpdatedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
            if(filters["datesLastUpdatedOn"][0] && filters["datesLastUpdatedOn"][1])
                url=`${url}&last_updated_from_date=${moment(filters["datesLastUpdatedOn"][0]).format("YYYY-MM-DD")}&last_updated_to_date=${moment(filters["datesLastUpdatedOn"][1]).format("YYYY-MM-DD")} 23:59:59`
        }

        if(filters["interviewDate"]==="today")
            url=`${url}&interviews_from_date=${moment().format("YYYY-MM-DD")}&interviews_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["interviewDate"]==="last2Days")
            url=`${url}&interviews_from_date=${moment().subtract(1,"days").format("YYYY-MM-DD")}&interviews_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["interviewDate"]==="last4Days")
            url=`${url}&interviews_from_date=${moment().subtract(3,"days").format("YYYY-MM-DD")}&interviews_to_date=${moment().format("YYYY-MM-DD")} 23:59:59`
        if(filters["interviewDate"]==="customDates"){
            if(filters["datesInterviewDate"][0] && !filters["datesInterviewDate"][1])
                url=`${url}&interviews_from_date=${moment(filters["datesInterviewDate"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
            if(!filters["datesInterviewDate"][0] && filters["datesInterviewDate"][1])
                url=`${url}&interviews_from_date=2019-01-01&interviews_to_date=${moment(filters["datesInterviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
            if(filters["datesInterviewDate"][0] && filters["datesInterviewDate"][1])
                url=`${url}&interviews_from_date=${moment(filters["datesInterviewDate"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(filters["datesInterviewDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        }

        if(filters["callStatusDate"][0] && !filters["callStatusDate"][1])
            url=`${url}&call_status_from_date=${moment(filters["callStatusDate"][0]).format("YYYY-MM-DD")}&call_status_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["callStatusDate"][0] && filters["callStatusDate"][1])
            url=`${url}&call_status_from_date=2019-01-01&call_status_to_date=${moment(filters["callStatusDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["callStatusDate"][0] && filters["callStatusDate"][1])
            url=`${url}&call_status_from_date=${moment(filters["callStatusDate"][0]).format("YYYY-MM-DD")}&call_status_to_date=${moment(filters["callStatusDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["approvalStatus"].includes(""))
            url=`${url}&approvalStatus=${filters["approvalStatus"]}`
        if(!filters["callStatus"].includes(""))
            url=`${url}&callStatus=${filters["callStatus"]}`
        if(!filters["appliedBy"].includes(""))
            url=`${url}&appliedBy=${filters["appliedBy"]}`
        if(!filters["interviewMode"].includes(""))
            url=`${url}&interview_mode=${filters["interviewMode"]}`
        if(!filters["interviewFollowUp"].includes(""))
            url=`${url}&status=${filters["interviewFollowUp"]}`
        if(!filters["recruiterFeedback"].includes(""))
            url=`${url}&reFeedback=${filters["recruiterFeedback"]}`
        if(!filters["joiningStatus"].includes(""))
            url=`${url}&joining=${filters["joiningStatus"]}`
        if(filters["profile_shared_date"][0] && !filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=${moment(filters["profile_shared_date"][0]).format("YYYY-MM-DD")}&profile_shared_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["profile_shared_date"][0] && filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=2019-01-01&profile_shared_to_date=${moment(filters["applicationDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["profile_shared_date"][0] && filters["profile_shared_date"][1])
            url=`${url}&profile_shared_from_date=${moment(filters["profile_shared_date"][0]).format("YYYY-MM-DD")}&profile_shared_to_date=${moment(filters["profile_shared_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if(filters["selectionDate"][0] && !filters["selectionDate"][1])
            url=`${url}&selection_from_date=${moment(filters["selectionDate"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["selectionDate"][0] && filters["selectionDate"][1])
            url=`${url}&selection_from_date=2019-01-01&selection_to_date=${moment(filters["selectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["selectionDate"][0] && filters["selectionDate"][1])
            url=`${url}&selection_from_date=${moment(filters["selectionDate"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(filters["selectionDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["joiningDate"][0] && !filters["joiningDate"][1])
            url=`${url}&joining_from_date=${moment(filters["joiningDate"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["joiningDate"][0] && filters["joiningDate"][1])
            url=`${url}&joining_from_date=2019-01-01&joining_to_date=${moment(filters["joiningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["joiningDate"][0] && filters["joiningDate"][1])
            url=`${url}&joining_from_date=${moment(filters["joiningDate"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(filters["joiningDate"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        if(filters["dateOfLeaving"][0] && !filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=${moment(filters["dateOfLeaving"][0]).format("YYYY-MM-DD")}&leaving_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters["dateOfLeaving"][0] && filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=2019-01-01&leaving_to_date=${moment(filters["dateOfLeaving"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters["dateOfLeaving"][0] && filters["dateOfLeaving"][1])
            url=`${url}&leaving_from_date=${moment(filters["dateOfLeaving"][0]).format("YYYY-MM-DD")}&leaving_to_date=${moment(filters["dateOfLeaving"][1]).format("YYYY-MM-DD")} 23:59:59`
        


        setFilterUrl(url)
    }
    useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem("showingReminderRecord")
        }, 2000);
    }, [])
    useEffect(() => {
        // console.log("filters triggered")
        ApplyFilter();
    }, [filters])
    const getActiveApplicationList = (rows, page) =>{
        if(jobValue){
            const url=`${urlLinks.baseUrl}recruiter/getAssignedWorkerRecruiter?job_details_id=${jobValue.jobDetailId}&job_id=${jobValue.jobId}&recuiter_id=${recruiterData.userID}&page_no=${page-1}&rows=${rows}${filterUrl}${sortUrl}`
            Axios.get(url)
            .then(response =>{
                // console.log({response})
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else{
                    setLoading(false)
                    throw toast.error("No data inside from API")
                }
            })
            .catch(error =>{
                setLoading(false)
                throw toast.error("No data from API")
            })
        }
    }
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 2, 
            minimumFractionDigits: 0, 
        }).format(value);}
    

    useEffect(() => {
        setLoading(true)
        // if(props.data.jobId && props.data.jobDetailsId)
            getJobDetails();
            getVacancyStatus();
            getActiveApplicationList(perPage, 1);
    }, [filterUrl, sortUrl, jobValue])
    const makeReminderList =(data) =>{
        let list =[]
        data.map(reminder =>{
            list.push(reminder)
        })
        return list;
    }
    const handleReminderOK =(data, setModal) =>{
        const postData={
            isDismiss: 1,
            jobId: data.job_id  ,
            recruiterId: data.recruiterId,
            reminderId: data.reminder_id ,
            userId: data.user_id
        }
        Axios.post(`${urlLinks.baseUrl}recruiter/dismissReminder`, qs.stringify(postData))
            .then(response =>{
                setModal(false)
            })

    }
    const handleReminderSnooze =(data, setModal) =>{
        const postData={
            snoozeDate: new Date(),
            snoozeTime: moment().format("HH:mm:ss"),
            jobId: data.job_id  ,
            recruiterId: data.recruiterId,
            reminderId: data.reminder_id ,
            userId: data.user_id
        }
        Axios.post(`${urlLinks.baseUrl}recruiter/snoozeReminder`, qs.stringify(postData))
            .then(response =>{
                getAllReminders();
                setModal(false)
            })
    }
    const showReminder =() =>{
        if(reminderList?.length>0){
            console.log({reminderList})
            reminderList.map(reminder =>{
                if(moment().format("DD/MM/YYYY")===moment(reminder.rem_date).format("DD/MM/YYYY") && moment().format("HH:mm")=== moment(reminder.rem_time, "HH:mm:ss").format("HH:mm")){
                    console.log("entered reminder list")
                    const display=<ShowReminder
                        data={reminder}
                        jobValue={jobValue}
                        recruiterData={recruiterData}
                        handleOk={handleReminderOK}
                        handleSnooze={handleReminderSnooze}
                        /> 
                        setDisplayReminders([...displayReminders, display]);
                    }
                    
                })
        }
    }
    useEffect(() => {
        getAllReminders();
    }, [])
    useEffect(() => {

        setIntervalHook(setInterval(() => {
            showReminder()
        }, 60*1000))
    }, [reminderList])
    const handleExternalFilters = (list) =>{
        setFilters({...filters, ...list})
    }
    const resetFilters=() =>{
        const filtersTemp={
            callStatus:[""],
            lastUpdatedOn:"",
            dateAssignedOn:"",
            interviewDate:"",
            datesLastUpdatedOn:[null, null],
            datesInterviewDate:[null, null],
            datesAssignedOn:[null, null],
            intExt: '',
            workerId: '',
            callStatusDate: [null, null],
            score: 0,
            approvalStatus:[""],
            applicationStage:[""],
            interviewMode:[""],
            interviewStatus:'',
            preferredInterviewDate: [null, null],
            interviewSelectionDate:[null, null],
            interviewFollowUp:[""],
            recruiterFeedback:[""],
            employerFeedback:[""],
            appliedBy:[""],
            finalFeedback:[""],
            selectionDate:[null, null],
            joiningDate:[null, null],
            offeredSalary:0,
            joiningStatus:[""],
            dateOfLeaving:[null, null],
            nameNumber: '',
            remark_status:[],
            preferred_job_type_id:[],
            low_salary:0,
            preferred_location_id:[],
            skill_mismatch: null,
            communication_issue:null,
            call_status_date: [null, null],
            profile_shared_date: [null, null],
            recruiter_assigned_date: [null, null],
            calls_connected: false,
            candidates_interested: false,
            candidates_approved: false,
            interviews_scheduled: false,
            candidate_appeared: false,
            candidates_selected: false,
            candidate_joined: false,
            calls_pending: false,
            followup_calls: false
        }
        setFilters(filtersTemp)
        return filtersTemp;
    }

    const handlePageChange = page => {
        setLoading(true)
        getActiveApplicationList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getActiveApplicationList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const loader=<div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
    const table=<Styles style={{padding: '0 !important'}}><div className="tableContainer" style={{width:"100%"}}>
                    <table {...getTableProps()}  className="table"  style={{width:"100%"}}>
                        <thead className="thead" style={{height: '50px'}}>
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (  
                                <th {...column.getHeaderProps()}  style={{textAlign: "left"}}
                                    className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                        column.render('Header')==="Name"?"stickWorkerNameMatchNew zIndex1080 header":
                                            column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                            {column.render('Header')}
                                    
                                    </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        {
                            loading?
                                loader:
                                <tbody {...getTableBodyProps()}> 
                                    {rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="row tableRow">
                                            {row.cells.map(cell => {
                                                // console.log("Render started", cell.column.id);
                                                return <td {...cell.getCellProps()} style={{verticalAlign: "top", paddingTop: "16px"}}
                                                    className={cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"}>{cell.render('Cell')}</td>
                                            })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div></Styles>

    // console.log({jobValue, vacancyStatusData})
    return (
                <div className={' profile-content recruiterDashboard'}>
			<Header leftMenu={[{url:"/dashboard", name:"Dashboard"},{url:"/recruiter-dashboard", name:"Track applications"}]} link='true' />
            <Grid container className="main-content">
                <Grid container>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>
            <Grid item sm={12}><span className="fontSize24 fontWeightBold">Recruiter Dashboard</span></Grid>
            {(localStorage.getItem('roleType')=== "Super Admin" || localStorage.getItem('roleType')=== "Operational Admin")  && <Grid container className="padding0" style={{marginBottom: "20px"}}>
                <CustomAutocomplete
                    id="jobList"
                    options={recruiterList}
                    value={recruiterData}
                    popupIcon= {<ExpandMoreIcon />}
                    onChange={(event, value) => setRecruiterData(value)} 
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName?option.lastName:''}`
                        }
                    style={{ width: "450px", margin: "10px 50px 0 0", padding:'0'}}
                    renderInput={(params) => <TextField {...params} 
                        // style={{paddingLeft: "16px"}} 
                        className="margin0 " 
                        placeholder="Select Recruiter" 
                        variant="outlined" />}
                />
            </Grid>}
            <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="space-between">
                <CustomAutocomplete
                    id="jobList"
                    options={jobList}
                    value={jobValue}
                    popupIcon= {<ExpandMoreIcon />}
                    onChange={(event, value) => setJobValue(value)}
                    style={{ width: "450px", margin: "0 50px 0 0", padding:'0'}}
                    getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                    renderInput={(params) => <TextField {...params} 
                        // style={{paddingLeft: "16px"}} 
                        className="marginTop20 " 
                        placeholder="Select Vacancy" 
                        variant="outlined" />}
                />
            </Grid>
            <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="space-between" style={{marginTop: "20px"}}>
                {vacancyStatusData && jobValue && shortLink && <VacancyRecruiterDetails 
                    data={vacancyStatusData}
                    values={jobDetails}
                    jobValue={jobValue}
                    recruiterData={recruiterData}
                    filters={filters}
                    showToast={showToast}
                    updateMyData={updateMyData}
                    setFilters={setFilters}
                    link={shortLink}
                />}
            </Grid>
            {vacancyStatusData && jobValue && <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="space-between">
                <Grid item sm={9} className="flexDisplay padding0" direction ="row" justify="space-between">
                    <TextField
margin="dense"
                        className={`${classes.root} marginLeft0 marginRight20`}
                        placeholder="Enter name or phone number"
                        fullWidth
                        value={nameNumberSearch}
                        onChange={(event) => setNameNumberSearch(event.target.value)}
                        variant="outlined"
                        InputProps={{
                        className: classes.input,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton 
                            onClick={() =>setNameNumberSearch("")}
                        >
                            <CloseSharpIcon />
                        </IconButton>
                        )
                        }}
                    />
                    
                </Grid>
                <Grid item sm={3} className="flexDisplay padding0" direction ="row" justify="space-between">
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel htmlFor="sortBy">Sort by:</InputLabel>
                        <Select
                            id="sortBy"
                            IconComponent= {() => <ExpandMoreIcon />}
                            value={sortBy}
                            onChange={event => setSortBy(event.target.value)}
                            className={classes.select}
                            label="Sort by:"
                            inputProps={{
                                name: 'Sort by:',
                                id: 'sortBy',
                            }}
                            >
                            {optionsSortBy.map(option => (
                                <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {sortBy && <ToggleButton
                        selected={sortDirection}
                        className = "recSortOrderButton"
                        // style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                        onChange={() => {
                        setSortDirection(!sortDirection);
                        }}
                    >
                        {!sortDirection?<ArrowUpwardSharpIcon />:<ArrowDownwardSharpIcon />}
                    </ToggleButton>}
                    
                </Grid>
            </Grid>}
            {vacancyStatusData && jobValue && <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="space-between">
                <Grid item sm className="flexDisplay padding0 marginLeft-20" direction ="row">
                <img src={calender} className="inputIcons" className="inputIcons" />
                        <SelectInput
                           // IconComponent = {() =>}
                            openClear={true}
                            filters={filters}
                            extraClass="paddingLeft36 "
                            id="dateAssignedOn"
                            labelClass="paddingLeft25 filterInput"
                            label="Date assigned on"
                            setFilters={setFilters}
                        >
                        </SelectInput>
                        <IconButton 
                            className="filterCLoseButton"
                            onClick={() =>setFilters({...filters, dateAssignedOn:"", datesAssignedOn:[null, null]})}
                        >
                            <CloseSharpIcon />
                        </IconButton>

                </Grid>
                <Grid item sm className="flexDisplay padding0" direction ="row">
                <img src={phone} className="inputIcons"  />
                    <SelectInput
                        // IconComponent = {() => }
                        filters={filters}
                        id="callStatus"
                        label="Call status"
                        extraClass="paddingLeft36 "
                        labelClass="paddingLeft25 filterInput"
                        multipleFlag={true}
                        setFilters={setFilters}
                    />
                    <IconButton 
                        className="filterCLoseButton"
                        onClick={() =>setFilters({...filters, callStatus:[""]})}
                    >
                        <CloseSharpIcon />
                    </IconButton>
                </Grid>
                <Grid item sm className="flexDisplay padding0" direction ="row">
                    <img src={file} className="inputIcons"  />
                    <SelectInput
                        // IconComponent = {() =>}
                        filters={filters}
                        extraClass="paddingLeft36 "
                        labelClass="paddingLeft25 filterInput"
                        label="Last updated on"
                        id="lastUpdatedOn"
                        setFilters={setFilters}
                    />
                    <IconButton 
                        className="filterCLoseButton"
                        onClick={() =>setFilters({...filters, lastUpdatedOn:"", datesLastUpdatedOn:[null, null]})}
                    >
                        <CloseSharpIcon />
                    </IconButton>
                </Grid>
                <Grid item sm className="flexDisplay padding0" direction ="row">
                    <img src={calender} className="inputIcons" style={{position: "relative", right: "-25px"}}  />
                    <SelectInput
                        // IconComponent = {() => }
                        filters={filters}
                        id="interviewDate"
                        extraClass="paddingLeft36 "
                        labelClass="paddingLeft25 filterInput "
                        label="Interview date"
                        setFilters={setFilters}
                    />
                    <IconButton 
                        className="filterCLoseButton"
                        onClick={() =>setFilters({...filters, interviewDate:"", datesInterviewDate:[null, null]})}
                    >
                        <CloseSharpIcon />
                    </IconButton>
                </Grid>
                <Grid item sm className="flexDisplay padding0 margin20" direction ="row" justify="flex-end">
                    <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
                        <IconButton 
                            className="filterButton marginLight20"
                            onClick={() =>setFilterDrawer(true)}
                        >
                            <img src={filter} alt="img" />
                        </IconButton>
                    </Badge>
                </Grid>
            </Grid>}
            {vacancyStatusData && jobValue && <Grid item sm={12} className="flexDisplay padding0" direction ="row" justify="flex-end">
                
                <Button
                    style={{float: 'right',borderRadius: '2px',border: 'solid 1px #cccccc'}}
                    className=""
                    disabled={Object.keys(selectedRowIds).length===0}
                    onClick={() => setOpenAssignVacancyModal(true)}
                >
                    Assign Candidate
                </Button>

            </Grid>}
            {vacancyStatusData && jobValue && <Grid item sm={12} className="padding0" direction ="row" justify="space-between">
                {table}
                <Pagination 
                    rowsperpage={perPage}
                    pageNo={currentPage}
                    totalElements={totalRows}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            </Grid>}
            <Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false) }>
                <Filters 
                    filters={filters}
                    updateFilters={handleExternalFilters}
                    resetFilters={resetFilters}
                    isFilterApplied={isFilterApplied}
                    setIsFilterApplied={setIsFilterApplied}
                />           
            </Drawer>
            {openAssignVacancyModal && 
                <AssignToVacancy
                    openAssignVacancyModal={openAssignVacancyModal}
                    setOpenAssignVacancyModal ={setOpenAssignVacancyModal}
                    jobList={jobList}
                    currentJob={jobValue}
                    recrutierData={recruiterData}
                    showToast={showToast}
                    selectedRowIds={selectedRowIds}
                    userIdList={userIdList}
                    toggleAllRowsSelected= {toggleAllRowsSelected}
                />
            }
            {displayReminders}
        </Grid>
        
            </Grid>
        </div>    
        )
}
export default RecruiterDashboard;
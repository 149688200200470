const Constant = {
  employer: {
    status: 'Status',
    onboardingReviewStatus: 'OnBoarding ReviewStatus',
    employerId: 'Employer ID',
    userId: 'User ID',
    companyName: 'Company Name',
    brandName: 'Brand Name',
    industry: 'Industry',
    city: 'Location',
    appRegistered: 'App Registered',
    contactPerson: 'Contact Person',
    phone: 'Phone',
    email: 'Email',
    registrationDate: 'OnBoarding Date',
    addedBy: 'Added By',
    odActive: 'OD Active',
    odInactive: 'OD Inactive',
    ptFtActive: 'PT/FT Active',
    ptFtInactive: 'PT/FT Inactive',
    activeHiring: 'Active Hiring',
    hired: 'Hired',
    inActivejobs: 'Inactive Jobs',
    inactive: 'Inactive',
    cancelled: 'Cancelled',
    totalPositions: 'Total Positions Needed',
    rating: 'Rating',
    pendingPayment: 'Payment Pending',
    updatedOn: 'Updated On',
    action: 'Action',
    idName: 'employerId',
    review: "Review"
  },
  job: {
    urgency: 'Urgency',
    category: ' Job Category',
    jobDetailsId: 'Job Position ID',
    status: 'Status',
    jobId: 'ID',
    encryptId: "Encrypt ID",
    brandName: 'Brand Name',
    date: 'Posted On',
    jobDate: 'Job Start Date',
    endDate: 'Job End Date',
    timeStart: 'Start Time',
    timeEnd: 'End Time',
    location: 'Business Location',
    city: 'City',
    contactPerson: "Contact Person",
    industry: 'Industry',
    title: 'Job Role',
    jobtype: 'Job Type',
    gender: 'Gender',
    age:'Age',
    cvRequired:'CV Required',
    englishFluency:'English Fluency',
    vacancyType:'Vacancy Type',
    accountManager:'Account Manager',
    contactNo:'Contact No',
    emailAccountManager:'Email Account Manager',
    contactCandidateNumber:'Candidate Contact Number',
    boostJob:'Boost Job',
    contactCandidateName:'Candidate Contact Name',
    qualification: 'Qualification',
    experience: 'Experience',
    salary: 'Salary',
    salaryType: 'Salary Type',
    requirements: 'Additional Requirements',
    specialRequirements: 'Special Requirements',
    interview: 'Interview Time slot',
    quantity: 'Qty',
    addedBy: 'Added By',
    jobStatus: 'Current Status',
    idName: 'jobId'
  },
  employerOnBoarding: {
    status: 'Status',
    employerId: 'ID',
    company_name: 'Comapany Name',
    industry: 'Industry',
    location: 'Location',
    contact_person: 'Contact Person',
    phone_number: 'Contact Number',
    email: 'Email',
    registered_on: 'Registered On',
    referral_code: 'Referral Code',
    last_activity_on: 'Last Activity On',
    review_status: 'Review Status',
    last_comment: 'Last Comment',
    action: 'Action',
    idName: 'employerId'
  },
  employerScreening: {
    status: 'Status',
    employerId: 'ID',
    company_name: 'Comapany Name',
    rating: 'Rating',
    payment_overdue: 'Payment Overdue',
    overdues_days: 'Overdues days',
    cancellation: 'Cancellation',
    total: '#Total',
    other_breach: '#Other Breach',
    last_updated: 'Last Updated',
    last_reviewed: 'Last Reviewed',
    action: 'Action',
    idName: 'employerId'
  },
  employerInactive: {
    employerId: 'ID',
    company_name: 'Comapany Name',
    industry: 'Industry',
    location: 'Location',
    contact: 'Contact',
    phone_number: 'Contact Number',
    email: 'Email',
    last_status: 'Last Status',
    last_activity: 'Last Activity',
    review: 'Review',
    last_comments: 'Last Comment',
    action: 'Action',
    idName: 'employerId'
  },
  employerPayments: {
    employerId: 'ID',
    company_name: 'Comapany Name',
    amount_due: 'Amount Due',
    unpaid_invoices: '#UnpaidIn',
    idName: 'employerId'
  },

  worker: {
    status: 'Status',
    appRegistered: 'App Registered',
    workerId: 'Worker ID',
    name: 'Name',
    email: 'Email',
    cv:'Download CV',
    userId: 'User Id',
    contactNumber: 'Contact Number',
    insertedOn: 'OnBoarding Date',
    address: 'Location',
    city: 'City',
    totalGigs: 'OD Jobs',
    gender: 'Gender',
    averageRating: 'Average Rating',
    addedBy: 'Added By',
    jobType: 'Job Experience',
    industryType: 'Industry Experience',
    experience: 'Experience',
    lastUpdatedOn: 'Last Updated On',
    action: 'Action',
    idName: 'userId'
  },
  workerScreening: {
    status: 'Status',
    app_registered: 'App Registered',
    workerId: 'ID',
    name: 'Name',
    od_rating: 'OD Rating',
    pt_ft_rating: 'PT/FT Rating',
    od_breach_score: 'OD Breach Score',
    pt_ft_breach_score: 'PT/FT Breach Score',
    action: 'Action',
    idName: 'workerId'
  },
  workerOnBoarding: {
    status: 'Status',
    workerId: 'ID',
    name: 'Name',
    phone_number: 'Contact Number',
    registration_date: 'Registration Date',
    added_on: 'Added On',
    added_by: 'Added By',
    referral_code: 'Referral code',
    review_status: 'Review Status',
    action: 'Action',
    idName: 'workerId'
  },

  configData: {
    category_ID: 'ID',
    category_type: 'Category Type',
    category_sub_type: 'Category Sub Type',
    type_key: 'Type Key',
    type_value: 'Type Value',
    inserted_by: 'Inserted By',
    inserted_on: 'Inserted On'
  },

  ODJob: {
    urgency: 'Urgency',
    enjobId:'Encrept ID',
    job_id: 'Job Id',
    contactPerson:"Contact Person",
    job_detailed_id: 'Job Position ID',
    brand_name: 'Brand Name',
    posted_on: 'Posted On',
    job_date: 'Job Start Date',
    start_time: 'Start Time',
    end_time: 'End Time',
    job_location: 'Job Location',
    city: 'City',
    industry: 'Industry',
    end_date: 'Job End Date',
    title: 'Title',
    gender: 'Gender',
    qualification: 'Qualification',
    experience: 'Experience',
    other_requirements: 'Additional Requirements',
    specialRequirements: 'Special Requirements',
    quantity: 'Qty',
    status: 'Status',
    last_comment: 'Last Comment',
    action: 'Action',
    idName: 'jobId'
  },
  ptftJob: {
    urgency: 'Urgency',
    enjobId:'Encrept ID',
    job_id: 'Job Id',
    job_detailed_id: 'Job Position ID',
    brand_name: 'Brand Name',
    posted_on: 'Posted On',
    targetDate: 'Target Job',
    job_date: 'Job Start Date',
    startEndTime: 'Start-End Time',
    industry: 'Industry',
    title: 'Job Title',
    city: "City",
    contactPerson: "Contact Person",
    end_date: 'Job End Date',
    requirements: 'Additional Requirements',
    specialRequirements: 'Special Requirements',
    quantity: 'Qty',
    interviewTimeslot: 'Interview TimeSlot',
    interviewScheduled: '#Interview Scheduled',
    interviewConducted: '#Interview Conducted',
    status: 'Status',
    last_comment: 'Last Comment',
    action: 'Action',
    idName: 'jobId'
  }
};
export default Constant;

import React, {useState} from "react"
import { Grid, Button, Modal, Backdrop, FormControl, Select, InputLabel , MenuItem} from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import * as moment from 'moment'
import Axios from 'axios'
import urlLinks from '../../urlLinks'
import EditableDropdown from '../../components/RecruiterDB/RecrutierDBElements/EditableDropdownSubComponent'
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import qs from 'querystring';

import { makeStyles } from '@material-ui/core/styles';

const flexDisplay = {
    margin: 'auto',
  },
  ipBorder = {
    border: 'solid 1px #1c75bc',
    borderRadius: '4px',
    width: '150px',
    padding: '0 8px'
  },
  subTitle = {
    objectFit: 'contain',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  },
  confirm = {
    width: '116px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#1c75bc',
    color: '#f4f4f4',
    fontSize: '14px',
    float: 'right',
    marginRight: '25px'
  },
  cancel = {
    width: '111px',
    height: '40px',
    borderRadius: '4px',
    border: 'solid 1px #1c75bc',
    backgroundColor: '#ffffff',
    color: '#1c75bc',
    float: 'right'
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '450px'
    },
  }));

const Reschedule = (props) => {
  const classes = useStyles();

  const { row ,reModal, setReModal, updateMyData, showToast, setLoading} = props;
  const [f2fInterviewData , setF2fInterviewData] = useState({
    interviewDate: row.interviewStartTime, 
    startTime: row.interviewStartTime,
    endTime: row.interviewEndTime,
    interviewDateError:"",
    startTimeError : "",
    endTimeError: ""
  })
  const [teleInterviewData , setTeleInterviewData] = useState({
    startDate: row.startDate,
    endDate: row.endDate,
    startDateError: "",
    endDateError: ""
  })
  const [mode, setMode] = useState(row.interviewMode)
  const handleModal = () => {
    setReModal(false)
  }


  const  handleRescheduleConfirm= () =>{
    if(mode===2){
      let startDateError=""
      let endDateError=""
      if(!teleInterviewData.startDate){
        startDateError= "Enter start date"
      }
      if(!teleInterviewData.endDate){
        endDateError= "Enter end date"
      }
      if(startDateError || endDateError)
        setTeleInterviewData({...teleInterviewData, startDateError: startDateError, endDateError: endDateError})
      
      if(teleInterviewData.startDate && teleInterviewData.endDate){
        const postData={
          requested_by: localStorage.getItem('userId'),
          job_detail_id: row.jobDetailsId,
          user_id: row.userId,
          interview_status:1,
          from_date: moment(teleInterviewData.startDate).format("YYYY-MM-DD"), 
          to_date: moment(teleInterviewData.endDate).format("YYYY-MM-DD"),
          interview_mode: mode
        }
        if(row.interviewId)
            postData.interview_id=row.interviewId
        Axios.post(`${urlLinks.baseUrl}interview/schedule`,qs.stringify(postData))
        .then(response => {
            if (response.data.code === 1000) {
                showToast("success", 'Interview rescheduled Successfully');
                // if(props.type!=="interviewRescheduleStatus")
                // let updateStatusData={
                //   interview_status: "RESCHEDULE",
                //   interview_id: row.interviewId,
                //   updated_by: localStorage.getItem('userId')
                // }
                // Axios.post(`${urlLinks.baseUrl}tracking/updateInterviewStatus`, qs.stringify(updateStatusData))
                //   .then(response =>{
                //       if (response.data.code === 1000) {
                //           updateMyData()
                //       } else {
                //           showToast(response.data.message);
                //       }
                //   })
                updateMyData()
                setReModal(false)
                
            } else {
                setReModal(false)
                throw showToast("error", response.data.message);
            }
        })
        .catch(error => {
            setReModal(false)
            showToast("error", "Status update failed");
        });
      }
    }
    if(mode===3){
      let startTimeError=""
      let endTimeError=""
      let interviewDateError= ""
      if(!f2fInterviewData.interviewDate){
        interviewDateError= "Enter interview date"
      }
      if(!f2fInterviewData.startTime){
        startTimeError= "Enter start time"
      }
      if(!f2fInterviewData.endTime){
        endTimeError= "Enter start time"
      }
      if(interviewDateError || startTimeError || endTimeError)
        setTeleInterviewData({...teleInterviewData, interviewDateError: interviewDateError, startTimeError: startTimeError, endTimeError: endTimeError})
      
      if(f2fInterviewData.interviewDate && f2fInterviewData.startTime && f2fInterviewData.endTime){
        const postData={
          requested_by: localStorage.getItem('userId'),
          job_detail_id: row.jobDetailsId,
          user_id: row.userId,
          interview_status:1,
          interview_start_time: `${moment(f2fInterviewData.interviewDate).format("YYYY-MM-DD")} ${moment(f2fInterviewData.startTime).format("HH:mm:ss")}`, 
          interview_end_time: `${moment(f2fInterviewData.interviewDate).format("YYYY-MM-DD")} ${moment(f2fInterviewData.endTime).format("HH:mm:ss")}`,
          interview_mode: mode
        }
        if(row.interviewId)
            postData.interview_id=row.interviewId
        Axios.post(`${urlLinks.baseUrl}interview/schedule`,qs.stringify(postData))
        .then(response => {
            if (response.data.code === 1000) {
                showToast("success", 'Interview rescheduled Successfully');
                // if(props.type!=="interviewRescheduleStatus")
                // let updateStatusData={
                //   interview_status: "RESCHEDULE",
                //   interview_id: row.interviewId,
                //   updated_by: localStorage.getItem('userId')
                // }
                // Axios.post(`${urlLinks.baseUrl}tracking/updateInterviewStatus`, qs.stringify(updateStatusData))
                //   .then(response =>{
                //       if (response.data.code === 1000) {
                //           updateMyData()
                //       } else {
                //           showToast(response.data.message);
                //       }
                //   })
                updateMyData()
                setReModal(false)
            } else {
                setReModal(false)
                throw showToast("error", response.data.message);
            }
        })
        .catch(error => {
            setReModal(false)
            showToast("error", "Status update failed");
        });
      }
    }
  }

  const handleRescheduleClose = () =>{
    setF2fInterviewData({
      interviewDate: row.interviewStartTime, 
      startTime: row.interviewStartTime,
      endTime: row.interviewEndTime,
      interviewDateError:"",
      startTimeError : "",
      endTimeError: ""
    })
    setTeleInterviewData({
      startDate: row.startDate,
      endDate: row.endDate,
      startDateError: "",
      endDateError: ""
    })
    setReModal(false)
  }
  const handleInterviewData =(date, location) =>{
    console.log({location})
    switch (location) {
        case 1:
            setTeleInterviewData({...teleInterviewData, startDate: moment(date).format("YYYY-MM-DD") })
            break;
        case 2:
            setTeleInterviewData({...teleInterviewData, endDate: moment(date).format("YYYY-MM-DD") })
            break;
        case 3:
            console.log({date, location})
            setF2fInterviewData({...f2fInterviewData, interviewDate: moment(date).format("YYYY-MM-DD HH:mm:ss") })
            break;
        case 4:
            setF2fInterviewData({...f2fInterviewData, startTime: moment(date).format("YYYY-MM-DD HH:mm:ss") })
            break;
        case 5:
            setF2fInterviewData({...f2fInterviewData, endTime: moment(date).format("YYYY-MM-DD HH:mm:ss") })
            break;
        default:
            break;
    }
    console.log({f2fInterviewData})
    
}

  const [selectedDate, handleDateChange] = useState(new Date());
  return (

    <Modal
        className={classes.modal}
        open={reModal}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <div className={classes.paper}>
          <Grid container>
            <Grid className="padding0" item xs={12} style={flexDisplay} direction="row">
                <h1>Reschedule interview</h1>
                {/* <Button style={close} onClick={handleModal}>X</Button> */}
            </Grid>
            <Grid item xs={12} className="padding0" style={{marginTop: "12px"}}>
              <FormControl variant="outlined" fullWidth margin="dense" className="margin0">
                <InputLabel htmlFor="interviewMode">Interview mode</InputLabel>
                <Select
                    id="interviewMode"
                    label="Interview mode"
                    value={mode}
                    variant= "outlined"
                    disableUnderline
                    onChange={(event) => setMode(event.target.value)}
                    inputProps={{
                        classes: {
                            icon: "selectIcon",
                        },
                    }}
                    >
                    <MenuItem value={2}>Telephonic</MenuItem>
                    <MenuItem value={3}>Face to face</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container>
              <MuiPickersUtilsProvider className="padding0" utils={DateFnsUtils}>
                {mode===2 && <Grid container justify="space-around">
                      <Grid item xs={6} className="padding0">
                        <KeyboardDatePicker
                            // margin="normal"
                            className="padding0 marginVertical20"
                            id="teleStartDate"
                            label="Start date"
                            format="dd-MM-yy"
                            minDate={new Date()}
                            inputVariant="outlined"
                            // style={{marginLeft:"0px"}}
                            value={teleInterviewData.startDate}
                            onChange={date => handleInterviewData(date, 1)}
                            inputProps={{
                                style:{
                                    height: "25px",
                                }
                            }}
                        />
                      </Grid>
                      <Grid item xs={6} className="padding0">
                        <KeyboardDatePicker
                            // margin="normal"
                            className="padding0 marginVertical20"
                            id="teleEndDate"
                            minDate={new Date()}
                            label="End date"
                            // style={{marginLeft:"0px"}}
                            inputVariant="outlined"
                            format="dd-MM-yy"
                            value={teleInterviewData.endDate}
                            onChange={date => handleInterviewData(date, 2)}
                            inputProps={{
                              style:{
                                  height: "25px",
                              }
                            }}
                            InputAdornmentProps={{
                              style:{
                                padding: "0px"
                              }
                            }}
                        />
                      </Grid>
                </Grid>}
                {mode ===3 && <Grid container justify="space-around">
                  <Grid item xs={12} className="padding0">
                      <KeyboardDatePicker
                          margin="normal"
                          id="interviewSlot3"
                          label="Interview date"
                          format="dd-MM-yyyy"
                          className="marginVertical20"
                          inputVariant="outlined"
                          fullWidth
                          value={f2fInterviewData.interviewDate}
                          onChange={date => handleInterviewData(date, 3)}
                          KeyboardButtonProps={{
                          'aria-label': 'change date',
                          }}
                          inputProps={{
                              style:{
                                  height: "25px",
                              }
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} className="padding0" style={{display: "flex", justifyContent:"space-between"}} >
                    <Grid item xs={6} className="padding0" style={{display: "flex", justifyContent:"space-between"}} >
                      <KeyboardTimePicker
                          label={"Start time"}
                          className="marginLeft0"
                          inputVariant="outlined"
                          value={f2fInterviewData.startTime}
                          onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  4)}
                          inputProps={{
                              style:{
                                  height: "25px",
                              }
                          }}
                      />
                    </Grid>
                    <Grid item xs={6} className="padding0" style={{display: "flex", justifyContent:"space-between"}} >
                      <KeyboardTimePicker
                          label={"End time"}
                          inputVariant="outlined"
                          className="marginLeft0"
                          error={moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm")}
                          helperText={moment(f2fInterviewData.endTime).format("HH:mm") < moment(f2fInterviewData.startTime).format("HH:mm") && "Enter interview end time after start time"}
                          value={f2fInterviewData.endTime}
                          onChange={date => handleInterviewData(moment(date).add(30, 'minutes').startOf('hour'),  5)}
                          inputProps={{
                              style:{
                                  height: "25px",
                              }
                          }}
                      />
                    </Grid>
                  </Grid>
                </Grid>}
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className="padding0" direction="row">
                  <Button style={cancel} onClick={handleRescheduleClose}>CANCEL</Button>
                  <Button style={confirm} onClick={handleRescheduleConfirm}>CONFIRM</Button>
            </Grid>
          </Grid>
        </div>
    </Modal>
  );
};

export default Reschedule
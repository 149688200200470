import React, {  } from 'react'
import { Modal,  Backdrop, Grid, Slide} from '@material-ui/core';
import {  makeStyles} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
// import './modals.css'
import * as moment from 'moment';
import WeekToggle from '../../components/Job/JobList/newJobViewComponents/weektoggleButton'
// import Availabilities from './Availabilitylist'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    minWidth:"1000px",
    width:"52%",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const JobModal =(props) =>{
    const classes = useStyles();
    const {values, openJobModal, setOpenJobModal} =props
    console.log({values})
    
    const jobView=
        <Grid container>
            <Grid item xs={12} className="columnOrder padding20">
                <span className="headings">{values.brandName}</span>
            </Grid>
            <Grid item xs={12} className="columnOrder padding20">
                <span className="headings">About Company</span>
            </Grid>
            <Grid item xs={12} className="columnOrder padding20">
                <span className="">{values.companyDetails}</span>
            </Grid>
            <Grid item xs={12} className="rowOrder padding20">
                <Grid item xs={4} className="padding0 key">
                    <span>Perks </span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.perks.join(", ")}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding0 columnOrder padding20">
                <span className="padding0 headings">Job details</span>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Job title</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.jobTitle}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Number of opening</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.noOfOpenings}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Job Category</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.workType===1?'Full Time':values.workType===2?'Part Time':'On Demand'}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Job description</span>
                </Grid>
                <Grid item xs={8} className="padding0 value" style={{whiteSpace: "pre-line"}}>
                    <span>{`${values.jobDescription}`}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Job location</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.ExactJobAddress}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Google map link</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    {(values.lat && values.long)?
                        <a className="linkColor" href={`https://www.google.com/maps/search/${values.lat},${values.long}`} target="_blank">
                            Job Location
                        </a>:
                        <span>No link</span>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Job Schedule</span>
                </Grid>
                <Grid item xs={8} className= "padding0">
                    {values.workType===1?
                        <Grid container className= "padding0">
                            <Grid item xs={5} className= "padding0">
                                <Grid item xs={12} className= "padding0 fieldRow">
                                    <span>No. of Days</span>
                                </Grid>
                                <Grid item xs={12}  className="padding0 value">
                                    <span>{values.noOfDays}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} className= "padding0">
                                <Grid item xs={12} className= "padding0 fieldRow">
                                    <span>No. of Hours</span>
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    <span>{values.noOfHrs}</span>
                                </Grid>
                            </Grid>
                        </Grid>:
                    values.workType===2?
                        <Grid container className= "padding0">
                            <Grid item xs className= "padding0">
                                <Grid item xs={12} className="padding0 fieldRow">
                                    <span>Days</span>
                                </Grid>
                                <Grid item xs={12} className= "padding0">
                                    <WeekToggle 
                                        value= {values.weekdays}
                                        isdisabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs className= "padding0">
                                <Grid item xs={12} className="padding0 fieldRow">
                                    <span>Time</span>
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    <span>{`${values.startTime} - ${values.endTime}`}</span>
                                </Grid>
                            </Grid>
                        </Grid>:
                        values.workType===3?
                        <Grid container className= "padding0">
                            <Grid item xs={5} className= "padding0">
                                <Grid item xs={12} className= "fieldRow">
                                    <span>No. of Days: </span>
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    <span>{values.noOfDays}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} className= "padding0">
                                <Grid item xs={12} className= "padding0 fieldRow">
                                    <span>Start Date: </span>
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    <span>{values.startDate}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className= "padding0">
                                <Grid item xs={12} className= "padding0 fieldRow">
                                    <span>Time: </span>
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    <span>{`${values.startTime} - ${values.endTime}`}</span>
                                </Grid>
                            </Grid>
                        </Grid>: null}
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Salary</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{`${values.salary} ${values.salaryType}`}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Payroll type</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.payrollType}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding20 columnOrder padding20">
                <span className="padding0 headings">Candidate requirements</span>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Experience</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.exp}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Age bracket</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.ageBracket}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>English fluency</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.engFluency}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Minimum qualification</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.qualification}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Qualification (with specialization)</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.specialization}</span>
                </Grid>
            </Grid>
            {(values.jobRole>44 && values.jobRole<53)?
                <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                    <Grid item xs={4} className="padding0 key">
                        <span>Cuisine</span>
                    </Grid>
                    <Grid item xs={8} className="padding0 value">
                        <span>{values.cuisine}</span>
                    </Grid>
                </Grid>: null
            }
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Gender Preference</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.gender}</span>
                </Grid>
            </Grid>
            <Grid xs={12} className="padding20 columnOrder padding20">
                <span className="padding0 headings">Interview details</span>
            </Grid>
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Interview process</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.interviewProcess}</span>
                </Grid>
            </Grid>
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Interview address</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.interviewAddress}</span>
                </Grid>
            </Grid>
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Google map link</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    {(values.lat && values.long)?
                        <a className="linkColor" href={`https://www.google.com/maps/search/${values.lat},${values.long}`} target="_blank">
                            Interview Location
                        </a>:
                        <span>No link</span>
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                    <span>Point of contact</span>
                </Grid>
                <Grid item xs={8} className="padding0 value">
                    <span>{values.pocName}</span>
                </Grid>
            </Grid>
            <Grid xs={12} className="padding5_0_5_20 rowOrder contactDetailsPadding">
                <Grid item xs={4} className="padding0 key">
                        <span>Preferred interview day and slot</span>
                    </Grid>
                <Grid item xs={8} className="padding0">
                    <Grid container>
                        {/*{values.interviewSlots.map(slot => 
                            <Grid item xs>
                                <Grid item xs={12} className="padding0 fieldRow">
                                    {slot.date}
                                </Grid>
                                <Grid item xs={12} className="padding0 value">
                                    {`${slot.startTime} - ${slot.endTime}`}
                                </Grid>
                            </Grid>
                        )}*/}
                        {values.interviewSlots.length>0?values.interviewSlots.map(slot => 
                            slot.weekdays?slot.weekdays.length>0?
                            <Grid item xs className="padding0">
                                <Grid item xs={12} className="padding0 fieldRow">
                                    <WeekToggle 
                                        value= {slot.weekdays}
                                        isdisabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} className="fieldRow padding0">
                                    {`${moment(slot.startTime).format("hh:mm A")} - ${moment(slot.endTime).format("hh:mm A")}`}
                                </Grid>
                            </Grid>: "Empty slot":null    
                        ): "No slots Selected"}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    return (
        <Modal
            className={classes.modal}
            open={openJobModal}
            onClose={() => setOpenJobModal(false)}
            closeAfterTransition
            
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className={`${classes.paper} modalContainer`} style={{height:"80%", overflow: "auto"}}>
                    {jobView}
                </div>
            </Slide>
        </Modal>
    )
}
export default JobModal;
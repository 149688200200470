const DropDownConstants = {
  industry: [
    {
      id: 105,
      industry: 'Restaurant'
    },
    {
      id: 106,
      industry: 'Hotel'
    },
    {
      id: 107,
      industry: 'Event'
    },
    {
      id: 108,
      industry: 'Catering'
    },
    {
      id: 109,
      industry: 'Office Jobs'
    },
    {
      id: 110,
      industry: 'Sales & Marketing'
    },
    {
      id: 111,
      industry: 'Warehouse'
    },
    {
      id: 112,
      industry: 'Logistics'
    },
    {
      id: 113,
      industry: 'Retail'
    },
    {
      id: 113,
      industry: 'Other'
    }
  ],
  states: {
    'Delhi': 71,
    'Uttar Pradesh': 72,
    'Haryana': 73
  },
  idToState: {
    71: 'Delhi',
    72: 'Uttar Pradesh',
    73: 'Haryana'
  },
  cities: [
    {
      id: 71,
      city: 'Delhi'
    },
    {
      id: 71,
      city: 'Central Delhi'
    },
    {
      id: 71,
      city: 'New Delhi'
    },
    {
      id: 72,
      city: 'Noida'
    },
    {
      id: 73,
      city: 'Gurugram'
    },
    {
      id: 73,
      city: 'Gurgaon'
    },
    {
      id: 72,
      city: 'Gautam Buddh Nagar'
    }
  ]
};

export default DropDownConstants;

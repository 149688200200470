import React from 'react';
import { MdComment,MdModeEdit,MdLibraryAdd} from "react-icons/md";
import { FaEye} from "react-icons/fa";

const WorkerOnBoardUtils={
    title:"OnBoarding Review",
    OnBoardingListAction:
        [{
            id:1,
            name:"View Employer Details",
            subIcon:<FaEye className="fitIcon"/>,
            route:"/worker/onBoarding"
        },
        {
            id:2,
            name:"Update Payment Term",
            subIcon:<MdModeEdit className="fitIcon"/>,
            route:"/worker/onBoarding"
        }
        ,
        {
            id:3,
            name:"Update Status",
            subIcon:<MdLibraryAdd className="fitIcon"/>,
            route:"/worker/onBoarding"
        },
        {
            id:4,
            name:"Comments",
            subIcon:<MdComment className="fitIcon"/>,
            route:"/worker/onBoarding"  
        }
    ],
 OnBoardingFilterList:[
     {status:["Registered Only","Partly Filled Info","All Info Filled"],id:"1",name:"status",label:"Status",default:[]},
     {reviewStatus:["Not Reviewed","In Review","Rejected","Reviewed","ReviewOnHold"],id:"2",name:"reviewStatus",label:"Review Status",default:[]}

 ]

    
}

export default WorkerOnBoardUtils
import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
function PrivacyPolicy() {


    return (
        <>
            <Header />
            <Grid container className="Rectangle-989">
                <h2 id="simple-modal-title">Privacy Policy</h2>
                <Grid item xs={12} className="padding0" >
                    <p id="simple-modal-description">
                        You must read and agree to our Terms of Use (the “Agreement”) because it forms the binding contract between you (freelance recruiter) and OkayGo (Company). <br />
  The OkayGo and the RECRUITMENT PARTNER are hereinafter referred to individually as <b>“Party”</b> and collectively as <b>“Parties”</b>. <br />
                        <br /><br />
                        <b>WHEREAS </b><br />
                        <ol type="A">
                            <li> OkayGo is engaged in the business, inter alia, of Recruitment. </li> <br />
                            <li> The RECRUITMENT PARTNER is enrolled under OkayGo-Remote Recruitment Partner arrangement and is engaged in the business of identifying and assisting in recruiting candidates with certain skill sets and abilities; recruitment processes (selection and scrutiny) and related services to prospective employers and have the necessary experience, expertise, databases of prospective candidates, contact with other similar RECRUITMENT PARTNERS to share databases containing curriculum vitae’s(CV), resume’s, bio-data, etc of prospective candidates, skilled and experienced staff, analysts, experts, offices, financial capacity, etc to provide such services; </li> <br />
                            <li> The RECRUITMENT PARTNER, has agreed to assist the Client in the processes of recruitment of prospective candidates and provide related services, recruit candidates for positions across functions for a consideration;</li> <br />
                            <li> OkayGo desires to engage the RECRUITMENT PARTNER on a non-exclusive basis to carry out the aforesaid service from time-to-time in accordance with the terms of this Agreement;</li> <br />
                            <li> The RECRUITMENT PARTNER, justifies the minimum eligibility criteria and agrees to the terms shared in Annexure-A. </li>
                        </ol>
                        <br />
  NOW THEREFORE, in consideration of the mutual promises contained herein, the Parties agree as follows:
  <br /><br />
                        <ol type="1">
                            <li> <b>Acceptance.</b> By using the Service, you agree to this Agreement and our Privacy Policy.</li> <br />
                            <li> <b>Eligibility.</b> You must be at least 18 to use the Service and not prohibited by law from using the Service. You promise to follow the law and that you’re not a convicted felon or sex offender.</li> <br />
                            <li> <b>Modifying the Services; Termination.</b> We may modify the Services at any time or discontinue them altogether. You can terminate your account by writing to us at operation@okaygo.in, and we reserve the right to terminate you if you violate this Agreement or if we deem your use of the Service to be inappropriate.</li> <br />
                            <li> <b>Safety.</b> OkayGo is not responsible for the actions of its users or your interactions with them, and we don’t conduct background checks. Be careful and be sure to read and follow standard Safety Tips on interacting with people on or off OkayGo platform.</li> <br />
                            <li> <b>Rights OkayGo Grants You.</b> OkayGo grants you the right to use our Service as authorized and permitted by this Agreement. OkayGo grants you a personal, non-assignable, nonexclusive, revocable, and non- sublicensable license to access and use the Services. This license is for the sole purpose of letting you earn money by offering employment opportunities to people in your network as intended by OkayGo and permitted by this Agreement.
      <br /> <br />
      Therefore, you <b>agree not to:</b> <br /> <br />
                                <ul style={{ listStyleType: "disc" }}>
                                    <li> use the Service or any content contained in the Service for any direct commercial purposes or transactions bypassing OkayGo without our written consent. </li>
                                    <li> copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content or proprietary information accessible through the Service without OkayGo’s prior written consent. </li>
                                    <li> express or imply that any statements you make are endorsed by OkayGo. </li>
                                    <li> use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method or process to access, retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents. </li>
                                    <li> use the Services in any way that could interfere with, disrupt or negatively affect the Service or the servers or networks connected to the Service. </li>
                                </ul>
                                <br />
      The Company may investigate and take any available legal action in response to illegal and/ or unauthorized uses of the Service, including termination of your account.
      <br /><br />
      Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device’s settings.
    </li> <br />
                            <li> <b>Rights You Grant OkayGo.</b> By creating an account, you grant to OkayGo a transferable, sub-licensable, royalty-free, right and license to host, store, use, copy, display, reproduce, adapt, edit, publish, modify and distribute information you authorize us to access from Facebook and Gmail, as well as any information you post, upload, display or otherwise make available (collectively, “post”) on the Service or transmit to other users (collectively, “Content”). Our license to your Content is subject to your rights under applicable law (for example laws regarding personal data protection to the extent any Content contains personal information as defined by those laws) and is for the limited purpose of operating, developing, providing, and improving the Service and researching and developing new ones. You agree that any Content you place or that you authorize us to place on the Service may be viewed by other users and may be viewed by any person visiting or participating in the Service (such as individuals who may receive shared Content from other OkayGo users). <br /> <br />
                                <ul style={{ listStyleType: "disc" }}>
                                    <li> You agree that all information that you submit upon creation of your account, including PAN and Aadhaar card information, is accurate and truthful. You understand and agree that we may monitor or review any Content you post as part of a Service. We may delete any Content, in whole or in part, that in our sole judgment violates this Agreement or may harm the reputation of the Service. </li>
                                    <li> When communicating with our customer care representatives, you agree to be respectful and kind. If we feel that your behaviour towards any of our customer care representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account. </li>
                                    <li> In consideration for OkayGo allowing you to use the Services, you agree that we, our affiliates, and our third-party partners may place advertising on the Services. By submitting suggestions or feedback to OkayGo regarding our Services, you agree that OkayGo may use and share such feedback for any purpose without compensating you. </li>
                                    <li>You agree that OkayGo may access, preserve and disclose your account information and Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary, such as to: (i) comply with legal process; (ii) enforce this Agreement; (iii) respond to claims that any Content violates the rights of third parties; (iv) respond to your requests for customer service; or (v) protect the rights, property or personal safety of the Company or any other person. </li>
                                </ul>
                            </li> <br />
                            <li> <b>Rules.</b> By using the Services, you agree that you will not: <br /> <br />
                                <ul style={{ listStyleType: "disc" }}>
                                    <li> use the Service for any purpose that is illegal or prohibited by this Agreement. </li>
                                    <li> spam, solicit money from or defraud any users. </li>
                                    <li> impersonate any person or entity. </li>
                                    <li> bully, “stalk,” intimidate, harass or defame any person. </li>
                                    <li> post any Content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual. </li>
                                    <li> solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other users or disseminate another person’s personal information without his or her permission. </li>
                                    <li> use another user’s account. </li>
                                    <li> create another account if we have already terminated your account, unless you have our permission. </li>
                                </ul>
      OkayGo reserves the right to investigate and/ or terminate your account without a refund of any purchases if you have misused the Service or behaved in a way that OkayGo regards as inappropriate or unlawful, including actions or communications that occur off the Service but involve users you meet through the Service.
    </li> <br />
                            <li> <b>Disclaimers.</b> Our Service is provided as a platform connecting job seeking candidates with the relevant employment opportunities. We disclaim legal liability for the quality, safety, or financial reliability of job offered after the candidate has joined the organisation. </li> <br />
                            <li> <b>Limitation of Liability.</b> To the extent allowed by law, we limit our liability to you for certain types of damages for claims relating to your use of the Service, the conduct of other users and unauthorized access or use of your Content. Our aggregate liability will not exceed the fees, if any, we pay you for the work done by you.</li> <br />
                            <li> <b>Arbitration.</b> Our Agreement contains a mandatory arbitration clause. You agree that any disputes will be settled by arbitration, and you waive your right to a trial by jury or to participate in a class action.</li> <br />
                            <li> <b>Indemnity.</b> You agree to indemnify us for actions arising out of your use of the Service, your Content or your violation of this Agreement.</li> <br />
                            <li> <b>This Agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts at Gurgaon </b></li> <br />
                            <li> <b>Applicable law :</b> The Recruitment Partner agrees to abide by the laws and regulations that are applicable to it and shall ensure that it conducts its business in accordance with such laws at all times.</li> <br />
                            <li> <b>Entire Agreement:</b> This Agreement supersedes all prior and contemporaneous agreements, representations and understandings and contains the entire agreement between the parties. </li> <br />
                            <li> <b>Amendments.</b> No amendment or modification of any provision of this Agreement shall be effective unless in writing and signed by a duly authorized representative of each Party. </li> <br />
                            <li> <b>Waiver:</b> No failure or delay of either Party in exercising any right or remedy under this Agreement shall operate as a waiver of such right or remedy</li> <br />
                            <li> <b>Independent Contractor:</b> The Recruitment Partner shall conduct its work under this Agreement as an independent contractor and this Agreement creates no relationship of principal and agent, partner, employee or any similar relationship. The Recruitment Partner acknowledges that it does not have any authority to act on the Company's behalf. The Recruitment Partner will not represent itself to be an agent for the Company and will not attempt to create any obligation or make any representation on behalf of or in the name of the Company</li> <br />
                            <li>
                                <b>PAYMENT: </b>
                                <ol>
                                    <li> OkayGo shall display the sourcing fee/pay-out in relation to each Opening on the app or dashboard or via email. The Recruitment Partner agrees and acknowledges that the OkayGo shall have the sole discretion to determine the pay-out in relation to any Opening. <br /><br />
                                        <i>For Recruitment partner under Remote Recruitment partner scheme payment terms are as displayed on App/Dashboard Payout section or will be shared over email and in Annexure - A. </i></li> <br />
                                    <li>The Recruitment Partner shall send across the bank account details to which such fee shall be remitted. </li> <br />
                                    <li>OkayGo shall make all payments as specified on OkayGo Dashboard and as achieved by Recruitment Partner during the month within a period of first 10 working days next month. All amounts shall be remitted to the bank account of the Recruitment Partner, as specified by the Recruitment Partner. </li> <br />
                                    <li>OkayGo shall have the right to reject or hold the pay-out as per use case basis. </li>
                                </ol>
                            </li> <br />
                        </ol>
                        <br /><br /><br />
                        <b>Annexure – A</b><br />
                        <center><b>REMOTE RECRUITMENT PARTNER SCHEME</b></center>
                        <br />
                        <b>To the Selected Recruiters OkayGo Will Provide </b>
                        <ul style={{ listStyleType: "square" }}>
                            <li>Required Candidate data as per specific case</li>
                            <li>Required knowledge base to get up to speed</li>
                            <li>Necessary training materials like scripts, FAQ, Pitch etc.</li>
                            <li>OkayGo Recruiter Dashboard</li>
                            <li>Required software tool</li>
                        </ul>
                        <br /><br />
                        <b>Specialized Project</b><br />
  These are success based high earning potential requirements. Pay out details will be shared on email or on OkayGo dashboard.
  <br /><br />
                        <b>TERMS OF PAYOUT. </b><br />
                        <i>
                            <ul style={{ listStyleType: "square" }}>
                                <li> Payout to recruitment partner will be released on monthly basis only. </li>
                                <li>Payout will be released only once the candidate completes the replacement period at new employer’s end. </li>
                                <li>Replacement Period and Joining Amount for every vacancy will be variable and will be provided on the email/ OkayGo Dashboard / OkayGo App. </li>
                                <li>Payout will be made with in the first 10 days of month. </li>
                                <li>Slabs for higher rates for joining amount is only applicable for the candidate completing replacement period in the same month for which payout is being released. </li>
                            </ul>
                        </i>
                    </p>
                </Grid>
            </Grid>
        </>
    );
}



export default PrivacyPolicy

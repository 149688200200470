import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import './filter.css'
const useStyles = makeStyles({
 
});

export default function InputSlider(props) {
    
  const classes = useStyles();
  const {label, id, filters, setFilters}= props
  let max=5;
  if(label==="Experience" || id==="experience")
    max=40;
    if(label==="Salary" || id==="salary")
    max=1000000;    
  const handleSliderChange = (event, newValue) => {
    setFilters({...filters, [id]:newValue})
  };

  const handleInputChange = (event) => {
    setFilters({...filters, [id]:event.target.value === '' ? '' : Number(event.target.value)})
  };

  return (
      <Grid container  alignItems="center" style={{padding: "20px"}}>
        <Grid xs={12}>{label}</Grid>
        
        <Grid  xs={6}  className="marginTop13 filterSlider" style={{paddingRight: "10px", width: "280px"}}>
          <Slider
            min={0}
            step={(id==="salary" || label==="Salary")?500: 1}
            max={max}
            label={label}
            value={typeof filters[id] === 'number' ? filters[id] : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid  xs={2} >
          <Input
            className={classes.input}
            value={filters[id]}
            onChange={handleInputChange}
            inputProps={{
              style:{textAlign:"center", width:"90px"},
              step: 1,
              min: 0,
              max: {max},
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
  );
}

import React, { Component } from 'react'
import { Card, Grid, Button } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../../utils/Table/Table";
import Constant from '../../../constant';
import WorkerScreeningUtils from './WorkerScreeningUtils';

class WorkerScreening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filters: WorkerScreeningUtils.screeningFilterList ,
            currentPage: 0,
            count:0,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "status",
                label: Constant.workerScreening.status,
                isColumnVisible: true
            }, {
                id:"2",
                name:"app_registered",
                label:"",
                isColumnVisible:true,
            },{
                id: "3",
                name: "workerId",
                label: Constant.workerScreening.workerId,
                isColumnVisible: true
            }, {
                id: "4",
                name: "name",
                label: Constant.workerScreening.name,
                isColumnVisible: true
            }, {
                id: "5",
                name: "od_rating",
                label: Constant.workerScreening.od_rating,
                isColumnVisible: true
            }
                , {
                id: "6",
                name: "od_breach_score",
                label: Constant.workerScreening.od_breach_score,
                isColumnVisible: true
            },
            {
                id: "7",
                name: "pt_ft_rating",
                label: Constant.workerScreening.pt_ft_rating,
                isColumnVisible: true

            },
            {
                id: "8",
                name: "pt_ft_breach_score",
                label: Constant.workerScreening.pt_ft_breach_score,
                isColumnVisible: true
            }, 
            {
                id: "9",
                name: "action",
                label: Constant.workerScreening.action,
                isColumnVisible: true
            }]
        }
        this.data = [
            {
                "status":"", 
                "app_registered":"Yes",
                "workerId":"W_0001",
                "name":"Ram K",
                "od_rating":"3.5",
                "od_breach_score":"2.9",
                "pt_ft_rating":"1",
                "pt_ft_breach_score":"2",
                
            },
            {
                "status":"", 
                "app_registered":"Yes",
                "workerId":"W_0002",
                "name":"Mohan K",
                "od_rating":"3.5",
                "od_breach_score":"2",
                "pt_ft_rating":"1",
                "pt_ft_breach_score":"2",

            },
            {

                "status":"", 
                "app_registered":"Yes",
                "workerId":"W_0003",
                "name":"Ajay K",
                "od_rating":"2.9",
                "od_breach_score":"1",
                "pt_ft_rating":"0",
                "pt_ft_breach_score":"2",

            }
        ];


        this.rowsOptions=[5,10,25,50,100,200,500];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);
    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }
    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        //to do
     
    }

    componentDidMount() {
        // Axios.get("http://localhost:3001/employers").then(
        //     (response) => { 
        //         this.setState({
        //             employer: response.data,

        //         });
        //     }
        // ).catch(
        //     (error) => { console.log(error) }
        // );
        this.setState({ list: this.data });

    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}></HideUnhideColumns>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomFilter filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid>
                        <Grid item sm={3}>
                            <Button style={{fontSize:"0.7rem"}}  variant="contained" color="primary"> Add Breach Case</Button>
                        </Grid>
                    </Grid>
                </Card>
                <CustomTable name={Constant.workerScreening.idName} columns={this.state.column} data={this.state.list} links={WorkerScreeningUtils.screeningListAction} title={WorkerScreeningUtils.title} tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}

export default WorkerScreening
import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Link } from "react-router-dom";
import InterviewModal from './interviewModal'
import FeedbackModal from './FeedbackModal'
import ProfileRejectModal from '../../../../RecruiterDB/RecrutierDBElements/ProfileRejectModal'
import JoinedModal from './JoinedModal'
import DashboardRemarkModal from '../../../../../utils/Modals/dashboardRemarkModal'
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';



const EditableDropdownSubComponent =( props) =>{
    const {row, columnId, initialValue, updateMyData, showToast, setLoading, interviewAddress, jobDetails} = props
    let statusUpdateUrl=`${urlLinks.baseUrl}job/assigner/updateStatus`
    const [value, setValue] = useState(initialValue);
    const [openSelectedStatus, setOpenSelectedStatus] = useState(false)
    const [disableConfirm, setDisableConfirm] = useState(false)
    const [profileReject, setProfileReject] = useState(0)
    const [openUpdateSlots, setOpenUpdateSlots] = useState(false)
    const [openProfileRejectModal, setOpenProfileRejectModal] = useState(false)
    const [openJoinedStatus, setOpenJoinedStatus] = useState(false)
    const [openRemarkModal, setOpenRemarkModal] = useState(false)
    console.log({columnId,row})
    const [statusData, setStatusData] = useState({
        joiningdate:row.joiningDate?moment(row.joiningDate,"DD/MM/YYYY"):null,
        offerDate:row.selectionDate?moment(row.selectionDate,"DD/MM/YYYY"):null,
        salary: row.offeredSalary?row.offeredSalary:null,
        offeredSalaryType:row.offered_amount_type?row.offered_amount_type:0
    })
    console.log({statusData})
    let label=""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        if(columnId==="interviewMode" && e.target.value===4){
            setOpenProfileRejectModal(true)
        }
        else{
            setShowButtons(true)
        }
        if(columnId==="recruiterFeedback" && e.target.value==="REJECTED"){
            setOpenProfileRejectModal(true)
        }
        else{
            setShowButtons(true)
        }
        if((columnId==="callStatus" && e.target.value==="NOT_INTERESTED") || (columnId==="approvalStatus" && e.target.value==="NOT_APPROVED")){
            setOpenRemarkModal(true)
        }
        if(columnId==="interviewFollowUp" && e.target.value==="RESCHEDULE")
            setOpenUpdateSlots(true)
        if(columnId==="finalFeedback" && e.target.value==="SELECTED")
            setOpenSelectedStatus(true)
        if(columnId==="employerFeedback" && e.target.value==="SELECTED")
            setOpenSelectedStatus(true)
        if(columnId==="joiningStatus" && (e.target.value==="JOINED" || e.target.value==="POSTPONED" || e.target.value==="LEFT_AFTER_JOINING")){
            label= e.target.value==="JOINED" ?"Enter joining data": e.target.value==="POSTPONED"?"Enter postponed Date": e.target.value==="LEFT_AFTER_JOINING"?"Enter leaving date":''
            setOpenJoinedStatus(true)
        }
        setValue(e.target.value)
    }
    
    const updateStatus = () =>{
        setLoading(true)
        console.log("entered", value)

        const postData={
            interview_id: row.interviewId,
            updated_by: localStorage.getItem('userId')
        }
        if(columnId==="interviewFollowUp")
        {
            statusUpdateUrl=`${urlLinks.baseUrl}tracking/updateInterviewStatus`
            postData.interview_status= value
        }
        else if(columnId==="finalFeedback"){
            statusUpdateUrl=`${urlLinks.baseUrl}tracking/updateFeedbackStatus`
            postData.feedback_status= value
            if(statusData.joiningdate && value==="SELECTED")
                postData.update_joining_date=moment(statusData.joiningdate).format("YYYY-MM-DD 00:00:00")
            if(statusData.offerDate && value==="SELECTED")
                postData.update_offered_date=moment(statusData.offerDate).format("YYYY-MM-DD 00:00:00")
        }
        else if(columnId==="joiningStatus" && value!=="LEFT_AFTER_JOINING")
        {
            statusUpdateUrl=`${urlLinks.baseUrl}tracking/updateJoiningStatus`
            postData.joining_status= value
            if(statusData.joiningdate)
                postData.update_joining_date=moment(statusData.joiningdate).format("YYYY-MM-DD 00:00:00")
        }
        else{
            if(columnId==="callStatus")
                postData.field="call_status";
            else if(columnId==="approvalStatus")
                postData.field="approval_status";
            else if(columnId==="interviewMode")
                postData.field="interview_mode";
            else if(columnId==="recruiterFeedback")
                postData.field="recruiter_feedback";
            else if(columnId==="employerFeedback")
                postData.field="employer_feedback";
            else if(columnId==="joiningStatus" && value==="LEFT_AFTER_JOINING")
            {
                postData.date_of_leaving= statusData.joiningdate;
                postData.field="date_of_leaving";
            }
            postData.assign_id= row.assignId;
            postData.fieldStatus= value;
            postData.user_id= row.userId;
            postData.worker_id= parseInt((row.workerId).toString().replace(/[a-zA-Z\-]/g, ''))
            postData.job_id= row.jobId;
            postData.job_details_id= row.jobDetailsId;
        }
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData()
                    showToast("success", "Status updated successfully")
                    if(profileReject===4)
                        setProfileReject(0)
                    setLoading(false);
                } else {
                    showToast("success", "Status updated failed")
                    showToast(response.data.message);
                    setLoading(false);
                }
            })
        

    }
    const handleProfilereject =() =>{
        setValue(4);
        setOpenProfileRejectModal(true)
    }
    const handleConfirm =() =>{
        setDisableConfirm(true)
        setLoading(true)
        updateStatus();
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    useEffect(() => {
        if(profileReject===4)
            updateStatus();
    }, [profileReject])
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    let dropdown=<div></div>
    if(columnId==="callStatus")
        dropdown=<div className="index">
            <Select
                id="callStatus"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="callStatus" disabled>
                    <em>Call status</em>
                </MenuItem>
                <MenuItem value='INTERESTED'>Interested</MenuItem>
                <MenuItem value='NOT_INTERESTED'>Not interested</MenuItem>
                <MenuItem value='CALL_NOT_RECIEVED'>Call not Recieved</MenuItem>
                <MenuItem value='BUSY'>Busy</MenuItem>
                <MenuItem value='CALL_LATER'>Call later</MenuItem>
                <MenuItem value='INVALID_NUMBER'>Invalid number</MenuItem>
                <MenuItem value='SWITCHED_OFF'>Switched off</MenuItem>
            </Select>
            
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {row.callStatusDate && 
                <div>
                    <span>{row.callStatusDate}</span>
            </div>}
            
        </div>
        else if(columnId==="interviewMode"  && row.approvalStatus==="APPROVED")
        dropdown=<div className="index columnOrder">
            <Select
                id="interviewMode"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="interviewMode" disabled>
                    <em>Interview mode</em>
                </MenuItem>
                <MenuItem value={1}>Profile shared</MenuItem>
                <MenuItem value={2}>Telephonic</MenuItem>
                <MenuItem value={3}>Face to face</MenuItem>
                {row.interviewMode===4 && <MenuItem value={4}>Profile rejected</MenuItem>}
            </Select>
            {value===1 && <Button 
                className="linkButton"
                onClick={handleProfilereject}
            >
                Reject profile
            </Button>}
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {row.profileSharedDate && 
                <div>
                    <span>Profile shared on: {row.profileSharedDate}</span>
            </div>}
        </div>
    else if(columnId==="approvalStatus" && row.callStatus==="INTERESTED"){
        dropdown=<div className="index">
            <Select
                id="approvalStatus"
                value={value}
                disableUnderline
                // disabled={row.approvalStatus}
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="approvalStatus" disabled>
                    <em>Approval status</em>
                </MenuItem>
                <MenuItem value='APPROVED'>Approved</MenuItem>
                <MenuItem value='NOT_APPROVED'>Not approved</MenuItem>
            </Select>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}  disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="interviewFollowUp" && row.whichSlot!==0){
        dropdown=<div className="index">
            <Select
                id="interviewFollowUp"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="interviewFollowUp" disabled>
                    <em>Interview status</em>
                </MenuItem>
                <MenuItem value='GOING'>Going</MenuItem>
                <MenuItem value='NOT_GOING'>Not going</MenuItem>
                <MenuItem value='RESCHEDULE'>Reschedule</MenuItem>
                <MenuItem value='NO_RESPONSE'>No Response</MenuItem>
                <MenuItem value='APPEARED'>Appeared</MenuItem>
            </Select>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="recruiterFeedback" && (row.interviewFollowUp==="APPEARED" || row.interviewFollowUp==="GOING")){
        dropdown=<div className="index">
            <Select
                id="recruiterFeedback"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="recruiterFeedback" disabled>
                    <em>Feedback</em>
                </MenuItem>
                <MenuItem value='SELECTED'>Selected</MenuItem>
                <MenuItem value='REJECTED'>Rejected</MenuItem>
                <MenuItem value='ROUNDS_PENDING'>Rounds pending</MenuItem>
                <MenuItem value='RESULT_AWAITED'>Result awaited</MenuItem>
                <MenuItem value='CANDIDATE_DROPPED'>Candidate dropped</MenuItem>
                <MenuItem value='NOT_APPEARED'>Not appeared</MenuItem>
            </Select>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="employerFeedback" && (row.interviewFollowUp==="APPEARED" || row.interviewFollowUp==="GOING")){
        dropdown=<div className="index">
            <Select
                id="employerFeedback"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="employerFeedback" disabled>
                    <em>Feedback</em>
                </MenuItem>
                <MenuItem value='SELECTED'>Selected</MenuItem>
                <MenuItem value='REJECTED'>Rejected</MenuItem>
                <MenuItem value='ROUNDS_PENDING'>Rounds pending</MenuItem>
                <MenuItem value='RESULT_AWAITED'>Result awaited</MenuItem>
                <MenuItem value='CANDIDATE_DROPPED'>Candidate dropped</MenuItem>
                <MenuItem value='NOT_APPEARED'>Not appeared</MenuItem>
            </Select>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    else if(columnId==="finalFeedback" && (row.interviewFollowUp==="APPEARED" || row.interviewFollowUp==="GOING")){
        dropdown=<div className="index">
            <Select
                id="callStatus"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="finalFeedback" disabled>
                    <em>Feedback</em>
                </MenuItem>
                <MenuItem value='SELECTED'>Selected</MenuItem>
                <MenuItem value='REJECTED'>Rejected</MenuItem>
                <MenuItem value='ROUNDS_PENDING'>Rounds pending</MenuItem>
                <MenuItem value='RESULT_AWAITED'>Result awaited</MenuItem>
                <MenuItem value='CANDIDATE_DROPPED'>Candidate dropped</MenuItem>
                <MenuItem value='NOT_APPEARED'>Not appeared</MenuItem>
                <MenuItem value='CONFLICT'>Conflict</MenuItem>
                <MenuItem value='JOINED'>Joined</MenuItem>
                <MenuItem value='NOT_JOINING'>Not joining</MenuItem>
                <MenuItem value='LEFT_AFTER_JOINING'>Left after joining</MenuItem>
            </Select>
            {showButtons &&
                <div>
                    <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                        <DoneSharpIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={handleCancel} >
                        <CloseSharpIcon />
                    </IconButton>
                </div>
            }
        </div>
    }
    else if(columnId==="joiningStatus" && (row.finalFeedback==="SELECTED" || row.finalFeedback==="JOINED" || row.finalFeedback==="LEFT_AFTER_JOINING" || row.finalFeedback==="NOT_JOINING")){
        dropdown=
        <div>
            <Select
                id="joiningStatus"
                value={value}
                    disableUnderline
                    onChange={onChange}
                    inputProps={{
                        classes: {
                            icon: "selectIcon",
                        },
                    }}
                >
                <MenuItem value="joiningStatus" disabled>
                    <em>Joining status</em>
                </MenuItem>
                <MenuItem value='JOINED'>Joined</MenuItem>
                <MenuItem value='NOT_JOINING'>Not joining</MenuItem>
                <MenuItem value='POSTPONED'>Postponed</MenuItem>
                <MenuItem value='NO_RESPONSE'>No response</MenuItem>
                <MenuItem value='LEFT_AFTER_JOINING'>Left after joining</MenuItem>
            </Select>
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm} disabled={disableConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    }
    





    
    return (
        <React.Fragment>
            {dropdown}
            {openUpdateSlots && <InterviewModal
                interview={row}
                updateMyData={updateMyData}
                setLoading= {setLoading}
                showToast={showToast}
                type="interviewRescheduleStatus"
                interviewAddress={interviewAddress}
                openUpdateSlots={openUpdateSlots}
                setOpenUpdateSlots={setOpenUpdateSlots}
            />}
            {openSelectedStatus && <FeedbackModal
                statusData={statusData}
                setStatusData={setStatusData}
                openSelectedStatus={openSelectedStatus}
                setOpenSelectedStatus= {setOpenSelectedStatus}
                updateMyData={updateMyData}
                showToast={showToast}
                columnId={columnId}
                setLoading= {setLoading}
                interviewId={row.interviewId}
                row={row}
            />}
            {openJoinedStatus && <JoinedModal
                statusData={statusData}
                setStatusData={setStatusData}
                openJoinedStatus={openJoinedStatus}
                setOpenJoinedStatus= {setOpenJoinedStatus}
                updateMyData={updateMyData}
                showToast={showToast}
                setLoading= {setLoading}
                interivewId={row.interivewId}
                label={label}
            />}
            {openRemarkModal && <DashboardRemarkModal
                openRemarkModal={openRemarkModal}
                setOpenRemarkModal={setOpenRemarkModal}
                handleCancel= {handleCancel}
                showToast={showToast}
                status={value}
                row={row}
            />}
            {
                openProfileRejectModal && <ProfileRejectModal 
                    open={openProfileRejectModal}
                    setOpen={setOpenProfileRejectModal}
                    type={columnId==="interviewMode"?"interviewMode":columnId==="recruiterFeedback"?"recruiterFeedback":""}
                    row={row}
                    jobDetails={jobDetails}
                    updateStatus={updateStatus}
                    showToast={showToast}
                />
            }
        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;




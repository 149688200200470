import React, { Component } from 'react';
import {
  Typography,
  Grid,
  Button,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Card
} from '@material-ui/core';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import * as moment from 'moment'
import urls from '../../../urlLinks';
import { CircleLoader } from 'react-spinners';
import BackButton from '../../../utils/BackButton/BackButton';
import { toast } from 'react-toastify';
import utilFunctions from '../../../utilMethods';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  textStyle: {
    textDecoration: 'underline'
  },
  textStyle1: {
    textDecoration: 'underline',
    fontSize: '0.8rem'
  },
  grid: {
    marginBottom: '20px'
  },
  imageStyle: {
    height: '120px',
    width: '120px',
    margin: '10px 5px 10px 5px'
  },
  divStyle: {
    display: 'flex',
    flexFlow: 'column',
    height: '200px',
    width: '130px'
  },
  alignFormat: {
    margin: '0 auto 5px ',
    fontSize: '0.8rem'
  },
  gridStyle: {
    margin: '10px 0 10px 0'
  }
});

class ViewWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      globalAvailability: 0,
      personalDetails: {
        lastSalary:'',
        email:'',
        cvLink:'',
        english_fluency:'',
        gender:'',
        name: '',
        age: '',
        aboutMe: '',
        qualification: '',
        email: '',
        phone: '',
        address: '',
        landmark:'',
        city: '',
        pincode: ''
      },
      otherDetails: {
        gigJobs: '',
        odRating: '',
        pt_ftRating: '',
        odBreachScore: '',
        pt_ftBreachScore: ''
      },
      file: '',
      rating: '',
      location: '',
      status: '',
      availability: [
        {
          day: '',
          startTime: '',
          endTime: ''
        }
      ],
      experience: [],
      jobProfiles: [],
      buttons: [],
      cvButton:[]
    };
    this.driverLicense = '';
    this.getTableElements = this.getTableElements.bind(this);
  }

  mapNumbersToDays = days => {
    const numbersToDays = {
      2: 'Monday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Thursday',
      6: 'Friday',
      7: 'Saturday',
      1: 'Sunday'
    };
    const convertedDays = days.map(el => {
      return numbersToDays[el];
    });
    return convertedDays;
  };

  getTableElements() {
    const rowElement = this.state.experience.map((element, index) => {
      const cols = Object.keys(element).map((value, index) => {
        return <TableCell key={index}>{element[value]}</TableCell>;
      });
      return <TableRow key={index}>{cols}</TableRow>;
    });
    console.log({rowElement});
    
    return rowElement;
  }

  monthDiff = (dateFrom, dateTo) => {
    if (
      dateTo
        .toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
        .split(',')[0] === '1/1/1970'
    ) {
      dateTo = new Date();
    }
    if (dateFrom) {
      // console.log(dateFrom);
      // console.log(dateTo);
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    } else {
      return 0;
    }
  };

  getWorkerList = id => {
    Axios.get(`${urls.baseUrl}/workermaster/workerByUserIdWithEid?user_id=${id}`)
      .then(response => {
        const data = response.data.response.content[0];
        console.log(data);
        this.profileImageLink = data.profilePhoto ? data.profilePhoto : '';
        // console.log();
        this.setState({
          personalDetails: {
            name: data.firstName ? `${data.firstName} ${data.lastName || '' }` : '',
            age: data.age ? data.age : '',
            cvLink:data.cv_link ? 1:0,
            gender:data.gender ? data.gender == 26 ? 'Male' : 'Female' : 'Not Specified',
            dob: data.dateOfBirth?moment(data.dateOfBirth, "DD-MM-YYYY").format("DD MMM YYYY"):"N/A",
            english_fluency:data.english_known_level ?data.english_known_level==1?'No English':data.english_known_level==2?'Thoda English':'Good English':'Not Specified',
            email:data.emailId ? data.emailId : '---',
            qualification: data.qualification ? data.qualification : '',
            email: data.emailId ? data.emailId : '',
            lastSalary: data.last_salary ? data.last_salary: '',  
            phone: data.phoneNumber || data.paytmNumber,
            aboutMe: data.aboutMe ? data.aboutMe : '',
            address: data.addressLine1 ? data.addressLine1 : '',
            landmark:data.addressLine2 ? data.addressLine2 : '',
            city: data.newCity?`${data.newCity}${data.newState?", " :''}${data.newState?data.newState:''}`:'-',
            pincode: data.pincode || ''
          },
          otherDetails: {
            gigJobs: data.totalGigs ? data.totalGigs : 0,
            odRating: data.odRating ? data.odRating : 0,
            pt_ftRating: data.ptFtRating ? data.ptFtRating : 0,
            odBreachScore: data.odBreach ? data.odBreach : 0,
            pt_ftBreachScore: data.ptFtBreach ? data.ptFtBreach : 0
          },
          globalAvailability: data.globalAvailability,
          rating: data.averageRating || 0,
          location: data.cityName ? data.cityName : null,
          status: data.worker_status ? data.worker_status : ''
        });
        let workerExperiences = [];
        const jobProfiles = [];
        for (const profile of data.jobInterests) {
          jobProfiles.push(profile.jobTypeName);
        }

        for (const experience of data.workerExperiences) {
          workerExperiences.push({
            industry: experience.industryName ? experience.industryName : '',
            company: experience.companyName ? experience.companyName : '',
            jobProfile: experience.jobTypeName ? experience.jobTypeName : '',
            startDate:  moment(experience.fromDate).format("MMM YYYY"),
            endDate: experience.toDate?moment(experience.toDate).format("MMM YYYY"):"Present",
            location: experience.workLocation?experience.workLocation:'-'
          });
        }
        this.setState({
          experience: workerExperiences,
          jobProfiles: jobProfiles
        });

        let workerAvailability = [];
        for (const availability of data.workerAvailabilities) {
          let days = [];
          const daysList = JSON.parse(availability.workdaysDetails);
          for (const day in daysList) {
            if (daysList[day].toString().toLowerCase() === 'true') {
              days.push(day);
            }
          }
          days = this.mapNumbersToDays(days);
          workerAvailability.push({
            day: days.length === 7 ? 'Everyday' : days.join(', '),
            startTime: availability.loginTime,
            endTime: availability.logoutTime
          });
        }
        this.setState({ availability: workerAvailability, isLoading: false });

        const cvLink=[];
      
        cvLink.push({
          name:'CV Link',
          path:data.cv_link,
          
        })
        const cvButton=[];
        if(data.cv_link){
        cvLink.map((cvLink,index)=>{
          cvButton.push({
            component:(
              <Grid
                key={index + 1}
                container
                className={this.props.classes.gridStyle}>
                <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                  <Typography style={{ fontSize: '0.9rem' }}>
                    {cvLink.name}:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {/* <Button
                    style={{ fontSize: '0.8rem' }}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      utilFunctions.downLoadFile(cvLink.path, cvLink.name);
                      console.log(cvLink.path);
                    }}>
                    Download
                  </Button> */}
                <Button
                 href={cvLink.path}
                 variant="contained" color="primary"
                //  target="_blank"
                 download>
                 Download   
                </Button>

                </Grid>
             </Grid>
            )
          });
          return null;
        });
      }

        this.setState({cvButton:cvButton})
        

        const documents = [];
        for (const document of data.userDocuments) {
          documents.push({
            name: document['document_type'],
            path: document['file_path']
          });
        }
        const buttons = [];
        documents.map((document, index) => {
          buttons.push({
            component: (
              <Grid
                key={index + 1}
                container
                className={this.props.classes.gridStyle}
              >
                <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                  <Typography style={{ fontSize: '0.9rem' }}>
                    {document.name}:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <a
                    // style={{ fontSize: '0.8rem' }}
                    // variant='contained'
                    // color='primary'
                    // onClick={() => {
                    //   utilFunctions.downLoadFile(document.path, document.name);
                    // }}
                    href={document.path}
                    color="transparent"
                    target="_blank"
                    // className={classes.navLink}
                  >
                    Download
                  </a>
                </Grid>
              </Grid>
            )
          });
          return null;
        });
        this.setState({ buttons: buttons });
        if (this.profileImageLink) {
          utilFunctions.getBase64String(this.profileImageLink).then(result => {
            const base64 = 'data:image;base64,' + result;
            this.setState({
              ...this.state,
              file: base64
            });
          });
        }
      })
      .catch(error => {
        toast.error('Could not load page :(');
        // console.log(error);
      });
  };

  componentDidMount() {
    if (!this.props.location.id) {
      this.props.history.push('/worker/workerList');
    } else {
      this.getWorkerList(this.props.location.id);
    }
  }
  render() {
    const classes = this.props.classes;
    const rowData = this.getTableElements();
    const availability = this.state.availability.map((element, index) => {
      let elementToRender = null;
      if (element) {
        elementToRender = (
          <Grid item sm={12} key={index} style={{ marginBottom: '10px' }}>
            {`${element.day ? `Available Days = ${element.day}` : ''}`}
            <br />
            {`${
              element.startTime || element.endTime
                ? `Available Time = 
                ${(element.startTime.substring(0, 5).split(':')[0] >= 12 &&
                  (element.startTime.substring(0, 5).split(':')[0] - 12 || 12) +
                    ':' +
                    element.startTime.substring(0, 5).split(':')[1] +
                    ' PM') ||
                  (Number(element.startTime.substring(0, 5).split(':')[0]) ||
                    12) +
                    ':' +
                    element.startTime.substring(0, 5).split(':')[1] +
                    ' AM'} TO 
                ${(element.endTime.substring(0, 5).split(':')[0] >= 12 &&
                  (element.endTime.substring(0, 5).split(':')[0] - 12 || 12) +
                    ':' +
                    element.endTime.substring(0, 5).split(':')[1] +
                    ' PM') ||
                  (Number(element.endTime.substring(0, 5).split(':')[0]) ||
                    12) +
                    ':' +
                    element.endTime.substring(0, 5).split(':')[1] +
                    ' AM'}`
                : ''
            }`}
          </Grid>
        );
      }
      return elementToRender;
    });
    const jobProfiles = this.state.jobProfiles.join(',  ');
    if (this.state.isLoading) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
      return (
        <div>
          <Typography variant='h5' gutterBottom>
            {this.state.personalDetails.name || this.props.fullName}
            {this.state.personalDetails.phone
              ? ` (${this.state.personalDetails.phone})`
              : ` (${this.props.location.phoneNumber})`}
          </Typography>

          <div className={classes.root}>
            <BackButton />
            <div className={classes.divStyle}>
              <Avatar
                variant='square'
                alt='image'
                src={this.state.file}
                className={classes.imageStyle}
              />
              <Typography
                variant='h5'
                gutterBottom
                className={classes.alignFormat}
              >
                Rating: {this.state.rating}
              </Typography>
              <Typography
                variant='h5'
                gutterBottom
                className={classes.alignFormat}
              >
                {this.state.status}
              </Typography>
              <Typography
                variant='h5'
                gutterBottom
                className={classes.alignFormat}
              >
                {this.state.personalDetails.city}
              </Typography>
              <Link
                to={{
                  pathname: `/worker/workerList/editWorker`,
                  id: this.props.location.id
                }}
                className={classes.alignFormat}
              >
                <Button
                  style={{ fontSize: '0.8rem', textDecoration: 'none' }}
                  variant='contained'
                  color='primary'
                >
                  Edit
                </Button>
              </Link>
            </div>
            <Grid container style={{ marginLeft: '70px' }}>
              <Grid
                container
                className={classes.grid}
                style={{ maxWidth: '800px' }}
              >
                {this.state.personalDetails.aboutMe}
              </Grid>
              <Grid container className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle}>
                    Experience
                  </Typography>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Card style={{ maxWidth: '800px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Industry</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>Job Position</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Location</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{rowData}</TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>

              <Grid container className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle}>
                    Availability
                  </Typography>
                </Grid>

                {this.state.globalAvailability
                  ? 'Available All The Time'
                  : availability}
              </Grid>
              <Grid container className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle}>
                    Interested In
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle1}>
                    Job Profiles
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  {jobProfiles}
                </Grid>
              </Grid>
              <Grid container className={classes.gridStyle}>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle}>
                    Other Details
                  </Typography>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Age:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.age}{' '}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Date of birth:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.dob}{' '}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Gender:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.gender}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                  English Fluency:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.english_fluency}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                  Email:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.email}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Qualification:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.qualification}{' '}
                  </Grid>
                </Grid>
                {/* <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Email:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.email}{' '}
                  </Grid>
                </Grid> */}
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Address:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.address}{' '}
                  </Grid>
                </Grid>

                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Landmark:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.landmark}{' '}
                  </Grid>
                </Grid>

                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    City:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.city}{' '}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Pincode:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.pincode}{' '}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.gridStyle}>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    #Gig Jobs:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.otherDetails.gigJobs}{' '}
                  </Grid>
                </Grid>

                
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    Last Salay:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.personalDetails.lastSalary}{' '}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    #OD Rating:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.otherDetails.odRating}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    #PT/FT Rating:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.otherDetails.pt_ftRating}
                  </Grid>
                </Grid>
                {/* <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    OD Breach Score:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.otherDetails.odBreachScore}
                  </Grid>
                </Grid>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={3}>
                    PT/FT Breach Score:
                  </Grid>
                  <Grid item xs={4}>
                    {this.state.otherDetails.pt_ftBreachScore}
                  </Grid>
                </Grid> */}
              </Grid>

              <Grid container className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.textStyle}>
                    Documents
                  </Typography>
                  {/* {this.state.buttons.map(button => button.component)} */}
                  {this.state.cvButton.map(cvButton=>cvButton.component)}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(ViewWorker);

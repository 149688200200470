import React, { useState, useEffect } from 'react'
import {FormControl, InputLabel, Select , MenuItem} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin:"-12px 0 0 0 !important",
      minWidth: 120,
    }
  }));

const SelectComponent =(props) =>{
    const classes = useStyles();
    const {options, value, setValue, label, disable, setFieldValue, name, error} = props
    const SelectOptions=options.map(option => {
        return <MenuItem key={option[0]} value={option[0]} >{option[1]}</MenuItem>
    })
    if(props.disabledValue)
        SelectOptions.unshift(<MenuItem disabled key={props.disabledValue[0]} value={props.disabledValue[0]} >{props.disabledValue[1]}</MenuItem>)

    const handleChange =(event) =>{
        if(setValue)
            setValue(event.target.value)  
        else if(setFieldValue)
            setFieldValue(props.name, event.target.value)
    }


    return (
        <FormControl fullWidth={true} variant = "outlined"  className={classes.formControl}>
            {label && <InputLabel id={label}>{label}</InputLabel>}
            <Select 
                labelId={label}
                value={value}
                label={label}
                error={error?error:false}
                onChange={handleChange}
                disabled={disable}
            >
                {SelectOptions}
            </Select>
        </FormControl>
    )
}

export default SelectComponent;

import React from "react";
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import ProfileImages from '../assets/users/Image 2.png';
import ProfileImages1 from '../assets/users/testimonial1.png';
import ProfileImages2 from '../assets/users/testimonial2.png';
import ProfileImages3 from '../assets/users/testimonial3.png';
import LeftQuote from "../assets/quote-left.png";


const Testimonials = () => {
    return (
        <div class="carousel-wrapper">
            <Carousel navButtonsAlwaysInvisible={true} navButtonsAlwaysVisible={false} fullHeightHover={true}>
                <div>
                    <div className="myCarousel">
                        <div className="myCarousel-left">
                            <h3>Here's what our recruitment partners have to say!</h3>
                            <div class="yellowRectangle marginTop20"></div>
                            <div className="leftQuote">
                                <img src={LeftQuote} alt="LeftQuote" className="LeftQuoteImg" />
                            </div>
                        </div>
                        <div className="myCarousel-right">
                            <div>
                                <img src={ProfileImages1} alt="img1" className="carousal-content-left" />
                                <p className="carousal-content-right">
                                    OkayGo has very flexible working hours. The
                                    peers are very much helpful and supportive.
                                    OkayGo has a very flexible onboarding process
and I am happy to be a part of OkayGo.                                <div className="providedBy">- Suchismita, Gig Recruiter</div>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <div>
                    <div className="myCarousel">
                        <div className="myCarousel-left">
                            <h3>Here's what our recruitment partners have to say!</h3>
                            <div class="yellowRectangle marginTop20"></div>
                            <div className="leftQuote">
                                <img src={LeftQuote} alt="LeftQuote" className="LeftQuoteImg" />
                            </div>
                        </div>
                        <div className="myCarousel-right">
                            <div>
                                <img src={ProfileImages2} alt="img1" className="carousal-content-left" />
                                <p className="carousal-content-right">
                                    I am earning good income with flexibility of
                                    working hours. OkayGo has a supportive team
                                    always ready to help. They are providing more
                                    opportunities with attractive compensations
                                    encouraging us to work more.
                                    <div className="providedBy">- Reshma, Gig Recruiter</div>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div>
                    <div className="myCarousel">
                        <div className="myCarousel-left">
                            <h3>Here's what our recruitment partners have to say!</h3>
                            <div class="yellowRectangle marginTop20"></div>
                            <div className="leftQuote">
                                <img src={LeftQuote} alt="LeftQuote" className="LeftQuoteImg" />
                            </div>
                        </div>
                        <div className="myCarousel-right">
                            <div>
                                <img src={ProfileImages3} alt="img1" className="carousal-content-left" />
                                <p className="carousal-content-right">
                                    Its been a wonderful journey. Being a mother of
                                    two I am able to work during my available hours
                                    due to the flexible work time. The software
                                    OkayGo provides is really amazing with all
features required for recruitment processes.<div className="providedBy">- Bhavana, Gig Recruiter</div>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}

export default Testimonials;
import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import './filter.css'
const useStyles = makeStyles(theme=>({
    root: {
        "& > *": {
          margin: theme.spacing(1),
          width: "30ch"
        }
      }
}));

export default function InputTextFilter(props) {
    const {filters, setFilters, id, label}= props
    console.log(filters[id], id);
    
    const classes = useStyles();
    
    
    const handleInputChange = (event) => {
        const value= event.target.value === '' ? '' : event.target.value
        setFilters({...filters, [id] : value });
    };


    return (
        <Grid xs={12} className={classes.root} >
            <TextField
                className={`${id==="experience"?"marginTop-10":''}`}
                style={{minWidth: "175px"}}
                value={filters[id]}
                label={label}  
                onChange={handleInputChange}
            />
        </Grid>
  );
}

export const CommonConstants = {
    REGISTERED_TITLE: "Thanks for sharing your details.",
    REGISTERED_TEXT: `We will respond to you within 2-3 working days.`,
    UNDER_ASSESSMENT_TITLE: "Your profile is now under assessment.",
    UNDER_ASSESSMENT_TEXT: "OkayGo onboarding team may reach out to you if required.",
    APPROVED_TITLE: "Congratulations! You have been selected for working with OkayGo.",
    APPROVED_TEXT: `Please upload your documents on My Profile page. Reach out to us at <a href='mailto:rpsupport@okaygo.in'>rpsupport@okaygo.in</a> for any queries.`,
    PENDING_FOR_ONBOARDING_TITLE: "Congratulations and thank you for completing the registration and documentation process.",
    PENDING_FOR_ONBOARDING_TEXT: `As the next step you will be undergoing a brief training program before starting to work with OkayGo. OkayGo onboarding team reach out to you soon for the same. Please reach out to us at <a href='mailto:rpsupport@okaygo.in'>rpsupport@okaygo.in</a> for any queries.`,
    ON_HOLD_TITLE: "Your Recruitment Partner application has been kept on hold for now.",
    ON_HOLD_TEXT: `We are assessing your application and will reach out to you soon. Write to us at <a href='mailto:rpsupport@okaygo.in'>rpsupport@okaygo.in</a> in case of any queries or concerns`,
    REJECTED_TITLE: "Sorry, unfortunately as per our requirements and eligibilty criteria, we won't able to proceed further with your Recruitment Partner application currently.",
    REJECTED_TEXT: "We really appreciate you showing interest and will be keeping your profile for future consideration.",
    INACTIVE_TITLE: "Your OkayGo Recruitment Partner account has been deactivated.",
    INACTIVE_TEXT: `Write to us at <a href='mailto:rpsupport@okaygo.in'>rpsupport@okaygo.in</a> for any queries or if you wish to again start working with OkayGo.`
};
import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, IconButton,} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import InterviewModal from './interviewModal'
import FeedbackModal from './FeedbackModal'
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';



const EditableTextInput =( props) =>{
    const {row, column, setLoading, showToast, updateMyData} = props
    const initialValue=row.original[column.id]
    const statusUpdateUrl=`${urlLinks.baseUrl}job/assigner/updateStatus`
    const offerUpdateUrl=`${urlLinks.baseUrl}interview/offerjob?`
    const [value, setValue] = useState(initialValue);
    const label=""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    const updateOfferData =() => {
        setLoading(true)
        let postData={
            interview_id: row.original.interviewId,
            is_job_offered: 1,
            requested_by: parseInt(localStorage.getItem('userId'))
        }
        if(column.id==="offeredSalary"){
            postData['job_offered_amount']= value
            postData['joining_date']= `${moment(row.original.joiningDate, "DD/MM/YYYY").format("YYYY-MM-DD")} 00:00:00`
        }
        if(column.id==="joiningDate"){
            postData['job_offered_amount']= row.original.offeredSalary
            postData['joining_date']=  `${moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")} 00:00:00`
        }
        // console.log({postData});
        
        Axios.post(offerUpdateUrl, qs.stringify(postData))
            .then(response =>{
                if (response.data.code === 1000) {
                    updateMyData();
                    setShowButtons(false)
                    setLoading(false);
                } else {
                    throw showToast("error", response.data.message);
                    setLoading(false);
                }
            })
    }
    const updateStatus = () =>{
        setLoading(true)
        const postData={
            interview_id: row.original.interviewId,
            updated_by: localStorage.getItem('userId'),
            assign_id: row.original.assignId,
            field: "remark",
            fieldStatus: value,
            user_id: row.original.userId,
            worker_id: parseInt((row.original.workerId).toString().replace(/[a-zA-Z\-]/g, '')),
            job_details_id: row.original.jobDetailsId,
            job_id: row.original.jobId
        }
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData();
                    setShowButtons(false)
                    setLoading(false);
                } else {
                    throw showToast("error", response.data.message);
                    setLoading(false);
                }
            })
    }
    const handleConfirm =() =>{
        setLoading(true)
        if(column.id==="remarks")
            updateStatus();
        else if(column.id==="offeredSalary" || column.id==="joiningDate")
            updateOfferData();
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    return (
        <div>
            <TextField 
                id="standard-basic" 
                disableUnderline
                value={value}
                onChange={onChange}
                className="borderStd margin0"
                multiline={true} 
                InputProps={{
                    disableUnderline: true
                }} 
            />
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    )
}

export default EditableTextInput;
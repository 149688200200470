import React, { useState, useEffect , useCallback} from 'react'
import {Button, Grid} from '@material-ui/core'
import WorkerExpModal from '../../../../utils/Modals/WorkerExpModal'
import DeleteIcon from '@material-ui/icons/Delete';
import * as moment from 'moment'

const JobComponent =(props) =>{
    const { values, setFieldValue, index, lastExp, locationList, editFlag, setValues, errors, touched} = props
    const [openWorkerExpModal, setOpenWorkerExpModal] = useState(false)
    const [data, setData] = useState()
    const handleEditExp =() =>{
        if(lastExp){
            setFieldValue("newExp", values.lastExperience);
        }
        setOpenWorkerExpModal(true)
        
    }
    console.log({props, data})
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    const createData = ()=>{
        let expObject={}
        if(lastExp ){
            expObject.job_role=values.lastExperience.job_role ||'-'
            expObject.job_category=values.lastExperience.job_category ||'-'
            expObject.industry=values.lastExperience.industry ||'-'
            expObject.designation=values.lastExperience.designation ||'-'
            expObject.company=values.lastExperience.company ||'-'
            expObject.jobStartDate=moment(values.lastExperience.jobStartDate).format("MMM YYYY") ||'-'
            expObject.jobEndDate= values.lastExperience.jobEndDate ?moment(values.lastExperience.jobEndDate).format("MMM YYYY") :'Present'
            expObject.notice_period=values.notice_period
            expObject.salary= `${values.last_salary?numberFormat(values.last_salary):"N/a"} ${(values.last_salary && values.last_salary_type>=0)?values.last_salary_type===0?" / month": " / year":''}`
        }
        else if(index >=0){
            expObject.job_role=values.experienceList[index].job_role ||'-'
            expObject.job_category=values.experienceList[index].job_category ||'-'
            expObject.industry=values.experienceList[index].industry ||'-'
            expObject.designation=values.experienceList[index].designation ||'-'
            expObject.company=values.experienceList[index].company ||'-'
            expObject.jobStartDate=moment(values.experienceList[index].jobStartDate).format("MMM YYYY")  ||'-'
            expObject.jobEndDate= moment(values.experienceList[index].jobEndDate).format("MMM YYYY")  ||'-'
        }
        setData(expObject)
    }
    const handleOtherExperiences =(data) =>{
        setFieldValue("temporaryExperience", data)
    }
    useEffect(() => {
        createData();
    }, [values.experienceList, values.lastExperience, values.notice_period, values.last_salary, values.last_salary_type])

    return (
        <Grid  container className="padding0">
            {data && <Grid item xs={8}  className= "padding0">
                <Grid item xs={12}  className= "padding0">
                    <span><b>{data.job_role.name?data.job_role.name:""}</b>{` | ${data.job_category.name?data.job_category.name:""}`} &#9679; {`(${data.industry.name?data.industry.name:""})`}</span>
                </Grid>    
                <Grid item xs={12}  className= "padding0">
                    <span>{`${data.designation?data.designation:""}`}  &#9679; {`${data.company?data.company:""}`}  &#9679; {`${data.salary?data.salary:''}`}</span>
                </Grid>    
                {/* <Grid item xs={12}  className= "padding0">
                    <span>{`${data.jobStartDate} - ${data.jobEndDate}`}</span>
                </Grid>   */}  
                {data.notice_period && <Grid item xs={12}  className= "padding0">
                    <span>{`Notice period: ${data.notice_period} days`}</span>
                </Grid>}    
            </Grid>}
            <Grid item xs={4}  className= "padding0 columnOrder" direction="row-reverse">
                {lastExp && <Button
                    color="secondary"
                    onClick={() =>{
                        let temp= values.delExp
                        temp.push(values.lastExperience.expId)
                        setFieldValue("delExp", temp)  
                        setFieldValue('lastExperience', {})
                        setFieldValue('last_salary', '')
                        setFieldValue('notice_period', '')}}
                    >
                    <DeleteIcon />
                </Button>}
                <Button
                    className="linkButton"
                    onClick={handleEditExp}
                >
                    Edit
                </Button>
            </Grid>
            {openWorkerExpModal && <WorkerExpModal
                openWorkerExpModal={openWorkerExpModal}
                setOpenWorkerExpModal = {setOpenWorkerExpModal}
                lastExp= {lastExp}
                editIndex={index}
                values= {values}
                errors={errors}
                touched={touched}
                editFlag={editFlag}
                handleOtherExperiences={handleOtherExperiences}
                Editing={true}
                setFieldValue={setFieldValue}
                locationList={locationList}
            />}
        </Grid>
    )
}

export default JobComponent
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
const useStyles = {
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

class SimpleSelect extends React.Component{

    constructor(props) {
        super(props);            
        this.state = {
          val:this.props.val,
          type:this.props.type
        }
        this.option = this.props.option; 
    }
    handleChange = event => {
        this.setState({val:event.target.value,type:event.target.value.type},
         ()=>this.props.handleChange(event) 
     );
   };
  render(){
    const { classes } = this.props;
        return (
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label"> Sort By </InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.val}
                    onChange={this.handleChange}
                    >
                    {this.option.map(option => (
                        <MenuItem key={option.id} value={option}>
                            {option.label}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(useStyles)(SimpleSelect);
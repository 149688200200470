import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios'
import {Popover, Button ,Divider , Grid, IconButton, Badge } from '@material-ui/core';
import SelectFilter from '../../../utils/filters/SelectFilter'
import SliderFilter from '../../../utils/filters/SliderFilter'
import DateFilter from '../../../utils/filters/DateFilter'
import LocationFilter from '../../../utils/filters/locationFilter'
import FilterIcon from '../../../assets/icons8-filter-100.png'
import GroupedSelectFilter from '../../../utils/filters/groupedSelectFilter'
import InputTextFilter from '../../../utils/filters/inputTextFilter' 
import urlLinks from '../../../urlLinks'
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const {isFilterApplied, setIsFilterApplied, jobValue} = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState(props.filters)
    const [skillsList, setSkillsList] = useState([])
    const [recruiterList, setRecruiterList] = useState([])
    const [recruiterAdminList, setRecruiterAdminList] = useState([])
    useEffect(() => {
        
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                let list =[
                    {
                        name: "Not assigned",
                        id: 0
                    }
                ]
                response.data.response.map(x =>{
                    const el ={
                        id: x.userID,
                        name: `${x.firstName} ${x.lastName?x.lastName:''}` 
                    }
                    list.push(el)
                })
                setRecruiterList(list)
            })
        }
        else{
            let recList= [{
                    name: "Assigned with recruiter",
                    id: 1
                },
                {
                    name: "Assigned without recruiter",
                    id: 0
                }
            ]
            setRecruiterList(recList)
        }
    }, [jobValue])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}user/getAllAdminRecruiter`)
            .then(response =>{
                let list =[
                    {
                        name: "Not assigned",
                        id: 0
                    }
                ]
                response.data.response.map(x =>{
                    if(x.roleType===1 ||x.roleType===2 | x.roleType===5){
                        const el ={
                            id: x.userID,
                            name: `${x.roleType===1?"Super Admin":x.roleType===2?"Admin":"Recruiter"} - ${x.firstName} ${x.lastName?x.lastName:''}` 
                        }
                        list.push(el)
                    }
                })
                setRecruiterAdminList(list)
            })
    }, [])
    const makeSkillList =data =>{
        let list =[]
        if(data?.length>0){
            data.map(skill =>{
                let  el= {
                    id: skill.skills_type_id,
                    name: skill.skills_name
                }
                list.push(el)
            })
        }
        return list;
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}workermaster/getAllSkills`)
            .then(response =>{
                setSkillsList(makeSkillList(response.data.response))
            })
    }, [])
    console.log({recruiterList})
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    console.log({filters})
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters);
        setIsFilterApplied(false)
        
    }
    const handleApplyFilters =() =>{
        props.updateFilters(filters);
        handleClose();
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters);
        handleClose();
    }
    let popoverData= <Grid></Grid>
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    popoverData= 
        <Grid container>
            <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Assigned to"
                    id="assignedTo"
                    data={recruiterList}
                    multipleFlag={false}
                />
            </Grid>
            <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Added By"
                    id="addedBy"
                    data={recruiterAdminList}
                />
            </Grid>
            <Grid item sm className="padding20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Source"
                    id="source"
                />
            </Grid>
            <Grid item sm className="padding20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Gender"
                    id="gender"
                />
            </Grid>
            <Grid item sm className="padding20">
                <LocationFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="City"
                    id="newCity"
                />
            </Grid>
            <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Skills"
                    id="skills"
                    data={skillsList}
                />
            </Grid>
            <Grid item sm className="padding20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Year of passing"
                    id="year_of_passing"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Total Experience(in months) >"
                    id="experience"
                />
            </Grid>
            <Grid item sm className="padding20">
                <SelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Qualification"
                    id="qualification"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Preferred location"
                    id="preferred_location"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Salary (less than)"
                    id="salary"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Job role"
                    id="jobRole"
                />
            </Grid>
            {/* <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Notice period"
                    id="notice_period"
                />
            </Grid> */}
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Name"
                    id="name"
                />
            </Grid>
            <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Phone number"
                    id="phoneNumber"
                />
            </Grid>
            <Grid item xs={6} className="padding20">
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="Added on to"
                    labelFrom="Added on from"
                    id="added_on"
                />
            </Grid>
            <Grid item xs={6} className="padding20">
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="Last Activity on to"
                    labelFrom="Last Activity from"
                    id="last_activity"
                />
            </Grid>
        </Grid>


    return (
        <div>
            <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
                <IconButton 
                    className="filterButton marginLight20"
                    onClick={handleClick}
                >
                    <img src={FilterIcon} alt="img" />
                </IconButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                <Grid container className="buttonList">
                    <Grid item xs={3}>
                        <span className= "FilterHeading">All worker filters</span>
                    </Grid>
                    <Grid item xs={9} style={{padding: "20px"}} className="flexRowReverse">      
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{marginLeft: "30px"}}
                            onClick={handleResetFilters}
                        >Reset</Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{marginLeft: "30px"}}
                            onClick={handleApplyFilters}
                        >Apply</Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleCancelFilters}
                        >Cancel</Button>
                    </Grid>
                </Grid>
                <Divider />
                {popoverData}
            </Popover>
        </div>
    );
}

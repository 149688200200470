import React,{ useState } from "react"
import { Grid } from '@material-ui/core'
import '../../worker/externalWorker/externalWorker.css'

const 
colWidth = {
    width:  "220px !important",
    marginRight: "30px"
}
,circle = {
    background: "#cccccc",
    width: "9px",
    height: "9px",
    borderRadius: "100%"
},
blueCircle = {
    background: "#1c75bc",
    width: "9px",
    height: "9px",
    borderRadius: "100%"
},
greenCircle = {
    background: "#5cb85c",
    width: "9px",
    height: "9px",
    borderRadius: "100%"
},
redCircle = {
    background: "#d9534f",
    width: "9px",
    height: "9px",
    borderRadius: "100%"
},
Vline ={
    height: "30px",
    width: "2px",
    background: "#cccccc",
    margin: "auto"
},
title = {
    fontFamily: "OpenSans",
    fontSize: "14px",
    color: "#333333",
    fontWeight: "normal"
},
SubTitle = {
    fontFamily: "OpenSans",
    fontSize: "12px",
    color: "#707070",
    fontWeight: "normal",
    marginBottom: "14.5px"
},
sapceBetween = {
    marginRight: "10px"
};

const ApplicationStatus =(props) => {
    const {data} = props

    return(
        <Grid container className="flexDisplay padding0" direction="row" justify="flex-start" style={colWidth}>
            <Grid item xs={2} className="padding0">
                <div class={`${props.isInitial?"containerHistoryFirst":"containerHistory"}`}>
                    <div class={`${props.isInitial?"circleHistoryFirst":"circleHistory"} ${props.circleColor==="Green"?"greenColorBackground":props.circleColor==="Red"?"redColorBackground":props.circleColor==="Blue"?"blueColorBackground":"greyColorBackground"}`} >
                    </div>
                </div>
            </Grid>
            <Grid item xs={10} className="padding0 ">
                {data}
            </Grid>
        </Grid>
    );
}

export default ApplicationStatus
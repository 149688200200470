import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AmazonIcon from '../assets/Amazon_icon-fill.png'
import EmbedIcon from '../assets/embibe_iconi.png'
import NoBrokerIcon from '../assets/nobroker-icon.png'
import ShadiIcon from '../assets/shaadi-icon.png'
import TopperIcon from '../assets/toppr_icon.png'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
        },
        child: {
            width: "15%",
            height: "80px",
            margin: "0px auto"
        }
    }),
);

export default function OurClients() {
    const classes = useStyles();

    return (
        <div className={`${classes.root} ourClients`}>
            <Paper elevation={0} className={classes.child} ><img src={AmazonIcon} className="marginTop8" /> </Paper>
            <Paper elevation={0} className={classes.child} ><img src={EmbedIcon} /> </Paper>
            <Paper elevation={0} className={classes.child} ><img src={NoBrokerIcon} /> </Paper>
            <Paper elevation={0} className={classes.child} ><img src={ShadiIcon} /> </Paper>
            <Paper elevation={0} className={classes.child} ><img src={TopperIcon} /> </Paper>            
        </div>
    );
}

import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import './filter.css'
const useStyles = makeStyles({
 
});

export default function InputTextFilter(props) {
    const {column, row, filters, setFilters}= props
    const id=column.id;
    const classes = useStyles();
    const [value, setValue] = React.useState(filters[id]);
    
    
    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : event.target.value);
    };


    const handleBlur = (event) => {
            setFilters({...filters, [id]: value})
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}  style={{color:"white", marginTop: 1}}>
            <Input
                className={classes.input}
                value={value}
                margin="dense"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                style:{textAlign:"center", color: "white"},
                'aria-labelledby': 'input-slider',
                }}
            />
            </Grid>
        </Grid>
  );
}

import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
// import DatePicker from '../DatePicker/DatePicker';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: this.props.filters.map(element => {
        return { [element.name]: element.default };
      }),

      start: null,
      end: null,
      start_Label: 'Reg-Date-From',
      end_Label: 'Reg-Date-To',
     open:this.props.isOpen,
     anchorEl:<b></b>,
    };
    this.defaultValue = this.state.defaultValue;
    this.getFilterElemnts = this.getFilterElemnts.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleInputChange(name, data) {
    for (let el of this.defaultValue) {
      if (el[name]) {
        el[name] = data;
      }
    }
    this.setState({
      defaultValue: this.defaultValue,
      
    });
  }

  handleRequestClose(event){ 
    this.setState({
      open: !this.state.open,
      anchorEl:event.currentTarget, 
    })
  } 

  // handleClick = event => {
  //   setAn(event.currentTarget);
  // };


  async resetFilters() {
    for (let el of this.defaultValue) {
      const key = Object.keys(el)[0];
      el[key] = [];
    }
    await this.setState(
      {
        defaultValue: this.defaultValue
      },
      () => console.log(this.state.start, this.state.end)
    );
    this.props.updateFilters(this.defaultValue);
  }

  getFilterElemnts(classes) {
    const filters = this.props.filters.map((element, index) => {
      return (
        <Grid item xs={6} key={element.id} className={classes.gridItemStyle}>
          <CustomMultiSelectDropDown
            default={this.state.defaultValue[index][element.name]}
            updatedDefault={element.default}
            data={element[element.name]}
            name={element.name}
            label={element.label}
            updateChange={this.handleInputChange}
          ></CustomMultiSelectDropDown>
        </Grid>
      );
    });
    return filters;
  }
  

  render() {
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        
        {popupState => (
          <div>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
              //onClick={this.handleClick}   
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 1,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >
                {this.getFilterElemnts(this.props.classes)}
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.resetFilters();
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.props.updateFilters(this.defaultValue);
                      this.handleRequestClose();
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);

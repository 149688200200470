import React, { useState, useEffect } from 'react'
import EditableDropdown from '../RecrutierDBElements/EditableDropdownSubComponent'
import EditableInterviewStatus from '../RecrutierDBElements/EditableInterviewStatus'
import EditableTextInput from '../RecrutierDBElements/EditableTextInput'
import SubmitResponseModel from '../../../utils/Modals/submitResponseModel'
import urlLinks from '../../../urlLinks';
import Axios from 'axios';
import MarkLeftModal from './markleftModal'
import {Grid, Button,  Checkbox,  TableSortLabel} from '@material-ui/core';
const ApplicationUpdates =(props) =>{
    // console.log({props})
    const {row , column, updateMyData, showToast, setLoading,jobDetails, disableQuestionnaireButton, quesList} = props
    console.log(row.original)
    const [openSubmitResponseModel, setOpenSubmitResponseModel] = useState(false)
    const [getQuestion, setGetQuestion]= useState();
    const [openJoinedModal, setOpenJoinedModal] = useState(false)
    const [openMarkLeftModal, setOpenMarkLeftModal] = useState(false)
    const [rowData, setRowData]=useState();
    const openSubmitResponse = (row) =>{
        var job_id =row.original.jobId;
        var user_id = row.original.userId;
        const url=`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${job_id}`
        Axios.get(url).then(response=>{
            setRowData(row.original)
            setGetQuestion(response.data);
        })
        setOpenSubmitResponseModel(true);
    }
    
    useEffect(() => {
        console.log("started ApplicationUpdates")
    }, [])

    const handleMarkLeft=() =>{
        setOpenMarkLeftModal(true);
    }
    const openEditResponse = (row) =>{

        var job_id = row.original.jobId;
        var user_id = row.original.userId;
        const url=`${urlLinks.baseUrl}question_controller/getWorkerJobQuestion?job_id=${job_id}&user_id=${user_id}`
        Axios.get(url).then(response=>{
            setRowData(row.original)
            setGetQuestion(response.data.response);
        })
        setOpenSubmitResponseModel(true);
    }
    
    return(
        <Grid container style={{width:"190px"}}>
            {(row.original.interviewMode!==2 && row.original.interviewMode!==3) && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="callStatus"
                        showToast={showToast}
                        initialValue={row.original["callStatus"]}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                    />

            </Grid>}
            {(row.original.interviewMode!==2 && row.original.interviewMode!==3) && quesList>0 && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    {row.original.submitResponse===1? 
                    <Button variant="outlined" color="primary" onClick={() =>openEditResponse(row)}>
                        CHECK / EDIT RESPONSE
                    </Button>:
                    <Button variant="contained" color="primary" onClick={() =>openSubmitResponse(row)} disabled={disableQuestionnaireButton!==2?true:row.original.callStatus!=="INTERESTED"?true:false} >
                        SUBMIT RESPONSE
                    </Button>}
            </Grid>}
            {((row.original.interviewMode && (row.original.interviewMode===1 || row.original.interviewMode===4)) || ((row.original.submitResponse===1 || quesList===0) && !row.original.interviewMode)) && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="approvalStatus"
                        showToast={showToast}
                        initialValue={row.original["approvalStatus"]}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                        quesList={quesList}
                    />
            </Grid>}
            {row.original.approvalStatus==="APPROVED" && !row.original.recruiterFeedback &&  row.original.interviewFollowUp!=="APPEARED" && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="interviewMode"
                        showToast={showToast}
                        initialValue={row.original["interviewMode"]}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                        jobDetails={jobDetails}
                    />
            </Grid>}
            {row.original.interviewMode && row.original.recruiterFeedback!=="SELECTED" &&
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="interviewFollowUp"
                        showToast={showToast}
                        initialValue={row.original["interviewFollowUp"]}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                    />
            </Grid>}
            {row.original.interviewFollowUp==="APPEARED" && !row.original.joiningStatus && 
                <Grid item xs={12} className="padding0 marginBottom20">
                <EditableDropdown
                    row={row.original}
                    columnId="recruiterFeedback"
                    showToast={showToast}
                    initialValue={row.original["recruiterFeedback"]}
                    updateMyData={updateMyData}
                    setLoading={setLoading}
                    jobDetails={jobDetails}
                />
            </Grid>}
            {row.original.finalFeedback==="SELECTED" && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="offerAccepted"
                        showToast={showToast}
                        initialValue={row.original["workerResponse"]===1?"ACCEPTED":row.original["workerResponse"]===2?"REJECTED":""}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                    />
                </Grid>}
            {row.original["workerResponse"]===1 && 
                <Grid item xs={12} className="padding0 marginBottom20">
                    <EditableDropdown
                        row={row.original}
                        columnId="joiningStatus"
                        showToast={showToast}
                        initialValue={row.original["joiningStatus"]}
                        updateMyData={updateMyData}
                        setLoading={setLoading}
                        setOpenMarkLeftModal={setOpenMarkLeftModal}
                    />
                </Grid>}
            {row.original.joiningStatus==="JOINED" &&
                <Grid item xs={12} className="padding0 marginBottom20">
                    <Button
                        className="markLeftButton"
                        onClick={handleMarkLeft}
                    >
                        Mark as left 
                    </Button>
                </Grid>
            }
            {openSubmitResponseModel && getQuestion && <SubmitResponseModel
                openJobModal={openSubmitResponseModel}
                setOpenJobModal={setOpenSubmitResponseModel}
                questionValues={getQuestion}
                data={rowData}
                updateMyData={updateMyData}
            />}
            {openMarkLeftModal && <MarkLeftModal    
                    row={row.original}
                    openMarkLeftModal={openMarkLeftModal}
                    setOpenMarkLeftModal= {setOpenMarkLeftModal}
                    showToast={showToast}
                    updateMyData={updateMyData}
                />

            }
            
        </Grid>
    )
}
export default ApplicationUpdates;
import React, { useState, useEffect } from 'react'
import {Modal, Backdrop, Checkbox} from '@material-ui/core';
import { withStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {
    backgroundColor: "white",
    // border: '2px solid #000',
    height: "50%",
    width: "1025px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)

const AssignToJobModal =(props) =>{
    const {allColumns, getToggleHideAllColumnsProps, openColumnModal, handleColumnsModalClose, setToggleAllColumn, hiddenColumns} = props
    const classes = useStyles();
    if(hiddenColumns.length===0)
      setToggleAllColumn(true)
    else
      setToggleAllColumn(false)
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openColumnModal}
            onClose={handleColumnsModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <div className={`${classes.paper}`}>
              <div className="columnList">
                <div>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
                    All
                </div>
                {allColumns.map(column => (
                    column.id==="selection"?null:
                    <div key={column.id}>
                        <label>
                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                            {column.Header}
                        </label>
                    </div>  
                ))}
              </div>
            </div>
        </Modal>
    )
}

export default AssignToJobModal;
import React, { useState, useEffect } from 'react'
import {Grid, Button, TextField} from '@material-ui/core'
import * as moment from 'moment'
import CallModal from '../../../utils/Modals/CallModal'

const emailBox = {
    border: 'solid 1px #1c75bc',
    backgroundColor: 'rgba(28, 117, 188, 0.1)',
    padding: '0 15px',
    marginBottom: '20px'
},
marginSet = {
    marginBottom: '20px'
};

const Note =(props) =>{
    const data=props.data
    const [callReminderModal, setCallReminderModal] = useState(false)
    let note=null
    let message=""

    
    // bug isolation
    // console.log(data.message.indexOf("\n\n"))
    let emailMessage = data.message.toString();
    console.log(data.message);
    let temp=(data.message).replaceAll("<tab>", "\r\n")
    temp=temp.replaceAll("<br>", "\r\n")
    console.log(temp)
    // console.log(temp.join("<br />"))
//   isolation end
    if(data.template_name==='interview_reminder_tele'){
        message="Interview reminder"
    }
    else if(data.template_name==='selected_for_job'){
        message="Selected for job"
    }
    else if(data.template_name==='job_application'){
        message="Apply for job"
    }
    else if(data.template_name==='interview_reminder_f2f'){
        message="Interview reminder"
    }
    else if(data.template_name==='interview_scheduled_tele'){
        message="Interview scheduled"
    }
    else if(data.template_name==='interview_scheduled_f2f'){
        message="Interview scheduled"
    }
    else if(data.template_name==='interview_reschedule_f2f'){
        message="Interview rescheduled"
    }
    else if(data.template_name==='interview_reschedule_tele'){
        message="Interview rescheduled"
    }
    else if(data.template_name==='job_joined'){
        message="Job joined"
    }
    else if(data.template_name==='rejected'){
        message="Rejected"
    }
    else if(data.template_name==="candidate_dropped"){
        message="Candidate dropped"
    }
    else if(data.template_name==="profile_reject"){
        message="Profile rejected"
    }
    else if(data.template_name==="profile_shared"){
        message="Profile shared"
    }
    else if(data.template_name==="not_shortlisted"){
        message="Not shortlisted"
    }
    else if(data.template_name==="call_status_based"){
        message="Call status"
    }
    else if(data.template_name==="assigned_to_job"){
        message="assigned to job"
    }
    else if(data.template_name==="joining_reminder"){
        message="Call status"
    }
    else if(data.template_name==="left_after_joining"){
        message="Left after joining"
    }
    else if(data.template_name==="not_interested"){
        message="Not interested"
    }
    else if(data.template_name==="application_received"){
        message="Application received"
    }
    else if(data.template_name==="referral_earned"){
        message="Referral earned"
    }





    else if(data.template_name==='job_application'){
        message="Job offer details"
    }
    else if(data.template_name==='f2f_interview_scheduled'){
        message="Interview reminder"
    }
    else if(data.template_name==='telephonic_interview_scheduled'){
        message="Interview reminder"
    }
    else if(data.template_name==="joining_reminder"){
        message="Joining reminder"
    }
    else if(data.template_name==="candidated_joined"){
        message="Candidate joined"
    }
    else if(data.template_name==="candidate_dropped_out"){
        message="Candidate dropped"
    }
    else if(data.template_name==="not_approved"){
        message="Not approved"
    }
    else if(data.template_name==="profile_rejected"){
        message="Profile rejected"
    }
    else if(data.template_name==="application_received"){
        message="Application received"
    }
    else if(data.template_name==="candidate_not_interested"){
        message="Candidate not interested"
    }
    else if(data.template_name==="referral_amount_earned"){
        message="Referral amount earned"
    }
    else if(data.template_name==="download_app_and_apply_for_job"){
        message="Download app and apply for job"
    }
    switch (data.block_type) {
        case "message":
            note=<Grid container style={emailBox}>
                    <Grid item xs={12} className="padding0">
                        <Grid item xs className="padding0">
                            <span>{data.message_type==="EMAIL"?"Email sent by recruiter":"Whatsapp sent by recruiter"}</span>
                        </Grid>
                        <Grid item xs className="padding0">
                            <span>{moment(data.insert_on).format("DD/MM/YYYY hh:mm a")}</span>
                        </Grid>
                    </Grid>
                    <hr/>
                    <Grid item xs={12} className="padding0">
                        <Grid item xs={12} className="padding0">
                            <span>{message}</span>
                        </Grid>
                        <Grid item xs={12} className="padding0">
                            {data.message_type==="EMAIL"?<span style={{whiteSpace: "pre-line"}}>{temp}</span>:""}
                        </Grid>
                    </Grid>
                </Grid>
            break;
        case "note":
            note=<Grid container style={marginSet}>
                    <Grid item xs={12} className="padding0">
                        <span>{data.note}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>{moment(data.insert_on).format("DD/MM/YYYY hh:mm a")}</span>
                    </Grid>
                </Grid>
            break;
        case "remarks":
            note=<Grid container style={marginSet}>
                    <Grid item xs={12} className="padding0">
                        <span style={{whiteSpace: "pre-line"}}>{temp}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>{moment(data.insert_on).format("DD/MM/YYYY hh:mm a")}</span>
                    </Grid>
                </Grid>
            break;
        case "reminder":
            note=<Grid container style={marginSet}>
                    <Grid item xs={12} className="padding0">
                        <span>{`Call reminder set for ${moment(data.rem_time, "HH:mm:ss").format("hh:mm a")} on ${moment(data.rem_date).format("DD/MM/YYYY")}`}</span>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <Button className="bluecolor" onClick={() =>  setCallReminderModal(true)}>Change reminder time</Button>
                    </Grid>
                    <Grid item xs={12} className="padding0">
                        <span>{moment(data.insert_on).format("DD/MM/YYYY hh:mm a")}</span>
                    </Grid>
                </Grid>
            break;

    
        default:
            break;
    }
    return (
        <Grid container>
            <Grid item xs={1} style={{width:"20px"}} className="padding0">
                <div class={`${props.isInitial?"containerHistoryFirst":"containerHistory"}`}>
                    <div class={`${props.isInitial?"circleHistoryFirst":"circleHistory"} ${props.circleColor==="Green"?"greenColorBackground":props.circleColor==="Red"?"redColorBackground":props.circleColor==="Blue"?"blueColorBackground":"greyColorBackground"}`} >
                    </div>
                </div>
            </Grid>
            <Grid item xs={11} className="padding0">
                {note}
            </Grid>
            {callReminderModal && 
                <CallModal 
                    callReminderModal={callReminderModal}
                    setCallReminderModal= {setCallReminderModal}
                    noteData={data}
                    showToast= {props.showToast}
                    row={props.row}
                    getAllReminders={props.getAllReminders}
                />
            }
        </Grid>
        
    )

}

export default Note
import 'date-fns';
import React, { useState, useContext, useEffect } from 'react';
import { Button, Radio, RadioGroup, FormControl, FormControlLabel, Grid, TextField, MenuItem } from '@material-ui/core';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountCircle, ContactsTwoTone, Create } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Register.css';
import Axios from 'axios'
import urlLinks from '../urlLinks';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MultipleSelect } from "react-select-material-ui";
import { AccountContext } from '../_services/user.account';
import Alert from '@material-ui/lab/Alert';
import ProfileImages from '../assets/users/default_profile.png';
import PathIcon from '../assets/Path.png';
import Loader from './Loader';

const cityList = [];
const LanguageComponent = (props) => {
    const { getLanguages } = useContext(AccountContext);
    const [languageOptions, setLanguageOptions] = useState([]);
    useEffect(() => {
        getLanguages().then(data => {
            if (data.response) {
                const indOpt = [];
                data.response.map((language, key) => {
                    return indOpt.push({ value: language.id, label: language.type_value })
                });
                setLanguageOptions(indOpt);
            }
        }).catch(err => {
            console.log('err', err);
        });
    }, []);
    return (
        <MultipleSelect
            fullWidth
            placeholder="Select known languages"
            options={languageOptions}
            name="knownLanguagesList"
            key="knownLanguagesList"
            onChange={props.inputEvent}
            value={props.knownLanguagesList}
            className={` Rectangle-995 multiple-select-option`}
            SelectProps={{
                msgNoOptionsMatchFilter: "No language matches the filter",
            }}
        />
    )
}

const IndustryComponent = (props) => {
    const { getIndustries } = useContext(AccountContext);
    const [industriesOptions, setIndustriesOptions] = useState([]);
    useEffect(() => {
        
        getIndustries()
            .then(data => {
                const indOpt = [];
                data.response.map((industiry, key) => {
                    return indOpt.push({ value: industiry.recruiter_utils_id, label: industiry.utility_name })
                });
                setIndustriesOptions(indOpt);
            }).catch(err => {
                console.log('err', err);
            });
    }, []);

    return (
        <MultipleSelect
            fullWidth
            placeholder="Select from the list of industries and areas of specialisation"
            options={industriesOptions}
            name="industryExperiencesList"
            key="industryExperiencesList"
            onChange={props.inputEvent}
            value={props.industryExperiencesList}
            className={` Rectangle-995 multiple-select-option`}
            style={{ zIndex: 1501 }}
        />
    )
}

const JobsComponent = (props) => {
    const { getJobs } = useContext(AccountContext);
    const [jobOptions, setJobOptions] = useState([]);
    useEffect(() => {
        getJobs()
            .then(data => {
                const indOpt = [];
                data.response.map((job, key) => {
                    return indOpt.push({ value: job.recruiter_utils_id, label: job.utility_name })

                });
                setJobOptions(indOpt);
            }).catch(err => {
                console.log('err', err);
            });
    }, []);

    return (
        <>
            <MultipleSelect
                fullWidth
                placeholder="Select from the list of job functions to choose from"
                options={jobOptions}
                name="jobFunctionsList"
                key="jobFunctionsList"
                onChange={props.inputEvent}
                value={props.jobFunctionsList}
                className={` Rectangle-995 multiple-select-option`}
                style={{ zIndex: 1000 }}
            />
        </>
    )
}

const QualificationComponent = (props) => {

    return (
        <TextField
            onChange={props.inputEvent}
            value={props.qualificationId}
            name="qualificationId"
            fullWidth
            select
            className={` Rectangle-995`}
            variant="outlined"
            label="Select qualification"
        >
            <MenuItem key={5} value={5}>
                In College
            </MenuItem>
            <MenuItem key={4} value={4}>
                Diploma
            </MenuItem>
            <MenuItem key={6} value={6}>
                Graduate
            </MenuItem>
            <MenuItem key={7} value={7}>
                Post Graduate
            </MenuItem>
        </TextField>
    )
}

const CityComponent = (props) => {
    const { getCity } = useContext(AccountContext);
    const [cityOptions, setCityOptions] = useState([]);
    const valop = props.citySelected;

    useEffect(() => {
        getCity().then(data => {
            if (data.response) {
                data.response.sort((a, b) => a.state_name > b.state_name ? 1 : -1);
                var groupBy = data.response.reduce(function (acc, key) {
                    (acc[key['state_name']] = acc[key['state_name']] || []).push(key);
                    return acc;
                }, {});
                const cityDatas = [];
                Object.entries(groupBy).map((cityData, key) => {
                    const cityList = [];
                    cityData[1].sort((a, b) => a.city_name > b.city_name ? 1 : -1).map((values, key) => {
                        if (values.city_id == props.cityId) {
                            console.log(props.cityId)
                        }
                        cityList.push({ value: values.city_id, label: values.city_name, name: "cityId" })
                    })
                    return cityDatas.push({ label: cityData[0], options: cityList })
                });
                setCityOptions(cityDatas);
            }
        }).catch(err => {
            console.log('err', err);
        });
    }, []);
    const formatGroupLabel = data => {
        return (
            <div >
                <span>{data.label}</span>
            </div>
        )
    };
    return (
        <FormControl fullWidth className={` Rectangle-995`}>
            <Select
                // defaultValue={valop}
                style={{ zIndex: 1500 }}
                name="cityId"
                variant="outlined"
                onChange={props.handleCity}
                options={cityOptions}
                formatGroupLabel={formatGroupLabel}
                placeholder="Select city"
            />
        </FormControl>
    )
}

const Register = (props) => {
    const [selectedDate, setSelectedDate] = useState();
    const { addUpdateUser } = useContext(AccountContext);
    const [submitted, setSubmitted] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [profileFile, setProfileFile] = useState();
    const [resumeFile, setResumeFile] = useState();
    const [profileImage, setProfileImage] = useState(ProfileImages);
    const [spinner, setSpinner] = useState(true);

    const history = useHistory();
    const location = useLocation();
    const phonePattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    const types = ['image/png', 'image/jpeg', 'image/gif'];


    ////Added by Hemant
    const [documentTypeList, setDocumentTypeList] = useState()

    const createDocumentList =(documentList) =>{
        let list={}
        documentList.map(document =>{
            switch (document.typeValue) {
                case "cv":
                    list.cv= document.id;
                    break;
                case "Pan Card":
                    list["Pan Card"]= document.id;
                    break;
                case "Profile Photo":
                    list["Profile Photo"]= document.id;
                    break;
                case "Aadhar Card":
                    list["Aadhar Card"]= document.id;
                    break;
                case "Bank Proof":
                    list["Bank Proof"]= document.id;
                    break;
            
                default:
                    break;
            }
            return true;
        })
        setDocumentTypeList(list);
    }



    ////Added by Vaibhav
    
    // const [cvDocId, setCvDocId] = useState(null)
    // const [profileDocId, setProfileDocId] = useState(null)
    // const makeDocumentList = (documentsList) => {
    //     let doc = [];
    //     doc = documentsList;
    //     for (let i = 0; i <= doc.length; i++) {
    //         if(doc[i].typeValue==='cv'){
             
    //             setCvDocId(doc[i].id)
    //         }
    //         if(doc[i].typeValue==='Profile Photo'){
              
    //             setProfileDocId(doc[i].id)
    //         }

            
    //     }


    // }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}/configmaster/getAllDocuments`)
            .then(response => {
                if (response) {
                    console.log({ response })
                    createDocumentList(response.data.response)
                    // makeDocumentList(response.data.response)
                }
            })
            .catch(error => {
                // showToast("error", "Error loading next record")
            })

    }, [])
    ////
    const handleDateChange = (date) => {
        console.log(date);
        let birthDate = null;
        if (date === null) {
            setSelectedDate(null);
            birthDate = null;
        } else {
            setSelectedDate(moment(date).format('MM-DD-YYYY'));
            birthDate = moment(date).format('YYYY-MM-DD');
        }
        setFormInfo((preValue) => {
            return {
                ...preValue,
                dob: birthDate,
            };
        });
    };
    const setProfileFileInput = async (file) => {
        if (types.every(type => file.type !== type)) {
            setErrorMessage(`Only jpg/jpeg and png files are allowed!`);
            return
        } else {
            const objectUrl = URL.createObjectURL(file)
            setProfileImage(objectUrl)
            const base64 = await convertBase64(file);
            const fileName = file.name;
            setProfileFile(() => {
                return {
                    b64EncodedDocument: base64,
                    documentId: null,
                    documentName: fileName,
                    documentTypeId: documentTypeList["Profile Photo"]
                }
            })
        }
    }

    const setResumeFileInput = async (file) => {
        setErrorMessage();
        var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
        if (!allowedExtensions.exec(file.name)) {
            setErrorMessage(`Only docx and pdf files are allowed!`);
            return
        } else {
            const base64 = await convertBase64(file);
            const fileName = file.name;
            let file_size = file.size / 1024 / 1024;
            if (file_size > 5) {
                setErrorMessage("File of size upto 5 MB is allowed.");
            } else {
                setResumeFile(() => {
                    return {
                        b64EncodedDocument: base64,
                        documentId: null,
                        documentName: fileName,
                        documentTypeId: documentTypeList["cv"]
                    }
                })
            }
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    let userInf = '';
    useEffect(() => {
        setTimeout(() => setSpinner(false), 2000)
        if (localStorage.getItem('userInfo') !== 'undefined') {
            userInf = JSON.parse(localStorage.getItem('userInfo'));
        }
    }, []);

    const [formInfo, setFormInfo] = useState({
        recruiterMasterId: '',
        city: '',
        cityId: '',
        dailyCallsTarget: '',
        dataForRecruitment: '',
        dob: '',
        emailId: (location.state) ? location.state.emailId : '',
        externalRecruitingPlatforms: '',
        firstName: '',
        freelanceExp: '',
        freelanceExpMonths: '',
        freelanceExpYears: '',
        haveLaptopSmartPhoneWifi: '',
        hourAvailablePerWeek: '',
        industryExperiences: [],
        isConsent: 1,
        jobFunctions: [],
        knownLanguages: [],
        lastCompany: '',
        lastName: '',
        linkedinProfile: '',
        phoneNumber: '',
        qualificationId: '',
        specialisation: '',
        userId: (location.state) ? location.state.userId : '',
        workExperienceInMonths: '',
        workExperienceInYears: '',
        knownLanguagesList: [],
        industryExperiencesList: [],
        jobFunctionsList: [],
        languageMap: {},
        qualificationMap: {},
        jobFunctionsMap: {},
        industryExperienceMap: {},
        recruiterDocuments: []
    });
    useEffect(() => {
        if (localStorage.getItem('userInfo') !== 'undefined') {
            userInf = JSON.parse(localStorage.getItem('userInfo'));
            setSelectedDate(() => { return (userInf && userInf.dateOfBirth) ? moment(userInf.dateOfBirth).format("MM-DD-YYYY") : null })

            setFormInfo(() => {
                return {
                    recruiterMasterId: (userInf) ? userInf.recruiterMasterId : '',
                    city: (userInf) ? userInf.city : '',
                    cityId: (userInf) ? userInf.cityId : '',
                    dailyCallsTarget: (userInf) ? userInf.dailyCallsTarget : '',
                    dataForRecruitment: (userInf) ? userInf.dataForRecruitment : '',
                    dob: (userInf && userInf.dateOfBirth) ? moment(userInf.dateOfBirth).format("MM-DD-YYYY") : '',
                    emailId: localStorage.getItem('emailId'),
                    externalRecruitingPlatforms: (userInf) ? userInf.externalRecruitingPlatforms : '',
                    firstName: (userInf) ? userInf.firstName : '',
                    freelanceExp: (userInf && userInf.freelanceExp) ? userInf.freelanceExp : "",
                    freelanceExpMonths: (userInf) ? userInf.freelanceExpMonths : '',
                    freelanceExpYears: (userInf) ? userInf.freelanceExpYears : '',
                    haveLaptopSmartPhoneWifi: (userInf && userInf.haveLaptopSmartPhoneWifi) ? userInf.haveLaptopSmartPhoneWifi : "",
                    hourAvailablePerWeek: (userInf) ? userInf.hourAvailablePerWeek : '',
                    industryExperiences: (userInf) ? userInf.industryExperiences : [],
                    isConsent: 1,
                    jobFunctions: (userInf) ? userInf.jobFunctions : [],
                    knownLanguages: (userInf) ? userInf.knownLanguages : [],
                    lastCompany: (userInf) ? userInf.lastCompany : '',
                    lastName: (userInf) ? userInf.lastName : '',
                    linkedinProfile: (userInf) ? userInf.linkedinProfile : '',
                    phoneNumber: (userInf) ? userInf.phoneNumber : '',
                    qualificationId: (userInf) ? userInf.qualificationId : '',
                    specialisation: (userInf) ? userInf.specialisation : '',
                    userId: localStorage.getItem('userId'),
                    workExperienceInMonths: (userInf) ? userInf.workExpMonths : '',
                    workExperienceInYears: (userInf) ? userInf.workExpYears : '',
                    knownLanguagesList: (userInf && userInf.knownLanguages !== null) ? userInf.knownLanguages.split(',') : [],
                    industryExperiencesList: (userInf && userInf.industryExperiences !== null) ? userInf.industryExperiences.split(',') : [],
                    jobFunctionsList: (userInf && userInf.jobFunctions !== null) ? userInf.jobFunctions.split(',') : [],
                    languageMap: (userInf) ? userInf.languageMap : {},
                    qualificationMap: (userInf) ? userInf.qualificationMap : {},
                    jobFunctionsMap: (userInf) ? userInf.jobFunctionsMap : {},
                    industryExperienceMap: (userInf) ? userInf.industryExperienceMap : {},
                    recruiterDocuments: []
                }
            });

        }

        if (localStorage.getItem('emailId') !== 'undefined') {
            setFormInfo((preValue) => {
                return {
                    ...preValue,
                    emailId: localStorage.getItem('emailId'),
                };
            });
        }
    }, [])

    const inputEvent = (event) => {
        const { name, value } = event.target;
        setFormInfo((preValue) => {
            return {
                ...preValue,
                [name]: value,
            };
        });

        setSubmitted(false);
    }

    const handleCity = selectedOption => {
        setFormInfo((preValue) => {
            return {
                ...preValue,
                cityId: selectedOption.value,
                city: selectedOption.label,
            };
        });
    };

    const selectLang = (event) => {
        console.log(event);
        setFormInfo((preValue) => {
            return {
                ...preValue,
                knownLanguagesList: event
            }
        });
    }

    const selectIndustry = (event) => {
        setFormInfo((preValue) => {
            return {
                ...preValue,
                industryExperiencesList: event
            }
        });
    }

    const selectJobs = (event) => {
        setFormInfo((preValue) => {
            return {
                ...preValue,
                jobFunctionsList: event
            }
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmitted(true);
        if (profileFile) formInfo.recruiterDocuments.push(profileFile);
        if (resumeFile) formInfo.recruiterDocuments.push(resumeFile);
        if (formInfo.dob) {

        } else {
            setErrorMessage("Please select date of birth");
            setIsError(false);
        }

        if (formInfo.firstName && formInfo.lastName && formInfo.emailId && formInfo.phoneNumber && formInfo.qualificationId && formInfo.specialisation && formInfo.workExperienceInMonths && formInfo.workExperienceInYears && formInfo.linkedinProfile && formInfo.freelanceExp && formInfo.industryExperiencesList.length > 0 && formInfo.jobFunctionsList.length > 0 && formInfo.hourAvailablePerWeek && formInfo.haveLaptopSmartPhoneWifi && formInfo.dob && formInfo.dataForRecruitment) {

            formInfo.dob = moment(formInfo.dob).format('YYYY-MM-DD');
            formInfo.jobFunctions = formInfo.jobFunctionsList.join(',');
            formInfo.industryExperiences = formInfo.industryExperiencesList.join(',');
            formInfo.knownLanguages = formInfo.knownLanguagesList.join(',');

            var DOB = moment([formInfo.dob]).format("YYYY,MM,DD");
            var today = moment().format("YYYY,MM,DD");
            var totalMonths = parseInt(moment(today).diff(moment(DOB), 'years'));
            if (totalMonths < 18) {
                setErrorMessage("Your age should be more than 18 Years!");
                setIsError(false);
            } else if (!phonePattern.test(formInfo.phoneNumber)) {
                setErrorMessage("Please enter valid phone number.");
                setIsError(false);
            } else {
                setSpinner(true);
                addUpdateUser(formInfo).then(data => {
                    if (data.code) {
                        setErrorMessage('This phone no is already registered with us. Please register using a different phone no.');
                        setIsError(false);
                        setSpinner(false);
                    } else {
                        localStorage.setItem('userInfo', JSON.stringify(data[0]));
                        history.push('/success');
                    }

                }).catch(err => {
                    setSpinner(false);
                    setErrorMessage("Somethig went wrong!");
                    setIsError(false);
                });
            }
        } else {
            setSpinner(false);
            setErrorMessage("Please fill all required information");
            setIsError(false);
        }
    }

    return (
        <>
            {spinner && <Loader />}

            <Header text="Already a member?" link='Login' />
            <form className="Recruiter_create-account" onSubmit={handleSubmit}>
                <Grid container className="Rectangle-989">
                    <Grid item xs={12} className="padding0 Registration-with-OkayGo" >
                        <div className="fontSize32 fontWeightBold">My Profile </div>
                        <div className="padding0 Help-us-get-to-know-you" >
                            Please complete your profile details so that we can assign you relevant vacancies
                    </div>
                    </Grid>
                    {errorMessage ?
                        <Grid item xs={12} className="padding0 " >
                            <Alert variant="filled" severity={isError ? "success" : "error"}>
                                {errorMessage}
                            </Alert></Grid> : ''}
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label  required">First Name</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.firstName ? ' has-error' : '')} >
                            <TextField
                                name="firstName"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                onChange={inputEvent}
                                value={formInfo.firstName}
                                placeholder="First Name"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label  required">Last Name</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.lastName ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.lastName}
                                name="lastName"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="Last Name"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label  required">Email ID</span>
                            <span className="form-label verified">Verified <img src={PathIcon} /></span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.emailId ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.emailId}
                                name="emailId"
                                fullWidth
                                className={` Rectangle-995 bg-gray  `}
                                variant="outlined"
                                placeholder="you@example.com"
                                type="email"
                                id="standard-required"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label  required">Phone Number</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.phoneNumber ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.phoneNumber}
                                name="phoneNumber"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="xxxxxxxxxx"
                                type="number"
                                maxLenth="10"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label  required">Date of Birth</span>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} className={"padding0" + (submitted && !formInfo.dob ? ' has-error' : '')}>
                                <KeyboardDatePicker
                                    helperText={''}
                                    variant="outlined"
                                    maxDate={moment().subtract(18, 'years').format("MM-DD-YYYY")}
                                    maxDateMessage="Your age should be more than 18 years old"
                                    autoOk={true}
                                    fullWidth
                                    margin="normal"
                                    name="dob"
                                    id="date-picker-inline"
                                    className={` Rectangle-995 height40 ${(submitted && !formInfo.dob ? ' has-error' : '')}`}
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    clearable
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">City</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.city ? ' has-error' : '')} >
                            <CityComponent handleCity={handleCity} cityId={formInfo.cityId} citySelected={{ value: formInfo.cityId, label: formInfo.city }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className='field-content' justify="flex-start" alignItems="flex-start" >
                        <Grid xs={6} className="padding0 resume-content"  >
                            <label htmlFor="profileFile" style={{ position: "relative" }} >
                                <img src={profileImage} style={{ width: 120, borderRadius: "50%" }} />
                                <Create style={{ color: '#ffffff', fontSize: 20, backgroundColor: "#1c75bc", borderRadius: "6px", padding: "5px", position: "absolute", bottom: 0, right: 0 }} />
                            </label>
                        </Grid>
                        <Grid item xs={6} className="padding0 resume-content"  >
                            <span className="form-label inline-text">Resume/ CV</span>
                            {resumeFile ? <span style={{ fontSize: 18 }} className="inline-text selectedFile">{resumeFile.documentName} </span> : <span style={{ fontSize: 18, color: '#bbbbbb' }} className="inline-text">No file chosen</span>}
                            <label htmlFor="resumeFile" >
                                <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file"  >{resumeFile ? "Update File" : "Upload File"}</span></label>
                            <span style={{ fontSize: 14, color: '#bbbbbb' }} className="inline-text">PDF or DOC formats; File of size upto 5 MB is allowed.</span>

                        </Grid>
                        <input
                            id="profileFile"
                            style={{ display: 'none' }}
                            type={"file"}
                            onChange={(e) => setProfileFileInput(e.target.files[0])}
                            accept="image/x-png,image/gif,image/jpeg"
                        />
                        <input
                            id="resumeFile"
                            style={{ display: 'none' }}
                            type={"file"}
                            onChange={(e) => setResumeFileInput(e.target.files[0])}
                            accept=".doc,.docx,.pdf"
                        />
                    </Grid>

                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label">Languages known <div className="optional">(optional)</div></span>
                        </Grid>
                        <Grid item xs={12} className="padding0 " >

                            <LanguageComponent knownLanguagesList={formInfo.knownLanguagesList} inputEvent={selectLang} />
                            <div className="all-selected-tab">
                                {formInfo.languageMap && Object.values(formInfo.languageMap).map((lang, index) => (
                                    <span key={index} className="selected-toggle">{(lang) ? lang : ''}</span>
                                ))}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Highest educational qualification</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.qualificationId ? ' has-error' : '')}>

                            <QualificationComponent inputEvent={inputEvent} qualificationId={formInfo.qualificationId} />
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Specialization</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && !formInfo.specialisation ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.specialisation}
                                name="specialisation"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="Ex: Science"
                            />
                        </Grid>
                    </Grid>
                    <div className="devider"></div>
                    <Grid item xs={12} className="padding0 Help-us-get-to-know-you">
                        <span>Almost there. Few more questions</span>
                    </Grid>

                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Total work experience</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && (!formInfo.workExperienceInYears || !formInfo.workExperienceInMonths) ? ' has-error' : '')} >

                            <TextField
                                onChange={inputEvent}
                                value={formInfo.workExperienceInYears}
                                name="workExperienceInYears"
                                fullWidth
                                type="number"
                                className={` MuiGrid-grid-xs-6 year-month-border-right`}
                                variant="outlined"
                                InputProps={{ endAdornment: <InputAdornment position="end">Yrs</InputAdornment> }}
                                onInput={
                                    (e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }
                                }
                            />
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.workExperienceInMonths}
                                name="workExperienceInMonths"
                                fullWidth
                                type="number"
                                className={` MuiGrid-grid-xs-6 year-month-border-left`}
                                variant="outlined"
                                InputProps={{ endAdornment: <InputAdornment position="end">Months</InputAdornment> }}
                                onInput={
                                    (e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label">Company last worked <div className="optional">(optional)</div></span>
                        </Grid>
                        <Grid item xs={12} className="padding0 " >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.lastCompany}
                                name="lastCompany"
                                id="last_work"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="Ex: Zomato"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Linkedin profile</span>
                        </Grid>
                        <Grid item xs={6} className={'form-group' + (submitted && !formInfo.linkedinProfile ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.linkedinProfile}
                                name="linkedinProfile"
                                id="linkedinProfile"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="Please enter linkedin profile"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Do you have experience in freelance recruiting and if yes, how long? </span>
                        </Grid>
                        <Grid item xs={6} className={'form-group field-content'}  >

                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="freelanceExp" onChange={inputEvent} name="freelanceExp" defaultValue={formInfo.freelanceExp}>
                                    <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Yes" className={(submitted && !formInfo.freelanceExp ? ' has-error' : '')} />
                                    <FormControlLabel value={"2"} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {formInfo.freelanceExp === "1" &&
                            <Grid item xs={6} className='field-content' id={formInfo.freelanceExp}  >
                                <Grid item xs={12} className={'form-group' + (submitted && (!formInfo.freelanceExpYears || !formInfo.freelanceExpMonths) ? ' has-error' : '')} >
                                    <TextField
                                        onChange={inputEvent}
                                        value={formInfo.freelanceExpYears}
                                        name="freelanceExpYears"
                                        fullWidth
                                        type="number"
                                        className={` MuiGrid-grid-xs-6 year-month-border-right`}
                                        variant="outlined"
                                        InputProps={{ endAdornment: <InputAdornment position="end">Yrs</InputAdornment> }}
                                        onInput={
                                            (e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                            }
                                        }
                                    />
                                    <TextField
                                        onChange={inputEvent}
                                        value={formInfo.freelanceExpMonths}
                                        name="freelanceExpMonths"
                                        fullWidth
                                        type="number"
                                        className={` MuiGrid-grid-xs-6 year-month-border-left`}
                                        variant="outlined"
                                        InputProps={{ endAdornment: <InputAdornment position="end">Months</InputAdornment> }}
                                        onInput={
                                            (e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                            }
                                        }
                                    />

                                </Grid>

                            </Grid>
                        }
                    </Grid>


                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Choose the industries you have experience in</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && formInfo.industryExperiencesList.length === 0 ? ' has-error' : '')}>
                            <IndustryComponent industryExperiencesList={formInfo.industryExperiencesList} inputEvent={selectIndustry} />
                            <div className="all-selected-tab">
                                {formInfo.industryExperienceMap && Object.values(formInfo.industryExperienceMap).map((lang, index) => (
                                    <span key={index} className="selected-toggle">{lang}</span>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Which job functions have you recruited for before?</span>
                        </Grid>
                        <Grid item xs={12} className={'form-group' + (submitted && formInfo.jobFunctionsList.length === 0 ? ' has-error' : '')} >
                            <JobsComponent setJobOptionsList={formInfo.setJobOptionsList} inputEvent={selectJobs} jobFunctions={formInfo.jobFunctionsList} />
                            <div className="all-selected-tab">
                                {formInfo.jobFunctionsMap && Object.values(formInfo.jobFunctionsMap).map((job, index) => (
                                    <span key={index} className="selected-toggle">{job}</span>
                                ))}
                            </div>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">How many hours per week will you be available for work?</span>
                        </Grid>
                        <Grid item xs={6} className={'form-group' + (submitted && !formInfo.hourAvailablePerWeek ? ' has-error' : '')} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.hourAvailablePerWeek}
                                name="hourAvailablePerWeek"
                                id="hours_per_week"
                                fullWidth
                                select
                                className={` Rectangle-995`}
                                variant="outlined"
                                label="Hour per week"
                            >
                                <MenuItem key="8" value={8}>8</MenuItem>
                                <MenuItem key="16" value={16}>16</MenuItem>
                                <MenuItem key="32" value={32}>32</MenuItem>
                                <MenuItem key="36" value={36}>36</MenuItem>
                                <MenuItem key="40" value={42}>40</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Do you want to use your own data or our data for recruitment ? </span>
                        </Grid>
                        <Grid item xs={6} className="padding0 "  >
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="dataForRecruitment" onChange={inputEvent} name="dataForRecruitment" defaultValue={formInfo.dataForRecruitment}>
                                    <FormControlLabel value="SELF" control={<Radio color="primary" />} label="Self" className={'form-group' + (submitted && !formInfo.dataForRecruitment ? ' has-error' : '')} />
                                    <FormControlLabel value="OKAYGO_DATA" control={<Radio color="primary" />} label="OkayGo data" />
                                    <FormControlLabel value="BOTH" control={<Radio color="primary" />} label="Both" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label required">Please confirm if you have Laptop, Smart phone and Wi-fi </span>
                        </Grid>
                        <Grid item xs={6} className={'form-group'} >
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="haveLaptopSmartPhoneWifi" onChange={inputEvent} name="haveLaptopSmartPhoneWifi" defaultValue={formInfo.haveLaptopSmartPhoneWifi}>
                                    <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Yes" className={'form-group' + (submitted && !formInfo.haveLaptopSmartPhoneWifi ? ' has-error' : '')} />
                                    <FormControlLabel value={"2"} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} className='field-content'  >
                        <Grid item xs={12} className="padding0 " >
                            <span className="form-label">Any external recruiting platform(s) you are a part of <div className="optional">(optional)</div></span>
                        </Grid>
                        <Grid item xs={12} className={'form-group'} >
                            <TextField
                                onChange={inputEvent}
                                value={formInfo.externalRecruitingPlatforms}
                                name="externalRecruitingPlatforms"
                                id="plateform"
                                fullWidth
                                className={` Rectangle-995`}
                                variant="outlined"
                                placeholder="Name of the platform(s)"
                            />
                        </Grid>
                    </Grid>
                    {/* <div className="devider"></div> */}

                    <Grid item xs={12} className='field-content action-button'  >
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            className="save-button"

                        >Submit</Button>
                    </Grid>
                </Grid>
            </form>
            {/* <Footer /> */}
        </>
    );
}

export default Register;
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import qs from 'querystring';
import Styles from '../index.module.scss'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
// import Styles from '../index.module.scss'
import { ScaleLoader } from "react-spinners";
import { CSVLink } from "react-csv";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {open, setOpen, row, jobDetails, updateStatus, type } = props
  const [rejectReasonList, setRejectReasonList] = useState([])
  const [rejectDetails, setRejectDetails] = useState({
      rejectedBy:"admin",
      rejectedReason:"select",
      rejectedOther:"",
      rejectedOtherReason:""
  })
  const [disableDone, setDisableDone] = useState(true)
  const handleClose =() => {
      setOpen(false);
  }
  const createReasonList =(data) =>{
    let list = []
    data.map(el =>{
      if(el.typeValue!=="Others"){
        list.push(el.typeValue)
      }
    })
    setRejectReasonList(list);
  }
  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}configmaster/?category_type=reject_reason`)
      .then(response =>{
        createReasonList(response.data.response.content)
      })
  }, [])
  console.log({row})
  const handleDone =() =>{
    const postData={
        assign_id : row.assignId,
        updated_by: localStorage.getItem('userId'),
        
    }
    if(type==="interviewMode"){
      postData.rejected_by_role= rejectDetails.rejectedBy
    }
    else{
      postData.rejected_by_role= "employer"
    }
    if(rejectDetails.rejectedBy==="admin"){
        postData.rejected_by= parseInt(jobDetails.accountManager)
    }else{
        postData.rejected_by= parseInt(jobDetails.userId)
    }
    if(rejectDetails.rejectedOther){
        postData.rejection_reason= rejectDetails.rejectedOtherReason
    }
    else{
        postData.rejection_reason= rejectDetails.rejectedReason
    }
    Axios.post(`${urlLinks.baseUrl}job/assigner/updateRejectionReason`, qs.stringify(postData))
            .then(response =>{
                updateStatus();
                setOpen(false);
            })
            .catch(error =>{
                props.showToast("error", error.message)
            })
  }
  useEffect(() => {
      if(rejectDetails.rejectedReason!=="select" || (rejectDetails.rejectedOther && rejectDetails.rejectedOtherReason)){
          setDisableDone(false)
      }
      else{
        setDisableDone(true)
      }
  }, [rejectDetails])
  const handleOthers =(event) =>{
    setRejectDetails({...rejectDetails, rejectedOther: event.target.checked, rejectedReason: "select"})
  }
  const handleRejectReason =(event) =>{
    setRejectDetails({...rejectDetails, rejectedReason: event.target.value})
  }
  const handleRejectedBy =(event) =>{
    setRejectDetails({...rejectDetails, rejectedBy: event.target.value})
  }
  const handleOtherText =(event) =>{
    setRejectDetails({...rejectDetails, rejectedOtherReason: event.target.value})
  }
  const loader=<div style={{margin:"130px auto", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            classes={{paper: Styles.paper}}
            // className={Styles.paper}
        >
            <DialogTitle id="customized-dialog-title" onClose={() =>setOpen(false)}>
                <span>{type==="interviewMode"?"Profile":"Candidate"} Rejected</span>
            </DialogTitle>
            <DialogContent dividers>
                {type==="interviewMode" && <Typography variant="overline"  display="block">
                    Choose who rejected the candidate
                </Typography>}
                {type==="interviewMode" && <FormControl component="fieldset" className="margin0">
                    <RadioGroup row name="rejectBy"  value={rejectDetails.rejectedBy} onChange={handleRejectedBy}>
                        <FormControlLabel value="admin" control={<Radio color="primary" />} label="Admin" />
                        <FormControlLabel value="employer" control={<Radio color="primary" />} label="Employer" />
                    </RadioGroup>
                </FormControl>}
                <Typography variant="overline" display="block" gutterBottom>
                    Select the reason for rejection
                </Typography>
                {rejectReasonList.length>0 && <Select
                    id="rejectReason"
                    value={rejectDetails.rejectedReason}
                    disableUnderline
                    variant= "outlined"
                    style={{marginRight:"24px"}}
                    disabled={rejectDetails.rejectedOther}
                    onChange={handleRejectReason}
                    inputProps={{
                        classes: {
                            icon: "selectIcon",
                        },
                    }}
                    >
                        <MenuItem value="select" disabled={true}>Reason for rejection</MenuItem>
                        {rejectReasonList.map(reason => 
                          <MenuItem value={reason}>{reason}</MenuItem>
                        )}
                </Select>}
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={rejectDetails.rejectedOther}
                        onChange={handleOthers}
                        name="others"
                        color="primary"
                    />
                    }
                    label="Others"
                />
                {rejectDetails.rejectedOther && <Grid container>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Enter the reason"
                        value={rejectDetails.rejectedOtherReason}
                        onChange={handleOtherText}
                        className="marginLeft0"
                        rows={5}
                        multiline
                        fullWidth
                        variant="outlined"
                    />
                </Grid>}
            </DialogContent>
            <DialogActions>
                <button
                    className={Styles.blueOutlinedButton}
                    onClick={handleClose}
                >
                CANCEL
                </button>
                <button
                    disabled={disableDone}
                    onClick={handleDone}
                    className={Styles.blueContainedButton}
                >
                    CONFIRM
                </button>
            </DialogActions>
        </Dialog>
  
  

  return dialogData; 
  
}


// import React, { useEffect, useState } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
// import Axios from 'axios';
// import document from './document.svg'
// import Typography from "@material-ui/core/Typography";
// import * as moment from 'moment'
// import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import UrlLinks from '../../urlLinks'
import UploadBulkResponseModal from './UploadBulkResponseModal'
// import Download from './Group 3238.svg'
// import { set } from "date-fns";

// import Paper from '@material-ui/core/Paper';

// export default function UploadBulkDialog(props) {
//   const { showToast, updateMyData, closeBulkModal, userID, jobId } = props
//   const [excel, setExcel] = useState(null)
//   const [name, setName] = useState("")
//   const [excelError, setExcelError] = useState(false);
//   const [isLoading, setIsLoading] = useState(false)
//   const [fileLink, setFileLink] = useState(null)
//   const [responseData, setResponseData] = useState()
//   const [open, setOpen] = React.useState(props.open);
//   const [openBulkResponseModal, setOpenBulkResponseModal] = useState(false)
//   const handleClose = () => {
//     setOpen(false);
//     closeBulkModal()
//     // updateMyData()
//   } 
//   const handleUpload = (event) => {
//     const fileName = event.target.files[0].name.split(".").slice(-1)[0];
//     if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
//       setExcel(event.target.files[0]);
//       setName(event.target.files[0].name)
//       setExcelError(false)
//     }
//     else {
//       setExcelError(true)
//       setExcel(null);
//       setName("");
//     }
//     setResponseData();

//   }

//   const handleOpenModal = () => {
//     setOpenBulkResponseModal(true)
//   }
//   const handleCloseModal = () => {
//     setOpenBulkResponseModal(false)
//   }
//   const handleExcel = () => {
//     setIsLoading(true)
//     let formData = new FormData();
//     formData.append("file", excel);

//     Axios.post(`${UrlLinks.baseUrl}/recruiter/uploadBulkWorkerData?insertedBy=` + userID + `&jobId=` + jobId, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       }
//     })
//       .then(response => {
//         console.log({ response });
//         if (response.data) {
//           // setInvalidData(createInvalidData(response.data));
//           const summary = response.data.response
//           console.log({ summary })
//           setResponseData(summary);
//           updateMyData();
//           setIsLoading(false)
//           handleOpenModal()
//           console.log(openBulkResponseModal)
//           // handleClose()
//         }
//         else {
//           setResponseData([]);
//         }
//       })
//       .catch(error => {
//         // console.log({error})
//         setIsLoading(false)
//         showToast("error", error.message);
//       })
//   }

//   let dialogData =
//     <Dialog
//       aria-labelledby="customized-dialog-title"
//       open={open}
//       fullWidth={true}
//       disableBackdropClick={true}
//     >
//       <DialogTitle id="customized-dialog-title" onClose={handleClose}>
//         <span>Upload Bulk Woker Data</span> <a
//           href={fileLink}
//           target="_blank"
//         >
//         </a>
//       </DialogTitle>
//       <DialogContent dividers>
//         <Grid item xs={12} className="padding0">
//           <Grid container spacing={3}>
//             <Grid item xs={8}>
//               <input
//                 style={{ display: "none" }}
//                 id="contained-button-file"
//                 type="file"
//                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//                 onChange={handleUpload}
//               />
//               <label htmlFor="contained-button-file">
//                 <Button
//                   variant="outlined"
//                   className="uploadButton marginLight20"
//                   component="span"
//                   startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
//                 >
//                   Browse File
//                       </Button>
//               </label>
//               {/* <Grid item xs={4}> */}
//               <Grid item xs={12} className="padding0">
//                 <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>only .xls and .xlsx are allowed</span>
//               </Grid>
//             </Grid>
//             <Grid item xs={4}>
//               {name && <span ><span>File name: </span>{`${name}`}</span>}</Grid>
//           </Grid>
//         </Grid>
//         <Grid container spacing={3}>
//           <Grid item xs={8}>
//             <Paper>xs=8</Paper>
//           </Grid>
//           <Grid item xs={4}>
//             <Paper>xs=4</Paper>
//           </Grid>
//         </Grid>
//       </DialogContent>
//       <DialogActions>
//         {isLoading ? <span>Please wait... it may take few minutes</span> : <Button variant="contained" onClick={handleExcel} className={`${!excel ? "disabledButton" : "blueButtonContained"}`} disabled={!excel}>
//           Upload
//               </Button>}
//         <Button onClick={handleClose} color="primary" autoFocus>
//           Close
//           </Button>
//       </DialogActions>
//       {openBulkResponseModal && <UploadBulkResponseModal
//         openBulkResponseModal={openBulkResponseModal}
//         setOpen={setOpen}
//         responseData={responseData}
//         setOpenBulkResponseModal={setOpenBulkResponseModal}
//         showToast={showToast}
//         open={true}
//         handleCloseParent={handleClose}
//         // invalidData={invalidData}
//         type="Onboarding"
//       />}
//     </Dialog>



//   return dialogData;

// }
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from './document.svg'
// import ResponseModal from './ResponseModal'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from './index.module.scss'
// import urlLinks from "../../UrlLinks";
import Download from './Group 3238.svg'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { closeBulkModal, userID, jobId } = props
  const { openOnboardingModal, setOpenOnboardingModal, showToast, updateMyData } = props
  const [open, setOpen] = React.useState(props.open);
  const [excel, setExcel] = useState(null)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)
  const [responseData, setResponseData] = useState()
  // console.log({excel})
  // const fileLink = `http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/templates/worker_bulk_upload.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210127T115914Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=AKIAI5OYSIY7VDJWHSQA%2F20210127%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=d7c27b8358ec29193810df99ae0df2a0180ac2041528b0715c26874156f5c576`
  const handleClose = () => {
    // setOpenOnboardingModal(false);
    closeBulkModal()
    setOpen(false)

  }
  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}/admin/getSignedUrl?fileName=document/templates/worker_bulk_upload_excel.xlsx`,null , {timeout:60*5*1000})
      .then(response =>{
        // console.log({response})
        setFileLink(response.data.response)
      })

  }, [])
  const handleUpload = (event) => {
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name)
      setExcelError(false)
    }
    else {
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();

  }
  const createInvalidData = (records) => {
    // console.log({records})
    if (records.length > 0) {
      console.log("draw")
      let list = [];
      // let count = 0;
      // if (count < 1) {
      records.map(record => {// This is executing (records.length)*(records.length) times, I dont know why!
        // count++;c
        // if (record.hasOwnProperty("invalid_uploads_data")) {
        console.log("ICAME AGAIN");
        // if (record.WorkerDetail.isWorkerCreated === 0) {
        // for (let i = 0; i < records.length; i++) {
        // console.log("----> " + i)

        // records.
        list.push({
          "First Name":record["firstName"] == null ? '' :record["firstName"],
          "Last Name":record["lastName"] == null ? '' :record["lastName"],
          "Gender":record["gender"] == null ? '' :record["gender"],
          "Contact No":record["contactNo"] == null ? '' :record["contactNo"],
          "Email":record["email"] == null ? '' :record["email"],
          "WhatsApp No":record["whatsAppNo"] == null ? '' :record["whatsAppNo"],
          "City":record["city"] == null ? '' :record["city"],
          "Highest Qualification":record["highestQualification"] == null ? '' :record["highestQualification"],
          "Specialisation":record["specialisation"] == null ? '' :record["specialisation"],
          "Total Experience(in months)":record["totalExperience"] == null ? '' :record["totalExperience"],
          "Current/Last Company":record["lastCompany"] == null ? '' :record["lastCompany"],
          "Designation":record["designation"] == null ? '' :record["designation"],
          "Notice Period(in Days)":record["noticePeriod"] == null ? '' :record["noticePeriod"],
          "Annual Salary":record["annualSalary"] == null ? '' :record["annualSalary"],
          "Currently Working":record["currentlyWorking"] == null ? '' :record["currentlyWorking"],
          "Laptop":record["laptop"] == null ? '' :record["laptop"],
          "Smart Phone":record["smartPhone"] == null ? '' :record["smartPhone"],
          "Wifi":record["wifi"] == null ? '' :record["wifi"],
          "Driving License":record["drivingLicense"] == null ? '' :record["drivingLicense"],
          "Bike":record["bike"] == null ? '' :record["bike"],
          "Aadhaar Card":record["aadhaarCard"] == null ? '' :record["aadhaarCard"],
          "Pan Card":record["panCard"] == null ? '' :record["panCard"],
          "Reason Of Faliure":record["reasonOfFaliure"] == null ? '' :record["reasonOfFaliure"]

        })
        // }
        console.log(list)
        // }
        return true;

      })
      // }

      return list.length > 0 ? list : null;
    }
  }
  const handleExcel = () => {
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(`${UrlLinks.baseUrl}/recruiter/uploadBulkWorkerData?insertedBy=` + userID + `&jobId=` + jobId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(response => {
        if (response.data) {
          setInvalidData(createInvalidData(response.data.response["invalid_uploads_data"]))

          setResponseData(response.data.response)
          updateMyData();
          setIsLoading(false)
          setOpenResponseModal(true)
        }
        else {
          setResponseData([]);
        }
      })
      .catch(error => {
        // console.log({error})
        setIsLoading(false)
        showToast("error", error.message);
      })
  }

  let dialogData =
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Bulk Worker Data</span> <a
          href={fileLink}
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span><img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        {/* <RadioGroup  adioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel label="New workers" control={<Radio color="primary" />} value="new" />
                  <FormControlLabel label="Existing workers" control={<Radio color="primary" />} value="existing" />
              </RadioGroup> */}
        <Grid item xs={12} className="padding0">

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className="uploadButton"
              component="span"
              startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
            >
              Browse File
                      </Button>
          </label>
          {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
        </Grid>
        <Grid item xs={12} className="padding0" style={{marginTop: "16px"}}>
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>only .xls and .xlsx are allowed</span>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? <span>Please wait... it may take few minutes</span> : <Button variant="contained" onClick={handleExcel} className={`${!excel ? "disabledButton" : "blueButtonContained"}`} disabled={!excel}>
          Upload
              </Button>}
  
      </DialogActions>
      {openResponseModal && <UploadBulkResponseModal
        openResponseModal={openResponseModal}
        setOpenResponseModal={setOpenResponseModal}
        responseData={responseData}
        setOpenOnboardingModal={handleClose}
        showToast={showToast}
        invalidData={invalidData}
        type="Onboarding"
      />}
    </Dialog>



  return dialogData;

}

import React, { useState, useEffect } from 'react'
import { Modal, Button, Backdrop, Slide, TextField, Grid, FormControl, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '../SelectComponent/Select'
import urlLinks from '../../urlLinks';
import Axios from 'axios';
import ApprovalConfirmationModal from '../../utils/Modals/ApprovalConfirmationModal'
import { makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';
import locationIds from '../filters/locationIds'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const AssignToJobModal =(props) =>{
    const {showToast, openRemarkModal, setOpenRemarkModal, status, row, handleCancel, quesList, updateStatus, setShowButtons} = props
    const classes = useStyles();
    const [remarkStatus, setRemarkStatus] = useState(status)
    const [disableConfimation, setDisableConfimation] = useState(true)
    const [jobRoleList, setJobRoleList]=useState()
    console.log({showToast, openRemarkModal, setOpenRemarkModal, status})
    const [approvalConfirmationModal, setApprovalConfirmationModal] = useState(false)
    const [remarkIssues, setRemarkIssues] = useState({
        lowSalary: false,
        notInterestedInJobType:false,
        locationTooFar: false,
        skillMismatch: false,
        communicationMismatch:false,
        other: false
    })
    const [remarkIssuesValues, setRemarkIssuesValues] = useState({
        lowSalary: 0,
        notInterestedInJobType: null,
        locationTooFar: null,
        other: ''
    })
    const remarkStatusList =[
        ['NOT_INTERESTED', "Not Interested"],
        ["NOT_APPROVED", 'Not Approved']
    ]
    const handleRemarkIssues =(event) =>{
        setRemarkIssues({...remarkIssues, [event.target.name]: event.target.checked})
    }
    useEffect(() => {
        setRemarkStatus(status)
    }, [status])

    const checkboxGroup= status==="NOT_INTERESTED"?
        <FormControl component="fieldset" >
            <FormGroup>
                <Grid container>
                    <Grid item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.lowSalary} onChange={handleRemarkIssues} name="lowSalary" />}
                            label="Low salary"
                        />
                    </Grid>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.notInterestedInJobType} onChange={handleRemarkIssues} name="notInterestedInJobType" />}
                            label="Not interested in job type"
                        />
                    </Grid>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.locationTooFar} onChange={handleRemarkIssues} name="locationTooFar" />}
                            label="Location too far"
                        />
                    </Grid>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.other} onChange={handleRemarkIssues} name="other" />}
                            label="Other"
                        />
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>:
        <FormControl component="fieldset" >
            <FormGroup>
                <Grid container>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.skillMismatch} onChange={handleRemarkIssues} name="skillMismatch" />}
                            label="Skill Mismatch"
                        />
                    </Grid>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.communicationMismatch} onChange={handleRemarkIssues} name="communicationMismatch" />}
                            label="Communication Mismatch"
                        />
                    </Grid>
                    <Grid  item xs={6} className="padding0">
                        <FormControlLabel
                            control={<Checkbox checked={remarkIssues.other} onChange={handleRemarkIssues} name="other" />}
                            label="Other"
                        />
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    const handleRemarkIssuesValues =(event) =>{
        setRemarkIssuesValues({...remarkIssuesValues, [event.target.name]: event.target.value})
    }
    const handleRemarkModalClose =() =>{
        handleCancel();
        setOpenRemarkModal(false)
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}/configmaster/?inserted_by=0&page_no=0&rows=500&type_desc=job_type_name`)
            .then(response =>{
                let jobs=[]
                response.data.response.content.map(job =>
                    jobs.push({
                        id:job.id,
                        jobRole: job.categorySubType
                    })    
                )
                setJobRoleList(jobs)
            })
        
    }, [])
    const disableConfirmRemark= () =>{
        let disableStatus=true
        let salaryDisable= false;
        let jobRoleDisable= false;
        let locationDisable= false;
        let otherDisable= false;
        let commDisable= false;
        let skillDisable= false;
        
        const remarkSelected= Object.values(remarkIssues).find(element=> element === true)
        if(remarkSelected)
            disableStatus=false
        // if((remarkIssues.lowSalary && remarkIssuesValues.lowSalary) 
        //     || (remarkIssues.notInterestedInJobType && remarkIssuesValues.notInterestedInJobType) 
        //     || (remarkIssues.locationTooFar && remarkIssuesValues.locationTooFar) 
        //     || (remarkIssues.other && remarkIssuesValues.other))
        //     disableStatus=false
        // else if((remarkIssues.lowSalary && !remarkIssuesValues.lowSalary) 
        //     || (remarkIssues.notInterestedInJobType && !remarkIssuesValues.notInterestedInJobType) 
        //     || (remarkIssues.locationTooFar && !remarkIssuesValues.locationTooFar) 
        //     || (remarkIssues.other && !remarkIssuesValues.other))
        //     disableStatus=true
        
        if(remarkIssues.lowSalary && remarkIssuesValues.lowSalary) 
            salaryDisable=false
        else if(remarkIssues.lowSalary && !remarkIssuesValues.lowSalary) 
            salaryDisable=true
        if((remarkIssues.notInterestedInJobType || remarkIssues.skillMismatch) && remarkIssuesValues.notInterestedInJobType) 
            jobRoleDisable=false
        else if((remarkIssues.notInterestedInJobType || remarkIssues.skillMismatch) && !remarkIssuesValues.notInterestedInJobType) 
            jobRoleDisable=true
        if(remarkIssues.locationTooFar && remarkIssuesValues.locationTooFar) 
            locationDisable=false
        else if(remarkIssues.locationTooFar && !remarkIssuesValues.locationTooFar) 
            locationDisable=true
        if(remarkIssues.other && remarkIssuesValues.other)
            otherDisable=false
        else if(remarkIssues.other && !remarkIssuesValues.other)
            otherDisable=true
        disableStatus=(salaryDisable || jobRoleDisable || locationDisable ||  otherDisable || disableStatus)
        setDisableConfimation(disableStatus)
    }
    useEffect(() => {
        disableConfirmRemark();
    }, [remarkIssues, remarkIssuesValues])

    const handleSubmitRemark =() =>{
        let postData={
            "assign_id": row.assignId,
            "communication_issue": remarkIssues.communicationMismatch?1:0,
            "inserted_by": parseInt(localStorage.getItem('userId')),
            "job_id": row.jobId,
            "remark_status": status,
            "skill_mismatch": remarkIssues.skillMismatch?1:0,
            "worker_id": parseInt((row.workerId).toString().replace(/[a-zA-Z\-]/g, ''))
        }
        if(row.recruiterId){
            postData["recruiter_id"]=row.recruiterId
        }
        if(remarkIssuesValues.other){
            postData["other"]= remarkIssuesValues.other
        }
        if(remarkIssuesValues.lowSalary){
            postData["low_salary"]= parseInt(remarkIssuesValues.lowSalary)
        }
        if(remarkIssuesValues.notInterestedInJobType){
            postData["preferred_job_type_id"]= remarkIssuesValues.notInterestedInJobType.id
            postData["preferred_job_type"]= remarkIssuesValues.notInterestedInJobType.jobRole
        }
        if(remarkIssuesValues.locationTooFar){
            postData["preferred_location_id"]= remarkIssuesValues.locationTooFar.id
            postData["preferred_location"]= remarkIssuesValues.locationTooFar.city_name
        }   
        Axios.post(`${urlLinks.baseUrl}extWorker/remark`, postData)
            .then(response =>{
                if (response.data.code === 1000) {
                    showToast("success", "Remark added successfully");
                    setOpenRemarkModal(false)
                    if(status==="NOT_APPROVED"){
                        setApprovalConfirmationModal(true)
                    }
                } else {
                    showToast("error", response.data.message);
                }
            })
            .catch(error =>{
                showToast("error", error.message);
            })


    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openRemarkModal}
            onClose={handleRemarkModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={openRemarkModal} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                    <Grid container>
                        <span className="fontSize18 fontWeightBold marginVertical20">Remarks</span>
                    </Grid>
                    <Grid container>
                        <Select 
                            options={remarkStatusList}
                            value={remarkStatus}
                            setValue={setRemarkStatus}
                            disable={true}
                        />
                    </Grid>
                    {checkboxGroup}
                    {
                        remarkIssues.lowSalary && 
                        <Grid container>
                            <TextField
                                label="Preferred salary"
                                name="lowSalary"
                                value={remarkIssuesValues.lowSalary}
                                onChange={handleRemarkIssuesValues}
                            />
                        </Grid>
                    }
                    {
                        (remarkIssues.notInterestedInJobType || remarkIssues.skillMismatch) && 
                        <Grid container>
                            <Autocomplete
                                name="notInterestedInJobType"
                                options={jobRoleList.sort((a, b) => -b.jobRole.localeCompare(a.jobRole))}
                                groupBy={(option) => option.jobRole[0]}
                                value={remarkIssuesValues.notInterestedInJobType}
                                onChange={(event, value) => setRemarkIssuesValues({...remarkIssuesValues, notInterestedInJobType: value})}
                                getOptionLabel={(option) => option.jobRole}
                                style={{ width: "250px" }}
                                renderInput={(params) => <TextField {...params} label="Preferred job type" />}
                            />
                        </Grid>
                    }
                    {
                        remarkIssues.locationTooFar && 
                        <Grid container>
                            <Autocomplete
                                name="locationTooFar"
                                options={locationIds.sort((a, b) => -b.state_name.localeCompare(a.state_name))}
                                groupBy={(option) => option.state_name}
                                value={remarkIssuesValues.locationTooFar}
                                onChange={(event, value) => setRemarkIssuesValues({...remarkIssuesValues, locationTooFar: value})}
                                getOptionLabel={(option) => option.city_name}
                                style={{ width: "250px" }}
                                renderInput={(params) => <TextField {...params} label="Preferred Location" />}
                            />
                        </Grid>
                    }
                    {
                        remarkIssues.other && 
                        <Grid container>
                            <TextField
                                label="Give reason"
                                name="other"
                                multiline= {true}
                                variant="outlined"
                                fullWidth
                                rows={5}
                                value={remarkIssuesValues.other}
                                onChange={handleRemarkIssuesValues}
                            />
                        </Grid>
                    }
                    <Grid container>
                        <Button color="primary" disabled={disableConfimation} onClick={handleSubmitRemark} >Confirm</Button>
                        <Button color="secondary" onClick={handleRemarkModalClose} >Cancel</Button>
                    </Grid>
                    {
                        approvalConfirmationModal && <ApprovalConfirmationModal
                            approvalConfirmationModal={approvalConfirmationModal}
                            setApprovalConfirmationModal={setApprovalConfirmationModal}
                            row={row}
                            handleCancel= {handleCancel}
                            updateStatus={updateStatus}
                            quesList={quesList}
                            status={status}
                            setShowButtons={setShowButtons}
        
                        />
                    }
                </div>
            </Slide>
        </Modal>
    )
}

export default AssignToJobModal;
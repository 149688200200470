import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useLocation } from 'react-router';
//import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
//import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 800,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export default function AddEmployerCommment(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    category: 'Onboarding Review',
    comment: '',
    error:'',
    err:false
  });
  const categorymap = {
    'Onboarding Review': 232,
    'Screening Review': 233,
    'Rating Review': 234,
    'Inactivity Review': 235,
    'Payments': 236
  }
  const propsRecieved=props.location;
  const location = useLocation();
  const userId = location.id
  if(!userId) {
    props.history.push('/worker/workerList');
  }
  // console.log(userId)
  const handleChange =  event => {
    const target = event.target;
    // console.log(target.name);
    const name =target.name;
    setState({
      ...state,
      [name]: target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   if(state.comment.length===0){
    setState({
      ...state,
      error : "Please enter comments!!",
      err : true,
     });
   }
   else{
    setState({
      ...state,
      error : "",
      err : false,
     });
   }
   const values = {
    "categoryId": categorymap[state.category],
    "comment": state.comment,
    "requestedBy":localStorage.getItem('userId'),
    "userId": +userId
  }
   Axios.post(`${urlLinks.baseUrl}/admin/comment`, values)
   .then(response => props.history.push({
    pathname: '/worker/workerList/commentSection',
    id: +userId,
  }))
  };

  return (
    <div>
      <h1>Add Comments</h1>
      <FormControl className={classes.formControl}>
    <InputLabel htmlFor="cat">category</InputLabel>
    <Select
      native
      value={state.category}
      onChange={(event)=>{handleChange(event)}}
      inputProps={{
        name: 'category',
        id: 'cat',
      }}
    >
      {Object.keys(categorymap).map(el => <option>{el}</option>)}
    </Select>
    
      </FormControl>
      <h3>Comment</h3>
      <form className={classes.root} onSubmit={(e)=>{handleSubmit(e)}} noValidate autoComplete="off">
      <TextField
      name="comment"
      id="outlined-multiline-static"
      multiline
      rows="4"
      onChange={(event)=>{handleChange(event)}}
      variant="outlined"
      error={state.err}
      helperText={state.error}

    />
      <div>
      <Button variant="contained" type="submit" color="primary" className={classes.button}>
        Submit
      </Button>
      </div>
      </form>
      
    </div>
  
  );
}
import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import  '../tables.css'
import urlLinks from '../../../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import qs from 'querystring';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const InterviewModal =(props) =>{
    const {statusData, setStatusData, showToast, updateMyData, setLoading, openJoinedStatus, setOpenJoinedStatus,interviewId,  label} = props
    const classes = useStyles();
    console.log({statusData})
    const confirmJoinedStatus =() =>{
      setOpenJoinedStatus(false)
    }

    const handlejoinedStatusClose =() =>{
        setOpenJoinedStatus(false)
    }
    
    
    return (
        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openJoinedStatus}
                onClose={handlejoinedStatusClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openJoinedStatus} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                    
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={label}
                        format="dd-MM-yyyy"
                        value={statusData.joiningdate}
                        onChange={date => setStatusData({...statusData, joiningdate: `${moment(date).format("YYYY-MM-DD")} 00:00:00`})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    
                    </Grid>
                </MuiPickersUtilsProvider>
                <Button color="primary"  onClick={confirmJoinedStatus} >Confirm Date</Button>
                <Button color="secondary" onClick={handlejoinedStatusClose} >Cancel</Button>
                </div>
                </Slide>
            </Modal>
    )
}

export default InterviewModal;
import React, {useState, useEffect} from "react"
import { Grid, Button, Modal, Backdrop, TextField } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ShowReminderModal from './showReminderModal'
import * as moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import urlLinks from '../../../urlLinks'
import Axios from 'axios'
import qs from 'querystring'


const CallModal = (props) => {
  const {data, handleOk, handleSnooze, jobValue} = props;
  const [openShowReminderModal, setOpenShowReminderModal] = useState(false)

    useEffect(() => {
        if(!data.snooze_time){
            if(moment().format("DD/MM/YYYY")===moment(data.rem_date).format("DD/MM/YYYY") && moment().format("HH:mm")=== moment(data.rem_time, "HH:mm:ss").format("HH:mm")){
                setOpenShowReminderModal(true)
            }
        } 
        else if(data.snooze_time){
            if(moment().format("DD/MM/YYYY")===moment(data.snooze_date).format("DD/MM/YYYY") && moment().format("HH:mm")=== moment(data.snooze_time, "HH:mm:ss").format("HH:mm")){
                setOpenShowReminderModal(true)
            }
        }
    }, [])
  return (
      <div>
        {openShowReminderModal &&
            <ShowReminderModal
                data={data}
                jobValue={jobValue}
                handleOk={handleOk}
                handleSnooze={handleSnooze}
                openShowReminderModal={openShowReminderModal}
                setOpenShowReminderModal={setOpenShowReminderModal}
            />
        }
    </div>
  )
};

export default CallModal;

// export const AWSConstants = {
//     AWS_COGNITO_POOL_ID: 'us-east-1_fbjg9DHqK',
//     AWS_COGNITO_CLIENT_ID: '77t8nl8orrmd2kh595j2ri7870'
// };
// export const AWSConstants = {
//     AWS_COGNITO_POOL_ID: 'ap-south-1_KVe4zinZ2',
//     AWS_COGNITO_CLIENT_ID: '4na0837dm69fphirfej9nqcnu3'
// };

export const AWSConstants = {
    AWS_COGNITO_POOL_ID: 'ap-south-1_CPLohlvHS',
    AWS_COGNITO_CLIENT_ID: '76jgma994d3cd3pr37i8pt3knu'
};
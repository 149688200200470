import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import './filter.css'
const useStyles = makeStyles({
 
});

export default function InputSlider(props) {
    
  const classes = useStyles();
  const {column, row, filters, setFilters}= props
  const id=column.id;
  const [value, setValue] = React.useState(filters[id]);
  let max=5;
  if(props.column.id==="totalGigs")
    max=100;
  else if(props.column.id==="expInYears")
    max=40;
  else if(props.column.id==="offeredSalary")
    max=50000
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = (event) => {
      setFilters({...filters, [id]:value})
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}  className="marginTop13 filterSlider" style={{paddingBottom: 0}}>
          <Slider
            min={0}
            step={1}
            onChangeCommitted={handleBlur}
            max={max}
            style={{color:"white"}}
            margin="dense"
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item xs={12} className="marginTop-20" style={{color:"white"}}>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              style:{textAlign:"center", color: "white"},
              step: 1,
              min: 0,
              max: {max},
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
